/// <reference path="ProgressIndicatingControl.js" />
/// <reference path="DOMAttached.js" />
/// <reference path="GenericSounds.js" />

var ListBase = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, Object.extend({
			currentPage: 0,
			lastPage: false,
			pagingEffect: 'none',
			fixDimensions: true,
			reversePagingEffect: false,
			noProgressIndicator: false,
			legacy: true,
			defaultHeight: "120px",
			removeButtonsForOnePageLists: false,
			maxHeight: 0
		}, options));

		this.el.attachedObj = this;
		this.listEl = this.el.down('.fxListWrapper');
		if (GenericSoundsInstance == null) {
			new GenericSounds(this.options.FeedbackSoundOn);
		}
		
		if(this.options.lazyLoaded) {
			//All lists are initialized with a base height to make them look better during loading
			this.listEl.style.height = this.options.defaultHeight;
				
			// The list has not been rendered and needs to be lazy-loaded
			this.loadPage(this.options.currentPage);
		}
		
		if (this.listEl == undefined) {
			this.listEl = this.el.down('ul');
		}
		
		this.prevBtn = this.el.select('.prevBtn');
		this.nextBtn = this.el.select('.nextBtn');
		
		if (options.buttonsMustBeChildren == true) {
			var children = this.el.childElements();
			
			for (var i = 0; i < children.length; i++) {
				if (children[i].hasClassName('prevBtn'))
					this.prevBtn = new Array(children[i]);
					
				if (children[i].hasClassName('nextBtn'))
					this.nextBtn = new Array(children[i]);
			}
		}
			
		this.setBtnVisibility();
		this.prevBtn.each(function(e) {
			this.safeObserve(e, 'click', this.onPrevBtn.bindAsEventListener(this));
			this.safeObserve(e, 'doubleclick', this.onPrevBtn.bindAsEventListener(this));
		}.bind(this));
		
		this.nextBtn.each(function(e) {
			this.safeObserve(e, 'click', this.onNextBtn.bindAsEventListener(this));
			this.safeObserve(e, 'doubleclick', this.onNextBtn.bindAsEventListener(this));
		}.bind(this));
		
		this.disablePaging = false;
		this.dimensionsFixed = false;

		if (this.options.progressDotsControl != null && typeof (this.options.progressDotsControl) != 'undefined') {
			this.setupDotNavigation();
		}

		this.registerUnloadAction(this.cleanUp.bind(this));
	},

	setupDotNavigation: function() {
		var children = $(this.options.progressDotsControl).childElements();

		for (var i = 0; i < children.length; ++i) {
			this.safeObserve(children[i], 'click', this.onDotClick.bindAsEventListener(this));
			if (i == this.options.currentPage) {
				children[i].writeAttribute('src', this.options.progressDotsFullDotImageUrl);
			}
		}
	},

	resetDots: function() {
		var children = $(this.options.progressDotsControl).childElements();

		for (var i = 0; i < children.length; ++i) {
			if (children[i].readAttribute('src') != this.options.progressDotsEmptyDotImageUrl) {
				children[i].writeAttribute('src', this.options.progressDotsEmptyDotImageUrl);
			}
		}
	},
	
	onDotClick: function (ev) {
		// set all dots to default image (empty)
		this.resetDots();

		// set full dot image for clicked element
		ev.currentTarget.writeAttribute('src', this.options.progressDotsFullDotImageUrl);

		// which page to load?
		var classNames = $w(ev.currentTarget.className);
		for (var i = 0; i < classNames.length; ++i) {
			if (classNames[i].startsWith('dot_')) {
				var page = classNames[i].substring(4);
				this.loadPage(page);
				return;
			}
		}

	},

	cleanUp: function() {
		this.listEl = null;
		this.prevBtn = null;
		this.nextBtn = null;
		this.newList = null;
	},
	
	setBtnVisibility: function () {
		this.prevBtn.each(function (e) {
			// Originally, this only used the deactivated css class
			// to indicate controls which should not be used.
			// However, to be bootstrap compatible, we want to use the
			// disabled class instead. However, because some legacy JS and
			// CSS relies on the deactivated class, for now we just add
			// and remove both classes.
			if (this.options.currentPage == 0 && !this.options.circle) {
				e.addClassName("deactivated");
				e.addClassName("disabled");
			} else {
				e.removeClassName("deactivated");
				e.removeClassName("disabled");
			}
			
			this.enableButton(e, this.options.currentPage != 0 || this.options.circle);
		}.bind(this));
		this.nextBtn.each(function(e) {
			if (this.options.lastPage && !this.options.circle) {
				e.addClassName("deactivated");
				e.addClassName("disabled");
			} else {
				e.removeClassName("deactivated");
				e.removeClassName("disabled");
			}
				
			this.enableButton(e, !this.options.lastPage || this.options.circle);
		}.bind(this));
		
		if (this.options.removeButtonsForOnePageLists) {
			if (this.options.lastPage && this.options.currentPage == 0) {
				this.prevBtn.each(function (e) {
					e.style.visibility='hidden';
					e.addClassName("mia-u-remove");
				});

				this.nextBtn.each(function (e) {
					e.style.visibility='hidden';
					e.removeClassName("mia-u-remove");
				});

				this.el.style.paddingTop = "0px";
				this.el.style.paddingBottom = "0px";

				var buttonWrappers = this.el.select(".mia-ListHeaderFooter");
				buttonWrappers.each(function(w) {
					if (!w.hasClassName("legacyControl")) {
						w.remove();
					}
				});
			}
		}
	},
	
	enableButton: function(btn, enabled) {
		if (this.options.buttonDeactivation == "TurnGrey") {
			if (!enabled) {
				btn.style.backgroundImage = btn.style.backgroundImage.replace("_grey.png", ".png").replace(".png", "_grey.png");
				btn.style.cursor = "default";
			} else {
				btn.style.backgroundImage = btn.style.backgroundImage.replace("_grey.png", ".png");
				btn.style.cursor = "pointer";
			}
		} else if (this.options.buttonDeactivation == "Remove") {
			if (enabled)
				btn.show();
			else
				btn.hide();
		}
	},
	
	onPrevBtn: function(ev) {
		if (this.disablePaging) return;
		if (!this.dimensionsFixed && this.options.fixDimensions)
			this.fixDimensions();
		if (this.options.currentPage > 0) {
			this.disablePaging = true;
			this.loadPage(this.options.currentPage - 1);
			if (this.options.pagingEffect == 'none') GenericSoundsInstance.clickSound();
		} else if (this.options.circle) {
			this.disablePaging = true;
			this.loadPage(this.options.count - 1);
		}
	},
	
	onNextBtn: function(ev) {
		if (this.disablePaging) return;
		if (!this.dimensionsFixed && this.options.fixDimensions)
			this.fixDimensions();
		if (!this.options.lastPage) {
			this.disablePaging = true;
			this.loadPage(this.options.currentPage + 1);
			if (this.options.pagingEffect == 'none') GenericSoundsInstance.clickSound();
		} else if (this.options.circle) {
			this.disablePaging = true;
			this.loadPage(0);
		}
	},
	
	fixDimensions: function() {
		if (this.options.legacy)
			return;
			
		this.dimensionsFixed = true;
			
		if (global.isIE) {
			var elDims = this.el.getDimensions();
	
			this.el.setStyle({
				height: elDims.height + 'px',
				width: elDims.width + 'px'
			});

			var newDims = this.el.getDimensions();
			var diffX = newDims.width - elDims.width;
			var diffY = newDims.height - elDims.height;
			if (diffX != 0 || diffY != 0) {
				this.el.setStyle({
					height: (elDims.height - diffY) + 'px',
					width: (elDims.width - diffX) + 'px'
				});
			}
		} else {
			this.el.setStyle({
				height: this.el.getStyle('height'),
				width: this.el.getStyle('width')
			});
		}
	},
	
	resetDimensions: function() {
	    this.el.setStyle({height: "", width: ""});
	    this.listEl.setStyle({height: "", width: ""});
	    this.dimensionsFixed = false;
	},
	
	// page is an HTML snippet
	// oldPageNum can be null but some effects might not work then.
	displayPage: function(page, oldPageNum) {
		this.newList = new Element('div');
		this.newList.update(page);
		this.newList = this.newList.down();
		
		if (!this.options.legacy && this.dimensionsFixed) {
			if (!Object.isUndefined(this.listEl)) {
				var elDim = this.listEl.getDimensions();
				this.newList.setStyle({
					height: elDim.height + "px",
					width: elDim.width + "px"
				});
			}
		}

		if (this.options.progressDotsControl != null && typeof (this.options.progressDotsControl) != 'undefined') {
			var children = $(this.options.progressDotsControl).childElements();
			if (oldPageNum != null) {
				children[oldPageNum].writeAttribute('src', this.options.progressDotsEmptyDotImageUrl);
			}
			children[this.options.currentPage].writeAttribute('src', this.options.progressDotsFullDotImageUrl);
		}
	  	
		if (!global.isIE6 &&
				(this.options.pagingEffect == 'horizSlide' ||
				this.options.pagingEffect == 'vertSlide' ||
				this.options.pagingEffect == 'fade' ||
				this.options.currentPage == oldPageNum)) {

			this.listEl.setStyle({ position: 'absolute' });
			this.listEl.insert({after: this.newList});
			this.newList.setStyle({ visibility: 'hidden' });

			if (this.options.pagingEffect == 'vertSlide') {
				this.stretchPagesToUniformHeight();
			}
			
			if (this.options.currentPage != oldPageNum && (this.options.pagingEffect == 'horizSlide' || this.options.pagingEffect == 'vertSlide')) {
				var edge1;
				var edge2;
				if (this.options.pagingEffect == 'horizSlide') {
					edge1 = (oldPageNum < this.options.currentPage ? 'left' : 'right');
					edge2 = (oldPageNum < this.options.currentPage ? 'right' : 'left');
				} else {
					edge1 = (oldPageNum < this.options.currentPage ? 'top' : 'bottom');
					edge2 = (oldPageNum < this.options.currentPage ? 'bottom' : 'top');
				}
				if (this.options.reversePagingEffect || (this.options.circle && ((this.options.currentPage == 0 && oldPageNum != 1) || (this.options.currentPage == this.options.count - 1 && oldPageNum == 0)))) {
					var tmp = edge1;
					edge1 = edge2;
					edge2 = tmp;
				}
				GenericSoundsInstance.longWhooshSound();
				var effDuration = 1.0;
				new Effect.Parallel([
					new Effect.Slide(this.listEl, { out:true, edge:edge1, duration: effDuration, reserveSpace: true }),
					new Effect.Slide(this.newList, { out:false, edge:edge2, duration: effDuration, reserveSpace: true })
				], {
					duration: effDuration,
					afterFinish: (function() {
						this.removeElement(this.listEl);
						this.listEl = this.newList;
						this.newList = undefined;
						this.reenablePaging();
						
						if (this.options.legacy) {
							//YAL: Correct problem with list resizing
							this.listEl.style.height = "";
							this.listEl.style.width = "";
						}

						if (global.isIE7) {
							this.listEl.style.display = "none";
							setTimeout(this.renderPageIE7.bind(this), 1);
						}
					}).bind(this)
				});
			} else {
				this.listEl.style.opacity = 0.0;//IE10 Hack, up to Prototype supports this
				this.listEl.setOpacity(0.0);
				this.newList.style.opacity = 1.0;//IE10 Hack, up to Prototype supports this
				this.newList.setOpacity(1.0);
				this.newList.setStyle({visibility:'visible'});

				this.removeElement(this.listEl);
				this.listEl = this.newList;
				this.newList = undefined;
				this.reenablePaging();
				
				if (this.options.legacy) {
					//YAL: Correct problem with list resizing
					this.listEl.style.height = "";
					this.listEl.style.width = "";
				}

				if (global.isIE7) {
					this.listEl.style.display = "none";
					setTimeout(this.renderPageIE7.bind(this), 1);
				}
				
				/*
				new Effect.Parallel([
					new Effect.Fade(this.listEl, { from:1.0,to:0.0,duration:.05 }),
					new Effect.Fade(this.newList, { from:0.0,to:1.0,duration:.05 })
				], {
					duration: .05,
					afterFinish: (function() {
						this.removeElement(this.listEl);
						this.listEl = this.newList;
						this.newList = undefined;
						this.reenablePaging();
						
						if (this.options.legacy) {
							//YAL: Correct problem with list resizing
							this.listEl.style.height = "";
							this.listEl.style.width = "";
						}

						if (global.isIE7) {
							this.listEl.style.display = "none";
							setTimeout(this.renderPageIE7.bind(this), 1);
						}
					}).bind(this)
				}); */
			}
		} else {
			this.listEl.insert({after: this.newList});
			this.removeElement(this.listEl);
			this.listEl = this.newList;
			this.reenablePaging();
			
			if (this.options.legacy) {
				//YAL: Correct problem with list resizing
				this.listEl.style.height = "";
				this.listEl.style.width = "";
			}

			if (global.isIE7) {
				this.listEl.style.display = "none";
				setTimeout(this.renderPageIE7.bind(this), 1);
			}					
		}
		
	  this.onLoadPage(this.newList);
		
		if (this.options.pagingEffect == "returnToTop") {
			if (!Object.isUndefined(this.el)) {
				var position = this.el.cumulativeOffset()[1]-32;
				window.scrollTo(0,position);
			}
		}
	},

	stretchPagesToUniformHeight: function () {
		if(this.listEl.getDimensions().height >= this.options.maxHeight) {
			this.options.maxHeight = this.listEl.getDimensions().height;
			this.newList.setStyle({ height: this.options.maxHeight + "px" });
		} else {
			this.listEl.setStyle({ height: this.options.maxHeight + "px" });
		}
	},
	
	standardJSONRequest: function(url) {
		if ((this.blockRequests != undefined) || this.requestRunning)
			return;
		this.requestRunning = true;
		var req = new Ajax.Request(url, {
			method: 'get',
			onSuccess: this.standardJSONSuccess.bind(this),
			parameters: {
				nocache: Math.random()
			}
		});
	},
	
	standardJSONSuccess: function(req) {
		this.requestRunning = false;
		var json = req.responseText.evalJSON();

		if (json.html == undefined || json.html.length == 0)
			return;

		var oldPage = this.options.currentPage;
		var html = json.html;
		json.html = undefined;
		this.options = Object.extend(this.options, json);
		this.setBtnVisibility();
		this.displayPage(html, oldPage);
	},
	
	reenablePaging: function(req) {
		this.disablePaging = false;
	},
	
	refresh: function() {
		this.disablePaging = true;
		this.loadPage(this.options.currentPage);
	},

	// Overriding function should call displayPage(...) to display the page once it's loaded.
	// Overriding function should set lastPage.
	// Overriding function should set currentPage.
	// Instead of all of the above, the overriding function can let standardJSONRequest take care of everything.
	loadPage: function(page) {
	    ListBase.startedLoadingLists = true;
		ListBase.nrRunningRequests++;
		if (!this.options.noProgressIndicator) {
			if (this.options.customProgressIndicator)
				this.showProgressIndicator("div", false);
			else
				this.showProgressIndicator("div.listCtrl", false);
		}
	},
	
	onLoadPage: function(pageEl) {
		if (!this.options.noProgressIndicator) this.hideProgressIndicator();
		ListBase.nrRunningRequests--;
		this.el.fire('list:criteriaChanged', { options: this.options });
		setTimeout(this.fixDimensions.bind(this),10);
	},
	
	renderPageIE7 : function() {
		this.listEl.style.display = "block";
	}
});
ListBase.nrRunningRequests = 0;
ListBase.addMethods(ProgressIndicatingControl);
