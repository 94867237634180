/// <reference path="SearchBox.js" />

var GamesSearchBox = Class.create(SearchBox, {
	initialize: function ($super, el, options) {
		$super(el, options);

		this.listSelector = ".mia-SearchGameList";
		this.categoriesSelector = ".mia-AllGames";
	},

	onSearchButtonClick: function ($super, event) {
		$super(event);
		var searchText = this.inputBox.value;

		if (searchText) {
			jQuery(this.listSelector).show();
			jQuery(this.categoriesSelector).hide();
		} else {
			jQuery(this.categoriesSelector).show();
			jQuery(this.listSelector).hide();
		}
	}
});
