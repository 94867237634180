/// <reference path="DOMAttached.js" />

var HideableControl = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.hidden_control = $(options.hidden_control);
		this.replacement = $(options.replacement);
		this.trigger = $(options.trigger);
		
		this.hidden_control.toggle();
		
		this.safeObserve(this.trigger, 'click', this.change.bindAsEventListener(this));
	}, 
	change: function() {
		this.replacement.toggle();
		this.hidden_control.toggle();
	}
});