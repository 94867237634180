/// <reference path="DOMAttached.js" />

var JSTabSelector = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.tabs = $(options.Tabs);
		
		for(var i = 0; i < this.tabs.length; i++) {
			var button = $(this.tabs[i].button);
			var content = $(this.tabs[i].content);
			
			if(i > 0) {
				content.hide();
				button.setStyle({opacity:'0.5'});
			}
			
			this.safeObserve(button, 'click', this.change.bindAsEventListener(this, button, content));
		}
	}, 
	change: function(e, button, content) {
		for(var i = 0; i < this.tabs.length; i++) {
			$(this.tabs[i].content).hide();
			$(this.tabs[i].button).setStyle({opacity:'0.5'});
			
		}
		content.show();
		button.setStyle({opacity:'1'});
	}
});