/// <reference path="DOMAttached.js" />
/// <reference path="ActivePlazasList.js" />

var SearchBox = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);

		this.inputBox = $(options.inputBox);
		this.searchButton = $(options.searchButton);
		this.previousSearch = this.inputBox.value;
		this.safeObserve(this.searchButton, 'click', this.onSearchButtonClick.bindAsEventListener(this));
	},

	onSearchButtonClick: function (event) {
		var searchText = this.inputBox.value;
		// stop event propagation, i.e. don't reload the page
		event.stop();

		if (searchText === this.previousSearch) {
			return;
		}

		this.previousSearch = searchText;

		var headings = document.getElementsByClassName("categoryheading");
		for (var i = 0; i < headings.length; i++) {
			headings[i].style.display = "none";
		}

		var activePlazaListToNotify = ActivePlazasListLastInstances[this.options.activePlazaListToNotify];
		if (!!activePlazaListToNotify) {
			activePlazaListToNotify.loadPage(0, searchText);
		}
		var list = jQuery('#' + this.options.activePlazaListToNotify);
		list.trigger('searchList', [searchText]);
	}
});
