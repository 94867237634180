/// <reference path="DOMAttached.js" />

var PostingVisibility = Class.create(DOMAttached, {
	initialize: function($super, el) {
		$super(el);

		this.all = this.el.down('.all');
		this.friends = this.el.down('.friends');
		this.friendslist = this.el.down('ul');
		
		this.safeObserve(this.all, 'click', this.applyConstraints.bindAsEventListener(this));
		this.safeObserve(this.friends, 'click', this.applyConstraints.bindAsEventListener(this));
		
		if (this.all.checked) {
			this.friends.checked = true;
		}
		
		PostingVisibility.instance = this;
		
		this.applyConstraints();
	},
	
	applyConstraints: function() {
		if (this.all.checked) {
			this.friends.disabled = true;
			this.friends.checked = false;
		} else {
			this.friends.disabled = false;
		}
		
		if (this.friendslist != undefined) {
			if (this.friends.checked || this.all.checked) {
					this.friendslist.select('input').each(function (el) { el.disabled = true; el.checked = false; });
					if(typeof(FriendslistCreateOnTheFly) != 'undefined' && typeof(FriendslistCreateOnTheFly.instance) != 'undefined') {
						FriendslistCreateOnTheFly.instance.staticEnable(false);
					}
			} else {
					this.friendslist.select('input').each(function (el) { el.disabled = false; });
					if(typeof(FriendslistCreateOnTheFly) != 'undefined' && typeof(FriendslistCreateOnTheFly.instance) != 'undefined') {
						FriendslistCreateOnTheFly.instance.staticEnable(true);
					}
			}
		}		
	},
	
	uncheckAllAndFriends : function() { //remote uncheck
		this.all.checked = false;
		this.friends.checked = false;
		this.applyConstraints();
	}
});