var MiniMeSpeechBubble = Class.create({
	namespace: "http://www.w3.org/2000/svg",
	
	initialize: function (svgDoc, bubble) {
		this.bubble = document.createElementNS(this.namespace, "image");
		this.bubble.setAttribute("x", bubble.x);
		this.bubble.setAttribute("y", bubble.y);
		this.bubble.setAttribute("width", bubble.width);
		this.bubble.setAttribute("height", bubble.height);
		this.bubble.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', bubble.src);
		this.bubble.setAttribute("style", "opacity:0");
		svgDoc.appendChild(this.bubble);

		this.fadeInSpeechbubble();
		window.setTimeout(function() {
			this.fadeOutSpeechbubble();
		}.bind(this), 3000);
	},
	
	fadeInSpeechbubble: function() {
		while (parseFloat(this.bubble.style.opacity) < 1) {
			this.bubble.style.opacity = parseFloat(this.bubble.style.opacity) + 0.02;
			window.setTimeout(function () {
				this.fadeInSpeechbubble();
			}.bind(this), 10);
		}
	},

	fadeOutSpeechbubble: function() {
		if (parseFloat(this.bubble.style.opacity) >= 0.1) {
			this.bubble.style.opacity = parseFloat(this.bubble.style.opacity) - 0.02;
			window.setTimeout(function () {
				this.fadeOutSpeechbubble();
			}.bind(this), 10);
		} else {
			this.bubble.style.opacity = 0;
			this.bubble.style.display = "none";
		}
	}
});
