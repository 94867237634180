/*
	Saves a svg image as a png image on our server.
	1) converts svg to canvas, using canvg library
	2) send base64 encoded png to server, using canvas.toDataUrl()
*/

var SaveableSVG = Class.create({
	initialize: function (svgDoc, saveUrl, callback) {
		this.svgDoc = svgDoc;
		this.saveUrl = saveUrl;
		this.callback = callback;
	},

	saveSVG: function (parameters, ids, version) {
		if (MiniMeLastVersionSaved == version) return;

		this.parameters = parameters;
		this.ids = ids;
		this.version = version;

		this.first = false;

		this.convertToCanvas();
	},

	convertToCanvas: function () {
		this.createCanvas();
		this.createCanvasTransparent();

		canvg("miniMeCanvas", this.getXMLString(this.svgDoc), { ignoreMouse: true, ignoreAnimation: true, renderCallback: this.sentAjaxRequest.bind(this) });

		var clone;
		if (window.DOMParser) {
			parser = new DOMParser();
			clone = parser.parseFromString(this.getXMLString(this.svgDoc), "image/svg+xml");
		}
		else // Internet Explorer
		{
			clone = new ActiveXObject("Microsoft.XMLDOM");
			clone.async = false;
			clone.loadXML(this.getXMLString(this.svgDoc));
		}
		clone.getElementById("background").style.display = "none";
		canvg("miniMeCanvasTransparent", this.getXMLString(clone), { ignoreMouse: true, ignoreAnimation: true, renderCallback: this.sentAjaxRequest.bind(this) });
	},

	createCanvas: function () {
		this.canvas = document.getElementById("miniMeCanvas");
		if (this.canvas == null) {
			this.canvas = document.createElement("canvas");
			this.canvas.id = "miniMeCanvas";
			this.canvas.style.display = "none";
			this.canvas.width = 510;
			this.canvas.height = 727;
			document.body.insertBefore(this.canvas, document.body.childNodes[0]);
		}
	},

	createCanvasTransparent: function () {
		this.canvasTransparent = document.getElementById("miniMeCanvasTransparent");
		if (this.canvasTransparent == null) {
			this.canvasTransparent = document.createElement("canvas");
			this.canvasTransparent.id = "miniMeCanvasTransparent";
			this.canvasTransparent.style.display = "none";
			this.canvasTransparent.width = 510;
			this.canvasTransparent.height = 727;
			document.body.insertBefore(this.canvasTransparent, document.body.childNodes[0]);
		}
	},

	sentAjaxRequest: function () {
		if (!this.first) {
			this.first = true;
			return;
		}

		var parameters = this.parameters;
		var ids = this.ids;
		var version = this.version;

		var normal = this.canvas.toDataURL();
		var transparent = this.canvasTransparent.toDataURL();

		var minCanvasSize = 35000;
		if (normal.length < minCanvasSize || transparent.length < minCanvasSize) {
			return;
		}

		var that = this;
		var req = new Ajax.Request(this.saveUrl, {
			method: 'post',
			asynchronous: true,
			parameters: {
				pngBase64: normal,
				pngBase64Transparent: transparent,
				settings: Object.toJSON(MiniMeSettings),
				accsettings: parameters,
				accuniqueId: ids
			},
			onSuccess: function (transport) {
				document.fire('miniMe:hideProgressIndicator');
				if (transport.responseJSON.success) {
					MiniMeLastVersionSaved = version;
					document.fire('miniMe:showSuccessfulSaveBubble');					
				} else {
					that.callback();
				}
			}
		});
	},

	getXMLString: function (svg) {
		return (new window.XMLSerializer()).serializeToString(svg);
	}
});
