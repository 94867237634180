/// <reference path="DOMAttached.js" />

var ControlSwitcher = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		this.initiallyShownControl = $(options.initiallyShownControl);
		this.initiallyHiddenControl = $(options.initiallyHiddenControl);
		this.clickableControl = $(options.clickableControl);
		this.safeObserve(this.clickableControl, 'click', this.onClick.bindAsEventListener(this));
	},

	onClick: function(ev) {
		ev.preventDefault();
		this.initiallyShownControl.setStyle({
			display: 'none'
		});
		this.initiallyHiddenControl.setStyle({
			display: 'block'
		});
	}
});
