/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />

var DragonWorldServerInterface = null;

var DragonWorld = Class.create(DOMAttached, {
  initialize: function($super, el, options) {
    $super(el, options);
    //DragonHatching
    this.hatchedDragonType = "PLH_hatchedDragonType";
	//CustomizeDragon
    this.dragonHatID = "PLH_dragonHatID";
	this.dragonBeardID = "PLH_dragonBeardID";
	this.dragonColorID = "PLH_dragonColorID";
	//saveDragonStatsAndIncreaseHatchingCounter
	this.dragonStat1 = "PLH_dragonStat1";
    this.dragonStat2 = "PLH_dragonStat2";
	this.dragonStat3 = "PLH_dragonStat3";
	this.dragonStat4 = "PLH_dragonStat4";
	this.dragonStat5 = "PLH_dragonStat5";
	//saveDragonItems
	this.items = "PLH_dragonWorldItemList";
	//save currentChallengePosition
	this.currentChallengePosition = "PLH_dragonWorldCurrentChallengePosition";
	//checkForGold
	this.gold = "PLH_gold";
	//callForMoneyTransaction
	this.goldTag = "PLH_goldTag";
	//saveHighscore
	this.score = "PLH_score";
	this.miniGameID = "PLH_dragonWorldMinigameID";
	//getGameContent and setGamePassed
	this.gameId = "PLH_gameId";
	//resetGamesPassedInCategory
	this.categoryNameTRKey = "PLH_categoryNameTRKey";
	//awardTrophy
	this.trophyId = "PLH_trophyId";
	this.trophyColor = "PLH_trophyColor";
	
    DragonWorldServerInterface = this;
  },
  
  resetHatchingCounterAndAddHatchedDragon: function(type){
	new Ajax.Request(
		new UrlTemplate(this.options.hatchedDragonUrl)
		.r(this.hatchedDragonType, type)
		.url,
		{
			method: 'get',
			onSuccess: function(response) {
			}
		}
	);
  }, // resetHatchingCounterAndAddHatchedDragon
  
  customizeDragon: function(hatID, beardID, colorID){ //, onTrueCallback, onFalseCallback){
	new Ajax.Request(
		new UrlTemplate(this.options.customizeUrl)
		.r(this.dragonHatID, hatID)
		.r(this.dragonBeardID, beardID)
		.r(this.dragonColorID, colorID)
		.url,
		{
			method: 'get',
			onSuccess: function(response) {
			}
		}
	);
  }, // customizeDragon
  
  saveDragonStatsAndIncreaseHatchingCounter: function(stat1, stat2, stat3, stat4, stat5){ //, onTrueCallback, onFalseCallback){
	new Ajax.Request(
		new UrlTemplate(this.options.statsUrl)
		.r(this.dragonStat1, stat1)
		.r(this.dragonStat2, stat2)
		.r(this.dragonStat3, stat3)
		.r(this.dragonStat4, stat4)
		.r(this.dragonStat5, stat5)
		.url,
		{
			method: 'get',
			onSuccess: function(response) {
				//var parsedResponse = response.responseText.evalJSON();
				//if(parsedResponse.success){
				//	onTrueCallback();
				//}else{
				//	onFalseCallback();
				//}
			}
		}
	);
  }, // saveDragonStatsAndIncreaseHatchingCounter
  
  storeItems: function(items){
	new Ajax.Request(
		new UrlTemplate(this.options.itemUrl)
		.r(this.items, items)
		.url,
		{
			method: 'get',
			onSuccess: function(response) {
			//var parsedResponse = response.responseText.evalJSON();
			}
		}
	);
  }, // storeItems

  storeCurrentChallengePosition: function(currentChallengePosition){
	new Ajax.Request(
		new UrlTemplate(this.options.storeCurrentChallengePositionUrl)
		.r(this.currentChallengePosition, currentChallengePosition)
		.url,
		{
			method: 'get',
			onSuccess: function(response) {
			}
		}
	);
  }, // storeItems
  
  hasMemberEnoughMoney: function(amount, onTrueCallback, onFalseCallback){
	new Ajax.Request(
		new UrlTemplate(this.options.checkGoldUrl)
		.r(this.gold, amount)
		.url,
		{
			method: 'get',
			onSuccess: function(response) {
			var parsedResponse = response.responseText.evalJSON();
				if(parsedResponse.success){
					onTrueCallback();
				}else{
					onFalseCallback();
				}
			}
		}
	);
  }, // hasMemberEnoughMoney
  
	callForMoneyTransaction: function(amount, tag){
		new Ajax.Request(
			new UrlTemplate(this.options.callForMoneyTransactionUrl)
			.r(this.gold, amount)
			.r(this.goldTag, tag)
			.url,
			{
				method: 'get',
				onSuccess: function(response) { }
			}
		);
	}, // callForMoneyTransaction
	
	saveHighscore: function(score, miniGameID){
		new Ajax.Request(
			new UrlTemplate(this.options.saveHighScoreUrl)
			.r(this.score, score)
			.r(this.miniGameID, miniGameID)
			.url,
			{
				method: 'get',
				onSuccess: function(response) {
				//var parsedResponse = response.responseText.evalJSON();
				}
			}
		);
	}, // saveHighscore
	
	getGameContent: function(gameId, callback){
		new Ajax.Request(
			new UrlTemplate(this.options.getGameContentUrl)
			.r(this.gameId, gameId)
			.url,
			{
				method: 'get',
				onSuccess: function(response) {
					callback(response.responseJSON.gameContent);				
				},
				onFailure: function() {
					callback(null);
				},
				onException: function() {
					callback(null)
				}
			}
		);
	}, // getGameContent
	
	setGamePassed: function(gameId){
		new Ajax.Request(
			new UrlTemplate(this.options.setGamePassedUrl)
			.r(this.gameId, gameId)
			.url,
			{
				method: 'get',
				onSuccess: function(response) {
				//var parsedResponse = response.responseText.evalJSON();
				}
			}
		);
	}, // setGamePassed
	
	resetGamesPassedInCategory: function(categoryNameTRKey){
		new Ajax.Request(
			new UrlTemplate(this.options.resetGamesPassedInCategoryUrl)
			.r(this.categoryNameTRKey, categoryNameTRKey)
			.url,
			{
				method: 'get',
				onSuccess: function(response) {
				//var parsedResponse = response.responseText.evalJSON();
				}
			}
		);
	}, // resetGamesPassedInCategory
	
	awardTrophy: function(trophy, color) {
		if (typeof(color) === "undefined") {
			color = 0;
		}
		
		new Ajax.Request(
			new UrlTemplate(this.options.giveTrophyUrl)
			.r(this.trophyId, trophy.id)
			.r(this.trophyColor, color)
			.url,
			{
				method: 'get',
				onSuccess: function(response) {
				//var parsedResponse = response.responseText.evalJSON();
				}
			}
		);
	} // awardTrophy
});
