/// <reference path="DOMAttached.js" />

var RegisterHelp = Class.create(DOMAttached, {
	initialize: function($super, el) {
		$super(el);

		for (var curLi = this.el.down("li"); curLi != undefined; curLi = curLi.next("li")) {
			var inp = curLi.down("input");
			if (inp == undefined) {
				inp = curLi.down("select");
			}
			var tooltip = curLi.down(".registerHelpTooltip");
		  if (inp != undefined && tooltip != undefined) {
				this.safeObserve(inp, 'focus', this.onFocusBlur.bindAsEventListener(this, tooltip));
				this.safeObserve(inp, 'blur', this.onFocusBlur.bindAsEventListener(this, tooltip));
			}
		}
	},
	
	onFocusBlur: function(ev) {
		var data = $A(arguments);
		data.shift();
		if (data[0] == undefined) {
			return;
		}
		if (data[0].hasClassName('registerHelpTooltipShown')) {
			data[0].removeClassName('registerHelpTooltipShown');
		} else {
			data[0].addClassName('registerHelpTooltipShown');
		}
	}
});