/// <reference path="DOMAttached.js" />

var InvitationMethod = Class.create(DOMAttached, {
	initialize: function($super, el) {
		$super(el);
		
		if(InvitationMethod.CurrentMethod == undefined) {
			InvitationMethod.CurrentMethod = this.el.up(0).down('.selected', 0);
			if(InvitationMethod.CurrentMethod == undefined) {
				this.changeSelectedMethod(this.el);
			}
		}

		this.safeObserve(this.el, 'click', this.onInvitationMethodClick.bindAsEventListener(this));
	},
	
	onInvitationMethodClick : function(ev) {
		this.changeSelectedMethod(ev.currentTarget);
	},
	
	changeSelectedMethod : function(element) {	
		if(element == undefined || element == InvitationMethod.CurrentMethod)
			return;
		element.addClassName('selected');
		if(InvitationMethod.CurrentMethod != undefined) {
			InvitationMethod.CurrentMethod.removeClassName('selected');
		}
		InvitationMethod.CurrentMethod = element;
	}
});