function DesignstudioSaveDialog() {

	function createFinalImage() {
		var ctxFinal = finalCanvas.getContext("2d");
		ctxFinal.clearRect(0, 0, designData.paintingWidth, designData.paintingHeight);
		ctxFinal.drawImage(backgroundCanvas, 0, 0);

		ctxFinal.globalCompositeOperation = "source-atop";
		ctxFinal.drawImage(freestyleCanvas, 0, 0);

		designitem.paintShape("finaltmp");

		ctxFinal.globalCompositeOperation = "source-over";
		ctxFinal.drawImage(document.getElementById("finaltmp"), 0, 0);
	}

	function saveFreestyleAsImage(name, price, offerItemInShop) {
		if (designData.isSavingInProcess) {
			return;
		}
		designData.isSavingInProcess = true;

		createFinalImage();


		designitem.draft_stash();
		var item = designitem.draft_last();

		var dataUrl = finalCanvas.toDataURL("image/png");
		var dict2 = {
			"shapeId": designData.shapeId,
			"imagedataurl": dataUrl,
			"name": name,
			"price": price,
			"draft": JSON.stringify(item),
			"offerItemInShop": offerItemInShop
		};

		var request = new Ajax.Request(designData.saveAjaxUrl, {
			method: 'post',
			parameters: dict2,
			onSuccess: function (answer) {
				var response = JSON.parse(answer.responseText);
				if (response["success"]) {
					window.location.replace(designData.onSaveRedirectUrl);
				}

				designData.isSavingInProcess = false;
				
				var message = response["message"];
				if (!message) {
					return;
				}
				
				var inputId = response["inputId"];
				if (inputId) {
					showError(message, inputId, response["errorElementId"]);
				}
				else {
					confirmAI(message, function () { }, function () { }, false, true);
				}
			},
			onException: function (err) {
				designData.isSavingInProcess = false;
			},
			onFailure: function (err) {
				designData.isSavingInProcess = false;
			}
		});
	}

	function saveDraftAjaj(status) {

		designitem.draft_stash();
		item = designitem.draft_last();
		if (designData.isSavingInProcess) {
			return;
		}

		var params = {
			"draft": JSON.stringify(item),
			"shapeid": designData.shapeId,
			"name": jQuery('#draftname').val(),
			"status": status,
			"image": finalCanvas.toDataURL("image/png")
		};

		var request = new Ajax.Request(designData.saveDraftAjaxUrl, {
			method: 'post',
			parameters: params,
			onSuccess: function (answer) {
				var response = JSON.parse(answer.responseText);
				if (response["success"]) {
					window.location.replace(designData.onSaveRedirectUrl);
				} else {
					designData.isSavingInProcess = false;
					var message = response["message"];
					if (message) {
						confirmAI(message, function () { }, function () { }, false, true);
					}
				}
			},
		});
	}

	function onConfirmationDialogSave(event) {
		event.preventDefault();

		if (jQuery('#offerInShopCheckBox').is(':checked')) {
			jQuery('#saveConfirmation').hide();
			jQuery('#shopdetails').show();
			jQuery('#itemname').focus();
		} else {
			saveFreestyleAsImage("", 0, false);
		}
	}
	function onShowSaveDraftOptions(event) {
		event.preventDefault();
		jQuery('#saveConfirmation').hide();
		jQuery('#draftdetails').show();
		jQuery('#draftname').focus();

	}
	
	function onConfirmationDialogSaveDraft(event) {
		event.preventDefault();
		createFinalImage();
		saveDraftAjaj("USERDRAFT");
	}

	function onConfirmationDialogCancel(event) {
		event.preventDefault();

		jQuery('#savedialog').modal("hide");
	}

	function onShopDialogSave(event) {
		event.preventDefault();

		var name = jQuery('#itemname').val();
		if (!name.trim()) {
			showError(designData.emptyNameMessage, 'itemname', 'itemNameError');
			return;
		}

		var price = jQuery('#itemprice').val();
		if (!price.trim()) {
			showError(designData.emptyPriceMessage, 'itemprice', 'itemPriceError');
			return;
		}

		saveFreestyleAsImage(name, price, true);
	}

	function showError(message, inputId, errorElementId) {
		var input = jQuery('#' + inputId);
		var errorElement = jQuery('#' + errorElementId);

		errorElement.text(message).show();
		input.addClass('invalid').on('input.validation', function() {
			errorElement.text("").hide();
			input.removeClass('invalid').off('input.validation');
		});
	}

	function onShopDialogCancel(event) {
		event.preventDefault();

		jQuery('#saveConfirmation').show();
		jQuery('#shopdetails').hide();
		jQuery('#savedialog').modal("hide");
	}

	this.initialize = function () {
		jQuery('#confirmationSaveButton').click(onConfirmationDialogSave.bind());
		jQuery('#confirmationCancelButton').click(onConfirmationDialogCancel.bind());
		jQuery('#showSaveDraftOptionsButton').click(onShowSaveDraftOptions.bind());
		jQuery('#confirmationSaveDraftButton').click(onConfirmationDialogSaveDraft.bind());
		jQuery('#saveDraftCancelButton').click(onConfirmationDialogCancel.bind());

		if (designData.memberOwnsAShop) {
			jQuery('#shopSaveButton').click(onShopDialogSave.bind());
			jQuery('#shopCancelButton').click(onShopDialogCancel.bind());
		}
	}
}
