/// <reference path="DOMAttached.js" />
/// <reference path="ProgressIndicatingControl.js" />
var DesignstudioEditor = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);
	    this.showProgressIndicator();

		var svgPath = options.svgPath;
		var svgContent = "";
		//get content of svg file
		if (svgPath != "") {
			jQuery.get(svgPath, function (data) {
				var svgContent = new XMLSerializer().serializeToString(data.documentElement);
				initializeDesignStudio(options, svgContent);

			});
		}
		else {
			//should never arrive here...?
		}
	    this.hideProgressIndicator();

    }
});
DesignstudioEditor.addMethods(ProgressIndicatingControl);
