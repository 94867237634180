/**
 * These are convenience methods for providing exception handling
 * for all of our games.
 */
var ExceptionHandling = {
	onPageLoadException: function (req, ex) {
		MiaErrorPrompt("Sorry, an unexpected error occurred.");
		throw ex;
	}
};
