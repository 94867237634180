/// <reference path="DOMAttached.js" />
/// <reference path="GamesQuiz.js" />
/// <reference path="../../../node_modules/soundmanager2/script/soundmanager2-nodebug.js" />

var ToggleFeedbackSoundInstance = null;

var ToggleFeedbackSound = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);

		ToggleFeedbackSoundInstance = this;

		this.button = $(options.buttonLink);
		this.imgPlay = $(options.playButton);
		this.imgPause = $(options.pauseButton);
		this.turnOnTooltip = options.turnOnTooltip;
		this.turnOffTooltip = options.turnOffTooltip;

		this.url = this.button.href;
		this.button.href = '#?'; // Don't jump

		this.safeObserve(this.button, 'click', this.onClick.bindAsEventListener(this));
		if (GenericSoundsInstance !== undefined && GenericSoundsInstance !== null) {
			if (GenericSoundsInstance.playFeedbackSound) {
				this.imgPlay.style.display = 'none';
				this.imgPause.style.display = 'block';
				this.button.title = this.turnOffTooltip;
			}
			else {
				this.imgPlay.style.display = 'block';
				this.imgPause.style.display = 'none';
				this.button.title = this.turnOnTooltip;
			}
		}
	},

	onClick: function (ev) {
		new Ajax.Request(this.url, {
			method: 'get',
			onSuccess: this.onSuccess.bind(this),
		});
	},

	onSuccess: function (ev) {
		if (GenericSoundsInstance !== undefined && GenericSoundsInstance.playFeedbackSound !== undefined) {
			GenericSoundsInstance.playFeedbackSound = !GenericSoundsInstance.playFeedbackSound;
			if (GenericSoundsInstance.playFeedbackSound) {
				this.imgPlay.style.display = 'none';
				this.imgPause.style.display = 'block';
				this.button.title = this.turnOffTooltip;
				if (window.PlayFeedbackSound != null) {
					window.PlayFeedbackSound = true;
				}
			}
			else {
				soundManager.stop('intro');
				soundManager.stop('theme');
				this.imgPlay.style.display = 'block';
				this.imgPause.style.display = 'none';
				this.button.title = this.turnOnTooltip;
				if (window.PlayFeedbackSound != null) {
					window.PlayFeedbackSound = false;
				}
			}
		}
	},
});
