/// <reference path="UrlTemplate.js" />
/// <reference path="ListBase.js" />
/// <reference path="SearchBox.js" />
/// <reference path="SortableBox.js" />

var PlazaList = Class.create(ListBase, {
	initialize: function ($super, el, options) {
		$super(el, Object.extend({pagingEffect: 'vertSlide', tags: [], searchPhrases: []}, options));
		
		this.initializeSearchBox();
		
		this.initializeSortableBox('a');
		
		this.sorterControl = this.el.down('.sorterBtns');
		
		global.registerTagSearchableList(this);
	},

	loadPage: function($super, page) {
		$super(page);
		var tagCloud = global.findTagCloud();
		if (tagCloud != undefined) {
			tagCloud.preUpdate();
		}
		this.standardJSONRequest(
			new UrlTemplate(this.options.url).
				r('PLH_page', page).
				r('PLH_Csearch', this.options.searchPhrases.join(',')).
				r('PLH_Ctag', this.options.tags.join(',')).
				r('PLH_PlazaListSorting', this.options.sortOrder).url
		);
	},
		
	onLoadPage: function($super, pageEl) {
		$super(pageEl);
		if(this.options.sorters != undefined && this.sorterControl != undefined) {
			this.sorterControl.update(this.options.sorters);
			this.initializeSortableBox('a');
		}
		if (this.options.tagCloud != undefined) {
			var tagCloud = global.findTagCloud();
			if (tagCloud != undefined) {
				tagCloud.update(this.options.tagCloud);
			}
		}
	},
	
	refresh: function() {
		this.loadPage(0);
		this.el.fire('list:criteriaChanged', {options: this.options});
	},

	addTag: function(tag) {
		this.options.tags.push(tag);
		this.refresh();
	},
    
	removeTag: function(tag) {
		this.options.tags = this.options.tags.without(tag);
		this.refresh();
	}
});
PlazaList.addMethods(SearchBox);
PlazaList.addMethods(SortableBox);
