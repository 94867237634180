var miniworldHouseCommunication = new function () {
	var wrapResponse = function (successHook) {
		return function (response) {
			response.responseJSON["settings"] = JSON.parse(response.responseJSON["settings"]);
			successHook(response);
		};
	};

	this.loadFirstRoom = function (ownerId, successHook) {
		new Ajax.Request("/ajaj/games/miniworldHouseInside/loadfirstroom",
			{
				method: "get",
				parameters: { id: ownerId },
				onSuccess: wrapResponse(successHook),
			});
	};

	this.loadRoom = function (roomId, successHook) {
		new Ajax.Request("/ajaj/games/miniworldHouseInside/loadroom",
		    {
		    	method: "get",
		    	parameters: { miniworldRoom: roomId },
		    	onSuccess: wrapResponse(successHook),
		    });
	};

	this.saveRoom = function (representation, successHook) {
		new Ajax.Request('/ajaj/games/miniworldHouseInside/saveroom',
					{
						method: "post",
						parameters: {
							miniworldRoomId: representation["id"],
							miniworldRoomName: representation["name"],
							miniworldRoomSettings: representation["settings"],
							miniworldRoomDoors: JSON.stringify(representation["doors"]),
							miniworldRoomItems: JSON.stringify(representation["items"])
						},
						onSuccess: successHook,
					});
	};

	this.userHasEnoughMoneyToBuyRoom = function (successHook) {
		new Ajax.Request("/ajaj/games/miniworldHouseInside/userhasenoughmoneytobuydoor",
		    {
		    	method: "get",
		    	onSuccess: function (response) {
		    		successHook(response.responseJSON["ans"], response.responseJSON["message"]);
		    	},
		    });
	};

	this.buyDoor = function (roomId, doorPosition, doorColor, successHook) {
		new Ajax.Request("/ajaj/games/miniworldHouseInside/buydoor",
			{
				method: "get",
				parameters: {
					miniworldRoom: roomId,
					miniworldDoorPosition: doorPosition,
					miniworldRoomDoorColor: doorColor
				},
				onSuccess: function (response) {
					if (response.responseJSON["success"]) {
						successHook(response.responseJSON["roomid"]);
					}
				},
			});
	};

	this.storeItemInInventory = function (itemId) {
		new Ajax.Request("/ajaj/games/miniworldHouseInside/storeItemInInventory",
			{
				method: "get",
				parameters: {
					miniworlditeminstanceid: itemId
				},
				onSuccess: function () { },
			});
	};

	this.storeItemInRoom = function (roomId, item) {
		new Ajax.Request("/ajaj/games/miniworldHouseInside/storeItemInRoom", {
			method: "get",
			parameters: {
				miniworldRoom: roomId,
				miniworldRoomItem: JSON.stringify({
					"instanceid": item.instanceid,
					"positionx": item.positionx,
					"positiony": item.positiony
				})
			},
			onSuccess: function () {},
		});
	};


	this.deleteItem = function (itemId, currentItemCategory) {
		new Ajax.Request("/ajaj/games/miniworldHouseInside/deleteItemInstance",
			{
				method: "get",
				parameters: {
					miniworlditeminstanceid: itemId
				},
				onSuccess: function () {
					if (currentItemCategory != null) {
						InventoryListInstance.filterForCategory(currentItemCategory);
					}
				},
			});
	};

	this.deleteRoom = function (roomId) {
		new Ajax.Request("/ajaj/games/miniworldHouseInside/deleteroom",
		    {
						method: "get",
						parameters: { miniworldRoom: roomId },
						onSuccess: function () { },
		    });
	};

};
