var SortableBox = {
	initializeSortableBox : function(tagName) {
		this.sortTagName = tagName;
		this.sorterBtnDiv = this.el.down('.sorterBtns');
		if (this.sorterBtnDiv != undefined) {
			this.setupSorterBtns();
		}
	},
	
	setupSorterBtns: function(opacityOnly) {
		for (var btn = this.sorterBtnDiv.down(this.sortTagName); btn != undefined; btn = btn.next(this.sortTagName)) {
			if (this.options.sortOrder == btn.readAttribute('class')) {
				btn.addClassName('selected');
				btn.style.fontWeight = "bold";
			} else {
				btn.removeClassName('selected');
				btn.style.fontWeight = "normal";
			}
			
			if (!opacityOnly) {
				this.safeObserve(btn, 'click', this.onSorterBtnClick.bindAsEventListener(this));
				this.safeObserve(btn, 'mouseover', this.onSorterBtnOver.bindAsEventListener(this));
				this.safeObserve(btn, 'mouseout', this.onSorterBtnOut.bindAsEventListener(this));
			}
		}
	},
	
	onSorterBtnClick: function(ev) {
		ev.stop();
		if (this.requestRunning || this.disablePaging)
			return;

		var btn = ev.findElement(this.sortTagName);
		if (this.options.sortOrder == btn.readAttribute('class').replace(/\s*selected\s*/, ''))
			return;

		this.options.sortOrder = btn.readAttribute('class').replace(/\s*selected\s*/, '');
		this.setupSorterBtns(true);
		this.options.currentPage = 0;
		this.refresh();
	},
	
	onSorterBtnOver: function(ev) {
		var btn = ev.findElement(this.sortTagName);
		if (!btn.hover) {
			btn.hover = true;
			//btn.addClassName('selected');
		}
	},
	
	onSorterBtnOut: function(ev) {
		var btn = ev.findElement(this.sortTagName);
		btn.hover = false;
		if (this.options.sortOrder == btn.readAttribute('class').replace(/\s*selected\s*/, ''))
			btn.addClassName('selected');
		else
			btn.removeClassName('selected');
	}
};