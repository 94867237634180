/// <reference path="ListBase.js" />

var GNOMList = Class.create(ListBase, {
	initialize: function($super, el, options) {
		$super(el, Object.extend({pagingEffect: 'horizSlide', noProgressIndicator: false}, options));
		
		this.emptyel = this.el.down('.emptyList a');
		this.el.attachedObj = this;
		
		if (this.emptyel != undefined && this.options.emptyListUrl != undefined)
			this.safeObserve(this.emptyel, 'click', this.onClick.bindAsEventListener(this));
	},

	loadPage: function($super, page, customUrl) {
		$super(page);
		
		if (customUrl == undefined)
			this.standardJSONRequest(this.options.url.replace('PLH_pagenum', page));
		else
			this.standardJSONRequest(customUrl.replace('PLH_pagenum', page));
	},
	
	onClick: function(ev) {
		this.el.up('.tabbedApplication').attachedObj.findAndLoadTab(this.options.emptyListUrl);
		ev.stop();
	}
});

