/// <reference path="DOMAttached.js" />

var TextBox = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		this.defaultButton = $(options.defaultButtonId);
		if (this.defaultButton != null) {
			this.safeObserve(this.el.down('input'), 'keypress', this.onKeyPress.bindAsEventListener(this));
		}
		
		if(this.options.CapsLockWarningID != null){
			this.safeObserve(this.el.down('input'), 'keypress', this.onKeyup2.bindAsEventListener(this));
			this.warningLabel = document.getElementById("contentcontent_"+this.options.CapsLockWarningID);
		}

		if (this.options.TextBoxMode == "SingleLine") {
			this.fadeInterval = 100;
			this.safeObserve(this.el.down('input'), 'focus', this.onFocus.bindAsEventListener(this));
			this.safeObserve(this.el.down('input'), 'blur', this.onBlur.bindAsEventListener(this));
			this.safeObserve(this.el.down('input'), 'keyup', this.onChange.bindAsEventListener(this));
	    } else if (this.options.TextBoxMode == "MultiLine") {
			this.makeTextareaAutoResize();
			if (this.options.MaxLength > 0) {
				this.safeObserve(this.el.down('textarea'), 'keyup', this.onKeyup.bindAsEventListener(this));
			}
		this.previous = this.el.down('textarea').value;

	    	if (this.options.RemainingCharactersControl != null) {
	    		this.counter = document.getElementById(this.options.RemainingCharactersControl);
	    		this.counter.innerHTML = this.options.RemainingCharactersTR.replace("{charactersleft}",this.options.MaxLength-this.el.down('textarea').value.length);
	    	}
	    }

		if (this.options.isFocused) {
			this.el.down('input').focus();
		}

		if (this.options.showPasswordControlId) {
			this.isPasswordShownAsText = false;
			this.safeObserve($(this.options.showPasswordControlId), 'click', this.onShowPasswordClick.bindAsEventListener(this));
		}
	},

	// Use default button when hitting return key in this text box.
	// When shift key is down, nothing happens (-> user can still line-break in multi-line textboxes).
	onKeyPress: function (ev) {
		if (this.isEnterKeyPressed(ev) == true && !ev.shiftKey) {
			ev.stop();
			// Just calling defaultButton.click() won't work in FF if the button is a link.
			this.defaultButton.dispatchEvent(new MouseEvent("click"));
		}
	},

	isEnterKeyPressed: function (ev) {
		return ev.keyCode == 13;
	},
	
	onFocus: function (ev) {
		this.el.addClassName('focused');
		if (this.options.passwordRulesId !== undefined
			&& this.el.down('input').value.length < this.options.passwordMinimalLength) {
			jQuery("#" + this.options.passwordRulesId).fadeIn(this.fadeInterval);
		}
	},
	
	onBlur: function(ev) {
		this.el.removeClassName('focused');
	},
	
	onChange: function (ev) {
		if (this.options.passwordRulesId !== undefined) {
			if (this.el.down('input').value.length < this.options.passwordMinimalLength) {
				jQuery("#" + this.options.passwordRulesId).fadeIn(this.fadeInterval);
			} else {
				jQuery("#" + this.options.passwordRulesId).fadeOut(this.fadeInterval);
			}
		}
	},

	// code from http://www.codeproject.com/Articles/17180/Detect-Caps-Lock-with-Javascript
	// check whether shift with small letter is smal letter 
	// or shift with big letter ist big letter -> caps lock activated
	// checks for caps lock
	onKeyup2: function(e) {
		 kc = e.keyCode?e.keyCode:e.which;
		 sk = e.shiftKey?e.shiftKey:((kc == 16)?true:false);
		 if(((kc >= 65 && kc <= 90) && !sk)||((kc >= 97 && kc <= 122) && sk))
		  this.warningLabel.style.visibility = 'visible';
		 else
		  this.warningLabel.style.visibility = 'hidden';
		  
		var charKeyCode = e.keyCode ? e.keyCode : e.which; // To work with both MSIE & Netscape
		var shiftKey = e.shiftKey ? e.shiftKey : ((charKeyCode == 16) ? true : false);
	},
	
	onKeyup: function(ev) {
	
		if (this.el.down('textarea').value.length > this.options.MaxLength) {
			this.el.down('textarea').value = this.previous;
		} else {
			this.previous = this.el.down('textarea').value;
			if (this.counter !== null && this.counter !== undefined) {
				this.counter.innerHTML = this.options.RemainingCharactersTR.replace("{charactersleft}", this.options.MaxLength - this.el.down('textarea').value.length);
			}
		}
	},

	onShowPasswordClick: function () {
		if (this.isPasswordShownAsText) {
			this.el.down('input').setAttribute("type", "password");
			$(this.options.showPasswordControlId).innerHTML = this.options.showPasswordText;
		} else {
			this.el.down('input').setAttribute("type", "text");
			$(this.options.showPasswordControlId).innerHTML = this.options.hidePasswordText;
		}
		this.isPasswordShownAsText = !this.isPasswordShownAsText;
	},

	// https://stackoverflow.com/a/25621277
	makeTextareaAutoResize: function() {
		jQuery(this.el.down('textarea')).each(function() {
			this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:scroll;");
		}).on("input", function() {
			this.style.height = "auto";
			this.style.height = (this.scrollHeight) + "px";
		});
	},
});
