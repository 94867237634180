var MiniworldHouseDoorAdd = function (svgId) {

	this.svgIcon = document.getElementById(svgId);

	this.type = MiniworldHouseMenu.type.DOORADD;
	this.reactToNormalMouseInteraction = function () {
		return true;
	};

	this.reactToCurrentStateInteraction = function () {
		var room = miniworldHouseGetCurrentRoom();
		return (room.state == MiniworldHouseRoom.interactionStates.NO_INTERACTION ||
			room.state == MiniworldHouseRoom.interactionStates.PREVIEW_DOOR_INTERACTION);
	};

	this.newDoorPosition;
	this.newDoorColor;

	this.clickInteraction = function () {
		var roomState = miniworldHouseGetCurrentRoom().state;
		if (roomState == MiniworldHouseRoom.interactionStates.NO_INTERACTION) {
			this.svgIcon.style.backgroundColor = '#bce5ff';
			miniworldHousePreviewDoor();
		} else if (roomState == MiniworldHouseRoom.interactionStates.PREVIEW_DOOR_INTERACTION) {
			this.svgIcon.style.backgroundColor = '';
			miniworldHouseAbortPreviewDoor();
		}
	};

	miniworldHouseTryToBuyNewRoom = (function (position) {
		this.newDoorPosition = position;
		this.newDoorColor = MiniworldHouseDoorItem.DEFAULT_COLOR;
		miniworldHouseCommunication.userHasEnoughMoneyToBuyRoom(this.popup.bind(this));
	}).bind(this);

	this.popup = function (ans, message) {
		if (ans) {
			confirmAI(message, this.getNewRoom.bind(this), function () { }, true, true);
		} else {
			confirmAI(message, function () { }, function () { }, true, false);
		}
		this.svgIcon.style.backgroundColor = '';
	};

	this.getNewRoom = function () {
		var currentRoomId = miniworldHouseGetCurrentRoom().id;
		miniworldHouseCommunication.buyDoor(currentRoomId, this.newDoorPosition, this.newDoorColor, this.reloadRoom);
	};

	this.reloadRoom = function () {
		miniworldHouseSwitchToRoom(miniworldHouseGetCurrentRoom().id);
	};
};

var miniworldHouseTryToBuyNewRoom = function () { };
