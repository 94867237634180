/// <reference path="DOMAttached.js" />

var Animation = Class.create(DOMAttached, {

	initialize: function($super, el, options) {
		$super(el, options);
		
		// fade effect
		this.timer = setInterval(this.fadeIn.bind(this), 100);

		// animation
		setInterval(
			function(){
				var posX = this.nextFrame * this.options.width;
				this.el.style.backgroundPosition = "-"+posX+"px 0px";
				this.nextFrame += this.increment;
				if (this.nextFrame >= this.options.tilecount || this.nextFrame < 0){
					if (this.options.rewind){
						this.increment = -this.increment;
						this.nextFrame += this.increment;
					} else {
						this.nextFrame = 0;
					}
				}
			}.bind(this), 
			this.options.speed
		);
	},
	
	nextFrame: 1,
	increment: 1,
	
	timer: null,
	fadeIn: function(){
		if (this.el.down(".hiddenimg").complete){
			this.el.style.visibility = "visible";	
			clearInterval(this.timer);
		}
	}
	
});
