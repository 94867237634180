/// <reference path="DOMAttached.js" />

/* MIT - Minions Invade Tahoe */

var GamesRaceInstance = null;

var GamesRace = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);

		GamesRaceInstance = this;

		this.instance = options.instance;
		this.opponentIds = options.opponentIds;

		$(this.options.dragonImages[0]).setStyle({ zIndex: 100 });

		SignalRConnection.connect("/multiplayerGameHub", function(connection) {
			connection.on("MoveDragon", GamesRaceInstance.onMove.bind(GamesRaceInstance));
		}).then(function(connection) {
			connection.invoke("RegisterToGroup", options.instance);
		});
	},

	onMove: function (json) {
		if (json.Position == this.options.maximum) {
			GamesMultiplayerQuizInstance.handleNextSpecificPageLoad(json.Instance);
		}
		if (json.Exhausted) {
			this.takePlayerDown(json.Sender, json.Position, json.Instance);
		}
		else {
			this.movePlayerTo(json.Sender, json.Position, json.Instance);
		}
	},

	movePlayerTo: function (memberId, position, messageInstance) {
		if (messageInstance == this.instance) {
			for (var i = 0; i < this.opponentIds.length; i++) {
				if (this.opponentIds[i] == memberId) {
					this.moveDragonTo(i + 1, position);
				}
			}
		}
	},

	takePlayerDown: function (memberId, position, messageInstance) {
		if (messageInstance == this.instance) {
			for (var i = 0; i < this.opponentIds.length; i++) {
				if (this.opponentIds[i] == memberId) {
					this.takeDragonDown(i + 1, position);
				}
			}
		}
	},

	moveDragonTo: function (dragonNr, position) {
		var switchedNr = this.options.dragonImages.length - 1 - dragonNr;
		var dragon = $(this.options.dragonImages[dragonNr]);

		// This is to let the player's own dragon fly a little later. 
		// This way we want to prevent that both dragons come in at the same time and the player loses.
		var dragonDelay;
		if (dragonNr == 0) {
			dragonDelay = 500;
		} else {
			dragonDelay = 0;
		}

		if (position != 0 && position != this.options.endPosition) {
			this.timer = window.setTimeout(function () { new Effect.Move(dragon, { x: position * 45 - dragonNr * 50 + switchedNr * 10, y: (switchedNr + 1) * 30, mode: 'absolute', duration: 3.0 }); }, dragonDelay);
		}
		else if (position == 0) {
			this.timer = window.setTimeout(function () { new Effect.Move(dragon, { x: dragonNr * (-50), y: 120 + switchedNr * 20, mode: 'absolute', duration: 3.0 }); }, dragonDelay);
		}
		else {
			this.timer = window.setTimeout(function () { new Effect.Move(dragon, { x: position * 45 - dragonNr * 50 - 30, y: 120, mode: 'absolute', duration: 3.0 }); }, dragonDelay);
		}
	},

	takeDragonDown: function (dragonNr, position) {
		if (position == this.options.endPosition) {
			this.moveDragonTo(dragonNr, position);
		}
		else {
			var switchedNr = this.options.dragonImages.length - 1 - dragonNr;
			new Effect.Move($(this.options.dragonImages[dragonNr]), { x: position * 45 - dragonNr * 50 + switchedNr * 10, y: 180, mode: 'absolute', duration: 3.0 });
		}
	}

});
