var TextResource = Class.create({
  initialize: function(tr) {
    this.tr = tr;
    this.unusedReplacements = new Array();
  },

  string: function() {
    return this.tr;
  },
  
  // r for replace
  r: function(placeholder, value) {
    if (this.tr.indexOf(placeholder) < 0) {
      this.unusedReplacements.push(placeholder + " -> " + value);
    } else {
      this.tr = this.tr.replace(placeholder, value);
    }
    return this;
  }
});
