/*
 * Unfortunately the Scriptaculous slide effects suck.
 * So we need to build our own.
 */
Effect.Slide = Class.create(Effect.Base, {
  initialize: function(element) {
    this.element = $(element);
    if (!this.element) throw(Effect._elementDoesNotExistError);
    this.inner = element.down();
    var options = Object.extend({
			out: false,
			reserveSpace: false,
			edge: 'top',
			duration: .5
    }, arguments[1] || { });
	  options.horizontal = (options.edge == 'left' || options.edge == 'right');
    this.start(options);
  },
  setup: function() {
    this.originalStyle = { };
    this.originalInnerStyle = { };
    ['top','left','bottom','right','width','height','position','margin','padding'].each(function(k) {
      this.originalStyle[k] = this.element.style[k];
      this.originalInnerStyle[k] = this.inner.style[k];
    }.bind(this));

		this.element.makePositioned();
		this.element.makeClipping();
		this.inner.makePositioned();
		
    this.originalElementDimensions = this.element.getDimensions();
    this.originalInnerDimensions = this.originalElementDimensions;

		this.inner.setStyle({
			width: this.originalInnerDimensions.width + 'px',
			height: this.originalInnerDimensions.height + 'px'
		});
    
    var origDisplay = this.element.getStyle('display');
    if (origDisplay == 'none') {
	    this.element.setStyle({
				width: "0px", height: "0px",
				display: 'block'
			});
		}
		if (this.element.getStyle('position') == 'absolute')
			this.originalElementPositioning = this.element.positionedOffset(); //{ top: this.element.offsetTop, left: this.element.offsetLeft };
		else
			this.originalElementPositioning = { top:0, left:0 };
		if (this.inner.getStyle('position') == 'absolute')
	    this.originalInnerPositioning = this.inner.positionedOffset(); //{ top: this.inner.offsetTop, left: this.inner.offsetLeft };
	  else
			this.originalInnerPositioning = { top:0, left:0 };
		if (origDisplay == 'none') {
			this.element.setStyle({
				width: this.originalElementDimensions.width + "px",
				height: this.originalElementDimensions.height + "px",
				display: origDisplay
			});
		}

    this.dims = [this.originalElementDimensions.height, this.originalElementDimensions.width];
    
    this.factor = 1;
    this.firstUpdate = true;
  },
  update: function(position) {
    var currentScale = this.factor * position;
    if (this.options.out)
			currentScale = 1 - currentScale;
		this.setDimensions(this.dims[0] * currentScale, this.dims[1] * currentScale);
		if (this.firstUpdate) {
	    if (!this.options.out) {
				this.element.show();
				if (this.element.getStyle('visibility') == 'hidden')
					this.element.setStyle({visibility: 'visible'});
			}
			this.firstUpdate = false;
		}
  },
  finish: function(position) {
    if (this.options.out)
			this.element.hide();
		this.element.undoClipping();
		this.element.undoPositioned();
		this.inner.undoPositioned();
    this.element.setStyle(this.originalStyle);
    this.inner.setStyle(this.originalInnerStyle);
  },
  setDimensions: function(height, width) {
    var d = { };
    var innerD = { };
    if (this.options.horizontal) {
			if (this.options.edge == 'right')
				d.paddingLeft = (this.originalElementDimensions.width - width.round()) + 'px';
			else {
				innerD.left = (this.originalInnerPositioning.left - this.originalElementDimensions.width + width.round()) + 'px';
				if (this.options.reserveSpace)
					d.paddingRight = (this.originalElementDimensions.width - width.round()) + 'px';
			}
		} else {
	    d.height = height.round() + 'px';
			if (this.options.edge == 'bottom')
				d.paddingTop = (this.originalElementDimensions.height - height.round()) + 'px';
			else {
				innerD.top = (this.originalInnerPositioning.top - this.originalElementDimensions.height + height.round()) + 'px';
				if (this.options.reserveSpace)
					d.paddingBottom = (this.originalElementDimensions.height - height.round()) + 'px';
			}
	  }
    this.element.setStyle(d);
    this.inner.setStyle(innerD);
  }
});
