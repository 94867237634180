var MiniworldHouseItemManager = function (canvasDiv, zIndexOffset, houseLevel) {
	this.canvasDiv = canvasDiv;
	this.zIndexOffset = zIndexOffset;
	this.layerOrder = [];
	this.draggedItem = null;
	this.relativeDragPositionX = null;
	this.relativeDragPositionY = null;
	this.lastValidX = null;
	this.lastValidY = null;
	this.houseLevel = houseLevel;

	this.addItem = function (drawnItem) {
		this.canvasDiv.appendChild(drawnItem.canvas);
		scaleToContainerWidth();
		this.layerOrder.push(drawnItem);
		this.resortLayers();
	};

	this.loadRoomItems = function (items, doors) {
		// Load a whole set of items and doors as delivered by the server

		for (var i = 0; i != items.length; ++i) {
			this.loadItem(items[i]);
		}
		for (var i = 0; i != doors.length; ++i) {
			this.loadDoor(doors[i]);
		}
	};

	this.loadItem = function (item) {
		var instanceId, imageUrl, posX, posY, width, height, doorColor, doorLinkedRoom;

		instanceId = item["instanceid"];
		imageUrl = item["imagepath"];
		posX = item["positionx"];
		posY = item["positiony"];
		width = item["width"];
		height = item["height"];

		if (item["free"]) {
			return new MiniworldHouseFloorItem(instanceId, imageUrl, posX, posY, width, height, this.houseLevel, this.addItem.bind(this), this.layerOrder);
		} else {
			return new MiniworldHouseWallItem(instanceId, imageUrl, posX, posY, width, height, this.houseLevel, this.addItem.bind(this), this.layerOrder);
		}
	}

	this.loadDoor = function (door) {
		posX = door["position"];
		width = 90;
		height = 187;
		doorColor = door["color"];
		doorLinkedRoom = door["roomid"];
		new MiniworldHouseDoorItem(doorLinkedRoom, posX, width, height, doorColor, this.houseLevel, this.addItem.bind(this));
	}

	this.createPreviewDoor = function () {
		var prevDoor = new MiniworldHouseDoorItem(0, 360, 90, 187, MiniworldHouseDoorItem.DEFAULT_COLOR, this.houseLevel, this.addItem.bind(this));
		prevDoor.state = MiniworldHouseDoorItem.interactionStates.PREVIEW;
		this.draggedItem = prevDoor;
	}

	this.serializeRoomItems = function () {
		var doors = [];
		var otherItems = [];
		for (var i = 0; i != this.layerOrder.length; ++i) {
			var item = this.layerOrder[i];
			if (item.lastValidX && item.lastValidY) {
				if (item.type == miniworldHouseItem.itemType.DOOR) {
					doors.push(item.serialize());
				} else {
					otherItems.push(item.serialize());
				}
			}
		}
		return {
			"doors": doors,
			"items": otherItems
		};
	};

	this.removeItem = function (item) {
		for (var i = 0; i != this.layerOrder.length; ++i) {
			if (this.layerOrder[i] === item) {
				this.layerOrder.splice(i, 1); // remove item from array
				break;
			}
		}

		this.resortLayers();
		this.canvasDiv.removeChild(item.canvas);
	};

	this.itemComparator = function (a, b) {
		// Determine the order of the item layers
		// Wall items and doors can't cover floor items
		if ((a.type == miniworldHouseItem.itemType.WALL
	    || a.type == miniworldHouseItem.itemType.DOOR)
	    && b.type == miniworldHouseItem.itemType.FLOOR) {
			return 1;
		} else if (a.type == miniworldHouseItem.itemType.FLOOR &&
	    (b.type == miniworldHouseItem.itemType.WALL
	    || b.type == miniworldHouseItem.itemType.DOOR)) {
			return -1;
		} else if (a.type == miniworldHouseItem.itemType.FLOOR) {
			return b.posY - a.posY;
		} else if (a === this.draggedItem) {
			// move dragged wall item in front of others
			return -1;
		} else if (b === this.draggedItem) {
			return 1;
		} else {
			return 0;
		}
	};

	this.isSorted = function (array, comp) {
		for (var i = 0; i < array.length - 1; ++i) {
			if (comp(array[i], array[i + 1]) < 0) {
				return false;
			}
		}
		return true;
	};

	this.zIndexForPosition = function (position) {
		// position in this.layerOrder
		return this.zIndexOffset + this.layerOrder.length - 1 - position;
	};

	this.zIndicesCorrect = function () {
		for (var i = 0; i < this.layerOrder.length; ++i) {
			if (this.layerOrder[i].canvas.style.zIndex != this.zIndexForPosition(i)) {
				return false;
			}
		}
		return true;
	};

	this.resortLayers = function () {
		if (!(this.isSorted(this.layerOrder, this.itemComparator.bind(this))
	      && this.zIndicesCorrect())) {
			this.layerOrder.sort(this.itemComparator.bind(this));
			for (var i = 0; i != this.layerOrder.length; ++i) {
				this.layerOrder[i].canvas.style.zIndex = this.zIndexForPosition(i);
			}
		}
	};

	this.tryToDragItem = function (pointerX, pointerY) {
		// if the pointer position is over an item return it, else return null
		for (var i = 0; i != this.layerOrder.length; ++i) {
			if (!miniworldHouseCanvasHelper.isTransparentAtPosition(this.layerOrder[i].canvas, pointerX, pointerY)) {
				this.draggedItem = this.layerOrder[i];

				this.draggedItem.startInteraction(pointerX, pointerY);
				this.resortLayers();

				return this.draggedItem;
			}
		}
		return null;
	};

	this.moveInteraction = function (pointerX, pointerY) {
		var mode = this.draggedItem.moveInteraction(pointerX, pointerY, this.layerOrder);
		this.resortLayers();
		return mode;
	};

	this.placeItemInteraction = function (pointerX, pointerY) {
		this.draggedItem.endInteraction(pointerX, pointerY);
		this.resortLayers();
		this.draggedItem = null;
	}

	this.removeItemInteraction = function () {
		this.removeItem(this.draggedItem);
		this.draggedItem = null;
	}
};
