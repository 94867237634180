/// <reference path="DOMAttached.js" />

var MemberSelector = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.groupSelector = this.el.down('select');
		this.memberPart = this.el.down('.memberPart');

		/* Check all box only exists if member selector supports multiple selections. */
		var checkAllBox = $("contentcontent_allChk");
		if (checkAllBox != null) {
			this.safeObserve(checkAllBox, 'click', this.onClick.bindAsEventListener(this));
		}
		
		if (!Object.isUndefined(this.groupSelector))
			this.safeObserve(this.groupSelector, 'change', this.onChanged.bindAsEventListener(this));
	},
	
	onClick: function(ev) {
		   // get all checkboxes
			var input = document.getElementsByTagName("input");
			
				for(var i in input){
					if(input[i].type == "checkbox" && input[i].id !="contentcontent_allChk"){
						// I wanted to comment this, but Christian said comments are totally overrated 
						if(document.getElementById("contentcontent_allChk").checked)
							input[i].style.visibility= 'hidden';
						else
							input[i].style.visibility = 'visible';
					}
				}	
	},
	
	onChange: function (ev) {
		if (this.groupSelector.value != 0) {
			this.memberPart.addClassName('collapsed');
		} else {
			this.memberPart.removeClassName('collapsed');
		}
	}
});
