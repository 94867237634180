/// <reference path="DOMAttached.js" />

var FeedLoader = Class.create(DOMAttached, {
	
	initialize: function($super, el, options) {
		$super(el, options);
		
		var node = document.getElementById("feedControl");
		
		var img = document.createElement("img");
		img.setAttribute("src",this.options.imageSource);
		img.setAttribute("alt",this.options.loadingTR);
		node.appendChild(img);
		this.loadFeed();
	},
	
	write: function(text) {
		var node = document.getElementById("feedControl");
		node.innerHTML = text;
	},
	

	loadFeed: function() {
		var req  =	new Ajax.Request(
			this.options.feedUrl, {
				method: 'get',
				onSuccess: this.onResponse.bind(this),
			}
		);
	},
	
	onResponse: function(req) {		
		var json;
		try {
			json = req.responseText.evalJSON();
		} catch (e) {
			this.write(e);
		}
		
		this.write(json.feed);
		
		var scripts = document.getElementById("feedControl").getElementsByTagName('script');
		
		for (var ix = 0; ix < scripts.length; ix++) {
		    eval(scripts[ix].text);
		}

		if (json.unreadcomments != 0)
			if (document.getElementById('unreadcomments') != null) {
				document.getElementById('unreadcomments').innerHTML = json.unreadcomments;
				document.getElementById('unreadcomments').style.display = "block";
			}
	}
});


