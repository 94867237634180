/// <reference path="DraggableSvgItem.js" />
/// <reference path="GenericSounds.js" />

var MiniMeAccessory = new Class.create(DraggableSvgItem, {
	initialize: function ($super, editor, accessory, feedback) {
		$super(editor);
		
		this.accessory = accessory;

		// play sound?
		if (GenericSoundsInstance == null) {
			new GenericSounds(feedback);
		}

		this.item = document.createElementNS(this.namespace, "image");
		this.item.setAttribute("x", accessory.x);
		this.item.setAttribute("y", accessory.y);
		this.item.setAttribute("width", accessory.width);
		this.item.setAttribute("height", accessory.height);
		this.item.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', accessory.src);
		this.item.style.pointerEvents = "painted";

		this.displayItem();

		if (!accessory.isApplied) {
			this.hideItem();
		}

		this.addListeners();
	},
	
	addListeners: function ($super) {
		$super();
		
		this.safeObserve(this.item, "dblclick", function () { this.changeTopBottomLayer() }.bindAsEventListener(this));
	},
	
	dragStop: function ($super, e) {
		if (this.isActive) {
			if (this.isCurrentPositionInsideOfElementBoundaries("boxGroup")) {
				this.hideItem();
			}
			GenericSoundsInstance.softClickSound();
			MiniMeLastVersion++;
		}
		$super();
	},

	isCurrentPositionInsideOfElementBoundaries: function(elementId) {
		var elementX = this.posx;
		var elementY = this.posy;
		var box = document.getElementById(elementId).getBoundingClientRect();
		var offset = this.getOffset(box);

		return elementX > offset.left && 
			elementX < offset.left + box.width && 
			elementY > offset.top && 
			elementY < offset.top + box.height;
	},

	/**
	 * Get the position coordinate relative to the page.
	 * https://stackoverflow.com/a/47768164
	 */
	getOffset: function(element) {
		var html = document.documentElement;

		return {
			top: element.top + window.pageYOffset - html.clientTop,
			left: element.left + window.pageXOffset - html.clientLeft
		};
	},
	
	changeTopBottomLayer: function () {
		this.accessory.isTopLayer = !this.accessory.isTopLayer;
		this.displayItem();
	},

	displayItem: function () {
		if (this.accessory.isTopLayer) {
			this.editor.getElementById("accessories-pre").appendChild(this.item);
		} else {
			this.editor.getElementById("accessories-post").appendChild(this.item);
		}
	},
	
	hideItem: function () {
		this.item.style.display = "none";
		this.accessory.isApplied = false;
	},
	
	showItem: function () {
		this.item.style.display = "block";

		this.accessory.isTopLayer = true;
		this.accessory.isApplied = true;
		
		this.displayItem();
	}
});
