// Appreciate, oh traveler, this multiple file structure for I made big sacrifices for it.
var MiniworldHouseImages = {};
var MiniworldHouseInstance = null;
var MiniworldHouse = function (canvasDivId, options) {

    MiniworldHouseInstance = this;
    MiniworldHouseImages.patterns = JSON.parse(options.patterns);
    MiniworldHouseImages.shadow = options.shadowimage;
    MiniworldHouseImages.door = options.doorimage;
    MiniworldHouseImages.colorpickerspectrum = options.colorpickerspectrumimage;
    MiniworldHouseImages.closeicon = options.closeiconimage;
    MiniworldHouseImages.colorpickerwalltextures = JSON.parse(options.colorpickerwalltextures);
    MiniworldHouseImages.colorpickerfloortextures = JSON.parse(options.colorpickerfloortextures);
	
	this.canvasDiv = document.getElementById(canvasDivId);
	this.options = options;
	this.editableRoom = this.options["editable"];
	if (!this.editableRoom) {
		document.getElementById("Inventory-Icon").style.display = "none";
		document.getElementById("Delete-Icon").style.display = "none";
	}

	this.zIndexOffset = 3;
	MiniworldHouseRoom.zIndexOffset = this.zIndexOffset;

	this.wallLinesCanvas = miniworldHouseCanvasHelper.createCanvas(this.canvasDiv, this.zIndexOffset - 2);
	this.colorPickerCanvas = miniworldHouseCanvasHelper.createCanvas(this.canvasDiv, 100);

	MiniworldHouseWall.drawWallLines(this.wallLinesCanvas);

	this.rooms = {};

	this.getRoomFromResponse = function (response) {
		var id = response.responseJSON["id"];
		var room = new MiniworldHouseRoom(this.zIndexOffset - 3, this.zIndexOffset);
		this.rooms[id] = room;
		room.load(response);
		return room;
	};

	this.loadRoomWithId = function (id, callBack) {
		var onSuccess = function (response) {
			callBack(this.getRoomFromResponse(response));
			scaleToContainerWidth();
		};

		if (!this.rooms.hasOwnProperty[id]) {
			miniworldHouseCommunication.loadRoom(id, onSuccess.bind(this));
		}
	};

	this.switchToRoom = function (room) {
		room.onShow();
		if (this.room === null) {
			this.canvasDiv.appendChild(room.wallCanvas);
			this.canvasDiv.appendChild(room.itemDiv);
			scaleToContainerWidth();
		} else {
			this.canvasDiv.replaceChild(room.wallCanvas, this.room.wallCanvas);
			this.canvasDiv.replaceChild(room.itemDiv, this.room.itemDiv);
		}
		this.room = room;
		this.updateRoomName();
	};

	miniworldHouseSwitchToRoom = (function (id) {
		this.saveRooms();
		this.loadRoomWithId(id, this.switchToRoom.bind(this));
	}).bind(this);

	this.room = null;

	miniworldHouseGetCurrentRoom = (function () {
		return this.room;
	}).bind(this);

	var setRoom = function (response) {
		this.switchToRoom(this.getRoomFromResponse(response));
	};
	miniworldHouseCommunication.loadFirstRoom(this.options["ownerId"], setRoom.bind(this));

	this.saveRooms = function () {
		var onSuccessRoomSave = function (result) {
			this.changedSinceSave = false;
			var response = JSON.parse(result.responseText);
			if (!response["success"]) {
				confirmAI(response["message"], function () { }, function () { }, false, true);
			}
		}

		for (var id in this.rooms) {
			if (!this.rooms.hasOwnProperty(id) || !this.rooms[id].changedSinceSave) {
				continue;
			}

			miniworldHouseCommunication.saveRoom(this.rooms[id].serialize(),
				onSuccessRoomSave.bind(this.rooms[id]),
				miniworldHouseSaveTimer.saveAfterInterval);
		}
	};
	miniworldHouseSaveTimer = new MiniworldHouseSaveTimer(this.saveRooms.bind(this));

	this.menu = new MiniworldHouseMenu(this.editableRoom, this.options);

	miniworldHouseColorPicker = new MiniworldHouseColorPicker(this.colorPickerCanvas);

	this.updateRoomName = function () {
		this.roomNameSpan.innerHTML = this.room.name;
	};

	this.showRoomNameInputField = function(show) {
		if (show) {
			this.roomNameSpan.classList.add("hidden");
			this.roomNameInput.classList.remove("hidden");
			this.editButton.classList.add("hidden");
			this.saveButton.classList.remove("hidden");
		} else {
			this.roomNameSpan.classList.remove("hidden");
			this.roomNameInput.classList.add("hidden");
			this.editButton.classList.remove("hidden");
			this.saveButton.classList.add("hidden");
		}
	}

	this.editRoomName = function (e) {
		e.stop();
		this.showRoomNameInputField(true);
		if (this.room.name !== this.room.unnamed) {
			this.roomNameInput.value = this.room.name;
		} else {
			this.roomNameInput.value = "";
			this.roomNameInput.placeholder = this.room.unnamed;
		}
	};

	this.saveRoomName = function (e) {
		e.stop();
		if (!this.roomNameInput.value.trim()) {
			confirmAI(this.room.emptyRoomNameMessage, function () { }, function () { this.roomNameInput.focus() }.bind(this), false, true);
			return;
		}
		this.showRoomNameInputField(false);
		this.room.name = this.roomNameInput.value;
		this.room.changedSinceSave = true;
		this.saveRooms();
		this.updateRoomName();
	}


	this.roomNameSpan = document.getElementsByClassName("house-roomName-name")[0];
	if (this.editableRoom) {
		this.roomNameInput = document.getElementsByClassName("house-roomName-input")[0];
		this.editButton = document.getElementsByClassName("house-roomName-editBtn")[0];
		this.editButton.onclick = this.editRoomName.bind(this);
		this.saveButton = document.getElementsByClassName("house-roomName-saveBtn")[0];
		this.saveButton.onclick = this.saveRoomName.bind(this);
	}

	this.startInteraction = function (pointerX, pointerY) {
		// Triggered by mousedown or touchstart
		if (this.room == null) {
			return;
		}
		this.room.startInteraction(pointerX, pointerY);
	};

	this.moveInteraction = function (pointerX, pointerY) {
		// triggered by mousemove, touchmove
		if (this.room == null) {
			return;
		}
		if (this.editableRoom) {
			this.room.moveInteraction(pointerX, pointerY);
		}
	};

	this.endInteraction = function (pointerX, pointerY) {
		// triggered by mouseup, touchend
		if (this.room == null) {
			return;
		}
		this.room.endInteraction(pointerX, pointerY);
	};

	// Mouse event handlers
	this.mouseStartInteraction = function (event) {
		var mousePos = miniworldHouseCanvasHelper.relativeMousePosition(this.wallLinesCanvas, event);
		this.startInteraction(mousePos.x, mousePos.y);
	};

	this.mouseMoveInteraction = function (event) {
		var mousePos = miniworldHouseCanvasHelper.relativeMousePosition(this.wallLinesCanvas, event);
		this.moveInteraction(mousePos.x, mousePos.y);
	};

	this.mouseEndInteraction = function (event) {
		var mousePos = miniworldHouseCanvasHelper.relativeMousePosition(this.wallLinesCanvas, event);
		this.endInteraction(mousePos.x, mousePos.y);
	};

	// Register mouse event listeners
	this.canvasDiv.onmousedown = this.mouseStartInteraction.bind(this);
	this.canvasDiv.onmousemove = this.mouseMoveInteraction.bind(this);
	this.canvasDiv.onmouseup = this.mouseEndInteraction.bind(this);
	if (this.editableRoom) {
		this.editButton.onclick = this.editRoomName.bind(this);
	}

	this.touchListeners = [this.menu];

	// Touch event handlers
	this.touchStartInteraction = function (e) {
		for (var i = 0; i < this.touchListeners.length; i++) {
			this.touchListeners[0].touchStartInteraction(e);
		}
		if (this.activeTouch === null) {
			this.activeTouch = event.changedTouches[0].identifier;
			var touchPos = miniworldHouseCanvasHelper.relativeTouchPosition(this.wallLinesCanvas, event, this.activeTouch);
			this.startInteraction(touchPos.x, touchPos.y);
			e.preventDefault();
		}
	};

	this.touchMoveInteraction = function (e) {
		for (var i = 0; i < this.touchListeners.length; i++) {
			this.touchListeners[0].touchMoveInteraction(e);
		}
		var touchPos = miniworldHouseCanvasHelper.relativeTouchPosition(this.wallLinesCanvas, event, this.activeTouch);
		if (touchPos !== null) {
			this.moveInteraction(touchPos.x, touchPos.y);
			e.preventDefault();
		}
	};

	this.touchEndInteraction = function (e) {
		for (var i = 0; i < this.touchListeners.length; i++) {
			this.touchListeners[0].touchEndInteraction(e);
		}
		var touchPos = miniworldHouseCanvasHelper.relativeTouchPosition(this.wallLinesCanvas, event, this.activeTouch);
		if (touchPos !== null) {
			this.activeTouch = null;
			this.endInteraction(touchPos.x, touchPos.y);
			e.preventDefault();
		}
	};

	this.activeTouch = null;

	this.canvasDiv.addEventListener("touchstart", this.touchStartInteraction.bind(this));
	this.canvasDiv.addEventListener("touchmove", this.touchMoveInteraction.bind(this));
	this.canvasDiv.addEventListener("touchend", this.touchEndInteraction.bind(this));
	this.canvasDiv.addEventListener("touchcancel", this.touchEndInteraction.bind(this));

	MiniworldHouseTR.tr_questionRoomName = this.options.tr_questionRoomName;
	MiniworldHouseTR.tr_deleteRoom = this.options.tr_deleteRoom;
	MiniworldHouseTR.tr_deleteItem = this.options.tr_deleteItem;
};

var MiniworldHouseTR = function () {
	this.tr_questionRoomName = "";
	this.tr_deleteRoom = "";
	this.tr_deleteItem = "";
};

var miniworldHouseSwitchToRoom = function () { };
var miniworldHouseGetCurrentRoom = function () { };

var disableUserSelect = function (control) {
	control.style.webkitUserSelect = "none"; /* Chrome all / Safari all */
	control.style.MozUserSelect = "none";  /* Firefox all */
	control.style.MsUserSelect = "none"; /* IE 10+ */
	control.style.userSelect = "none";
};
