/**
 * This class enables enlarging the image
 */
var UpScaledImageHandler = Class.create({
	initialize: function (game) {
		this.game = game;
	},

	initializeListenerForZoomInQuestionImg: function () {
		var image = this.game.el.down('.mia-QuestionImg');
		var upscaledImagePopup = document.querySelector('.mia-upscaledQuestionImage');
		if (image != undefined) {
			if (upscaledImagePopup == undefined) {
				// Check if the div is already created if not create one
				// Create a new Div that would be appended to the form Tag and return the new div
				upscaledImagePopup = this.createUpScaledImageDiv();
			}
			this.setImageToUpscaledImagePopup(image);

			// Set upscaled question image popup close handler
			this.game.stopObserveAllListeners(upscaledImagePopup);

			this.game.safeObserve(upscaledImagePopup, 'click', this.onUpscaledImagePopupCloseClick.bindAsEventListener(this.game));
			this.game.safeObserve(upscaledImagePopup, 'touchstart', this.onUpscaledImagePopupCloseTouch.bindAsEventListener(this.game));

			// Set upscaled question image popup open handler
			this.game.safeObserve(image, 'click', this.onUpscaledImagePopupOpenClick.bindAsEventListener(this.game));
		}
	},

	createUpScaledImageDiv: function () {
		var formTagElement = document.querySelector('form');
		var newUpscaledImageDiv = document.createElement('div');
		newUpscaledImageDiv.className = 'mia-upscaledQuestionImagePopup';
		var newUpscaledImageDivPopUp = document.createElement('div');
		newUpscaledImageDivPopUp.className = 'mia-upscaledQuestionImage';
		newUpscaledImageDiv.appendChild(newUpscaledImageDivPopUp);
		formTagElement.appendChild(newUpscaledImageDiv);

		return newUpscaledImageDiv;
	},

	setImageToUpscaledImagePopup: function (image) {
		var upscaledImage = document.querySelector('.mia-upscaledQuestionImage');
		var style = image.currentStyle || window.getComputedStyle(image, false);
		upscaledImage.style.backgroundImage = style.backgroundImage;
	},

	onUpscaledImagePopupCloseClick: function (event) {
		event.stopPropagation();
		document.querySelector('.mia-upscaledQuestionImagePopup').classList.remove("revealed");
	},

	onUpscaledImagePopupOpenClick: function () {
		document.querySelector('.mia-upscaledQuestionImagePopup').classList.add("revealed");
	},

	onUpscaledImagePopupCloseTouch: function (event) {
		event.stopPropagation();
	}
});
