/// <reference path="DOMAttached.js" />

var ActiveRatingBar = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

    if (this.options.showDescription) {
      this.desc = this.el.down('ul').select('li');
    }
		this.stars = this.el.select('span.star');
		this.rating = this.el.down('.ratingValue');
		
		this.safeObserve(this.el, 'mouseover', this.onMouseOver.bindAsEventListener(this));
		this.safeObserve(this.el, 'mouseout', this.onMouseOut.bindAsEventListener(this));
		this.safeObserve(this.el, 'click', this.onClick.bindAsEventListener(this));
	},
	
	onMouseOver: function(ev) {
		ev.stop();
		var star = ev.findElement('span');
		if (star == undefined || !star.hasClassName('star')) {
			return;
		}
		
		this.updateStars(star);
	},
	
	onMouseOut: function(ev) {
		ev.stop();
		var _star = ev.findElement('span');
		if (_star == undefined || !_star.hasClassName('star')) {
			return;
		}
		
		var rating = this.rating.value;
		var star = null;
		this.stars.each(function(el) {
			--rating;
			if (rating == 0) {
				star = el;
			}
		});
		this.updateStars(star);
	},
	
	updateStars: function(star) {
		var count = 0;
		var starMode = (star != null) ? 'On' : 'Off';
		this.stars.each(function(el) {
			if (starMode == 'On') {
				++count;
				el.setStyle({background: 'url(' + this.options.starOnSrc + ') top left no-repeat'});
			} else {
				el.setStyle({background: 'url(' + this.options.starOffSrc + ') top left no-repeat'});
			}
			if (star == el) {
				starMode = 'Off';
			}
		});
    if (this.options.showDescription) {
  		this.desc.each(function(el) {
  			--count;
  			if (count == -1) {
  				if (el.getStyle('display') == "none") {
  					el.setStyle({display: block});
  				}
  			} else {
  				if (el.getStyle('display') != "none") {
  					el.setStyle({display: none});
  				}
  			}
  		});
    }
	},
	
	onClick: function(ev) {
		ev.stop();
		var star = ev.findElement('span');
		if (star == undefined || !star.hasClassName('star')) {
			return;
		}
		
		var count;
		for (count = 0; count < this.stars.length; ++count) {
			if (this.stars[count] == star) {
				break;
			}
		}

		this.rating.value = (count + 1);
	}
});
