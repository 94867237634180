/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />

var SpellingFireServerInterface = null;

var SpellingFire = Class.create(DOMAttached, {
  initialize: function($super, el, options) {
    $super(el, options);
    this.valueParameterName = "PLH_number";
    this.actionParameterName = "PLH_Action";
    SpellingFireServerInterface = this;
  },
  
  storeMusicVolume: function(volume, onSuccessCallback, onFailureCallback) {
	//TODO
  }, // storeMusicVolume
  
  storeSoundVolume: function(volume, onSuccessCallback, onFailureCallback) {
	//TODO
  }, // storeSoundVolume
  
  hasMemberEnoughMoney: function(onTrueCallback, onFalseCallback){
	new Ajax.Request(
		new UrlTemplate(this.options.serverCommunicationURL).r(this.valueParameterName, 0).r(this.actionParameterName, 0).url,  // action: 0 = check for enough money
		{
			method: 'get',
			onSuccess: function(response) {
				var parsedResponse = response.responseText.evalJSON();
				if(parsedResponse.success){
					onTrueCallback();
				}else{
					onFalseCallback();
				}
			}
		}
	);
  }, // hasMemberEnoughMoney
  
  
  callForMoneyTransaction: function(onSuccessCallback, onFailureCallback) {
  new Ajax.Request(
		new UrlTemplate(this.options.serverCommunicationURL).r(this.valueParameterName, 0).r(this.actionParameterName, 1).url, // action: 1 = book money now/request payment
		{
			method: 'get',
			onSuccess: function(response) {
				// for now, we assume everything went okay
				// as we just checked if the user has enough money
			}
		}
	);
  }, // callForMoneyTransaction
  
  storeScore: function(score, onSuccessCallback, onFailureCallback) {
	new Ajax.Request(
		new UrlTemplate(this.options.serverCommunicationURL).r(this.valueParameterName, score).r(this.actionParameterName, 2).url, // action: 2 = store score
		{
			method: 'get',
			onSuccess: function(response) {
				onSuccessCallback();
			},
		}
	);
  }, // storeScore
  
  
  storeCurrentLevel: function (level, onSuccessCallback, onFailureCallback) {
  	new Ajax.Request(
		new UrlTemplate(this.options.spellingFireServerCommunicationURL).r(this.valueParameterName, level).r(this.actionParameterName, 1).url, // action: 1 = store current level
		{
			method: 'get',
			onSuccess: function (response) {
				var parsedResponse = response.responseText.evalJSON();
				if (parsedResponse.response == 0) { // 0 = All good. Check Navigation.Node.AJAJ.Games.SpellingFire for enum.	
					onSuccessCallback();
				}
			}
		}
	);
  }, // storeCurrentLevel
  
  storeMedal: function(medal, onSuccessCallback, onFailureCallback){
	new Ajax.Request(
		new UrlTemplate(this.options.spellingFireServerCommunicationURL).r(this.valueParameterName, medal).r(this.actionParameterName, 2).url, // action: 2 = store medal
		{
			method: 'get',
			onSuccess: function(response) {
				// Do nothing? Medal should be saved...
			}
		}
	);
  },
  
  getLevel: function(level, onSuccessCallback, onFailureCallback){
	new Ajax.Request(
		new UrlTemplate(this.options.spellingFireServerCommunicationURL).r(this.valueParameterName, level).r(this.actionParameterName, 1).url, // 1 = getLevel
		{
			method: 'get',
			onSuccess: function(response) {
				var levelDict = response.responseText.evalJSON();
				onSuccessCallback(levelDict);
			},
		}
	);
  }, // getLevel
  
  storeScoops: function(amount, onSuccessCallback, onFailureCallback){
	//TODO
  } // storeScoops
});
