/// <reference path="DOMAttached.js" />

var ShowcaseItem = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

		this.trigger = $(options.Trigger);
		this.width = options.Width;
		this.height = options.Height;
		this.left = options.Left;
		this.top = options.Top;
		this.url = options.Url;
		
		this.safeObserve(this.trigger, 'click', this.openPopup.bindAsEventListener(this));
	}, 
	openPopup: function(ev) {
		var optionString = "width=" + this.width + ", height=" + this.height + ", left=" + this.left + ", top=" + this.top;
		var newWindow = window.open(this.url, "Award", optionString);
		newWindow.focus();
		ev.preventDefault();
	}
});