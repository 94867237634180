/// <reference path="DOMAttached.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="TextResource.js" />

var GamesHangman = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

		this.completed = false;
		this.solution = this.options.solution;
		this.errorImage = this.el.down('img.errors');
		this.message = this.el.down('span.message');
		this.word = this.el.down('.mia-word');
		this.wordLetters = Element.select(this.el, '.letter');

		this.rightCount = 0;
		this.maxErrorCount = this.el.down('.maxErrors').value;
		this.errorCount = 0;

		this.errorImage.src = this.errorImage.src.replace(/\d+_\d+\.png/, this.maxErrorCount + '_' + (this.maxErrorCount-this.errorCount) + ".png");

		this.marginRight = 2;

		Element.select(this.el, '.letter').each(function (e) {
			this.safeObserve(e, 'mouseover', function(ev) {
				if (this.letterToFind == undefined || e.innerHTML != '_')
					return;
				this.originalFontColor = e.style.color;
				e.style.color = "red";
				e.style.cursor = "pointer";
								
				var oldWidth = e.offsetWidth;
				e.innerHTML = this.letterToFind;
				var dwidth = oldWidth - e.offsetWidth
				if (dwidth > 0) {
					e.style.paddingRight = dwidth + 'px';
				} else {
					e.style.marginRight = (dwidth + this.marginRight) + 'px';
				}
			}.bindAsEventListener(this));
			this.safeObserve(e, 'mouseout', function(ev) {
				if (this.letterToFind == undefined || e.innerHTML != this.letterToFind)
					return;
				e.style.color = this.originalFontColor;
				e.style.cursor = "default";
				e.style.paddingRight = '';
				e.style.marginRight = this.marginRight + 'px';
				e.innerHTML = '_';
			}.bindAsEventListener(this));
			
			this.safeObserve(e, 'click', function(ev) {
				if (this.letterToFind == undefined || e.innerHTML != this.letterToFind)
					return;
				e.style.color = this.originalFontColor;
				e.style.cursor = "default";
				e.style.paddingRight = '';
				e.style.marginRight = this.marginRight + 'px';
				e.innerHTML = '_';
				var letter = this.letterToFind;
				this.letterToFind = undefined;
				this.spanClicked.style.backgroundColor = '#FFA7D1';
				if (e.assocLetter == letter) {
					this.uncover(letter, this.spanClicked);
					this.checkSuccess();
				}
				else {
					this.onError(letter);
					this.setMessage(new TextResource(this.options.trWrongPosition).r('{letter}', letter).string(), 'red');
				}
			}.bindAsEventListener(this));
		} .bind(this));

		this.letters = "";
		Element.select(this.el, '.letterSelector div.char').each(function(e) {
			this.safeObserve(e, 'click', this.onLetterClick.bindAsEventListener(this));
			this.letters = this.letters + e.title;
		} .bind(this));
		var count = 0;
		this.wordLetters.each(function(e) {
			var ch = this.solution.charAt(count);
			if (this.letters.indexOf(ch) == -1) {
				++this.rightCount;
				e.innerHTML = ch;
				e.style.display = "none";
			}
			e.assocLetter = this.solution.charAt(count++);
		} .bind(this));
		
		Element.select(this.el, '.letterToUncover').each(function (e) {
			Element.select(this.el, '.letterSelector div.char').each(function(f) {
				if (e.value == f.title) {
					this.uncover(e.value, f);
				}
			} .bind(this));
		} .bind(this));
	},

	onLetterClick: function(ev) {
		if (this.completed)
			return;

		if (this.errorCount >= this.maxErrorCount) {
			return;
		}

		var span = ev.findElement('div.char');
		if (span.hasClassName('used'))
			return;

		if (this.spanClicked != undefined && !this.spanClicked.hasClassName('used'))
			this.spanClicked.style.backgroundColor = '#FFA7D1';
		this.spanClicked = undefined;
		this.letterToFind = undefined;	
		
		var letter = span.lastChild.data;
		
		var lettercnt = this.solution.split(letter).length - 1;
		if (lettercnt == 0) {
			this.uncover(letter, span);
			this.onError(letter);
		} else if (lettercnt < 3) {
			this.uncover(letter, span);
			this.checkSuccess();
		} else {
			this.setMessage(new TextResource(this.options.trGuessPosition).r('{letter}', letter).string(), '');
			this.spanClicked = span;
			this.spanClicked.style.backgroundColor = '#ADFF2F';
			this.letterToFind = letter;
		}
	},

	uncover: function(letter, span) {
		span.addClassName('used');
		span.style.backgroundColor = '#ccc';
		span.style.cursor = 'default';
		this.wordLetters.each(function(e) {
			if (e.assocLetter == letter) {
				e.innerHTML = letter;
				e.style.marginRight = '0px';
				var next = e.next();
				if (next != undefined && next.style.display == "none")
					next.style.display = "";
				var previous = e.previous();
				if (previous != undefined && previous.style.display == "none")
					previous.style.display = "";
				++this.rightCount;
			}
		} .bind(this));
	},

	checkSuccess: function() {
		this.removeMessage();
		if (this.rightCount == this.solution.length) {
			new Ajax.Request(this.options.urlCompleted, {
				method: 'get',
				onSuccess: this.onSuccess.bind(this)
			});
		}
	},

	onSuccess: function(req) {
		var json = req.responseText.evalJSON();
		var amount = json.Amount;
		this.setMessage(new TextResource(this.options.trCompleted).r('{amount}', amount).string(), 'green');
		this.message.style.color = "green";
		if (this.word) {
			this.word.style.color = "green";
		}
		this.completed = true;
	},

	onError: function(letter) {
		++this.errorCount;

		this.errorImage.src = this.errorImage.src.replace(/\d+\.png/, (this.maxErrorCount-this.errorCount) + ".png");

		if (this.errorCount >= this.maxErrorCount) {
			this.setMessage(this.options.trFailed, 'red');
			if (this.word) {
				this.word.style.color = "red";
			}
			Element.select(this.el, '.letterSelector div.char').each(function(e) {
				e.addClassName('used');	
				e.style.backgroundColor = '#ccc';
				e.style.cursor = 'default';
			});
			this.wordLetters.each(function(el) {
				el.innerHTML = el.assocLetter;
			});
			return;
		}

		this.setMessage(new TextResource(this.options.trWrong).r('{letter}', letter).string(), 'red');
	},
	
	removeMessage: function() {
		this.message.style.display = "none";
	},
	
	setMessage: function(message, color) {
			this.message.style.display = "block";
			this.message.style.color = color;
			this.message.innerHTML = message;
	}
});
