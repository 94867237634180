/*
 * Convenience class for logic that is unique to multiple choice quizzes:
 * There are several answer options, only one of them being correct. The user
 * has to choose one answer by clicking it. This class is responsible for
 * providing the click handlers and feedback.
 */
var MultipleChoiceQuiz = Class.create({
	/**
	 * The container is used to gain access to the DOM. Depending on the user's 
	 * answer choice, the corresponding callback is called.
	 */
	initialize: function (container, gameSettings, correctAnswerCallback, incorrectAnswerCallback, updateQuestionCallback) {
		// $mia-color-success-green-500
		this.correctAnswerBackgroundColor = '#2CD56D';
		// $mia-color-warning-red-400
		this.incorrectAnswerBackgroundColor = '#FF5439';
		// $mia-color-black
		this.correctAnswerFontColor = '#383838';
		// $mia-color-white
		this.incorrectAnswerFontColor = '#FFFFFF';

		this.container = container;
		this.gameSettings = gameSettings;
		this.correctAnswerCallback = correctAnswerCallback;
		this.incorrectAnswerCallback = incorrectAnswerCallback;
		this.updateQuestionCallback = updateQuestionCallback;
	},

	/**
	 * Public. Call this whenever you have loaded a new question in order to
	 * set up click handlers etc.
	 */
	onNextQuestionLoaded: function () {
		this.locked = false;
		this.correctAnswerElement = this.getCorrectAnswerElement();
		this.incorrectAnswerElements = this.getIncorrectAnswerElements();
		this.initializeAllAnswerClickHandlers();
	},

	/**
	 * Public. Called by the games quiz instance to get the current TTS elements.
	 */
	createTextToSpeechElements: function (questionSpeechFilePath, answerSpeechFilePaths) {
		var textToSpeechElements = [];
		textToSpeechElements.push(this.createQuestionTextToSpeechElement(questionSpeechFilePath));
		for (var i = 0; i < answerSpeechFilePaths.length; i++) {
			textToSpeechElements.push(this.createAnswerTextToSpeechElement(i, answerSpeechFilePaths));
		}
		return textToSpeechElements;
	},

	/**
	 * Public. This is called by the games quiz instance in order to play the speech 
	 * file for the correct answer.
	 */
	getIdentifierOfCorrectAnswer: function () {
		return this.correctAnswerIdentifier;
	},

	/**
	 * Looks up the DOM element containing the correct answer. This is the element
	 * that needs to be clicked in order to correctly answer the question.
	 * 
	 * Todo: This should be passed in from the server-side code, not depend on class names.
	 */
	getCorrectAnswerElement: function () {
		return this.container.el.down('.solution');
	},

	/**
	 * Looks up the DOM elements containing the incorrect answers. If one of these
	 * elements is clicked this means the member gave an incorrect answer to the question.
	 * 
	 * Todo: These should be passed in from the server-side code, not depend on class names.
	 * Todo: Currently this only works with four answers, not with more!
	 */
	getIncorrectAnswerElements: function () {
		var allAnswerElements = [
			this.container.el.down('.answer0'),
			this.container.el.down('.answer1'),
			this.container.el.down('.answer2'),
			this.container.el.down('.answer3')
		];
		return allAnswerElements.filter(function (value) {
			return !value.classList.contains('solution');
		});
	},

	/**
	 * Tries to parse the domElement's class name (e.g. answer1) to figure out which 
	 * answer this element belongs to. If the given domElement does not belong to any
	 * answer, this method returns -1; otherwise, the index of the answer is returned.
	 */
	getAnswerId: function (domElement) {
		var classes = domElement.className.split(/\s+/);
		for (var i = 0; i < classes.length; i++) {
			if (classes[i].indexOf('answerId') == 0)
				return parseInt(classes[i].substring(8));
		}
		return -1;
	},

	initializeAllAnswerClickHandlers: function () {
		this.initializeCorrectAnswerClickHandler();
		for (var i = 0; i < this.incorrectAnswerElements.length; i++) {
			this.initializeIncorrectAnswerClickHandler(i);
		}
	},

	initializeCorrectAnswerClickHandler: function () {
		this.addListener(this.correctAnswerElement, 'click', this.onCorrectAnswerClicked);
	},

	initializeIncorrectAnswerClickHandler: function (index) {
		var _this = this;
		this.addListener(this.incorrectAnswerElements[index], 'click', function (ev) {
			_this.onIncorrectAnswerClicked(_this.incorrectAnswerElements[index], ev);
		});
	},

	/**
	 * Convenience wrapper around the DOMAttached method for safely observing an element.
	 */
	addListener: function (element, event, func) {
		this.container.safeObserve(element, event, func.bindAsEventListener(this));
	},

	/**
	 * Only to be used as the click handler for the correct answer element.
	 */
	onCorrectAnswerClicked: function (ev) {
		if (this.locked) {
			return;
		} else {
			this.locked = true;
		}
		this.displayAnswerFeedback(this.correctAnswerElement, true);
		this.correctAnswerCallback(this.getAnswerId(this.correctAnswerElement))
			.then(function(response) {
				this.updateQuestionCallback(Object.assign({}, response.ProcessAnswerResult, response.QuestionData), false);
			}.bind(this));
	},

	/**
	 * Only to be used as the click handler for the incorrect answer elements.
	 */
	onIncorrectAnswerClicked: function (clickedElement, ev) {
		if (this.locked) {
			return;
		} else {
			this.locked = true;
		}
		this.displayAnswerFeedback(clickedElement, false);
		this.incorrectAnswerCallback(this.getAnswerId(clickedElement))
			.then(function(response) { 
				this.updateQuestionCallback(Object.assign({}, response.ProcessAnswerResult, response.QuestionData));
			}.bind(this));
	},

	/**
	 * Depending on the chosen feedback mode, this method highlights correct / incorrect answers.
	 */
	displayAnswerFeedback: function (clickedElement, answerIsCorrect) {
		if (this.gameSettings.shouldDisplayWhetherChosenAnswerIsCorrect) {
			if (answerIsCorrect) {
				this.setBackgroundColor(clickedElement, this.correctAnswerBackgroundColor);
				this.setFontColor(clickedElement, this.correctAnswerFontColor);
			} else {
				this.setBackgroundColor(clickedElement, this.incorrectAnswerBackgroundColor);
				this.setFontColor(clickedElement, this.incorrectAnswerFontColor);
			}
		}
		if (!answerIsCorrect && this.gameSettings.shouldDisplayCorrectSolution) {
			this.flashCorrectSolution(3000);
		}
	},

	/**
	 * When this is called, the correct answer element will flash in green background 
	 * color in intervals of the given durationInMilliseconds.
	 */
	flashCorrectSolution: function (durationInMilliseconds) {
		var _this = this;
		this.flashingCorrectSolution = setInterval(this.toggleCorrectSolutionBackground.bind(this), 250);
		setTimeout(function () {
			clearInterval(_this.flashingCorrectSolution);
			// Necessary so that the correct answer stays highlighted during loading of next question
			_this.toggleCorrectSolutionBackground();
		}, durationInMilliseconds);
	},

	/**
	 * Convenience method used when flashing the correct solution.
	 */
	toggleCorrectSolutionBackground: function () {
		if (this.getBackgroundColor(this.correctAnswerElement) == this.correctAnswerBackgroundColor) {
			this.setBackgroundColor(this.correctAnswerElement, this.defaultAnswerBackgroundColor);
			this.setFontColor(this.correctAnswerElement, this.incorrectAnswerFontColor);
		} else {
			this.defaultAnswerBackgroundColor = this.getBackgroundColor(this.correctAnswerElement);
			this.setBackgroundColor(this.correctAnswerElement, this.correctAnswerBackgroundColor);
			this.setFontColor(this.correctAnswerElement, this.correctAnswerFontColor);
		}
	},

	/**
	 * Convenience method for setting an element's background color to the given color.
	 */
	setBackgroundColor: function (element, color) {
		element.setStyle({
			backgroundColor: color
		});
	},

	/**
	 * Convenience method for setting an element's font color to the given color.
	 */
	setFontColor: function (element, color) {
		element.setStyle({
			color: color
		});
	},

	/**
	 * Convenience method for getting an element's background color.
	 */
	getBackgroundColor: function (element) {
		return element.getStyle('backgroundColor');
	},


	/**
	 * Convenience method for getting an element's font color.
	 */
	 getFontColor: function (element) {
		return element.getStyle('color');
	},

	/**
	 * Combines all the information needed for TextToSpeech to play the speech corresponding
	 * to the question text.
	 */
	createQuestionTextToSpeechElement: function (questionSpeechFilePath) {
		var questionElement = {};
		questionElement.domElement = this.container.el.down('.question');
		questionElement.identifier = 'question';
		questionElement.soundUrl = questionSpeechFilePath;
		questionElement.highlightFontColor = 'white';
		questionElement.defaultFontColor = this.getFontColor(questionElement.domElement);
		return questionElement;
	},

	/**
	 * Combines all the information needed for TextToSpeech to play the speech corresponding
	 * to the answer text of the answer with the given index.
	 */
	createAnswerTextToSpeechElement: function (answerIndex, answerSpeechFilePaths) {
		var answerElement = {};
		answerElement.domElement = this.container.el.down('.answer' + answerIndex);
		answerElement.identifier = 'answer' + answerIndex;
		answerElement.soundUrl = answerSpeechFilePaths[answerIndex];
		answerElement.highlightFontColor = 'white';
		answerElement.defaultFontColor = this.getFontColor(answerElement.domElement);

		if (this.isCorrectAnswer(answerElement)) {
			this.correctAnswerIdentifier = answerElement.identifier;
		}

		return answerElement;
	},

	/**
	 * In multiple choice quizzes, the correct answer div has the class name "solution",
	 * so we can check if the given answerElement's domElement contains that class name.
	 */
	isCorrectAnswer: function (answerElement) {
		var classNames = answerElement.domElement.className.split(/\s+/);
		return classNames.indexOf('solution') > -1;
	}
});
