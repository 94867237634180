/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />

var ShootingPrototypeServerInterface = null;

var ShootingPrototype = Class.create(DOMAttached, {
  initialize: function($super, el, options) {
    $super(el, options);
    this.valueParameterName = "PLH_number";
    this.actionParameterName = "PLH_Action";
    ShootingPrototypeServerInterface = this;
  },
  
  storeMusicVolume: function(volume, onSuccessCallback, onFailureCallback ) {
	//TODO
  }, // storeMusicVolume
  
  storeSoundVolume: function(volume, onSuccessCallback, onFailureCallback) {
	//TODO
  }, // storeSoundVolume
  
  hasMemberEnoughMoney: function(onTrueCallback, onFalseCallback){
	new Ajax.Request(new UrlTemplate(this.options.serverCommunicationURL).r(this.valueParameterName, 0).r(this.actionParameterName, 0).url,  // action: 0 = check for enough money
		{
			method: 'get',
			onSuccess: function(response) {
				var parsedResponse = response.responseText.evalJSON();
				if(parsedResponse.success){
					onTrueCallback();
				}else{
					onFalseCallback();
				}
			}
		}
	);
  }, // hasMemberEnoughMoney
  
  callForMoneyTransaction: function(onSuccessCallback, onFailureCallback) {
  new Ajax.Request(
		new UrlTemplate(this.options.serverCommunicationURL).r(this.valueParameterName, 0).r(this.actionParameterName, 1).url, // action: 1 = book money now/request payment
		{
			method: 'get',
			onSuccess: function(response) {
				// for now, we assume everything went okay
				// as we just checked if the user has enough money
			}
		}
	);
  }, // callForMoneyTransaction
  
  storeScore: function(score, onSuccessCallback, onFailureCallback) {
	new Ajax.Request(
		new UrlTemplate(this.options.serverCommunicationURL).r(this.valueParameterName, score).r(this.actionParameterName, 2).url, // action: 2 = store score
		{
			method: 'get',
			onSuccess: function(response) {
				onSuccessCallback();
			},
		}
	);
  }
});
