	function scaleToContainerWidth() {
		jQuery(".mia-js-scaleToContainerWidth").each(function (index, element) {
			var element = jQuery(element);
			var scalingFactor = element.parent().first().width() / element.width();
			element.css({
				"transform": "scale(" + scalingFactor + ")",
				"transform-origin": "0 0",
				"margin-bottom": element.height() * (scalingFactor - 1)
			});
		});
	}

	jQuery(document).ready(scaleToContainerWidth);
	jQuery(window).on("resize", scaleToContainerWidth);
	jQuery(window).on("orientationchange", scaleToContainerWidth);
