/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />

var BusinessSimulationServerInterface = null;

var BusinessSimulation = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

		//variables for placeholder replacement
		this.itemId = "PLH_itemId";
		this.itemIsOnSale = "PLH_isOnSale";
		this.machine = "PLH_machine";
		this.gold = "PLH_gold";
		this.goldTag = "PLH_goldTag";
		this.rangeStart = "PLH_rangeStart";
		this.amountToLoad = "PLH_amountToLoad";
		this.objectCategory = "PLH_ObjectCategory";

		BusinessSimulationServerInterface = this;
	},
	
	redirectToAdvertising: function() {
		window.location.href = this.options.advertisingUrl;
	},

	setItemOnSale: function(itemId) {
		new Ajax.Request(
			new UrlTemplate(this.options.salesUrl)
			.r(this.itemId, parseInt(itemId))
			.r(this.itemIsOnSale, true)
			.url,
			{
				method: 'get',
				onSuccess: function(response) {
				}
			}
		);
	},
	
	setItemOffSale: function(itemId) {
		new Ajax.Request(
			new UrlTemplate(this.options.salesUrl)
			.r(this.itemId, parseInt(itemId))
			.r(this.itemIsOnSale, false)
			.url,
			{
				method: 'get',
				onSuccess: function(response) {
				}
			}
		);
	},
	
	buyMachine: function(itemId, machine) {
		new Ajax.Request(
			new UrlTemplate(this.options.machineUrl)
			.r(this.itemId, parseInt(itemId))
			.r(this.machine, parseInt(machine))
			.url,
			{
				method: 'get',
				onSuccess: function(response) {
				}
			}
		);
	},
	
	callForMoneyTransaction: function(amount, tag){
		new Ajax.Request(
			new UrlTemplate(this.options.callForMoneyTransactionUrl)
			.r(this.gold, amount)
			.r(this.goldTag, tag)
			.url,
			{
				method: 'get',
				onSuccess: function(response) { }
			}
		);
	},
	
	checkForGold: function(amount, onTrueCallback, onFalseCallback){
		new Ajax.Request(
			new UrlTemplate(this.options.checkGoldUrl)
			.r(this.gold, amount)
			.url,
			{
				method: 'get',
				onSuccess: function(response) {
					var parsedResponse = response.responseText.evalJSON();
					if(parsedResponse.success){
						onTrueCallback();
					}else{
						onFalseCallback();
					}
				}
			}
		);
	},

	loadItems: function (category, onTrueCallback) {
		new Ajax.Request(
			new UrlTemplate(this.options.loadItemsUrl)
			.r(this.objectCategory, category)
			.url,
			{
				method: 'get',
				onSuccess: function (response) {
					onTrueCallback(response.responseJSON.items);
				},
			}
		);
	},

	loadItemGraphInformation: function (itemId, onTrueCallback) {
		new Ajax.Request(
			new UrlTemplate(this.options.loadItemInfoUrl)
			.r(this.itemId, itemId)
			.url,
			{
				method: 'get',
				onSuccess: function (response) {
					onTrueCallback(response.responseJSON.stats);
				},
			}
		);
	}
});
