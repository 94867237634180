var FilterableBox = {
	initializeFilterableBox : function(tagName) {
		this.filterTagName = tagName;
		this.filterBtnDiv = this.el.down('.filterBtns');
		if (this.filterBtnDiv != undefined) {
			this.setupFilterBtns();
		}
	},
	
	setupFilterBtns: function(opacityOnly) {
		for (var btn = this.filterBtnDiv.down(this.filterTagName); btn != undefined; btn = btn.next(this.filterTagName)) {
			if (this.options.memberFilter == btn.readAttribute('class'))
				btn.addClassName('selected');
			else
				btn.removeClassName('selected');
			if (!opacityOnly) {
				this.safeObserve(btn, 'click', this.onFilterBtnClick.bindAsEventListener(this));
				this.safeObserve(btn, 'mouseover', this.onFilterBtnOver.bindAsEventListener(this));
				this.safeObserve(btn, 'mouseout', this.onFilterBtnOut.bindAsEventListener(this));
			}
		}
	},
	
	onFilterBtnClick: function(ev) {
		ev.stop();
		if (this.requestRunning || this.disablePaging)
			return;

		var btn = ev.findElement(this.filterTagName);
		if (this.options.memberFilter == btn.readAttribute('class').replace(/\s*selected\s*/, ''))
			return;

		this.options.memberFilter = btn.readAttribute('class').replace(/\s*selected\s*/, '');
		this.setupFilterBtns(true);
		this.options.currentPage = 0;
		this.refresh();
	},
	
	onFilterBtnOver: function(ev) {
		var btn = ev.findElement(this.filterTagName);
		if (!btn.hover) {
			btn.hover = true;
			btn.addClassName('selected');
		}
	},
	
	onFilterBtnOut: function(ev) {
		var btn = ev.findElement(this.filterTagName);
		btn.hover = false;
		if (this.options.memberFilter == btn.readAttribute('class').replace(/\s*selected\s*/, ''))
			btn.addClassName('selected');
		else
			btn.removeClassName('selected');
	}
};