/// <reference path="ListBase.js" />

var NewspaperEditionList = Class.create(ListBase, {
	initialize: function ($super, el, options) {
		$super(el, Object.extend({ pagingEffect: 'vertSlide' }, options));
	},

	loadPage: function ($super, page) {
		$super(page);
		this.standardJSONRequest(this.options.url.replace('PLH_pagenum', page));
	}
});