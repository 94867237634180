/// <reference path="DOMAttached.js" />

var FeatureLevelSelector = Class.create(DOMAttached, {

	selector: null,
	triggeringControl: null,
	triggeringControlEventHandle: null,
	previousValue: null,

	initialize: function ($super, el, options) {
		$super(el, options);
		this.selector = $(el).down(".featureLevelSelector");
		this.selector.on("change", this.onValueChange.bind(this));
		this.previousValue = this.selector.value;
		if (this.options.warningTriggeringControl !== undefined) {
			this.triggeringControl = $(this.options.warningTriggeringControl);
			this.triggeringControlEventHandle = this.triggeringControl.on("click", this.onTriggeringControlClicked.bind(this));
		}
	},

	onValueChange: function (event) {
		if (this.selector.value != this.options.fullSocialEnumValue) {
			this.previousValue = this.selector.value;
		}
	},

	onTriggeringControlClicked: function (event) {
		if (this.selector.value == this.options.fullSocialEnumValue) {
			event.stop();
			confirmAI(this.options.warningTR, this.onConfirm.bind(this), this.onCancel.bind(this), true, true);
		}
	},

	onConfirm: function () {
		// in some browsers, simulating a click on a link does not work,
		// so instead we follow the link manually
		var link = $(this.triggeringControl).down('a');
		if (link) {
			window.location.href = $(link).readAttribute("href");
		} else {
			this.triggeringControlEventHandle.stop();
			this.triggeringControl.simulate("click");
		}
	},

	onCancel: function() {
		this.selector.value = this.previousValue;
	},

});
