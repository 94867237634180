var MiniworldHouseInventory = function (svgId) {

	this.svgIcon = document.getElementById(svgId);

	this.inventoryDiv = document.getElementById("inventoryDiv");
	this.inventoryDiv.style.display = "none";

	this.furnitureButton = document.getElementById("buttonFurniture");
	this.artButton = document.getElementById("buttonArt");
	this.petButton = document.getElementById("buttonPet");
	this.closeButton = document.getElementById("buttonClose");

	this.miniWorldHouseInsideCanvasDiv = jQuery(".mia-miniworldhouseinside-canvasDiv");
	this.miniWorldHouse = jQuery(".miniworldHouse");
	this.type = MiniworldHouseMenu.type.INVENTORY;

	this.reactToCurrentStateInteraction = function () {
		var room = miniworldHouseGetCurrentRoom();
		return (room.state == MiniworldHouseRoom.interactionStates.NO_INTERACTION ||
			(room.state == MiniworldHouseRoom.interactionStates.ITEM_INTERACTION && room.draggedItem().type != miniworldHouseItem.itemType.DOOR));
	};

	this.currentItemCategory = "Furniture";

	this.changeInventoryCategory = function (type) {
		document.getElementById("button" + this.currentItemCategory).classList.remove("selected");
		InventoryListInstance.filterForCategory(type);
		this.currentItemCategory = type;
		document.getElementById("button" + this.currentItemCategory).classList.add("selected");
	};

	this.close = function () {
		this.inventoryDiv.style.display = "none";
		this.svgIcon.style.backgroundColor = '';
		// Show room again
		this.miniWorldHouse.show();
		this.miniWorldHouseInsideCanvasDiv.css("margin-bottom", "0px");
		// Add handler so miniWorldHouseInsideCanvasDiv will be tracked by resize event and will have a height of the highest child
		trackAllScaleHeightToAbsoluteChildrenElements();
		jQuery(window).on("resize", trackAllScaleHeightToAbsoluteChildrenElements);
	};

	this.clickInteraction = function () {
		this.svgIcon.style.backgroundColor = '#bce5ff';
		this.inventoryDiv.style.display = "block";
		// Remove handler so miniWorldHouseInsideCanvasDiv won't be tracked by resize event
		// And thus let the container has the height of just opened(display: block, position:relative) this.inventoryDiv list
		jQuery(window).off("resize", "", trackAllScaleHeightToAbsoluteChildrenElements);
		this.miniWorldHouseInsideCanvasDiv.height("unset");

		// Hide room because height of inventory list is less than height of room.
		// Add negative margin-bottom(-$height--houseInventory-menu) to keep the same distance between miniWorldHouseInsideCanvasDiv and comment section
		// as inventory block has bottom: $height--houseInventory-menu and thus distance between miniWorldHouseInsideCanvasDiv and comment section is big.
		this.miniWorldHouse.hide();
		this.miniWorldHouseInsideCanvasDiv.css("margin-bottom", "-48px");

		this.changeInventoryCategory(this.currentItemCategory);
	};


	this.mouseUpInteraction = function () {
		if (miniworldHouseGetCurrentRoom().state == MiniworldHouseRoom.interactionStates.ITEM_INTERACTION) {
			miniworldHouseGetCurrentRoom().storeItemInInventory();
		}
	};


	this.onItemSelected = function (item) {
		var currentRoom = miniworldHouseGetCurrentRoom();
		currentRoom.placeItemFromInventory(item);
		this.close();
		miniworldHouseCommunication.storeItemInRoom(currentRoom.id, item);
	};

	this.closeButton.onclick = (function () {
		this.close();
	}).bind(this);
	this.furnitureButton.onclick = (function () {
		this.changeInventoryCategory("Furniture")
	}).bind(this);
	this.artButton.onclick = (function () {
		this.changeInventoryCategory("Art")
	}).bind(this);
	if (this.petButton) {
		this.petButton.onclick = (function () {
			this.changeInventoryCategory("Pet")
		}).bind(this);
	}

	GlobalOnInventoryItemSelectedListener = this.onItemSelected.bind(this);
};