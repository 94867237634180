//Enums
activeDrag = {
	NO: 0,
	YES: 1
};
currentMode = {
	DRAWPOINT: 0,	// draw a single point/line
	FILLSHAPE: 1,	// fill a part of the designitem
	STAMP: 2,		// put a stamp (image) on the designitem
	LETTER: 3,		// put a stamp (letter) on the designitem
	ERASE: 4		// erase
};
function ColorScheme() {
	//these values will be changed by construction parameters according to ai/cd. See below
	this.SecondaryColor = "#BEBEBE";
	this.ContentBackground = "white";
	this.ControlTextColor = "white";
	this.ControlButton = "#383838";
	this.ActiveButton = "#66CCCC";

}
colorscheme = new ColorScheme();

// injection in CanvasGradients because javascript can't restore gradients
// see https://stackoverflow.com/questions/20823204/how-to-extract-properties-from-html-canvas-canvasgradient-and-canvaspattern-obje?rq=1
// save native linear gradient function
var nativeCreateLinearGradient = CanvasRenderingContext2D.prototype.createLinearGradient;
// redefine createLinearGradient with a function that stores creation data
// new properties : gradientType, x0, y0, x1, y1, colorStops
CanvasRenderingContext2D.prototype.createLinearGradient = function (x0, y0, x1, y1) {
	// actually create the gradient
	var newGradient = nativeCreateLinearGradient.apply(this, arguments);
	// store creation data
	newGradient.gradientType = 0; // 0 for linear, 1 for radial
	newGradient.x0 = x0; newGradient.y0 = y0;
	newGradient.x1 = x1; newGradient.y1 = y1;
	newGradient.colorStops = [];
	return newGradient;
};
var nativeCreateRadialGradient = CanvasRenderingContext2D.prototype.createRadialGradient;
CanvasRenderingContext2D.prototype.createRadialGradient = function (x0, y0, r0, x1, y1, r1) {
	// actually create the gradient
	var newGradient = nativeCreateRadialGradient.apply(this, arguments);
	// store creation data
	newGradient.gradientType = 1; // 0 for linear, 1 for radial
	newGradient.x0 = x0; newGradient.y0 = y0;
	newGradient.x1 = x1; newGradient.y1 = y1;
	newGradient.r0 = r0; newGradient.r1 = r1;
	newGradient.colorStops = [];
	return newGradient;
};
var dummyContext = document.createElement('canvas').getContext('2d');
var nativeAddColorStop = CanvasGradient.prototype.addColorStop;
CanvasGradient.prototype.addColorStop = function (offset, color) {
	// evaluate offset (to avoid reference issues)
	offset = +offset;
	// evaluate color (to avoid reference issues)
	dummyContext.fillStyle = color;
	color = dummyContext.fillStyle;
	// store color stop
	this.colorStops.push([offset, color]);
	// build the real gradient
	nativeAddColorStop.call(this, offset, color);
	return this;
};

function DesignstudioEditorData(constructionParameters, svgContent) {

	var itemCanvas = document.getElementById('freestyle');
	var control = document.getElementsByClassName('designstudioEditorControlDiv')[0];
	this.svgContent = svgContent;
	this.controlWidth = window.getComputedStyle(control, null).getPropertyValue("width").slice(0,-2);
	this.controlHeight = window.getComputedStyle(control, null).getPropertyValue("height").slice(0,-2);
	this.paintingWidth = window.getComputedStyle(itemCanvas, null).getPropertyValue("width").slice(0,-2);
	this.paintingHeight = window.getComputedStyle(itemCanvas, null).getPropertyValue("height").slice(0,-2);
	this.XScale = 1;
	this.YScale = 1;
	this.paintAllFirstTime = true;
	this.saveAjaxUrl = constructionParameters.saveAjaxUrl;
	this.saveDraftAjaxUrl = constructionParameters.saveDraftAjaxUrl;
	this.loadDraft = constructionParameters.loadDraft;
	if (this.loadDraft == "true") {
		var tmp = JSON.parse(constructionParameters.draft);
		this.draft = tmp;
	}
	this.onSaveRedirectUrl = constructionParameters.onSaveRedirectUrl;
	this.memberOwnsAShop = constructionParameters.memberOwnsAShop;
	this.shapeId = constructionParameters.shapeId;
	this.productionCosts = constructionParameters.productionCosts;
	this.highestAllowedPrice = constructionParameters.highestAllowedPrice;
	this.emptyNameMessage = constructionParameters.emptyNameMessage;
	this.emptyPriceMessage = constructionParameters.emptyPriceMessage;
	this.isSavingInProcess = false;
	this.picker = JSON.parse(constructionParameters.picker);

	function create_cursors(constructionParameters) {
		this.eraser = constructionParameters.eraserCursor;
		this.bucket = constructionParameters.bucketCursor;
		this.brush = constructionParameters.brushCursor;
		this.stamp = constructionParameters.stampCursor;
	}

	this.cursor = new create_cursors(constructionParameters);
	function create_buttonimg() {
		this.brushButton = constructionParameters.brushButton;
		this.eraserButton = constructionParameters.eraserButton;
		this.bucketButton = constructionParameters.bucketButton;
		this.letterButton = constructionParameters.letterButton;
		this.stampButton = constructionParameters.stampButton;
		this.undoButton = constructionParameters.undoButton;
		this.undoDisabledButton = constructionParameters.undoDisabledButton;
	}

	this.buttonimg = new create_buttonimg();
}

//resize canvas and especially scale canvas-context so that items appear as large as possible
function resizeCanvas() {

	var canvasWidth = designData.paintingWidth;
	var canvasHeight = designData.paintingHeight;

	var canvaslist = [];
	canvaslist.push(backgroundCanvas, freestyleCanvas, toppingCanvas, objecthoverCanvas, clicklayer, finalCanvasTmp);

	designData.XScale = designData.YScale = Math.min(designData.paintingWidth / designitem.width, designData.paintingHeight / designitem.height);

	for (var i = 0; i < canvaslist.length; ++i) {
		canvaslist[i].width = canvasWidth;
		canvaslist[i].height = canvasHeight;
		canvaslist[i].style.webkitUserSelect = "none";
		canvaslist[i].style.webkitTapHighlightColor = "rgba(0,0,0,0)";
		canvaslist[i].getContext("2d").scale(designData.XScale, designData.YScale);
	}

	controlCanvas.width = designData.controlWidth;
	controlCanvas.height = designData.controlHeight;
	controlCanvas.style.webkitUserSelect = "none";

	finalCanvas.width = designitem.width * designData.XScale;
	finalCanvas.height = designitem.height * designData.YScale;
}

initializeDesignStudio = function (constructionParameters, svgContent) {
	document.removeEventListener("DOMContentLoaded", arguments.callee, false);

	// global variables
	designData = new DesignstudioEditorData(constructionParameters, svgContent);
	designitem = new Item();
	designitem.init();
	controlpanel = new ControlPanel();
	designEventHandler = new DesignstudioEventHandler();

	finalCanvas = document.getElementById('final');
	finalCanvasTmp = document.getElementById('finaltmp');
	backgroundCanvas = document.getElementById('background');
	freestyleCanvas = document.getElementById('freestyle');
	toppingCanvas = document.getElementById('topping');
	controlCanvas = document.getElementById('control');
	objecthoverCanvas = document.getElementById('objecthover');
	clicklayer = document.getElementById('clicklayer');

	document.getElementById("clicklayer").style.cursor = "url(" + designData.cursor.brush + "), auto";
	document.getElementById("contentcontent_designstudioeditor").style.borderSpacing = "0px";
	document.getElementById("contentcontent_tditem").style.borderTopRightRadius = "0px";
	document.getElementById("contentcontent_tditem").style.borderBottomRightRadius = "0px";
	document.getElementById("contentcontent_tdcontrol").style.borderTopLeftRadius = "0px";
	document.getElementById("contentcontent_tdcontrol").style.borderBottomLeftRadius = "0px";

	activePaint = false; //is currently mouse down for painting

	controlCanvas.addEventListener("mousedown", designEventHandler.control_getPosition, true);
	clicklayer.addEventListener("mousedown", designEventHandler.getPosition, true);
	clicklayer.addEventListener("mousedown", designEventHandler.letterPosition, true);
	clicklayer.addEventListener("mousedown", designEventHandler.objectPositionImage, true);
	clicklayer.addEventListener("touchend", designEventHandler.objectPositionImage, true);
	clicklayer.addEventListener("touchend", designEventHandler.letterPosition, true);
	clicklayer.addEventListener("mousemove", designEventHandler.handlerMousemove, true);
	clicklayer.addEventListener("touchmove", designEventHandler.handlerTouchmove, true);

	document.addEventListener("mouseup", designEventHandler.paintDeactivate, false);
	document.addEventListener("touchend", designEventHandler.paintDeactivate, false);
	document.addEventListener("keydown", designEventHandler.selectLetter, true);
	
	var saveDialog = new DesignstudioSaveDialog();
	saveDialog.initialize();

	if (window.jQuery) {
		var $ = document;

		var currentDomain = window.location.protocol + "//" + window.location.host + "/";

		var files = ["gradx.css"];
		var path = "generated/css/min/";

		for (var a = 0; a < files.length; ++a) {
			var head = $.getElementsByTagName('head')[0];
			var link = $.createElement('link');
			link.rel = 'stylesheet';
			link.type = 'text/css';
			link.href = currentDomain.concat(path, files[a]);
			link.media = 'all';
			head.appendChild(link);
		}


		styleGradx();
		designEventHandler.importColorFromColorPicker();
	}

	if (designData.loadDraft == "true") {
		for (var i = 0; i < designData.draft.colors.length; ++i){
			// if current jsoncolor is a gradient, build a gradient object and replace json with gradient
			if (typeof (designData.draft.colors[i]) == "object") {
				var gradient;
				if (designData.draft.colors[i].gradientType==0){
					gradient = dummyContext.createLinearGradient(designData.draft.colors[i].x0, designData.draft.colors[i].y0, designData.draft.colors[i].x1, designData.draft.colors[i].y1);
				}
				else {
					gradient = dummyContext.createRadialGradient(designData.draft.colors[i].x0, designData.draft.colors[i].y0, designData.draft.colors[i].r0, designData.draft.colors[i].x1, designData.draft.colors[i].y1, designData.draft.colors[i].r1);
				}
				for (var c = 0; c < designData.draft.colors[i].colorStops.length; ++c) {
					gradient.addColorStop(designData.draft.colors[i].colorStops[c][0], designData.draft.colors[i].colorStops[c][1]);
				}
				designData.draft.colors[i] = gradient;
			}
		}
		designitem.draft_stash(designData.draft);
		designitem.draft_pop();
	}

	paintAll();

	document.getElementById("clicklayer").style.cursor = "url(" + designData.cursor.brush + "), auto";
};


function styleGradx() {

	gradX("#gradX", {
		code_shown: false,
		sliders: [
			{
				color: "red",
				position: 20
			},
			{
				color: "green",
				position: 50
			},
			{
				color: "blue",
				position: 80
			}
		],
		change: function (stops, styles) {
			// When only one color is selected we need to set up background color manually
			if (styles.length === 1) {
				var gradXTarget = document.getElementById("gradXtarget2");
				gradXTarget.style.background = styles[0];
			}
			var gradxAddSlider = document.getElementById("gradx_add_slider");

			if (stops.length >= controlpanel.maxNumberOfStops) {
				gradxAddSlider.style.pointerEvents = "none";
				gradxAddSlider.classList.add("disabled");
				stops = stops.slice(0, controlpanel.maxNumberOfStops)
			} else {
				gradxAddSlider.style.pointerEvents = "auto";
				gradxAddSlider.classList.remove("disabled");
			}
			controlpanel.chosenColor.colorpickerstatus = [stops, styles];
		},
		targets: ["#gradXtarget2"]
	});



	document.getElementById('gradx_show_code').classList.add("hidden");
	document.getElementById('gradXtable').classList.add("hidden");

	var x = document.getElementsByClassName("gradx");
	var i;
	for (i = 0; i < x.length; i++) {
		x[i].style.background = colorscheme.PrimaryColor;
		x[i].style.border = 0;
	}

	// We want to get rid of some functionality of gradx in order to keep it simple to use.
	// As a certain developer insists of not modifying 3rd party libraries this is a
	// super-ugly hack to "hide" everything we want.
	var subtype2 = document.getElementById("gradx_gradient_subtype2");
	var parent = subtype2.parentNode;
	parent.removeChild(subtype2);
	var newchild = document.createElement("div");
	newchild.setAttribute('id', "gradx_gradient_subtype2");
	newchild.style.width = "0px";
	newchild.style.visibility = "hidden";
	newchild.style.height = "0px";
	parent.appendChild(newchild);


	var addslider = document.getElementById("gradx_add_slider");
	addslider.style.background = colorscheme.SecondaryColor;
	addslider.style.borderColor = colorscheme.SecondaryColor;
	addslider.style.borderBottomColor = colorscheme.SecondaryColor;
	addslider.style.color = "white";

	var x = document.getElementsByClassName("gradx_gradient_type");
	var i;
	for (i = 0; i < x.length; i++) {
		x[i].style.background = colorscheme.SecondaryColor;
		x[i].style.color = "white";
	}

	var x = document.getElementsByClassName("gradx_slider");
	var i;
	for (i = 0; i < x.length; i++) {
		x[i].style.width = 20;
		x[i].style.height = 20;
	}
}
