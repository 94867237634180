/// <reference path="DOMAttached.js" />

var GNOMPetImageBox = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.el.zIndex = 300;
		this.link = options.link;
		
		if (this.link != "") {
			this.safeObserve(this.el, 'click', this.onClick.bindAsEventListener(this));
			this.safeObserve(this.el, 'mouseover', this.onMouseOver.bindAsEventListener(this));
			this.el.addClassName('clickable');
		}
	},
	
	onClick: function (ev) {
		GenericSoundsInstance.pickUpSound();
		this.el.up('.GNOMApplication').down('.popupContainer').attachedObj.showPopup(this.link);
	},

	onMouseOver: function(ev) {
	}
});
