/// <reference path="DOMAttached.js" />
/// <reference path="SaveableSVG.js" />
/// <reference path="DraggableSvgItem.js" />
/// <reference path="ColorableSvgItem.js" />
/// <reference path="MiniMeAccessoryManager.js" />
/// <reference path="ColorSelector.js" />
/// <reference path="ChangeableMiniMeItem.js" />
/// <reference path="MiniMeSpeechBubble.js" />
/// <reference path="GenericSounds.js" />
/// <reference path="../../SoundPlayer/soundmanager2-nodebug.js" />
/// <reference path="ProgressIndicatingControl.js" />

var MiniMeSettings = {};
var MiniMeStaticItems = { hair: [], bottom: [], top: [], shoes: [] };
var MiniMeLastVersion = 0;
var MiniMeLastVersionSaved = 0;
var MiniMeLastVersionSaving = 0;
var MiniMeSpeechBubbles = [];
var IsSaveChangesBubleShown = false;

var MiniMeMain = Class.create(DOMAttached, {
	namespace: "http://www.w3.org/2000/svg",
	lastSave: null,
	itemInstancePrefix: "ii_",
	
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.showProgressIndicator();
		this.safeObserve(document, 'miniMe:hideProgressIndicator', this.hideProgressIndicator.bindAsEventListener(this));
		this.safeObserve(document, 'miniMe:showSuccessfulSaveBubble', this.showSuccessfulSaveBubble.bindAsEventListener(this));

		//Parameters
		this.options = options;

		//sounds
		this.playFeedbackSound = this.options.feedbackSoundOn;
		if (GenericSoundsInstance == null) {
			new GenericSounds(this.playFeedbackSound);
		}

		MiniMeSettings = options.settings.evalJSON(true);
		this.ensureSelectedItemConfiguration();
		this.validateMiniMeSettings();

		MiniMeStaticItems.face = options.staticFace;
		MiniMeStaticItems.hair = options.staticHair;
		MiniMeStaticItems.bottom = options.staticBottom;
		MiniMeStaticItems.top = options.staticTop;
		MiniMeStaticItems.shoes = options.staticShoes;
		MiniMeSpeechBubbles = options.speechBubbles;
		
		this.loadItems();
	},
	
	ensureSelectedItemConfiguration: function () {
		var defaultSettings = this.options.defaultSettings.evalJSON(true);
		this.ensureSelectedItemConfigurationFor('hair', defaultSettings.hair);
		this.ensureSelectedItemConfigurationFor('face', defaultSettings.face);
		this.ensureSelectedItemConfigurationFor('top', defaultSettings.top);
		this.ensureSelectedItemConfigurationFor('bottom', defaultSettings.bottom);
		this.ensureSelectedItemConfigurationFor('shoes', defaultSettings.shoes);
	},

	ensureSelectedItemConfigurationFor: function (itemKey, defaultSetting) {
		if (MiniMeSettings[itemKey] == undefined || MiniMeSettings[itemKey] == null) {
			MiniMeSettings[itemKey] = defaultSetting;
		}
	},

	validateMiniMeSettings: function () {
		this.miniMeSettingsAreValid = true;

		if (this.isSelectedCustomItemMissing(MiniMeSettings.bottom, this.options.customBottoms)) {
			MiniMeSettings.bottom = 1;
			this.miniMeSettingsAreValid = false;
		}

		if (this.isSelectedCustomItemMissing(MiniMeSettings.top, this.options.customTops)) {
			MiniMeSettings.top = 1;
			this.miniMeSettingsAreValid = false;
		}

		if (this.isSelectedCustomItemMissing(MiniMeSettings.shoes, this.options.customShoes)) {
			MiniMeSettings.shoes = 1;
			this.miniMeSettingsAreValid = false;
		}
	},

	isSelectedCustomItemMissing: function (setting, customItems) {
		return typeof (setting) == "string"
			&& setting.indexOf(this.itemInstancePrefix) === 0
			&& customItems.map(function (i) { return i.id; }).indexOf(setting.substring(3)) == -1;
	},
	
	loadItems: function () {
		var svgDatas = [{
			"id": this.options.blank.id,
			"relativePath": this.options.blank.relativePath
		}];

		svgDatas = svgDatas.concat(
			this.getSvgData(this.options.staticFace),
			this.getSvgData(this.options.staticHair),
			this.getSvgData(this.options.staticTop),
			this.getSvgData(this.options.staticBottom),
			this.getSvgData(this.options.staticShoes));
		
		var request = new Ajax.Request(
			this.options.itemsLoadUrl, {
				method: 'post',
				parameters: { svgData: JSON.stringify(svgDatas) },
				onSuccess: this.onSuccess.bind(this),
			}
		);
	},

	getSvgData: function (staticItems) {
		return staticItems.map(function (item) { 
			return { 
				"id": item.id, 
				"relativePath": item.relativePath 
			}; 
		});
	},

	onSuccess: function (req) {
		var json;
		try {
			json = req.responseText.evalJSON();
		} catch (e) {
			console.log(e);
		}
		var node = document.createElement('div');
		node.innerHTML = json.content;
		document.body.appendChild(node);
		this.initializeSVG();
		document.fire('miniMe:hideProgressIndicator');
	},

	initializeSVG: function () {
		this.editor = $(this.options.miniMeEditorId);
		
		this.initializeSaveButton();
		this.initializeColorSelector();
		this.initializeBackground();
		this.initializeMiniMe();
		this.initializeSpeechBubble();
		
		this.safeObserve($(this.options.avatarId), "mouseout", function () { this.showSaveBubble(false) }.bindAsEventListener(this));
	},
	
	initializeSaveButton: function() {
		var saveButton = jQuery('#saveButton');
		saveButton.css('cursor', 'pointer');
		
		saveButton.on("click", function () {
			this.saveButtonClick(true);
			saveButton.css('cursor', 'wait');
			window.setTimeout(function () {
				saveButton.css('cursor', 'pointer');
			}, 1000);
		}.bindAsEventListener(this));
	},

	// Show save changes bubble warning only after the first changes of the avatar on each page load. 
	showSaveBubble: function () {
		if (MiniMeLastVersionSaved < MiniMeLastVersion && !IsSaveChangesBubleShown) {
			var speechBubble = new MiniMeSpeechBubble(this.editor, MiniMeSpeechBubbles["saveChanges"]);
			IsSaveChangesBubleShown = true;
		}
	},
	
	saveButtonClick: function (sound) {
		if (sound) {
			GenericSoundsInstance.softClickSound();
		}

		var version = MiniMeLastVersion;
		if (version <= MiniMeLastVersionSaved || version <= MiniMeLastVersionSaving) {
			return;
		}
		MiniMeLastVersionSaving = version;
		
		if (ChangeInProgress) {
			return;
		}
		
		//copy to second svg
		var miniMeDocument = $(this.options.miniMeContainerForSavingId).getSVGDocument();

		this.copyBackgroundColorToDocument(miniMeDocument);
		
		var miniMe = document.createElementNS(this.namespace, 'g');
		this.copySelectedItemsToDocument(miniMe);
		miniMeDocument.getElementById('layer1').appendChild(miniMe);

		//save accessory settings
		var accSettings = this.accessoryManager.saveAccessorySettings();
		var svgSaver = new SaveableSVG(miniMeDocument, this.options.savePictureUrl, this.retrySaving.bind(this));

		this.showProgressIndicator();
		svgSaver.saveSVG(accSettings.parameters, accSettings.ids, version);

		Element.remove(miniMe);
	},

	showSuccessfulSaveBubble : function () {
		var speechBubble = new MiniMeSpeechBubble(this.editor, MiniMeSpeechBubbles["successfulSave"]);
	},

	copyBackgroundColorToDocument: function (miniMeDocument) {
		miniMeDocument.getElementById("bgStop1").style.stopColor = this.editor.getElementById("bgStop1").style.stopColor;
		miniMeDocument.getElementById("bgStop2").style.stopColor = this.editor.getElementById("bgStop2").style.stopColor;
	},

	copySelectedItemsToDocument: function (miniMe) {
		miniMe.setAttribute("transform", "translate(127,-69)");

		miniMe.appendChild(this.cloneNodeById('accessories-post'));

		miniMe.appendChild(this.cloneNodeById('body'));
		miniMe.appendChild(this.cloneNodeById('face' + MiniMeSettings.face));
		
		miniMe.appendChild(this.cloneClothingNodeByKey('bottom'));
		miniMe.appendChild(this.cloneClothingNodeByKey('top'));
		miniMe.appendChild(this.cloneClothingNodeByKey('shoes'));

		miniMe.appendChild(this.cloneNodeById('hair' + MiniMeSettings.hair));

		miniMe.appendChild(this.cloneNodeById('accessories-pre'));
	},

	cloneClothingNodeByKey: function (key) {
		var setting = MiniMeSettings[key];
		if (typeof (setting) == "string" && setting.indexOf(this.itemInstancePrefix) === 0) {
			return document.getElementById(setting).cloneNode();
		}

		return this.cloneNodeById(key + setting);
	},

	cloneNodeById: function (id) {
		var groupElement = document.createElementNS(this.namespace, 'g');
		var selectedItem = document.getElementById(id);

		for (var child = selectedItem.firstElementChild; child != null; child = child.nextElementSibling) {
			if (child.tagName !== "metadata") {
				groupElement.appendChild(child.cloneNode(true));
			}
		}
		return groupElement;
	},

	retrySaving: function() {
		MiniMeLastVersionSaving--;
		this.saveButtonClick(false, true);
	},
	
	initializeBackground: function() {
		var svg = this.editor;
		this.bgStop1 = svg.getElementById("bgStop1");
		this.bgStop2 = svg.getElementById("bgStop2");
		
		var settings = MiniMeSettings;
		if (typeof(settings.background) == "undefined") {
			settings.background = {};
		}
		if (settings.background.backgroundcolor != null) {
			this.bgStop1.style.stopColor = "#" + this.decToHex(settings.background.backgroundcolor);
			this.bgStop2.style.stopColor = "#" + this.decToHex(settings.background.backgroundcolor);
		}
		
		this.safeObserve(svg.getElementById("background"), "mouseup", function () { this.colorBackground(); }.bindAsEventListener(this));
	},
	
	colorBackground: function() {
		if (ColorSelectorActiveColor != null) {

			this.bgStop1.style.stopColor = this.getActiveColor(ColorSelectorActiveColor);
			this.bgStop2.style.stopColor = this.getActiveColor(ColorSelectorActiveColor);
			GenericSoundsInstance.softClickSound();
			MiniMeSettings.background.backgroundcolor = this.hexToDec(this.getActiveColor(ColorSelectorActiveColor).substring(1));
			MiniMeLastVersion++;
			ColorSelectorActiveColor = null;
			this.colorSelector.resetColors();
		}
	},
	
	initializeColorSelector: function() {
		this.colorSelector = new ColorSelector(this.editor, this.options.colorSelectorColors, 450, 798, true, this.playFeedbackSound);
	},
	
	initializeMiniMe: function() {
		//insert everything inside a document fragment to do only one page reflow
		var fragment = document.createDocumentFragment();
	
		var options = this.options;
		var colorSelector = this.colorSelector;
		
		var blank = new ColorableSvgItem(colorSelector, this.createBlankSvg(), options.blank.objectChains[0].first(), options.blank.objectChains[0].last());
		fragment.appendChild(blank.item);

		var face = new MiniMeFaces(fragment, colorSelector);
		var bottom = new MiniMeBottom(fragment, colorSelector, options.customBottoms);
		var top = new MiniMeTop(fragment, colorSelector, options.customTops);
		var hair = new MiniMeHair(fragment, colorSelector);
		var shoes = new MiniMeShoes(fragment, colorSelector, options.customShoes);
		
		this.editor.getElementById("character").appendChild(fragment);
		
		this.accessoryManager = new MiniMeAccessoryManager(this.editor, this.options.accessories, this.playFeedbackSound);
	},

	createBlankSvg: function () {
		var useSvg = document.createElementNS(this.namespace, 'use');
		useSvg.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#bodycolor');
		return useSvg;
	},
	
	initializeSpeechBubble: function () {
		if (this.options.firstMiniMe) {
			var bubble = new MiniMeSpeechBubble(this.editor, MiniMeSpeechBubbles["firstMiniMe"]);
		} else if (!this.miniMeSettingsAreValid) {
			var bubble = new MiniMeSpeechBubble(this.editor, MiniMeSpeechBubbles["changeAvatar"]);
		} else {
			var bubble = new MiniMeSpeechBubble(this.editor, MiniMeSpeechBubbles["initial"]);
		}
	},
	
	hexToDec: function(hex) {
		return parseInt("FF" + hex, 16);
	},
	
	decToHex: function(dec) {
		return dec.toString(16).toUpperCase().substring(2);
	},
	
	colorToHex: function(color) {
    	if (color.substr(0, 1) === '#') {
		return color;
    	}
   	 	var digits = /(.*?)rgb\((\d+), (\d+), (\d+)\)/.exec(color);
    
    	var red = parseInt(digits[2]);
    	var green = parseInt(digits[3]);
    	var blue = parseInt(digits[4]);
    
    	var rgb = blue | (green << 8) | (red << 16);
    	var rgb16 = rgb.toString(16);
    	while (rgb16.length < 6) {
    		rgb16 = "0" + rgb16;
    	}
    	return digits[1] + '#' + rgb16;
	},
	
	getActiveColor: function() {
		return this.colorToHex(ColorSelectorActiveColor); 
	}
});

MiniMeMain.addMethods(ProgressIndicatingControl);
