/// <reference path="DOMAttached.js" />

var FriendshipRequestButton = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		this.options = options;
		this.el = $(el);
		
		this.acceptBtn = $(this.options.acceptBtn);
		this.conReqLit = $(this.options.conReqLit);
		this.responseMsg = $(this.options.responseMessage);
		if (this.responseMsg !== undefined) {
			this.responseMsg.hide();
		}
		if(this.acceptBtn){
			this.safeObserve(this.acceptBtn, 'click', this.onAccept.bindAsEventListener(this));
		}
	},
	
	onAccept: function(event) {
		event.stop();
		this.acceptBtn.hide();
		this.responseMsg.show();
		if (!this.options.alreadyFriends) {
			new Ajax.Request(
				new UrlTemplate(this.options.acceptLink).url, {
					method: 'get',
					onSuccess: this.onResponse.bind(this),
				}
			);
		} else {
			this.conReqLit.show();
		}
	},
	
	onResponse: function(req) {
		var json = req.responseText.evalJSON();
		this.conReqLit.innerHTML = json.status;
		this.conReqLit.show();
	}
});
