/*
 * Convenience class for logic that is unique to free input quizzes.
 */
var FreeInputQuiz = Class.create({
	/**
	 * The container is used to gain access to the DOM. The submitButton is used 
	 * by the child to submit its answer. Depending on the submitted answer, the 
	 * corresponding callback is called.
	 */
	initialize: function (container, gameSettings, correctAnswerCallback, incorrectAnswerCallback, updateQuestionCallback) {
		this.container = container;
		this.gameSettings = gameSettings;
		this.correctAnswerCallback = correctAnswerCallback;
		this.incorrectAnswerCallback = incorrectAnswerCallback;
		this.updateQuestionCallback = updateQuestionCallback;
		this.questionResponse = null;
	},

	/**
	 * Public. Call this whenever you have loaded a new question in order to
	 * set up click handlers etc.
	 */
	onNextQuestionLoaded: function () {
		this.locked = false;
		this.initializeListeners();
		if (this.container.el.down('.solutionInput') != undefined) {
			this.container.el.down('.solutionInput').down('input').focus();
		}
	},

	/**
	 * Public. Called by the games quiz instance to get the current TTS elements.
	 */
	createTextToSpeechElements: function (questionSpeechFilePath, answerSpeechFilePaths) {
		var textToSpeechElements = [];
		textToSpeechElements.push(this.createQuestionTextToSpeechElement(questionSpeechFilePath));
		return textToSpeechElements;
	},

	/**
	 * Combines all the information needed for TextToSpeech to play the speech corresponding
	 * to the question text.
	 */
	createQuestionTextToSpeechElement: function (questionSpeechFilePath) {
		var questionElement = {};
		questionElement.domElement = this.container.el.down('.question');
		questionElement.identifier = 'question';
		questionElement.soundUrl = questionSpeechFilePath;
		questionElement.highlightFontColor = 'white';
		questionElement.defaultFontColor = this.getFontColor(questionElement.domElement);
		return questionElement;
	},

	/**
	 * Convenience method for getting an element's background color.
	 */
	getBackgroundColor: function (element) {
		return element.getStyle('backgroundColor');
	},
	
	/**
	 * Convenience method for getting an element's font color.
	 */
	getFontColor: function (element) {
		return element.getStyle('color');
	},

	/**
	 * Sets up a click listener for the submit button and a listener for the return key.
	 */
	initializeListeners: function () {
		this.container.safeObserve(this.container.el.down('.submitButton'), 'click', this.onSubmitButtonClick.bindAsEventListener(this));
		this.container.safeObserve(this.container.el.down('.nextQuestionButton'), 'click', this.onNextQuestionButtonClick.bindAsEventListener(this));
		jQuery(this.container.el).on('keypress', this.onKeyPress.bind(this));
	},

	onSubmitButtonClick: function (ev) {
		this.onAnswerSubmitted(ev);
	},

	/**
	 * Pulls the next question / content from the ajax response.
	 */
	onNextQuestionButtonClick: function(ev) {
		ev.stopPropagation();
		this.updateQuestionCallback(this.questionResponse);
	},

	/**
	 * Either submit an answer or load the next page on "Enter" keypress depending on the state of the UI.
	 */
	onKeyPress: function (ev) {
		if (ev.keyCode == Event.KEY_RETURN) {
			ev.stopImmediatePropagation();

			if (jQuery(".submitButton").is(":visible"))  {
				this.onAnswerSubmitted(ev);
			} else if (jQuery(".nextQuestionButton").is(":visible")) {
				jQuery('.nextQuestionButton').hide();
				this.updateQuestionCallback(this.questionResponse);
			}
		}
	},

	/**
	 * Executed whenever the user submits an answer to the current question.
	 */
	onAnswerSubmitted: function (ev) {
		ev.preventDefault();
		
		if (this.locked) {
			return;
		}

		if (!this.getTextsSubmittedByUser()[0]) {
			this.container.el.down('.dispIfSolutionInputIsEmpty').show();
			this.container.el.down('.solutionInput').addEventListener('input', this.onInputChange.bind(this), { once: true })
			return;
		}

		this.locked = true;
		this.hideAnswerInput();

		var answerWasCorrect = this.doesSubmittedTextEqualCorrectSolution();
		this.displayFeedback(answerWasCorrect);
		if (answerWasCorrect) {
			this.correctAnswerCallback(" ").then(function(response) {
				this.questionResponse = Object.assign({}, response.ProcessAnswerResult, response.QuestionData);
				this.updateQuestionCallback(this.questionResponse, false);
			}.bind(this)); // There is no answer id for free input games.
		} else {
			// There is no answer id for free input games.
			this.incorrectAnswerCallback(" ").then(function(response) {
				this.questionResponse = Object.assign({}, response.ProcessAnswerResult, response.QuestionData);
				this.gameSettings.shouldDisplayWhetherChosenAnswerIsCorrect 
				? jQuery('.nextQuestionButton').show()
				: this.updateQuestionCallback(this.questionResponse);
			}.bind(this)); 
		}
	},

	onInputChange: function () {
		this.container.el.down('.dispIfSolutionInputIsEmpty').hide();
	},

	/**
	 * Hides everything that is used to input an answer.
	 */
	hideAnswerInput: function () {
		jQuery('.solutionInput').find('input').prop("readonly", true);
		this.container.el.down('.specialLetters').hide();
		this.container.el.down('.submitButton').hide();
	},

	/**
	 * The input parameter has the format {slightlyWrong: bool, success: bool}
	 * and is used to determine what kind of feedback should be displayed to the user.
	 */
	displayFeedback: function (answerWasCorrect) {
		if (this.gameSettings.shouldDisplayWhetherChosenAnswerIsCorrect) {
			if (answerWasCorrect) {
				this.container.el.down('.dispIfSolutionCorrect').show();
				jQuery('.mia-solutionIcon.mia-correctIcon').show();
			} else {
				jQuery('.mia-solutionIcon.mia-wrongIcon').show();
				if (this.gameSettings.shouldDisplayCorrectSolution) {
					this.container.el.down('.dispIfSolutionIncorrectWithCorrectSolution').show();
				} else {
					this.container.el.down('.dispIfSolutionIncorrect').show();
				}
			}
		}
	},

	/**
	 * Checks whether all input submitted by the user is correct.
	 */
	doesSubmittedTextEqualCorrectSolution: function () {
		var textsSubmittedByUser = this.getTextsSubmittedByUser();
		var textsOfCorrectSolution = this.getTextsOfCorrectSolution();

		// Todo: For some obscure reason, the div with class "correctSolution" is a child
		// element of the div with class "solutionInput", so textsSubmittedByUser contains
		// not only the texts submitted by the user but ALSO the textsOfCorrectSolution!
		// This current implementation here works, but the DOM structure should be fixed.
		return textsOfCorrectSolution.map(function (correctText, index) {
			return this.isAnswerCorrect(correctText, textsSubmittedByUser[index]);
		}.bind(this)).indexOf(false) == -1;
	},

	/**
	 * Collects all the texts entered into any input element inside the solution input element.
	 */
	getTextsSubmittedByUser: function () {
		return this.getValuesOfInputChildrenElementsOf('.solutionInput');
	},
	
	/**
	 * Collects all the texts contained in the correct solution element.
	 */
	getTextsOfCorrectSolution: function() {
		return this.getValuesOfInputChildrenElementsOf('.correctSolution');
	},

	/**
	 * Collects all the texts contained in any input element that is a child of the first 
	 * element that matches the given parentSelector.
	 */
	getValuesOfInputChildrenElementsOf: function(parentSelector) {
		var inputIndex = 0;
		var valuesOfInputElements = new Array();
		var inputElement = this.container.el.down(parentSelector).down('input', inputIndex);
		while (inputElement != undefined) {
			valuesOfInputElements.push(inputElement.value.trim());
			inputIndex++;
			inputElement = this.container.el.down(parentSelector).down('input', inputIndex);
		}
		return valuesOfInputElements;
	},

	/**
	 * Returns whether text that was submitted by user is correct answer to the question.
	 */
	isAnswerCorrect: function(correctText, textSubmittedByUser) {
		var validTimeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
		var validRemainderRegex = /^-?\d+\sR\s\d+$/;
		var validPercentageRegex = /^-?\d+(\.\d+)?%$/;
		var validDollarMoneyRegex = /^\$-?(\d{1,3}(?:,\d{3})*|\d+)(\.\d{2})?$/;
		var validNumberRegex = /^(-?(?=0|\d)(\d{1,3}(,\d{3})*|\d+)?(\.\d+)?|\.\d+)$/;
		
		if (validTimeRegex.test(correctText)) {
			return validTimeRegex.test(textSubmittedByUser) && correctText == textSubmittedByUser;
		} else if (validRemainderRegex.test(correctText)) {
			return validRemainderRegex.test(textSubmittedByUser) && correctText == textSubmittedByUser;
		} else if (validPercentageRegex.test(correctText)) {
			return validPercentageRegex.test(textSubmittedByUser) && parseFloat(correctText.replace(/%/, '')) == parseFloat(textSubmittedByUser.replace(/%/, ''));
		} else if (validDollarMoneyRegex.test(correctText)) {
			return validDollarMoneyRegex.test(textSubmittedByUser) && parseFloat(correctText.replace(/[$,]/g, '')) == parseFloat(textSubmittedByUser.replace(/[$,]/g, ''))
		} else if (validNumberRegex.test(correctText)) {
			return validNumberRegex.test(textSubmittedByUser) && parseFloat(correctText.replace(/,/g, '')) == parseFloat(textSubmittedByUser.replace(/,/g, ''));
		} else {
			window.onerror('Regex is not exist for {0}'.format(correctText));
			return true;
		}
	}
});
