/// <reference path="DOMAttached.js" />
/// <reference path="ProgressIndicatingControl.js" />
/// <reference path="GenericSounds.js" />
/// <reference path="../../../node_modules/soundmanager2/script/soundmanager2-nodebug.js" />

var blocked = new Boolean(false);
var TabbedApplication = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.el.attachedObj = this;
		
		this.tabs = $$('.tabappTab:not(.mia-feedbackSoundTab)');
		this.links = options.tabLinks;
		this.playFeedbackSound = options.playFeedbackSound;
		this.content = this.el.down('.contentPane');
		this.currentTab = this.tabs[0];
		this.currentTab.addClassName('selected');
		this.selectTab(0);
		
		for (var i = 0; i < this.tabs.length; i++)
			this.safeObserve(this.tabs[i], 'click', this.loadTab.bindAsEventListener(this, i));
			
		this.registerLinks();
		
		if (GenericSoundsInstance == null) {
			GenericSoundsInstance = new GenericSounds(this.playFeedbackSound);
		}
		
		GenericSoundsInstance.introSound();
		
		if (this.options.defaultPage != undefined) {
			this.loadPage(this.options.defaultPage);
		}
	},
	
	registerLinks: function() {
		var links = this.el.down('.contentPane').getElementsBySelector('a.tabLink');
		for (var i = 0; i < links.length; i++)
			this.safeObserve(links[i], 'click', this.onClickLink.bindAsEventListener(this, links[i].href));
	},
	
	onClickLink: function(ev, url) {
		if(!this.blocked) {
			ev.stop();
			this.loadPage(url);
		}
	},
	
	registerTimer: function(timer) {
		this.timer = timer;
	},
	
	registerInterval: function(interval) {
		this.interval = interval;
	},
	
	loadPage: function(url) {
		if(!this.blocked)
			this.loadPageWithOwnSuccessHandler(url, this.onPageLoaded.bind(this));
	},
	
	loadPageWithOwnSuccessHandler: function(url, onSuccessHandler) {
		this.showProgressIndicator(true, false);
		
		if (this.timer != undefined) {
			try {
				clearTimeout(this.timer);
			} finally {
				this.timer = undefined;
			}
		}
		
		if (this.interval != undefined) {
			try {
				clearInterval(this.interval);
			} finally {
				this.interval = undefined;
			}
		}

		var req = new Ajax.Request(url, {
			method: 'get',
			onSuccess: onSuccessHandler,
		});	
	},
	
	loadTab: function(event, index) {
		if(!this.blocked) {
			soundManager.stopAll();
			GenericSoundsInstance.clickSound();
			if(index == 3) {
				GenericSoundsInstance.shopSound();
			}
		}
		this.selectTab(index);
		this.loadPage(this.links[index]);
	},
	
	findTab: function(url) {
		for (var i = 0; i < this.links.length; ++i) {
			if (this.links[i] == url) {
				return i;
			}
		}
		return -1;
	},
	
	findAndLoadTab: function(url) {
		this.loadTab(null, this.findTab(url));
	},
		
	selectTab: function(index) {
		if (!this.blocked) {
			this.currentTab.classList.remove("selected");
			this.currentTab = this.tabs[index];
			this.currentTab.addClassName("selected");
		}
	},
	
	activatePlaceholder: function(name, caption, url) {
		for (var i = 0; i < this.tabs.length; i++) {
			if (this.tabs[i].identify().endsWith('_' + name)) {
				this.tabs[i].innerHTML = caption;
				this.links[i] = url;

				this.tabs[i].style.display = 'block';
				this.selectTab(i);
				break;
			}
		}
	},
	
	onPageLoaded : function(req) {
		var json = req.responseText.evalJSON();
		
		if (json.success) {
			if (json.successpopup != undefined) {
				this.el.down('.popupContainer').attachedObj.showContent(json.successpopup);
				this.hideProgressIndicator();
				return;
			}

			this.content.innerHTML = json.page;
			this.content.innerHTML.evalScripts();
			this.registerLinks();
		} else {
			//Does it come with a message popup?
			if (json.errorpopup != undefined) {
				this.el.down('.popupContainer').attachedObj.showContent(json.errorpopup);
				this.hideProgressIndicator();
				return;
			}
			
			//TODO: Proper error handling
			this.content.innerHTML = '<b>Could not load page. Try again!</b>';
		}
		
		this.hideProgressIndicator();
	},
});

TabbedApplication.addMethods(ProgressIndicatingControl);
