/// <reference path="DOMAttached.js" />

var ToggleQuestionReaderInstance = null;

var ToggleQuestionReader = Class.create(DOMAttached, {
  initialize: function($super, el, options) {
	$super(el, options);
	this.options = options;
	
	ToggleQuestionReaderInstance = this;

	this.button = $(options.buttonLink);
	this.imgPlay = $(options.playButton);
	this.imgPause = $(options.pauseButton);
	this.turnOnTooltip = options.turnOnTooltip;
	this.turnOffTooltip = options.turnOffTooltip;
	  
	this.url = this.button.href;
	this.button.href = '#?'; // Don't jump
	
	this.isAndroid = navigator.userAgent.match(/droid/i) != null;

	this.safeObserve(this.button, 'click', this.onClick.bindAsEventListener(this));
	
	this.showCorrectImage();
  },
  
  onClick: function (ev) {
	new Ajax.Request(this.url, {
		method: 'get',
		onSuccess: this.onSuccess.bind(this),
	});
  },
  
  onSuccess: function (ev) {
  	if (GamesTextToSpeechInstance !== null && GamesTextToSpeechInstance !== undefined) {
  		if (GamesTextToSpeechInstance.isEnabled) {
  			GamesTextToSpeechInstance.turnOffTextToSpeech();
  		} else {
  			GamesTextToSpeechInstance.turnOnTextToSpeech();
  			GamesQuizInstance.createAndReadAllTextToSpeech();
  		}
  		this.showCorrectImage();
  	}
  },

  showCorrectImage: function () {
  	if (GamesTextToSpeechInstance !== null && GamesTextToSpeechInstance !== undefined) {
  		if (GamesTextToSpeechInstance.isEnabled) {
  			this.imgPlay.style.display = 'none';
  			this.imgPause.style.display = 'block';
  			this.button.title = this.turnOffTooltip;
  		} else {
  			GamesTextToSpeechInstance.removeTextToSpeechEffects();
  			this.imgPlay.style.display = 'block';
  			this.imgPause.style.display = 'none';
  			this.button.title = this.turnOnTooltip;
  		}
  	}
  },
});
