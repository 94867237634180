/// <reference path="DOMAttached.js" />

var ThemedButton = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);

		this.linkElement = $(el).down("a");

		this.safeObserve(this.linkElement, 'click', this.onClick.bindAsEventListener(this));
	},

	onClick: function (ev) {
		ev.stop();

		confirmAI(this.options.trConfirmText, this.onConfirm.bind(this), this.onDelete.bind(this), true, true);
	},

	onConfirm: function (ev) {
		window.location.href = this.linkElement.href;
	},

	onDelete: function (ev) {
		// do nothing
	},
});