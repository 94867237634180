/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />

var TypingGuardianServerInterface = null;

var TypingGuardian = Class.create(DOMAttached, {
  initialize: function($super, el, options) {
    $super(el, options);
    
    TypingGuardianServerInterface = this;

    var isMobile = /iPad|iPhone|iPod|Android|webOS|Windows Phone/.test(navigator.userAgent);
    if (isMobile) {
    	confirmAI(options.mobileMessage,
			this.onMobileRedirect.bind(this), this.onMobileRedirect.bind(this),
			true, false, { confirmBtnCaption: this.options.mobileButtonText });
    }
  },

  onMobileRedirect: function () {
  	window.location.href = this.options.mobileRedirectURL;
  },
  
  levelReachedRequest: function(level, score) {
	new Ajax.Request(		new UrlTemplate(this.options.levelReachedURL).r('PLH_level', level).r('PLH_score', score).url, 		{			method: 'get',			onSuccess: function(response) {				var parsedResponse = response.responseText.evalJSON();				var temp = new TypingGuardianClientInterface();				temp.levelReachedResponse(parsedResponse.medal);			}		}
	);
  },
  
  getCurrentLevel: function(){
	return this.options.lastCompletedLevel;
  },
  
  getHighestMedalOnGameStart: function(){
	return this.options.highestAwardedMedal;
  }
});
