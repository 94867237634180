/// <reference path="DOMAttached.js" />

var GamePopup = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.buttons = this.el.down('.buttonPane').getElementsBySelector('input');
		
		for (var i = 0; i < this.buttons.length; i++) {
			if (this.options['button' + i] != "nolink") {
				this.safeObserve(this.buttons[i], 'click', this.onButtonClick.bindAsEventListener(this, 'button' + i));
			}
		}
	},
	
	onButtonClick: function(ev, id) {
		GenericSoundsInstance.clickSound();
		ev.stop();
		if (this.options[id] == "") {
			if (this.el.up('.popupContainer') != undefined) {
				this.el.up('.popupContainer').attachedObj.hidePopup();
			} else {
				this.el.up('.animationContainer').attachedObj.hideAnimation();
			}
		} else {
			var textboxes = this.el.getElementsBySelector('.textbox');
			var url = this.options[id];
			
			for (var i = 0; i < textboxes.length; i++) {
				var id = textboxes[i].identify();
				url = url.replace('PLH_' + id.substr(id.lastIndexOf('_') + 1), encodeURIComponent(textboxes[i].down("input").getValue()));
			}
			
			this.el.up('.tabbedApplication').attachedObj.loadPage(url);
		}
	}
});