/// <reference path="../UrlTemplate.js" />
/// <reference path="../TextResource.js" />

EasierLevelInstance = null;

var EasierLevel = Class.create({
	initialize: function (options) {
		this.trEasierLevelButton = options.trEasierLevelButton;
		this.trEasierLevelModalDescription = options.trEasierLevelModalDescription;

		EasierLevelInstance = this;
	},
	setEasierLevel: function(url, onSuccess) {
		new Ajax.Request(new UrlTemplate(url).r('PLH_level', -1).url, {
			method: 'get',
			onSuccess: onSuccess.bind(this),
		});
	},
	updateModalTitle: function(element, previousLevel) {
		element.down(".title").innerText = new TextResource(EasierLevelInstance.trEasierLevelButton).r('{number}', previousLevel).string();
	},
	updateModalDescription: function(element, previousLevel) {
		element.down(".easierLevelText").innerText = new TextResource(EasierLevelInstance.trEasierLevelModalDescription).r('{number}', previousLevel).string()
	},
	updateEasierLevelButtonText: function(element, previousLevel) {
		element.innerText = new TextResource(EasierLevelInstance.trEasierLevelButton).r('{number}', previousLevel).string();
	},
	hideEasierLevelButton: function(element) {
		element.style.display = "none";
	},
	showEasierLevelButton: function(element) {
		element.style.display = "";
	},
	initializeEasierLevel: function(context) {
		waitForElementToBePresent(".mia-easierLevelButton").then(this.initializeEasierLevelButton.bind(context));
		waitForElementToBePresent(".mia-easierLevelButtonText").then(this.initializeEasierLevelButtonText.bind(context));
		waitForElementToBePresent(".mia-EasierLevelModal").then(this.initializeEasierLevelModal.bind(context));
	},
	initializeEasierLevelModal: function(element) {
		if (this.level !== 1) { 
			var previousLevel = this.level - 1;
			EasierLevelInstance.updateModalTitle(element, previousLevel); 
			EasierLevelInstance.updateModalDescription(element, previousLevel);
		}
	},
	initializeEasierLevelButton: function(element) {
		if (this.level === 1) {
			EasierLevelInstance.hideEasierLevelButton(element);
		} else {
			EasierLevelInstance.showEasierLevelButton(element);
		}
	},
	initializeEasierLevelButtonText: function(element) {
		if (this.level !== 1) {
			EasierLevelInstance.updateEasierLevelButtonText(element, this.level - 1)
		}
	},
});
