/// <reference path="DOMAttached.js" />

var PopupOpener = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);
		this.safeObserve(this.el, 'click', this.onClick.bindAsEventListener(this));
		this.url = $(el).href;
	},

	onClick: function (e) {
		this.popup = window.open(this.url, "", "scrollbars=1");
		this.popup.resizeTo(this.options.Width, this.options.Height);

		if (this.options.adjustHeightToContent !== undefined && this.options.adjustHeightToContent) {
			this.safeObserve(this.popup, 'load', this.onLoad.bindAsEventListener(this));
		}
		e.stop();
	},

	onLoad: function () {
		this.headerHeight = this.options.Height - this.popup.document.documentElement.clientHeight;
		this.contentHeight = this.popup.document.body.clientHeight;
		this.popup.resizeTo(this.options.Width, this.headerHeight + this.contentHeight);
	}
});
