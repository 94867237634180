/// <reference path="../lib/protoaculous/protoaculous.1.9.0-1.7.2.0.js" />

Ajax.Responders.register({
	onException: function(request, e) {
		// We cannot use TextResources in this legacy JavaScript code so we hardcoded this to the current value of ErrorUnexpectedAnswerFromServer.
		MiaErrorPrompt("Sorry, an unexpected error occurred.");
		throw e;
	},
	onFailure: function(response) {
		// We cannot use TextResources in this legacy JavaScript code so we hardcoded this to the current value of ErrorUnexpectedAnswerFromServer.
		MiaErrorPrompt("Sorry, an unexpected error occurred.");
		throw new Error("AJAX Request failed: " + response.responseText);
	}
});
