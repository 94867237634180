/// <reference path="../UrlTemplate.js" />
/// <reference path="../TextResource.js" />
/// <reference path="../ProgressIndicatingControl.js" />
/// <reference path="ExceptionHandling.js" />

var Levels = Class.create({
	initialize: function (container, levelOptions) {
		this.container = container;
		this.levelOptions = levelOptions;
		this.el = this.container.el;

		this.showTextMediaInitially = true;

		this.jumpLevelsClicked = false;
		this.hasJustJumpedLevels = false;

		this.setLevelInformation(this.levelOptions.level);

		this.modalId = 'levelUpModal';
		this.modalElement = jQuery('.modal[modal-id=' + this.modalId + ']');

		this.medalBox = this.el.down('.medalBox').down('img');
		this.hideMedalBoxIfThereIsNoMedal();

		this.displayChallengeCompletedWindow = false;
		this.initializeClickListeners();

		if (!document.querySelector('.mia-Lightbox')) { 
			this.modalElement.appendTo("form");
		}
	},

	/**
	 * This is the method that is called from the quiz right before the next question 
	 * is displayed. The return value indicates whether the next question should be 
	 * displayed immediately.
	 */
	customLoadEvent: function (pageLoadJSON) {
		this.container.disableInput();
		var newLevelText = 'Level: ' + pageLoadJSON.level;
		this.adjustDisplayToNewRegularLevel(pageLoadJSON);
		
		if (pageLoadJSON.isLevelUp) {
			return this.onLevelUp(newLevelText);
		} else {
			return true;
		}
	},

	/**
	 * This is called by the quiz to pass in some data relevant for the new question.
	 */
	onSendAnswerToServerSuccess: function (response, rejectPromise) {
		this.json = response;
	},

	/**
	 * Initializes all the listeners for the game's level related buttons.
	 */
	initializeClickListeners: function () {
		jQuery('.levelUpButton').click(this.onNextLevelOkayClick.bindAsEventListener(this));
		jQuery('.lastLevelContinueButton').click(this.onNextLevelOkayClick.bindAsEventListener(this));
		jQuery('.levelDown').click(this.onLevelDownClick.bindAsEventListener(this));

		removeFocusFromModalInWindow(this.modalId);
	},

	hideMedalBoxIfThereIsNoMedal: function () {
		if (this.levelOptions.nomedal == "true" || (this.medalBox != undefined && this.medalBox.src == "")) {
			this.medalBox.style.display = "none";
		}
	},

	setLevelInformation: function (level) {
		this.el.down('span.messageLevel').innerHTML =
			new TextResource(this.levelOptions.trLevelInformation).r('{level}', level).string();
	},

	setLevelDownVisibility: function (currentLevel) {
		if (currentLevel == 1) {
			this.el.down('.levelDown').style.display = "none";
		} else {
			this.el.down('.levelDown').style.display = "";
		}
	},

	setNextMedalSignVisibility: function (nextMedalLevel, hasNextMedal) {
		if (hasNextMedal && nextMedalLevel > 0) {
			this.el.down('.nextMedalSpan').innerHTML = 'after Level ' + nextMedalLevel;
			this.el.down('.nextMedalSign').style.display = "inline-block";
		} else {
			this.el.down('.nextMedalSign').style.display = "none";
		}
	},

	adjustDisplayToNewRegularLevel: function (pageLoadJSON) {	
		this.el.down('.medalIcon').src = pageLoadJSON.nextMedalImageSource;
		this.el.down('.maxLevelSpan').style.display = "inline-block";
			
		this.setNextMedalSignVisibility(pageLoadJSON.nextMedalLevel, !pageLoadJSON.noNextMedal);
		this.setLevelDownVisibility(pageLoadJSON.level);
	},

	/**
	 * When the user reaches a new level, we display some overlay notifying him
	 * about that.
	 */
	onLevelUp: function (newLevelText) {
		var previousLevelWasLastLevelOfGame = newLevelText == 'Level: 1';

		this.el.down('.currentLevel').innerHTML = newLevelText;
		jQuery('.level').html(newLevelText);
		this.el.down('.questionsLeft').innerHTML = "";
		this.el.down('.progressBar').style.width = ($$(".progressBox")[0].clientWidth) + "px";

		jQuery('.lastLevelContinueButton').css("display", previousLevelWasLastLevelOfGame ? "inline-block" : "none");
		jQuery('.levelUpButton').css("display", previousLevelWasLastLevelOfGame ? "none" : "");
		jQuery(".endGameButtons").css("display", "none");

		jQuery('.modal[modal-id=' + this.modalId + '] .modal-header > h3').html( this.levelOptions.trCongratsTitle);
		jQuery('.infoText').html(new TextResource(this.levelOptions.trLevelReached).r('{level}', this.json.level).r('{amount}', this.json.amount).string());

		if (!this.hasJustJumpedLevels) {
			this.modalElement.modal({ backdrop: "static", keyboard: false });
			this.container.disableInput();
		} 
		if (this.hasJustJumpedLevels) {
			this.hasJustJumpedLevels = false;
			return true;
		} else if (previousLevelWasLastLevelOfGame) {
			// We don't want to display "congrats, you made it to level 1!", so we skip the overlay.
			this.onNextLevelOkayClick();
		}
		return false;
	},

	/*Encloses modal inside game window*/
	encloseModalInGameWindow: function () {
		jQuery(".modal-backdrop").appendTo(".quiz");
		var bodyJQuery = jQuery("body");
		bodyJQuery.removeClass("modal-open");
		//Bootstrap adds padding-right: 5px by default but we don't need it
		bodyJQuery.css("padding-right", 0);
	},

	/**
	 * This method is used as a click handler for any of the buttons that close
	 * a level-up overlay via an "Okay, continue" button. It either makes the next
	 * overlay show up or lets the user continue playing the game.
	 */
	onNextLevelOkayClick: function (ev) {
		if (ev != undefined) {
			ev.preventDefault();
		}

		var gotNewMedal = this.json.medal != undefined && !(this.json.medal.endsWith("games/medals/medal_none.svg"));
		var gotNewPet = this.json.pet != undefined && this.json.pet != "";
		var finishedTheGame = this.json.level == 1 && !this.hasJustJumpedLevels;
		
		if (gotNewMedal) {
			if (this.medalBox != undefined) {
				this.medalBox.src = this.json.medal;
				this.medalBox.style.display = "inline";
			}
			this.modalElement.find('.infoText').html(this.levelOptions.trMedalMessage);
			this.modalElement.find('.level').css("display", "none");
			this.modalElement.find('.image').css("display", "inline-block");
			this.modalElement.find('.image').attr("src", this.json.medal);
			this.modalElement.find('.imageCaption').css("display", "block");
			this.modalElement.find('.imageCaption').html(this.json.medalName);
			if (this.json.openProdding != undefined && this.json.openProdding != "") {
				this.modalElement.find('.openProdding').html(this.json.openProdding);
			}
			this.json.medal = "games/medals/medal_none.svg";
			this.displayChallengeCompletedWindow = true;
		} else if (gotNewPet) {
			this.modalElement.find('.infoText').html(this.levelOptions.trPetMessage);
			this.modalElement.find('.level').css("display", "none");
			this.modalElement.find('.image').attr("src", this.json.pet);
			this.modalElement.find('.image').css("display", "inline-block");
			this.modalElement.find('.imageCaption').html(this.json.petName);
			this.modalElement.find('.imageCaption').css("display", "block");
			this.modalElement.find('.openProdding').html("");
			this.json.pet = "";
		} else {
			this.modalElement.find('.image').css("display", "none");
			this.modalElement.find('.imageCaption').css("display", "none");
			this.modalElement.find('.openProdding').html("");
			if (finishedTheGame) {
				this.modalElement.find('.infoText').html(
					new TextResource(this.levelOptions.trEndOfGame).r('{amount}', this.json.amount).r('{newreward}', this.json.newreward).string());
				this.modalElement.find('.level').css("display", "none");
				this.modalElement.find('.levelButtons').css("display", "none");
				this.modalElement.find('.endGameButtons').css("display", "block");
				this.modalElement.find('.continueButton').css("display", "inline-block");
			} else {
				this.startNextLevel();
				// Only if this function was called from the click handler of the confirm 
				// button we have to manually trigger the loading of the next question.
				if (ev != undefined) {
					this.container.showPage(true);
				}
			}
		}
		
		if (this.json.challengeWasCompleted) {
			this.modalElement.find('.levelButtons').css("display", "none");
			this.modalElement.find('.challengeCompletedButtons').css("display", "inline-block");
		}
	},

	startNextLevel: function () {
		this.modalElement.modal("hide");

		if (this.jumpLevelsClicked) {
			this.jumpLevelsClicked = false;
			this.jumpLevels(4);
		}

		this.container.hideProgressIndicator();
		this.container.enableInput();
	},

	jumpLevels: function (n) {
		this.hasJustJumpedLevels = true;
		new Ajax.Request(new UrlTemplate(this.levelOptions.levelJumpUrl).r('PLH_level', n).url, {
			method: 'get',
			onSuccess: function(response) {
				var responseTextJSON = response.responseText.evalJSON();
				this.container.updateQuestion(Object.assign({}, responseTextJSON.ProcessAnswerResult, responseTextJSON.QuestionData));
				var newLevelText = 'Level: ' + responseTextJSON.ProcessAnswerResult.level;
				this.el.down('.currentLevel').innerHTML = newLevelText;
			}.bind(this),
		});
	},

	onLevelDownClick: function (ev) {
		this.jumpLevels(-1);
	},
});

Levels.addMethods(ProgressIndicatingControl);
Levels.addMethods(ExceptionHandling);
