/// <reference path="DOMAttached.js" />

var PopupEmailPreview = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);

		this.previewUrl = options.previewUrl;
		this.previewButton = $(options.previewButton);

		this.safeObserve(this.previewButton, 'click', this.loadPreview.bindAsEventListener(this));
	},

	loadPreview: function (evt) {
		evt.stop();

		this.previewWindow = window.open("", "Preview", "height=800,width=800,resizable=1");
		
		var url = this.previewUrl;
		var params = this.getParameters();

		for (var key in params) {
			url = url.replace(key, encodeURIComponent(JSON.stringify(params[key])));
		}

		this.previewWindow.location.href = url;
		this.previewWindow.focus();
	},
});
