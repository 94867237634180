/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="Communication.js" />
/// <reference path="TextResource.js" />
/// <reference path="GamesMultiplayerConstruction.js" />

var GamesMultiplayerQuizListItem = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);

		this.constructionElement = $(this.options.construction_element);
		this.teamChooser = this.constructionElement.select('[id*="teamChooserddl"]')[0];
		this.friendsChooser = this.constructionElement.select('[id*="friendsChooserddl"]')[0];
		this.gameSelection = this.constructionElement.down('.gameSelection');
		this.dragonChooser = this.constructionElement.down('.dragonChooser');
		this.friendsChooserContainer = this.constructionElement.down('.friendsChooserContainer');
		this.raceAkiBtnWrapper = this.constructionElement.down('.raceAkiWrapper');
		this.raceAkiButton = this.constructionElement.down('.raceAkiBtn');
		this.akiDifficultyWrapper = this.constructionElement.down('.akiDifficulty');
		this.backButton = this.constructionElement.down('.backButton');
		this.animatedDragon = this.constructionElement.down('.animatedDragon');

		// Get all the buttons that can start the game:
		this.easyAkiButton = this.constructionElement.down('.akiEasyBtn');
		this.mediumAkiButton = this.constructionElement.down('.akiMediumBtn');
		this.hardAkiButton = this.constructionElement.down('.akiHardBtn');
		this.friendButton = this.constructionElement.down('.friendBtn');

		var _this = this;
		// Get rid of all previous event listeners and add one that starts this game:
		this.easyAkiButton.stopObserving();
		this.mediumAkiButton.stopObserving();
		this.hardAkiButton.stopObserving();
		this.friendButton.stopObserving();
		this.safeObserve(this.easyAkiButton, 'click', this.onAkiOkClick.bindAsEventListener(this));
		this.safeObserve(this.mediumAkiButton, 'click', this.onAkiOkClick.bindAsEventListener(this));
		this.safeObserve(this.hardAkiButton, 'click', this.onAkiOkClick.bindAsEventListener(this));
		this.safeObserve(this.friendButton, 'click', _this.onAkiOkClick.bindAsEventListener(this).bind(_this));

		this.safeObserve(this.raceAkiButton, 'click', _this.onRaceAkiClick.bindAsEventListener(this));
		this.safeObserve($(this.options.gamePic), 'click', this.onGamePicClick.bindAsEventListener(this));
		this.safeObserve(this.backButton, 'click', _this.showFirstScreen.bindAsEventListener(this));
		window.setTimeout(function () { _this.getNextButton(); }, 500);
	},

	getNextButton: function () {
		this.nextBtnSearch = document.getElementsByClassName("nextBtn");
		if (typeof this.nextBtnSearch != "undefined") {
			this.nextBtn = this.nextBtnSearch[0];
		}
	},

	onAkiOkClick: function (event) {
		// stop event propagation, i.e. don't reload the page
		event.stop();

		// This button shouldn't be clickable more than once.
		this.friendButton.disabled = true;
		
		//get team
		var teamId = this.teamChooser.options[this.teamChooser.selectedIndex].value;
		//get friend
		var friendId = this.friendsChooser.options[this.friendsChooser.selectedIndex].value;
		GamesMultiplayerConstructionInstance.opponent = this.friendsChooser.options[this.friendsChooser.selectedIndex].text;

		//get level
		var elementId = event.findElement().identify();
		this.level = 0;
		if (elementId == this.easyAkiButton.id) this.level = 1;
		if (elementId == this.mediumAkiButton.id) this.level = 2;
		if (elementId == this.hardAkiButton.id) this.level = 3;
		if (this.level == 0 && friendId == "mem") return false;

		if (this.level == 0 && friendId != "mem") {
			teamId = this.teamChooser.options[0].value;
			var member = [this.options.member_array, friendId];
		}
		else var member = [this.options.member_array];

		GamesMultiplayerConstructionInstance.level = this.level;

		var req = new Ajax.Request(new UrlTemplate(this.options.create_url).r('PLH_AkiLevel', this.level).r('PLH_team', teamId).r('PLH_Cmem', member).url, {
			method: 'get',
			onSuccess: this.onSuccess.bind(this),
			onError: this.onError.bind(this),
		});
	},

	showFirstScreen: function (event) {
		event.stop();
		$(this.backButton).setStyle({
			display: 'none'
		});
		$(this.raceAkiBtnWrapper).setStyle({
			display: 'none'
		});
		$(this.friendsChooserContainer).setStyle({
			display: 'none'
		});
		$(this.akiDifficultyWrapper).setStyle({
			display: 'none'
		});
		$(this.animatedDragon).setStyle({
			display: 'none'
		});

		$(this.gameSelection).setStyle({
			display: 'block'
		});
		$(this.dragonChooser).setStyle({
			display: 'block'
		});
	},

	showSecondScreen: function (event) {
		event.stop();
		$(this.gameSelection).setStyle({
			display: 'none'
		});
		$(this.dragonChooser).setStyle({
			display: 'none'
		});
		$(this.akiDifficultyWrapper).setStyle({
			display: 'none'
		});
		$(this.backButton).setStyle({
			display: 'block'
		});
		$(this.animatedDragon).setStyle({
			display: 'block'
		});

		if (this.options.communityPlusEnabled) {
			$(this.raceAkiBtnWrapper).setStyle({
				display: 'block'
			});
			$(this.friendsChooserContainer).setStyle({
				display: 'block'
			});
		} else {
			$(this.akiDifficultyWrapper).setStyle({
				display: 'block'
			});
		}
		this.bindBackButton(this.showFirstScreen);
	},

	showThirdScreen: function (event) {
		event.stop();
		$(this.raceAkiBtnWrapper).setStyle({
			display: 'none'
		});
		$(this.friendsChooserContainer).setStyle({
			display: 'none'
		});
		$(this.akiDifficultyWrapper).setStyle({
			display: 'block'
		});
		this.bindBackButton(this.showSecondScreen);
	},

	bindBackButton: function (screen) {
		this.backButton.stopObserving();
		this.safeObserve(this.backButton, 'click', screen.bindAsEventListener(this));
	},

	onGamePicClick: function (event) {
		this.showSecondScreen(event);
	},

	onRaceAkiClick: function (event) {
		this.showThirdScreen(event);
	},

	onSuccess: function (req) {
		GamesMultiplayerConstructionInstance.onSuccess(req);
	},

	onError: function (req) {
		GamesMultiplayerConstructionInstance.onError(req);
	},
});
