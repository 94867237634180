var MiniworldHouseSaveTimer = function (saveFunction) {
	this.saveFunction = saveFunction;
	this.timer = null;
	this.timerInterval = 2500; // in milliseconds

	this.stopTimer = function () {
		if (this.timer !== null) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
	};

	this.saveNow = function () {
		this.stopTimer();
		this.saveFunction();
	};

	this.saveAfterInterval = function () {
		this.stopTimer();
		this.timer = window.setTimeout(this.saveNow.bind(this), this.timerInterval);
	};
};
var miniworldHouseSaveTimer = null;