/// <reference path="DOMAttached.js" />
/// <reference path="MiniMeSpeechBubble.js" />
/// <reference path="GenericSounds.js" />

var ColorSelectorActiveColor = null;

var ColorSelector = Class.create(DOMAttached, {
	colors: [],
	height: 0,
	width: 0,
	namespace: "http://www.w3.org/2000/svg",
	
	mouseUpHappened: true,
	activeElement: null,
	
	initialize: function ($super, editor, colors, width, height, vertical, feedback) {
		$super();
		
		this.editor = editor;
		this.colors = colors;
		this.width = width;
		this.height = height;

		this.playFeedbackSound = feedback;
		if (GenericSoundsInstance == null) {
			new GenericSounds(this.playFeedbackSound);
		}
		
		if (vertical) {
			this.drawVerticalSelector();
		}
	},
	
	drawVerticalSelector: function () {
		var colorsCount = this.colors.length;
		var boxHeight = this.height / colorsCount;
		var boxWidth = boxHeight;
		
		for (var i = 0; i < colorsCount; i++) {
			var r = boxWidth / 2;
			var x = this.width - boxWidth - 20 + r;
			var y = i * boxHeight + r;
			
			var color = document.createElementNS(this.namespace, "circle");
			color.setAttribute("cx", x);
			color.setAttribute("cy", y);
			color.setAttribute("r", r - 1);
			color.style.fill = this.colors[i];
			color.style.stroke = this.colors[i];
			color.style.strokeWidth = 2;
			color.onclick = function (e) {
				GenericSoundsInstance.putDownSound();
				var color = e.target;
				var another = this.activeElement != e.target;
				if (this.activeElement != null) {
					this.resetColors();
				}
				
				if (another) {
					ColorSelectorActiveColor = color.style.fill;
					this.activeElement = color;
					color.setAttribute("cx", color.getAttribute("cx") - 20);
					color.style.stroke = "#000000";
					color.style.x -= 20;
				} else {
					ColorSelectorActiveColor = null;
				}
			}.bind(this);
			color.onmousedown = function(e) {
				this.mouseUpHappened = false;
			}.bind(this);
			color.onmouseup = function(e) {
				this.mouseUpHappened = true;
			}.bind(this);
			color.onmouseout = function(e) {
				if (!this.mouseUpHappened) {
					var bubble = new MiniMeSpeechBubble(this.editor, MiniMeSpeechBubbles["colors"]);
				}
			}.bind(this);
			this.editor.getElementById("colorSelector").appendChild(color);
		}
	},
	
	resetColors: function() {
		if (this.activeElement != null) {
			this.activeElement.setAttribute("cx", (parseFloat(this.activeElement.getAttribute("cx")) + 20));
			this.activeElement.style.stroke = this.activeElement.style.fill;
			this.activeElement = null;
		}
	}
});