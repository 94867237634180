var miniworldHouseCanvasHelper = new function () {
	this.createCanvas = function (canvasDiv, zIndex) {
		var newCanvas = document.createElement("canvas");

		newCanvas.width = 950;
		newCanvas.height = 550;
		newCanvas.style.position = "absolute";
		newCanvas.style.left = 0;
		newCanvas.style.top = 0;
		newCanvas.classList.add("mia-js-scaleToContainerWidth");

		if (typeof zIndex !== "undefined" && zIndex !== null) {
			newCanvas.style.zIndex = zIndex;
		}

		if (typeof canvasDiv !== "undefined" && canvasDiv !== null) {
			canvasDiv.appendChild(newCanvas);
			scaleToContainerWidth();
		}

		return newCanvas;
	};

	this.moveToPoint = function (context, point) {
		context.moveTo(point.x, point.y);
	};

	this.lineToPoint = function (context, point) {
		context.lineTo(point.x, point.y);
	};

	this.relativeMousePosition = function (canvas, event) {
		var rect = canvas.getBoundingClientRect();
		// css transformation scales down houseview for small screen sizes. Here we adjust our clickhandlers
		var scale = parseFloat(canvas.style.transform.substring(6, 13))
		return {
			x: (event.clientX - rect.left) / scale,
			y: (event.clientY - rect.top) / scale
		};
	};

	this.relativeTouchPosition = function (canvas, event, activeTouch) {
		var posX = null;
		var posY = null;
		var rect = canvas.getBoundingClientRect();
		// css transformation scales down houseview for small screen sizes. Here we adjust our clickhandlers
		var scale = parseFloat(canvas.style.transform.substring(6, 13));
		for (var i = 0; i < event.changedTouches.length; i++) {
			if (event.changedTouches[i].identifier == activeTouch) {
				posX = event.changedTouches[i].clientX;
				posY = event.changedTouches[i].clientY;
				break;
			}
		}

		if (posX === null || posY === null) {
			return null;
		} else {
			return {
				x: (posX - rect.left) / scale,
				y: (posY - rect.top) / scale
			};
		}
	};

	this.isTransparentAtPosition = function (canvas, posX, posY) {
		var context = canvas.getContext("2d");
		var pixel = context.getImageData(posX, posY, 1, 1);
		return pixel.data[3] == 0;
	};
};