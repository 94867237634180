/// <reference path="DOMAttached.js" />

var MiniWorldExitButton = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);
		this.safeObserve($(this.options.exitButtonId), 'click', this.onClick.bindAsEventListener(this));
	},

	onClick: function () {
		var gradXtable = document.getElementById('gradXtable');
		if (gradXtable && !gradXtable.classList.contains("hidden")) {
			// If gradXtable is not hidden, we want to hide it first.
			gradXtable.classList.add("hidden");
		} else {
			window.location = this.options.worldUrl;
		}
	}
});
