var MiniworldHouseIcon = function (svgId, menuFunction, options) {

	this.menuFunction = new menuFunction(svgId, options);
	this.svgIcon = document.getElementById(svgId);
	disableUserSelect(this.svgIcon);

	this.show = function () {
		this.svgIcon.classList.remove("hidden");
	};

	this.hide = function () {
		this.svgIcon.classList.add("hidden");
	};

	this.clickInteraction = function () {
		if (this.menuFunction.reactToCurrentStateInteraction()) {
			this.menuFunction.clickInteraction();
		}
	};

	this.mouseUpInteraction = function () {
		if (this.menuFunction.reactToCurrentStateInteraction() &&
			(this.menuFunction.type == MiniworldHouseMenu.type.DELETEDOOR || this.menuFunction.type == MiniworldHouseMenu.type.INVENTORY)) {
			this.menuFunction.mouseUpInteraction();
		}
	};
	
	this.getActiveTouch = function (touchEvent) {
		// When more than one finger is on the screen, it might happen that the finger that we
		// track does not move. The finger will then not be in the changedTouches list. Because
		// we do not want to manually handle this case, we retrieve the active touch from the
		// list of all touches. We cannot always do this because for touchend events, the relevant
		// finger will be in the changedTouches list only.
		var touchesList = touchEvent.type == "touchend" ? touchEvent.changedTouches : touchEvent.touches;
		for (var i = 0; i < touchesList.length; i++) {
			if (touchesList[i].identifier == this.activeTouchId) {
				return touchesList[i];
			}
		}
		return null;
	};

	this.pointIntersectsThisElement = function (clientX, clientY) {
		var bounds = this.svgIcon.getBoundingClientRect();
		return clientX >= bounds.left
			&& clientX <= bounds.right
			&& clientY >= bounds.top
			&& clientY <= bounds.bottom;
	}

	this.activeTouchId = null;

	this.touchStartInteraction = function (event) {
		// this null check ensures that we only listen to the first finger placed on the screen
		if (this.activeTouchId === null) {
			this.activeTouchId = event.changedTouches[0].identifier;
		}		
	};

	this.touchMoveInteraction = function (event) {
	
	};

	this.touchEndInteraction = function (event) {
		var activeTouch = this.getActiveTouch(event);
		this.activeTouchId = null;

		// This method will be called anytime that a touchend event occurs. 
		// Therefore, we may be calling this method during a touchend event 
		// that does not correspond to this.activeTouchId, in which case,
		// this.getActiveTouch(event) will return null.
		if (activeTouch !== null && this.pointIntersectsThisElement(activeTouch.clientX, activeTouch.clientY)) {
			this.mouseUpInteraction();
		}
	};

	this.svgIcon.addEventListener("click", this.clickInteraction.bind(this));
	this.svgIcon.addEventListener("mouseup", this.mouseUpInteraction.bind(this));
};


// Some (shorter) menu functions
// MiniworldHouseDoorAdd and MiniworldHouseInventory are implemented in seperate files

var MiniworldHouseDoorBack = function (svgId, options) {

	this.svgId = svgId;
	this.options = options;
	this.type = MiniworldHouseMenu.type.DOORBACK;

	this.reactToCurrentStateInteraction = function () {
		var room = miniworldHouseGetCurrentRoom();
		return (room.state == MiniworldHouseRoom.interactionStates.NO_INTERACTION);
	};

	this.clickInteraction = function () {
		var goBackToId = miniworldHouseGetCurrentRoom().parentId;
		if (goBackToId != null) {
			miniworldHouseSwitchToRoom(goBackToId);
		} else {
			MiniworldHouseInstance.saveRooms();
			window.location.href = this.options.leaveHouseUrl;
		}
	};
};


var MiniworldHouseDeleteItem = function (svgId) {

	this.svgId = svgId;
	this.type = MiniworldHouseMenu.type.DELETEDOOR;

	this.reactToCurrentStateInteraction = function () {
		var room = miniworldHouseGetCurrentRoom();
		return (room.state == MiniworldHouseRoom.interactionStates.ITEM_INTERACTION && room.itemManager.draggedItem.category == "DOOR");
	};

	this.clickInteraction = function () { };

	this.mouseUpInteraction = function () {
		var currentRoom = miniworldHouseGetCurrentRoom();
		currentRoom.state = MiniworldHouseRoom.interactionStates.NO_INTERACTION;
		currentRoom.tryToDeleteItem();
	};
};

var MiniworldHouseColorMode = function (svgId) {

	this.svgIcon = document.getElementById(svgId);

	this.type = MiniworldHouseMenu.type.COLORMODE;

	this.reactToCurrentStateInteraction = function () {
		var room = miniworldHouseGetCurrentRoom();
		return (room.state == MiniworldHouseRoom.interactionStates.NO_INTERACTION ||
			(room.editable && room.state == MiniworldHouseRoom.interactionStates.COLOR_INTERACTION));
	};

	this.clickInteraction = function () {
		if (miniworldHouseGetCurrentRoom().switchColorState()) {
			this.svgIcon.style.backgroundColor = '#bce5ff';
		} else {
			this.svgIcon.style.backgroundColor = '';
		}
	};
};
