/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="ProgressIndicatingControl.js" />
/// <reference path="gameUtils/AssessmentMode.js" />
/// <reference path="gameUtils/ExceptionHandling.js" />
/// <reference path="gameUtils/FreeInputQuiz.js" />
/// <reference path="gameUtils/GamesTextToSpeech.js" />
/// <reference path="gameUtils/GameSettings.js" />
/// <reference path="gameUtils/MultipleChoiceQuiz.js" />
/// <reference path="gameUtils/LevelMedia.js" />
/// <reference path="gameUtils/Levels.js" />
/// <reference path="gameUtils/UpScaleImageHandler.js" />

var GamesQuizInstance = null;

var GamesQuiz = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);
		GamesQuizInstance = this;

		this.displayMath = this.options.DisplayMath;
		this.levelMedia = new LevelMedia(this);
		this.upScaledImageHandler = new UpScaledImageHandler(this);
		this.gameSettings = createGameSettings(this.options.feedbackMode);
		this.levelHandling = this.options.IsAssessmentMode ? new AssessmentMode(this, this.options) : new Levels(this, this.options);
		this.hideEasierQuestion = false;
		
		if (this.options.level == 1 && this.el.down(".levelDown")){
			this.el.down(".levelDown").style.visibility = 'hidden';
			this.hideEasierQuestion = true;
		}
		if (GamesTextToSpeechInstance == null) {
			new GamesTextToSpeech(this, this.options.TextToSpeechEnabled && this.options.ReadQuestionsOn, this.options.TextToSpeechEnabledByParent);
		}
		if (GenericSoundsInstance == null) {
			new GenericSounds(this.options.FeedbackSoundOn);
		}

		if (this.options.useList) {
			this.quiz = new MultipleChoiceQuiz(this,
				this.gameSettings,
				this.onCorrectAnswerGiven.bind(this),
				this.onIncorrectAnswerGiven.bind(this),
				this.updateQuestion.bind(this));
		} else {
			this.quiz = new FreeInputQuiz(this,
				this.gameSettings,
				this.onCorrectAnswerGiven.bind(this),
				this.onIncorrectAnswerGiven.bind(this),
				this.updateQuestion.bind(this));
		}
		this.safeObserve(this.el, "closeMedia", function() { 
			this.displayQuestions() 
		}.bindAsEventListener(this));

		if (soundManager.ok() || !GamesTextToSpeechInstance.isEnabled) {
			this.loadFirstQuestion(this.levelHandling.showTextMediaInitially);
		} else {
			soundManager.onready(this.loadFirstQuestion(this.levelHandling.showTextMediaInitially));
		}
	},

	disableInput: function () {
		this.quiz.locked = true;
	},

	enableInput: function () {
		this.quiz.locked = false;
	},

	/**
	 * Executed when the member has given the correct answer.
	 */
	onCorrectAnswerGiven: function (answerId) {
		GamesTextToSpeechInstance.removeTextToSpeechEffects();
		var millisecondsToNextQuestion = 1500;
		if(!this.gameSettings.shouldDisplayWhetherChosenAnswerIsCorrect && !this.gameSettings.shouldDisplayCorrectSolution) {
			millisecondsToNextQuestion = 0;
		} else {
			this.playFeedbackSoundForCorrectAnswer();
		}
		
		return this.sendAnswerToServer(true, this.pageLoadJSON.questionID, answerId, millisecondsToNextQuestion);
	},

	/**
	 * Executed when the member has given an incorrect answer.
	 */
	onIncorrectAnswerGiven: function (answerId) {
		GamesTextToSpeechInstance.removeTextToSpeechEffects();

		var millisecondsToNextQuestion = this.gameSettings.shouldDisplayCorrectSolution ? 3000 : 1500;
		if(!this.gameSettings.shouldDisplayWhetherChosenAnswerIsCorrect && !this.gameSettings.shouldDisplayCorrectSolution) {
			millisecondsToNextQuestion = 0;
		} else {
			this.playFeedbackSoundForIncorrectAnswer();
		}
		if (!this.options.useList) {
			// Don't want to have any delay for free input games when answer is incorrect
			millisecondsToNextQuestion = 0;
		}
		return this.sendAnswerToServer(false, this.pageLoadJSON.questionID, answerId, millisecondsToNextQuestion);
	},

	/**
	 * This is called by ToggleQuestionReader to start reading all content
	 * when text to speech gets turned on. Don't remove or rename!
	 */
	createAndReadAllTextToSpeech: function () {
		GamesTextToSpeechInstance.setTextToSpeechElements(this.quiz.createTextToSpeechElements(this.pageLoadJSON.questionPath, this.pageLoadJSON.answerPaths));
		GamesTextToSpeechInstance.readSpeechForAllElementsInOrder();
	},

	/**
	 * Notifies the server about which answer the user chose and whether it was correct,
	 * in order to log it and get a response that is relevant for leveled games.
	 */
	sendAnswerToServer: function (success, questionId, answerId, millisecondsToNextQuestion) {
		return jQuery.ajax({url: new UrlTemplate(this.options.processAnswerUrl).r('PLH_success', success).r('PLH_nquestion', questionId ? questionId : -2).r('PLH_answer', answerId).url })
			.then(function(response) { 
				return new Promise(function(resolve, reject) {
					setTimeout(function() {
						this.onSendAnswerToServerSuccess(Object.assign({}, response.ProcessAnswerResult, response.QuestionData), reject);
						resolve(response);
					}.bind(this), millisecondsToNextQuestion);
				}.bind(this));
			}.bind(this));
	},

	/**
	 * This handler is needed for leveled games.
	 */
	onSendAnswerToServerSuccess: function (response, rejectPromise) {
	  	this.levelHandling.onSendAnswerToServerSuccess(response, rejectPromise);
	},

	/**
	 * Pulls the first question / content from the ajax request.
	 */
	loadFirstQuestion: function(displayTextMedia) {
		jQuery.ajax({ url: new UrlTemplate(this.options.firstQuestionUrl).url })
			.then(function(response) {
				this.updateQuestion(response, displayTextMedia);
			}.bind(this));
	},

	/**
	 * Pulls the next question / content from the ajax response.
	 */
	updateQuestion: function(response, displayTextMedia) {
		this.showProgressIndicator();
		var isLevelUp = response && response.isLevelUp;
		try {
			this.pageLoadJSON = response;
			this.pageLoadJSON.isLevelUp = isLevelUp;
			if (this.hideEasierQuestion && isLevelUp && this.el.down(".levelDown") && !this.options.hideEasierQuestion){
				this.el.down(".levelDown").style.visibility = 'visible';
				this.hideEasierQuestion = false;
			}
		} catch (e) {
			global.handleFailedAJAJRequest();
			return;
		}
		this.hideProgressIndicator();
		if (this.levelHandling.customLoadEvent(this.pageLoadJSON)) {
			this.showPage(displayTextMedia);
		}
	},

	/**
	 * This is called once the server sends us the content for the next question.
	 * It displays the question, updates the progress indicator, starts text to speech etc.
	 */
	showPage: function (displayTextMedia) {
		if (typeof this.pageLoadJSON == 'undefined') {
			return;
		}
		if (this.pageLoadJSON.mediaText && !jQuery.isEmptyObject(this.pageLoadJSON.mediaText)) {
			this.levelMedia.loadMedia(this.pageLoadJSON.mediaText);
			jQuery(".textMediaButton").show();
			if (displayTextMedia) {
				this.levelMedia.showMedia();
			}
			else {
				this.displayQuestions();
			}
		} else {
			jQuery(".textMediaButton").hide();
			this.displayQuestions();
		}
	},

	/**
	 * Loads questions content without media.
	 */
	displayQuestions: function() {
		this.displayPage(this.pageLoadJSON.page);
		this.styleQuestionDisplay(this.el.down('.question2'));
		this.updateProgressBar(this.pageLoadJSON.progress, this.pageLoadJSON.questionsLeft);
		this.updatePlayPauseButtonSoundFile(this.pageLoadJSON.soundFile);
		// The following code requires that we are actually displaying a question;
		// if and only if that is the case, questionPath will be set to something truthy.
		if (this.pageLoadJSON.questionPath) {
			this.quiz.onNextQuestionLoaded();
			this.renderMath();
			this.createAndReadAllTextToSpeech();
		}
	},

	/**
	 * Ensures that all LaTeX math content is correctly rendered.
	 */
	renderMath: function () {
		if (this.displayMath) {
			MathJax.Hub.Typeset();
		}
	},

	/**
	 * Some games have a play / pause button for toggling reading the question
	 * (audio games?)
	 */
	updatePlayPauseButtonSoundFile: function (soundFile) {
		if (PlayPauseButtonInstance != null && soundFile) {
			// auto-play the given sound indefinitely
			PlayPauseButtonInstance.setSoundUrl(soundFile, true, true);
		}
	},

	/**
	 * Sets the correct number of remaining questions and the length of the
	 * progress bar.
	 */
	updateProgressBar: function (progress, numberQuestionsLeft) {
		if (progress != undefined) {
			$(this.el.down('.progressBar')).setStyle({ width: (progress * this.el.down(".progressBox").clientWidth) + 'px' });

			var questionsLeftText = numberQuestionsLeft == "1"
				? this.options.questionsLeftTR_singular
				: this.options.questionsLeftTR.replace('{num}', numberQuestionsLeft);
			this.el.down('.questionsLeft').innerHTML = questionsLeftText;
		}
	},

	/**
	 * Displays whatever html is given as parameter inside the question container.
	 */
	displayPage: function (page) {
		this.el.down('.questionContainer').innerHTML = page;
		// The page contains the controls for opening the level media; 
		// thus, their listeners have to be initalized after putting them into
		// the webpage.
		this.levelMedia.initializeListenersForOpeningMedia();
		this.upScaledImageHandler.initializeListenerForZoomInQuestionImg();
	},

	/**
	 * Applies some formatting for choosing correct font size, line height 
	 * and alignment for the question text depending on its length.
	 */
	styleQuestionDisplay: function (questionContainer) {
		if (questionContainer !== undefined && questionContainer != "") {
			var questionContent = questionContainer.firstChild.firstChild;
			this.questionParent = questionContent.parentNode;
			while (questionContent.innerHTML !== undefined) {
				this.questionParent = questionContent;
				questionContent = questionContent.innerHTML;
			}

			if (questionContent.length < 400) {
				var mainQuestionBlockHeight = 160;

				$(this.questionParent).setStyle({
					fontSize: '25px',
					lineHeight: '30px',
					align: 'center'
				});

				if (mainQuestionBlockHeight < this.questionParent.scrollHeight) {
					$(this.questionParent).setStyle({
						fontSize: '20px',
						lineHeight: '20px'
					});
				}

				var questionPaddingTop = (mainQuestionBlockHeight - questionContainer.scrollHeight) / 2;
				$(questionContainer).setStyle({
					paddingTop: questionPaddingTop.toString() + 'px'
				});
			}
		}
	},

	playFeedbackSoundForCorrectAnswer: function () {
		GenericSoundsInstance.successSound();
	},

	playFeedbackSoundForIncorrectAnswer: function () {
		GenericSoundsInstance.failSound();
	},

	/**
	 * If configured as feedback mode, this plays the speech file for the correct
	 * answer so that the member can listen to the solution he should have picked.
	 */
	playSpeechForCorrectAnswer: function () {
		if (!this.gameSettings.shouldDisplayCorrectSolution) {
			return;
		}
		if (typeof this.quiz.getIdentifierOfCorrectAnswer !== 'function') {
			return;
		}
		var identifierOfCorrectAnswer = this.quiz.getIdentifierOfCorrectAnswer();
		if (identifierOfCorrectAnswer !== undefined) {
			GamesTextToSpeechInstance.playSpeechForTextIdentifier(identifierOfCorrectAnswer, function () { });
		}
	}
});

GamesQuiz.addMethods(ProgressIndicatingControl);
GamesQuiz.addMethods(ExceptionHandling);
