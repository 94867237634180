function ControlPanel() {

	// layout setup
	this.controlCanvas = document.getElementById("control");
	this.ctx = this.controlCanvas.getContext("2d");
	this.width = designData.controlWidth;
	this.height = designData.paintingHeight;
	// It's a limit point at which `addColorStop` will get negative input
	this.maxNumberOfStops = 100;

	// init selector boxes
	var colorPanel = ["black", "white", "#909090", "#CFCFCF", "#EC654C", "#E93F33", "#F9DC43", "#F29F33", "#69D49B", "#608C67", "#68dede", "#49a0a0", "#45aff0", "#2c82cd" , "#728ce0" , "#6463c8" , "#ec7ca4" , "#e94f81" , "#f7cfbf", "#f19e8a"];
	this.letters = ["A", "a", "B", "b", "C", "c", "D", "d", "E", "e", "F", "f", "G", "g", "H", "h", "I", "i", "J", "j", "K", "k", "L", "l", "M", "m", "N", "n", "O", "o", "P", "p", "Q", "q", "R", "r", "S", "s", "T", "t", "U", "u", "V", "v", "W", "w", "X", "x", "Y", "y", "Z", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

	// initial status
	this.paintMode = currentMode.DRAWPOINT;
	this.letterIdx = 0;
	this.letterDrag = activeDrag.NO;
	this.imageDrag = activeDrag.NO;
	this.imageIdx = [];
	for(var i=0; i<designData.picker.length; ++i)
		{this.imageIdx.push(0);}
	this.chosenpicture = false;
	this.brushsize = 3;

	// setup images
	this.pickerImages=[];
	for(p=0; p<designData.picker.length;++p){
		this.pickerImages[p]=[];
		for(img=0; img<designData.picker[p].length;++img){
			this.pickerImages[p][img]=new Image();
			this.pickerImages[p][img].src = designData.picker[p][img];
		}
	}

	this.firstColX = 20;
	this.secondColX = 130;
	this.thirdColX = 250;
	//buttons
	this.brushButton = new ButtonWithImage(this.firstColX, 20, 90, 90, designData.buttonimg.brushButton);
	this.eraserButton = new ButtonWithImage(this.firstColX, 120, 90, 90, designData.buttonimg.eraserButton);
	this.bucketButton = new ButtonWithImage(this.firstColX, 220, 90, 90, designData.buttonimg.bucketButton);
	this.letterButton = new ButtonWithImage(this.firstColX, 320, 90, 90, designData.buttonimg.letterButton);
	this.stampButton = new ButtonWithImage(this.firstColX, 420, 90, 90, designData.buttonimg.stampButton);
	this.colorpickerButton = new ButtonWithText(this.thirdColX, 425, 73, 73, "", "red");
	this.colorpickerButtonText = new ButtonWithText(this.thirdColX, 496, 73, 40, "Edit", "#383838");
	this.clearButton = new ButtonWithText(this.firstColX - 20, 550, 120, 50, "Clear", "#383838");
	this.undoButton = new ButtonWithImage(this.secondColX -10, 550, 60, 50, designData.buttonimg.undoButton);
	this.undoDisabledButton = new ButtonWithImage(this.secondColX -10, 550, 60, 50, designData.buttonimg.undoDisabledButton);
	this.saveButton = new ButtonWithText(this.secondColX + 50, 550, 170, 50, "Done", "#2CD56D");

	//the parameters x and y are mouse click coordinates. While each button is painted it can decide whether it is clicked
	this.paintAllControlElements = function (x, y) {
		var grd = this.ctx.createLinearGradient(200, 0, 200, 550);
		grd.addColorStop(0, "#e6e6e6");
		grd.addColorStop(1, "#e6e6e6");
		this.ctx.fillStyle = grd;
		this.ctx.fillRect(0, 0, this.width, this.height);
		this.clearButton.paint(this.ctx, x, y, this.clearClickHandler);
		this.saveButton.paint(this.ctx, x, y, this.saveClickHandler);
		if (designitem.draft_history_size() > 0) {
			this.undoButton.paint(this.ctx, x, y, this.undoClickHandler);
		}
		else {
			this.undoDisabledButton.paint(this.ctx, x, y, this.undoClickHandler);
		}
		this.eraserButton.paint(this.ctx, x, y, this.eraserClickHandler);
		this.bucketButton.paint(this.ctx, x, y, this.bucketClickHandler);
		this.brushButton.paint(this.ctx, x, y, this.brushClickHandler);
		this.letterButton.paint(this.ctx, x, y, this.letterClickHandler);
		this.stampButton.paint(this.ctx, x, y, this.stampClickHandler);

		if (this.paintMode != currentMode.FILLSHAPE) {
			this.paintBrushsizeMenu(x, y);
		}
		if (this.paintMode == currentMode.FILLSHAPE || this.paintMode == currentMode.DRAWPOINT || this.paintMode == currentMode.LETTER) {
			this.paintColorMenu(x, y);
			var gradctx = document.getElementById("gradXtarget2").getContext("2d");
			if(typeof  this.chosenColor.get()!="string") {
				this.chosenColor.save();
				var w = this.colorpickerButton.button.width;
				this.chosenColor.createGradient(gradctx,
					this.colorpickerButton.button.posx + w / 2,
					this.colorpickerButton.button.posy + w / 2,
					w, true);

				this.colorpickerButton.paint(this.ctx, x, y, this.colorpickerClickHandler, this.chosenColor.get());
				this.chosenColor.restore();
			}else{
				this.colorpickerButton.paint(this.ctx, x, y, this.colorpickerClickHandler, this.chosenColor.get());
			}
			this.colorpickerButtonText.paint(this.ctx, x, y, this.colorpickerClickHandler);
		}
		if (this.paintMode == currentMode.STAMP) {
			this.paintImageSelector(0, 130, 320, x, y);
			this.paintImageSelector(1, 250, 320, x, y);
			this.paintImageSelector(2, 250, 170, x, y);
			this.paintImageSelector(3, 250, 20, x, y);
		}
		if (this.paintMode == currentMode.LETTER) {
			this.paintLetterBox(x, y);
		}
		this.highlightActiveTool();
	}

	this.paintImageSelector = function (idx, posx, posy, x, y) {
		this.paintImageSelectorBox(x, y, posx, posy, this.pickerImages[idx], idx);
	}

	this.highlightActiveTool = function () {
		this.ctx.save();
		this.ctx.fillStyle = colorscheme.ActiveButton;

		var b = null;
		switch (this.paintMode){
			case currentMode.DRAWPOINT:
				b = this.brushButton;
				break;
			case currentMode.STAMP:
				b = this.stampButton;
				break;
			case currentMode.LETTER:
				b = this.letterButton;
				break;
			case currentMode.ERASE:
				b = this.eraserButton;
				break;
			case currentMode.FILLSHAPE:
				b = this.bucketButton;
				break;
		}
		this.ctx.fillRect(b.button.posx-1, b.button.posy-1, b.button.width+2, b.button.height+2);
		b.paint(this.ctx, -1, -1, function () { });
		this.ctx.restore();
	}

	this.paintImageSelectorBox = function (x, y, posx, posy, imageCollection, ImageBoxId) {
		activePaint = false;

		var height = 90;
		var main_width = 90;

		imageCollection[0].onload = function () {
			controlpanel.ctx.drawImage(this, posx, posy, main_width, height);
		}

		this.paintSelectorBox(x, y, posx, posy, main_width, height,
			function () {
				this.imageIdx[ImageBoxId] = (this.imageIdx[ImageBoxId] - 1 + imageCollection.length) % imageCollection.length;
			}, //leftarrowclickhandler
			function () {
				this.imageIdx[ImageBoxId] = (this.imageIdx[ImageBoxId] + 1 + imageCollection.length) % imageCollection.length;
			}, //rightarrowclickhandler
			function () {
				this.letterDrag = activeDrag.NO;
				this.imageDrag = activeDrag.YES;
				this.chosenpicture = imageCollection[this.imageIdx[ImageBoxId]];
			} //mainclickhandler
		);

		this.ctx.drawImage(imageCollection[this.imageIdx[ImageBoxId]], posx, posy, main_width, height);
	}

	this.paintSelectorBox = function (x, y, posx, posy, main_width, height, leftarrowclick, rightarrowclick, mainclick) {
		//generic function for selector box

		var arrow_width = main_width / 2;
		var arrow_height = main_width / 2;

		//main box
		this.ctx.beginPath();
		this.ctx.fillStyle = colorscheme.SecondaryColor;
		this.ctx.rect(posx, posy, main_width, height);
		this.ctx.fill();
		if (this.ctx.isPointInPath(x, y)) {
			mainclick.call(this);
			this.ctx.fillStyle = colorscheme.ContentBackground;
			this.ctx.rect(posx, posy, main_width, height);
			this.ctx.fill();
		}

		this.ctx.fillStyle = colorscheme.ControlButton;
		//left arrow
		this.ctx.beginPath();
		this.ctx.rect(posx, posy + height, arrow_width, arrow_height);
		this.ctx.fill();
		if (this.ctx.isPointInPath(x, y)) {
			leftarrowclick.call(this);
		}

		//right arrow
		this.ctx.beginPath();
		this.ctx.rect(posx + main_width / 2, posy + height, arrow_width, arrow_height);
		if (this.ctx.isPointInPath(x, y)) {
			rightarrowclick.call(this);
		}
		this.ctx.fill();

		this.ctx.font = "30px Trebuchet MS";
		this.ctx.fillStyle = colorscheme.ControlTextColor;
		this.ctx.fillText("<", posx + 15, posy + height + 35);
		this.ctx.fillText(">", posx + main_width / 2 + 15, posy + height + 35);
	}

	this.paintBrushsizeMenu = function (x, y) {
		var allbrushsizes = [1, 3, 6, 9, 12];

		var posx = this.secondColX;
		var posy = 35;

		this.ctx.save();
		this.ctx.fillStyle="#BEBEBE";
		var w = 90;
		this.ctx.fillRect(posx, posy-15, w, 250);
		this.ctx.restore();

    paintBrush = function(idx, radd) {
			var r = 3 + 3*idx + radd;
			var cx = posx + w/2;
			var cy = posy + idx * 18 + 3 * idx + 6*idx*idx;

			this.ctx.beginPath();
			this.ctx.rect(cx - w/2, cy - r, w, 2*r);
			if (this.ctx.isPointInPath(x, y)) {
				this.brushsize = allbrushsizes[idx];
			}

			this.ctx.beginPath();
			this.ctx.arc(cx, cy, r, 0, 6.28);
			this.ctx.fill();
		}.bind(this);

		//paint menu update brushsize
		for (var idx = 0; idx < allbrushsizes.length; ++idx) {
			this.ctx.fillStyle = "white";
			paintBrush(idx, 0);
		}

		//filling of currently active
		this.ctx.fillStyle = "#66CCCC";
		paintBrush(allbrushsizes.indexOf(this.brushsize), 6);
		this.ctx.fillStyle = "white";
		paintBrush(allbrushsizes.indexOf(this.brushsize), 0);
	}

	this.paintLetterBox = function (x, y) {
		activePaint = false;

		var posx = this.secondColX;
		var posy = 290;

		var main_width = 90;
		var height = 90;


		this.paintSelectorBox(x, y, posx, posy, main_width, height,
			function () {
				this.letterIdx = (this.letterIdx - 1 + this.letters.length) % this.letters.length;
			}, //leftarrowclickhandler
			function () {
				this.letterIdx = (this.letterIdx + 1) % this.letters.length;
			}, //rightarrowclickhandler
			function () {
				this.imageDrag = activeDrag.NO;
				this.letterDrag = activeDrag.YES;
			} //mainclickhandler
		);

		//text
		this.ctx.save();
		this.ctx.font = "50px Abeezee";
		this.ctx.textAlign = "center";
		this.ctx.shadowOffsetX = 2;
		this.ctx.shadowOffsetY = 2;
		this.ctx.shadowColor = "#323232";
		this.ctx.fillStyle = this.chosenColor.get();
		this.ctx.fillText(this.letters[this.letterIdx], posx + (main_width / 2), posy + (height / 2) + 15);
		this.ctx.restore();
	}

	this.chosenColor = new function () {

		this.colorpickerstatus = [];

		this.type = "linear";
		this.direction = "right";
		this.value = "grey";
		this.valueBackup = "grey";

		this.get = function () {
			return this.value;
		}
		this.set = function (v) {
			if (v == "erase") {
				this.valueBackup = this.value;
			}
			this.value = v;
		}
		this.save = function(){
			this.valueBackup = this.value;
		}
		this.restore = function () {
			this.value = this.valueBackup;
		}
		//when filling a shape the current color should be positioned according to mouse click
		this.getPositioned = function (x, y) {
			if (colorPanel.indexOf(this.value) != -1) {
				return this.value;
			}
			else {
				this.createGradient(ctx, x, y);
				return this.value;
			}
		}
		var colorstops = [];
		this.addColorStop = function (a, b) {
			var pair = [a, b];
			colorstops.push(pair);
		}
		this.clearColorStops = function () {
			colorstops = [];
		}
		this.chooseColorpickerGradient = function(){
			this.clearColorStops();
			for (i = 0; i < this.colorpickerstatus[0].length; ++i) {
				this.addColorStop(1-this.colorpickerstatus[0][i][1] / 100, this.colorpickerstatus[0][i][0]);
			}
			var oLinearGradient = this.colorpickerstatus[1][3];
			if (oLinearGradient) {
				if (oLinearGradient.includes("radial")) {
					this.type = "radial"
				}
				else {
					this.type = "linear";
					if (oLinearGradient.includes("top")) {
						this.direction = "top";
					}
					else if (oLinearGradient.includes("right")) {
						this.direction = "right";
					}
					else if (oLinearGradient.includes("bottom")) {
						this.direction = "bottom";
					}
					else if (oLinearGradient.includes("left")) {
						this.direction = "left";
					}
				}
			}
		}
		this._dynamicGradient = function (x, y, width, tmpctx, scaleoverride) {
			var usectx;
			if (tmpctx === undefined) {
				usectx = ctx;
			}
			else {
				usectx = tmpctx;
			}
			if (width === undefined) {
				width = 200;
			}
			var gradient;
			var xScaled = scaleoverride ? x : x / designData.XScale;
			var yScaled = scaleoverride ? y : y / designData.YScale;
			if (this.type == "linear") {
				if (this.direction == "right") {
					gradient = usectx.createLinearGradient(xScaled + width / 2, 0, xScaled - width / 2, 0);
				}
				if (this.direction == "bottom") {
					gradient = usectx.createLinearGradient(0, yScaled - width / 2, 0, yScaled + width / 2);
				}
				if (this.direction == "top") {
					gradient = usectx.createLinearGradient(0, yScaled + width / 2, 0, yScaled - width / 2);
				}
				if (this.direction == "left") {
					gradient = usectx.createLinearGradient(xScaled - width / 2, 0, xScaled + width / 2, 0);
				}
			}
			else {
				//PARAMETER:x0 center starting circle, y0 center starting circle, r0 radius starting circle
				//	  x1 center ending circle,   y1 center ending circle,   r1 radius ending circle
				gradient = usectx.createRadialGradient(xScaled, yScaled, 0, xScaled, yScaled, width / 2);
			}
			for (i = 0; i < colorstops.length; ++i) {
				gradient.addColorStop(colorstops[i][0], colorstops[i][1]);
			}
			return gradient;
		}
		this.createGradient = function (tmpctx, x, y, width, scaleoverride) {
			if (tmpctx === undefined) {
				tmpctx = document.getElementById("gradXtarget2").getContext("2d");
			}
			if (x === undefined) {
				x = 50;
			}
			if (y === undefined) {
				y = 50;
			}
			if (width === undefined) {
				width = 200;
			}
			if (scaleoverride === undefined) {
				scaleoverride = false;
			}
			this.value = this._dynamicGradient(x, y, width, tmpctx, scaleoverride);
			return false;
		}
	}

	this.paintColorMenu = function (x, y) {

		var posx = this.thirdColX;
		var square_width = 34;
		var margin = 5;

    var paintSelectorBox = function(idx, sadd) {
			var square_posx = posx + (idx % 2) * (square_width + margin);
			var square_posy = 20 + (idx - (idx % 2)) / 2 * (square_width + margin);

			this.ctx.beginPath();
			this.ctx.rect(square_posx, square_posy, square_width, square_width);
			if (this.ctx.isPointInPath(x, y)) {
				this.chosenColor.set(colorPanel[idx]);
			}

			this.ctx.fillRect(square_posx - sadd / 2, square_posy - sadd / 2, square_width + sadd, square_width + sadd);
		}.bind(this);

		for (var idx = 0; idx < colorPanel.length; ++idx) {
			this.ctx.fillStyle = colorPanel[idx];
			paintSelectorBox(idx, 0);
		}

		var idx = colorPanel.indexOf(this.chosenColor.get());
		if (idx != -1) {
			this.ctx.fillStyle = "#323232";
			paintSelectorBox(idx, 10);
			this.ctx.fillStyle = colorPanel[idx];
			paintSelectorBox(idx, 0);
		}
	}

	this.eraserClickHandler = function () {
		controlpanel.letterDrag = activeDrag.NO;
		controlpanel.imageDrag = activeDrag.NO;
		controlpanel.chosenColor.set("erase");
		document.getElementById("clicklayer").style.cursor = "url(" + designData.cursor.eraser + "),auto";
		controlpanel.paintMode = currentMode.ERASE;
	}

	this.bucketClickHandler = function () {
		controlpanel.switchMode(currentMode.FILLSHAPE);
	}

	this.brushClickHandler = function () {
		controlpanel.switchMode(currentMode.DRAWPOINT);
	}

	this.stampClickHandler = function () {
		controlpanel.switchMode(currentMode.STAMP);
	}

	this.letterClickHandler = function () {
		controlpanel.switchMode(currentMode.LETTER);
	}

	this.clearClickHandler = function () {
		designitem.freePoints = [];
		designitem.clearColors();
		ctxFree = freestyleCanvas.getContext("2d");
		ctxFree.clearRect(0, 0, designitem.width, designitem.height);
		freePointRedrawCache = 0;
	}

	this.undoClickHandler = function () {
		designitem.draft_pop();
	}

	this.saveClickHandler = function () {
		jQuery("#savedialog").modal("show");
	}

	this.colorpickerClickHandler = function () {
		var colorpickerdiv = document.getElementById("gradXtable").classList.remove("hidden");
	}

	this.switchMode = function (newMode) {
		this.letterDrag = activeDrag.NO;
		this.imageDrag = activeDrag.NO;
		if (this.paintMode == currentMode.ERASE) {
			this.chosenColor.restore();
		}
		this.paintMode = newMode;

		switch (this.paintMode) {
			case currentMode.ERASE:
				document.getElementById("clicklayer").style.cursor = "url(" + designData.cursor.eraser + "),auto";
				break;
			case currentMode.FILLSHAPE:
				document.getElementById("clicklayer").style.cursor = "url(" + designData.cursor.bucket + "),auto";
				break;
			case currentMode.DRAWPOINT:
				document.getElementById("clicklayer").style.cursor = "url(" + designData.cursor.brush + "),auto";
				break;
			case currentMode.LETTER:
				this.letterDrag = activeDrag.YES;
				document.getElementById("clicklayer").style.cursor = "url(" + designData.cursor.stamp + "),auto";
				break;
			case currentMode.STAMP:
				document.getElementById("clicklayer").style.cursor = "url(" + designData.cursor.stamp + "),auto";
				break;
		}
	}
}

function Button(_posx, _posy, _width, _height) {

	this.posx = _posx;
	this.posy = _posy;
	this.width = _width;
	this.height = _height;

	this.paint = function (ctx, x, y, clickhandler) {
		ctx.beginPath();
		ctx.rect(this.posx, this.posy, this.width, this.height);

		//click handler
		if (ctx.isPointInPath(x, y)) {
			clickhandler();
		}
	}
}

function ButtonWithImage(_posx, _posy, _width, _height, _filename) {

	this.button = new Button(_posx, _posy, _width, _height);
	this.img = new Image();
	this.src = _filename;

	this.paint = function (ctx, x, y, clickhandler) {
		if (this.img.src != this.src) {
			this.img.onload = function () {
				ctx.drawImage(this.img, this.button.posx, this.button.posy, this.button.width, this.button.height);
			}.bind(this)
			this.img.src = this.src;
		}
		else {
			ctx.drawImage(this.img, this.button.posx, this.button.posy, this.button.width, this.button.height);
		}
		this.button.paint(ctx, x, y, clickhandler);

	}
}

function ButtonWithText(_posx, _posy, _width, _height, _heading, specialBackgroundColor) {
	this.button = new Button(_posx, _posy, _width, _height);
	this.heading = _heading;
	this.backgroundColor = specialBackgroundColor == undefined ? colorscheme.SecondaryColor : specialBackgroundColor;

	this.paint = function (ctx, x, y, clickhandler, specialBackgroundColor) {
		if (specialBackgroundColor != undefined) {
			this.backgroundColor = specialBackgroundColor;
		}
		ctx.save();
		ctx.lineWidth = 0;
		this.button.paint(ctx, x, y, clickhandler);
		this.paintText(ctx, this.heading, this.button.posx + this.button.width / 2 - 25, this.button.posy + 30);
		ctx.restore();
	}

	this.paintText = function (ctx, text, x, y) {
		ctx.fillStyle = this.backgroundColor;
		roundRect(ctx, this.button.posx, this.button.posy, this.button.width, this.button.height, 0, true, 0);
		ctx.fillStyle = "white";
		ctx.font = "14px Abeezee";
		ctx.textAlign = 'center';
		ctx.fillText(text, _posx + (_width / 2), _posy + (_height / 2) + 5);
	}
}
