/// <reference path="DOMAttached.js" />
/// <reference path="DraggableSvgItem.js" />
/// <reference path="ColorSelector.js" />

function colorToHex(color) {
	if (color.substr(0, 1) === '#') {
    	return color;
	}
	 
	var digits = /(.*?)rgb\((\d+), (\d+), (\d+)\)/.exec(color);

	var red = parseInt(digits[2]);
	var green = parseInt(digits[3]);
	var blue = parseInt(digits[4]);

	var rgb = blue | (green << 8) | (red << 16);
	var rgb16 = rgb.toString(16);
	while (rgb16.length < 6) {
		rgb16 = "0" + rgb16;
	}
	return digits[1] + '#' + rgb16;
}
			
function getActiveColor () {
	return colorToHex(ColorSelectorActiveColor); 
}

function hexToDec(hex) {
	return parseInt("FF" + hex, 16);
}
			
function fillShape(shape, color) {
	// Some shapes need to be filled partially. For example in eyes shape 
	// we need to fill with color only iris and leave the white and black parts unchanged.
	// Such shape is a group (with id for coloring) of shapes we actually want to color 
	// and static shapes (with id = parent id + '-static' + anything).
	if (shape.parentNode && shape.id != undefined && shape.id.startsWith(shape.parentNode.id + "-static")) {
		return;
	}

	if (shape.hasChildNodes()) {
		for (var child = shape.firstChild; child != null; child = child.nextSibling) {
			fillShape(child, color);
		}
	} else {		
		if (shape.style) {
			shape.style.fill = color;
		}
	}
}

function decToHex(dec) {
		return dec.toString(16).toUpperCase().substring(2);
}

function check(ele) {
	return (typeof(ele) != "undefined");
}

var ColorableSvgItem = Class.create(DOMAttached, {
	namespace: "http://www.w3.org/2000/svg",

	initialize: function ($super, colorSelector, item, settingKey, colorableId) {
		$super();
		this.colorSelector = colorSelector;
		this.item = item;
		this.settingKey = settingKey;
		this.colorableId = colorableId;
		this.colorColorables();
		this.setListeners();
	},

	colorColorables: function () {
		var settings = MiniMeSettings[this.settingKey];
		if (settings) {
			var color = settings[this.colorableId];
			if (color) {
				var element = document.getElementById(this.colorableId);
				fillShape(element, "#" + decToHex(color));
			}
		}
	},
	
	setListeners: function () {
		var selector = this.colorSelector;
		var settingKey = this.settingKey;
		var colorableId = this.colorableId;
		var namespace = this.namespace;

		function color() {
			var activeColor = ColorSelectorActiveColor;
			if (activeColor != null) {
				selector.resetColors();

				var element = document.getElementById(colorableId);
				fillShape(element, activeColor);

				// In Safari browser SVG use element is not updated after changes to original SVG, see ticket #9725 and
				// http://stackoverflow.com/questions/30905493/how-to-force-webkit-to-update-svg-use-elements-after-changes-to-original
				function refreshUseNode(useNode) {
					var useNodeCopy = document.createElementNS(namespace, 'use');
					useNodeCopy.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + colorableId);
					useNodeCopy.addEventListener("click", color);
					useNode.parentNode.replaceChild(useNodeCopy, useNode);
				}

				refreshUseNode(this);

				if (!MiniMeSettings[settingKey]) {
					MiniMeSettings[settingKey] = {};
				}
				MiniMeSettings[settingKey][colorableId] = hexToDec(getActiveColor().substring(1));

				MiniMeLastVersion++;
			}
		}
		
		// There is a 300ms delay on touch devices before the click event is fired,
		// see http://stackoverflow.com/questions/25572070/javascript-touchend-versus-click-dilemma.
		// We would need to use something like https://github.com/ftlabs/fastclick to get rid of that.
		this.item.addEventListener("click", color);
	}
});