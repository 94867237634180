/// <reference path="DOMAttached.js" />

var GNOMRefreshingStatBox = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		if (this.options.hatchTR != undefined)
			this.el.up('.tabbedApplication').down('.eggHatchDesc').innerHTML = this.options.hatchTR;
		
		var timer = setTimeout(this.onTimer.bind(this), 10000);
		this.el.up('.tabbedApplication').attachedObj.registerTimer(timer);
		
		if (this.options.petPageUrl != undefined) {
			this.el.up('.tabbedApplication').attachedObj.activatePlaceholder('petTab', this.el.down('.gnomName').innerHTML, this.options.petPageUrl);
		}
		
		//Handle popup after page has been loaded completely
		setTimeout(this.onPopupTimer.bind(this), 0);
	},
	
	onPopupTimer: function() {
		if (this.options.hatching != undefined) {
			if (this.options.hatching != "None")
				this.el.up('.tabbedApplication').down('.imageBox .hatch').style.display = 'block';
			else
				this.el.up('.tabbedApplication').down('.imageBox .hatch').style.display = 'none';
		}
			
		if (this.options.hatchPopupUrl != undefined) {
			if (!this.el.up('.GNOMApplication').down('.popupContainer').attachedObj.isPopupVisible())
				this.el.up('.GNOMApplication').down('.popupContainer').attachedObj.showPopup(this.options.hatchPopupUrl);
		}
		
		if (this.options.petNamePopup != undefined) {
			if (!this.el.up('.GNOMApplication').down('.popupContainer').attachedObj.isPopupVisible())
				this.el.up('.GNOMApplication').down('.popupContainer').attachedObj.showPopup(this.options.petNamePopup);
		}
		
		if (this.options.timer != undefined) {
			var actionTimer = this.el.up('.tabbedApplication').down('.actionTimer');
			
			if (actionTimer != undefined) {
				actionTimer.attachedObj.setTime(this.options.timer);
			}
		}
	},
	
	onTimer: function() {
		var req = new Ajax.Request(this.options.url, {
			method: 'get',
			onSuccess: this.onBoxLoaded.bind(this),
		});
	},
	
	onBoxLoaded : function(req) {
		try {
			var json = req.responseText.evalJSON();
		} catch(e) {
			return;
		}
		
		if (json.success) {
			this.el.replace(json.box);
		}
	}
});

