/// <reference path="DOMAttached.js" />

var Invitation = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.nextLink = this.el.down('.nextStep');
		this.addLink = this.el.down('.addName');
		this.namesForm = this.el.down('.namesForm');
		this.noNamesError = this.el.down('.error');
		this.nameslist = this.el.down('.allNamesToInvite');
		this.invitationForm = this.el.down('.invitationForm');
		this.invitationFormAnyError = this.invitationForm.down('.error');
		this.heading = this.invitationForm.down('h3');
		this.currentName = undefined;
		this.inviteLink = this.el.down('.inviteSinglePerson');
		this.stateMessageForm = this.el.down('.invitationStateMessageForm');
		this.nextInvitationName = this.stateMessageForm.down('.nextNameToInvite');
		this.nextInvitationLink = this.stateMessageForm.down('.nextInvitation');
		this.moreInvitationsLink = this.stateMessageForm.down('.moreInvitations');
		
		this.safeObserve(this.nextLink, 'click', this.onNextClick.bindAsEventListener(this));
		this.safeObserve(this.addLink, 'click', this.onAddClick.bindAsEventListener(this));
		this.safeObserve(this.inviteLink, 'click', this.onInviteClick.bindAsEventListener(this));
		this.safeObserve(this.nextInvitationLink, 'click', this.onNextInvitationClick.bindAsEventListener(this));
		
		this.namesArray = new Array();
	},
	
	/* ClickHandler */
	
	onNextClick : function(ev) {
		this.fillNamesArray();
		this.retrieveNextCurrentName();
		if(this.currentName != undefined) {
			this.putNameInHeading();
			this.goToSecondStep();
		} else {
			this.displayNoNamesError();
		}
	},
	
	onAddClick : function(ev) {
		this.addNameRow();
	},
	
	onInviteClick : function(ev) {
		this.sendInvitation();
	},
	
	onNextInvitationClick : function(ev) {
		this.tryToAdaptMessageForNextName();
		this.retrieveNextCurrentName();
		this.putNameInHeading();
		this.cleanUpInputMethod();
		this.goToSecondStep();
	},
	
	
	/* Functionalities */
	
	displayNoNamesError : function() {
		this.noNamesError.removeClassName('collapsed');
	},
	
	sendInvitation : function() {
		var selectedMethod = this.invitationForm.down('.selected');
	
		var name = this.currentName;
		var message = selectedMethod.down('textarea').value;
		var contact = selectedMethod.down('.invitationMethodInput').down('input[type="text"]').value;
		var method = selectedMethod.down('.invitationMethodValue').value;
		
		var req = new Ajax.Request(this.options.inviteURL, {
			method: 'post',
			onSuccess: this.requestSuccess.bind(this),
			parameters: {
				name: name,
				message: message,
				contact: contact,
				method: method
			}
		});
	},
	
	addNameRow : function() {
		var newNameField = this.nameslist.down('.singleNameToInvite').cloneNode(true);
		newNameField.down('.textbox').down('input[type="text"]').value = '';
		this.nameslist.appendChild(newNameField);
	},
	
	goToSecondStep : function() {
		this.namesForm.addClassName('collapsed');
		this.stateMessageForm.addClassName('collapsed');
		this.invitationForm.removeClassName('collapsed');
	},
	
	goToFinalStep : function() {
		this.invitationForm.addClassName('collapsed');
		this.stateMessageForm.removeClassName('collapsed');
	},
	
	fillNamesArray : function() {
		this.nameslist.select('.singleNameToInvite').each(function(el) {
			var name = el.down('.textbox').down('input[type="text"]').value.strip();
			if(name.length > 0)
				this.namesArray.push(name);
		}.bind(this) );
		this.namesArray.reverse();
	},
	
	putNameInHeading : function() {
		this.heading.update(this.currentName);
	},
	
	putNextNameInNextInvitationLinkOrShowMoreInvitationsLink : function() {
		var nextName = this.namesArray.last();
		if(nextName != undefined) {
			this.nextInvitationName.update(nextName);
		} else {
			this.nextInvitationLink.addClassName('collapsed');
			this.moreInvitationsLink.removeClassName('collapsed');
		}	
	},
	
	retrieveNextCurrentName : function() {
		this.currentName = this.namesArray.pop();
	},
	
	cleanUpInputMethod : function() {
		this.invitationForm.select('.invitationMethodInput').each(function(item) {
			item.down('input[type="text"]').value = '';
		});
	},
	
	tryToAdaptMessageForNextName : function() {
		var message = this.invitationForm.down('.selected').down('textarea').value;
		message =	message.replace(new RegExp('\\b'+this.currentName+'\\b', "gi"), this.namesArray.last());
		this.invitationForm.down('.selected').down('textarea').value = message;
	},
	
	putSuccessMessageInPlace : function(message) {
		this.stateMessageForm.down('.successMessage').update(message);
	},
	
	showInvitationFormError : function(errorMsg) {
		this.invitationFormAnyError.down('p').update(errorMsg);
		this.invitationFormAnyError.removeClassName('collapsed');
	},
	
	
	/* Ajax Handler */
	
	requestSuccess : function(req) {
		var json;
		try {
			json = req.responseText.evalJSON();
		} catch (e) {
			this.requestFailure(req);
			return;
		}
		if(json.message) {
			if(json.success) {
				this.putNextNameInNextInvitationLinkOrShowMoreInvitationsLink();
				this.putSuccessMessageInPlace(json.message);
				this.goToFinalStep();
			} else {
				this.showInvitationFormError(json.message);
			}
		} else {
			this.requestFailure(req);
			return;
		}
	},
	
	requestFailure : function(req) {
		this.showInvitationFormError(this.options.genericError);
	}	
});
