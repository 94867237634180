/// <reference path="DOMAttached.js" />
/// <reference path="../../../node_modules/soundmanager2/script/soundmanager2-nodebug.js" />

var xMouse; // x-coordinate of mouse
var pageUrl; // page will be loaded after the animation
var index; // animation index
var pony, pony1, pony2, pony3, pony4, pony5, pony6; // pony animation
var water, water1, water2; // water animation
var prevBtnVis = false, nextBtnVis = false; // petlist buttons
var GameAnimationContainer = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.el.attachedObj = this;
		this.popup = this.el.down('.outerPopup');
	},

	loadAnimation: function(url, xMouse) {
		this.xMouse = xMouse;
		var req = new Ajax.Request(url, {
				method: 'get',
				onSuccess: this.onAnimationLoaded.bind(this),
			});
	},

	onAnimationLoaded: function (req) {
		var json = req.responseText.evalJSON();

		if (json.success) {
			if (json.successpopup != undefined) {
				this.showPopup(json.successpopup);
				return;
			} else {
				this.imgUrl = json.imgUrl;
				this.petImgUrl = json.petImgUrl;
				this.pageUrl = json.pageUrl;
				this.el.up('.tabbedApplication').attachedObj.blocked = true;
				switch (json.animationType) {
					case "Food":
						this.foodAnimation();
						break;
					case "Polish":
						this.polishAnimation();
						break;
					case "Music":
						this.musicAnimation();
						break;
					case "Incubate":
						this.incubateAnimation();
						break;
					case "Sunshine":
						this.sunshineAnimation();
						break;
					case "Blanket":
						this.blanketAnimation();
						break;
					case "Cuddle":
						this.cuddleAnimation();
						break;
					case "Bath":
						this.bathAnimation();
						break;
					case "Play":
						this.playAnimation();
						break;
					case "Trip":
						this.tripAnimation();
						break;
					case "Exercise":
						this.exerciseAnimation();
						break;
					case 11:
						this.beautySaloonAnimation(json.popup, json.popupWidth, json.popupHeight, json.receivedItems, json.numberOfItems, json.petImgUrl);
						break;
					case 12:
						this.circusAnimation(json.popup, json.popupWidth, json.popupHeight, json.receivedItems, json.numberOfItems);
						break;
					case 13:
						this.shoppingAnimation(json.popup, json.popupWidth, json.popupHeight, json.receivedItems, json.numberOfItems);
						break;
					case 14:
						this.treasureHuntAnimation(json.popup, json.popupWidth, json.popupHeight, json.receivedItems, json.numberOfItems, json.portal);
						break;
					case -1:
						throw new Error('unknown animation type');
				}
			}
		} else {
			if (json.errorpopup != undefined) {
				this.showPopup(json.errorpopup);
			}
		}
	},

	showPopup: function (content, cssClass) {
		this.popup.style.visibility = 'visible';
		this.popup.style.display = 'block';
		this.el.down('img').style.visibility = 'hidden';
		this.el.style.display = 'block';
		this.popup.innerHTML = content;
		this.popup.innerHTML.evalScripts();

		if (cssClass) {
			this.popup.classList.add(cssClass);
		}
		
		var dim = this.el.getDimensions();
			
		this.popup.style.display = 'block';
		
		// Give Focus to first input control
		var inputEl = this.el.down('input[type="text"]');
		if (inputEl != null) {
			inputEl.focus();
		}
	},

	hideAnimation: function() {
		this.el.style.display = 'none';
	},

	loadImg: function (name, x, y, img) {
		if (!img) {
			img = new Image();
		}
		img.src = this.options.pictures[name];
		img.style.position = 'absolute';
		img.style.left = x + 'px';
		img.style.top = y + 'px';
		img.style.display = 'none';
		return img;
	},

	foodAnimation: function() {
		soundManager.stopAll();

		this.popup.style.visibility = 'hidden'; // no popup needed
		var itemList = this.el.up('.GNOMApplication').down('.ajajScrollList').childElements();

		var img = this.el.down('img');
		img.src = this.imgUrl; // item image

		if (global.isFirefox || global.isChrome || global.isOpera || global.isIpad || global.isIphone) {
			if (itemList.length < 3 || this.xMouse < 450) {
				itemList[0].style.visibility = 'hidden';
			} else if (itemList.length < 5 || this.xMouse < 550) {
				itemList[2].style.visibility = 'hidden';
			} else if (itemList.length < 7 || this.xMouse < 650) {
				itemList[4].style.visibility = 'hidden';
			} else {
				itemList[6].style.visibility = 'hidden';
			}
		}

		if (this.xMouse < 450) {
			img.style.top = '335px';
			img.style.left = '95px';
		} else if (this.xMouse < 550) {
			img.style.top = '335px';
			img.style.left = '195px';
		} else if (this.xMouse < 650) {
			img.style.top = '335px';
			img.style.left = '295px';
		} else {
			img.style.top = '335px';
			img.style.left = '395px';
		}

		var x = this.options.xMouthPet - (img.width/2) + 10; // item moves to this position
		var y = this.options.yMouthPet - (img.height/2) + 50;
		
		this.el.style.display = 'block';
		
		new Effect.Move(img, { x: x, y: y, mode: 'absolute' , duration: 0.8});
		new Effect.Shrink(img, {duration: 0.8, queue: 'end'});
		window.setTimeout(function () { GenericSoundsInstance.foodContact1(); }, 800);
		window.setTimeout(function () { GenericSoundsInstance.foodContact2(); }, 1000);

		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () { 
			tabApp.blocked = false;
			tabApp.loadPage(page);
		}, 1600);
	},

	polishAnimation: function() {
		soundManager.stopAll();

		var img = this.el.down('img');
		img.src = this.options.pictures['towel2']; // towel image

		var img1 = this.el.appendChild(this.loadImg('sparkle', 10, 50));

		this.popup.style.visibility = 'hidden'; // no popup needed
		this.el.style.display = 'block';

		img.style.top = '90px';
		img.style.left = '10px';

		GenericSoundsInstance.toweling();
		new Effect.Move(img, {x: 70, y: 20, mode: 'relative', duration: 0.3} );
		new Effect.Move(img, {x: -70, y: 20, mode: 'relative', duration: 0.3, queue: 'end'});
		new Effect.Move(img, {x: 70, y: 20, mode: 'relative', duration: 0.3, queue: 'end'});
		new Effect.Move(img, {x: -70, y: 20, mode: 'relative', duration: 0.3, queue: 'end'});
		new Effect.Move(img, {x: 70, y: 20, mode: 'relative', duration: 0.3, queue: 'end'}); 
		this.timer = window.setTimeout( function() { GenericSoundsInstance.happyPet(); } , 1200);
		new Effect.Move(img, {x: -70, y: 20, mode: 'relative', duration: 0.3, queue: 'end'});
		this.timer = window.setTimeout(
			function() {
				soundManager.stop('toweling');
				img.style.visibility = 'hidden';
				img1.style.visibility = 'visible';
				new Effect.Appear(img1, { duration: 0.3}); 
				}
			, 1800);
		this.timer = window.setTimeout(function() { new Effect.Appear(img1, {from: 1.0, to: 0.0, duration: 0.3 }); }, 2200);
		
		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(
			function() {
				if(tabApp.blocked) {
					soundManager.stopAll();
					tabApp.blocked = false;
					tabApp.loadPage(page); 
				}
			}
		, 2800);
	},

	musicAnimation: function() {
		GenericSoundsInstance.eggMusic();
		
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(
			function() {
				if(tabApp.blocked) {
					soundManager.stopAll();
					tabApp.blocked = false;
					tabApp.loadPage(page); 
				}
			}
		, 37000);
	},

	incubateAnimation: function() {
		var backImgEgg = this.el.up('.GNOMApplication').down('.eggImage');

		new Effect.Highlight(backImgEgg, { startcolor: '#DC3401', endcolor: '#FF5926', duration: 0.7});
		new Effect.Highlight(backImgEgg, { startcolor: '#DC3401', endcolor: '#FF5926', duration: 0.7, queue: 'end'});
		new Effect.Highlight(backImgEgg, { startcolor: '#DC3401', endcolor: '#ffbbfa', duration: 0.7, queue: 'end'});
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function() {
			if(tabApp.blocked) {
				tabApp.blocked = false;
				tabApp.loadPage(page); 
			}
			}, 2100);
	},

	sunshineAnimation: function() {
		var backImgEgg = this.el.up('.GNOMApplication').down('.eggImage');

		new Effect.Highlight(backImgEgg, { startcolor: '#FAD400', endcolor: '#FFFE8B', duration: 0.7});
		new Effect.Highlight(backImgEgg, { startcolor: '#FAD400', endcolor: '#FFFE8B', duration: 0.7, queue: 'end'});
		new Effect.Highlight(backImgEgg, { startcolor: '#FAD400', endcolor: '#ffbbfa', duration: 0.7, queue: 'end'});
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function() { 
			if(tabApp.blocked) {
				tabApp.blocked = false;
				tabApp.loadPage(page); 
			}
		}, 2100);
	},

	blanketAnimation: function() {
		this.popup.style.visibility = 'hidden'; // no popup needed
		
		var blanket = this.el.down('img');
		blanket.src = this.options.pictures['blanket'];
		blanket.style.top = '166px';
		blanket.style.left = '10px';
		blanket.style.display = 'none';
		blanket.style.zIndex = 2000;

		var backImgEgg = this.el.up('.GNOMApplication').down('.imageBox');
		var pillow = backImgEgg.appendChild(this.loadImg('pillow', 0, 15));
		this.el.up('.GNOMApplication').down('.eggImage').style.zIndex = 1000;

		this.el.style.display = 'block';
		new Effect.Appear(blanket, {duration: 1.0});
		new Effect.Appear(pillow, {duration: 1.0});
		this.timer = window.setTimeout(function() { GenericSoundsInstance.yawn(); }, 900);

		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () { 
			if(tabApp.blocked) {
				tabApp.blocked = false;
				tabApp.loadPage(page); 
			}
		}, 5000);
	},

	cuddleAnimation: function() {
		//pet image
		var petImg = new Image();
		petImg.src = this.petImgUrl;
		petImg.style.position = 'absolute';
		petImg.style.top = '47px';
		petImg.style.left = '10px';
		petImg.style.display = 'block';
		this.el.appendChild(petImg);
		
		//hearts
		var heart = this.loadImg('heart', 50, 140, this.el.down('img'));
		var heart1 = this.el.appendChild(this.loadImg('heart', 90, 240));
		var heart2 = this.el.appendChild(this.loadImg('heart', 140, 200));
		
		this.popup.style.visibility = 'hidden'; // no popup needed
		this.el.up('.GNOMApplication').down('.imageBox').style.visibility = 'hidden';
		this.el.style.display = 'block';

		//effects
		Effect.Shake(petImg, {duration: 1.0, distance: 5, queue: { position: 'end', scope: 'petImg' }});

		new Effect.Appear(heart, {duration: 0.3, queue: { position: 'end', scope: 'heart' }});
		new Effect.Move(heart, {x: 0, y: -80, mode: 'relative', queue: { position: 'end', scope: 'heart'}});
		new Effect.Fade(heart, {duration: 0.3, queue: {position: 'end', scope: 'heart'}});

		this.timer = window.setTimeout(function() {
			new Effect.Appear(heart1, {duration: 0.3, queue: { position: 'end', scope: 'heart1' }});
			new Effect.Move(heart1, {x: 0, y: -150, mode: 'relative', queue: { position: 'end', scope: 'heart1'}});
			new Effect.Fade(heart1, {duration: 0.3, queue: {position: 'end', scope: 'heart1'}});
		}, 300);

		this.timer = window.setTimeout(function() {
		new Effect.Appear(heart2, {duration: 0.3, queue: { position: 'end', scope: 'heart2' }});
		new Effect.Move(heart2, {x: 0, y: -100, mode: 'relative', queue: { position: 'end', scope: 'heart2'}});
		new Effect.Fade(heart2, {duration: 0.3, queue: {position: 'end', scope: 'heart2'}});
		}, 600);

		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () { 
			tabApp.blocked = false;
			tabApp.loadPage(page); 
		}, 2300);
	},

	bathAnimation: function() {
		GenericSoundsInstance.runningWater();
		//shower image
		var shower = this.el.appendChild(this.loadImg('shower', 15, -10));

		//running water image
		this.water = this.el.appendChild(this.loadImg('water1', 40, 45));
		this.water1 = this.el.appendChild(this.loadImg('water2', 40, 45));
		this.water2 = this.el.appendChild(this.loadImg('water3', 40, 45));

		//startAnimation
		this.el.down('img').style.visibility = 'hidden';
		this.popup.style.visibility = 'hidden'; // no popup needed
		this.el.style.display = 'block';
		shower.style.display = 'block';
		this.waterAnimation(0);
		
		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () { 
			soundManager.stop('runningWater');
			tabApp.blocked = false;
			tabApp.loadPage(page); 
		}, 6000);
	},

	waterAnimation: function(index) {
		var water = this.water, water1 = this.water1, water2 = this.water2;
		//start animation
		water.style.display = 'block';
		this.timer = window.setTimeout(
			function() {
				water1.style.display = 'block';
				water.style.display = 'none';
			}
		, 100);
		this.timer = window.setTimeout(
			function() {
				water2.style.display = 'block';
				water1.style.display = 'none';
			}
		, 200);

		if(index < 30) {
			if(index == 14)
				GenericSoundsInstance.happyPet();
			index++;
			var act = this;
			this.timer = window.setTimeout(function() {act.waterAnimation(index);}, 200);
		}
	},

	playAnimation: function() {
		//animation images
		var ball = this.el.appendChild(this.loadImg('ball', 0, 200));
		ball.style.display = 'block';

		var ball1 = this.el.appendChild(this.loadImg('ball', 100, 650));
		ball1.style.display = 'block';

		//startAnimation
		this.el.down('img').style.visibility = 'hidden';
		this.popup.style.visibility = 'hidden'; // no popup needed
		this.el.style.display = 'block';

		new Effect.Move(ball, {x: 325, y: 415 , mode: 'absolute', duration: 0.97, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b' }});
		new Effect.Move(ball, {x: 650, y: 200 , mode: 'absolute', duration: 0.97, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b' }});
		new Effect.Move(ball, {x: 518, y: 0 , mode: 'absolute', duration: 0.6, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b' }});
		new Effect.Move(ball, {x: 243, y: 415 , mode: 'absolute', duration: 1.24, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b' }});
		new Effect.Move(ball, {x: 0, y: 109 , mode: 'absolute', duration: 0.98, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b' }});
		new Effect.Move(ball, {x: 87, y: 0 , mode: 'absolute', duration: 0.35, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b' }});
		new Effect.Move(ball, {x: 416, y: 415 , mode: 'absolute', duration: 1.32, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b' }});
		new Effect.Move(ball, {x: 650, y: 120 , mode: 'absolute', duration: 0.94, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b' }});
		new Effect.Appear(ball, { from: 1.0, to: 0.0, duration: 0.5, queue: {position: 'end', scope: 'b' }});

		new Effect.Move(ball1, {x: 400, y: 415 , mode: 'absolute', duration: 1.0, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b1' }});
		new Effect.Move(ball1, {x: 70, y: 0 , mode: 'absolute', duration: 1.33, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b1' }});
		new Effect.Move(ball1, {x: 0, y: 88 , mode: 'absolute', duration: 0.28, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b1' }});
		new Effect.Move(ball1, {x: 260, y: 415 , mode: 'absolute', duration: 1.04, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b1' }});
		new Effect.Move(ball1, {x: 535, y: 0 , mode: 'absolute', duration: 1.24, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b1' }});
		new Effect.Move(ball1, {x: 650, y: 174 , mode: 'absolute', duration: 0.52, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b1' }});
		new Effect.Move(ball1, {x: 490, y: 415 , mode: 'absolute', duration: 0.72, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b1' }});
		new Effect.Move(ball1, {x: 215, y: 0 , mode: 'absolute', duration: 1.24, transition: Effect.Transitions.linear, queue: {position: 'end', scope: 'b1' }});
		new Effect.Appear(ball1, { from: 1.0, to: 0.0, duration: 0.5, queue: {position: 'end', scope: 'b1' }});
		
		this.timer = window.setTimeout(function () {GenericSoundsInstance.happyPet();}, 1000);
		this.timer = window.setTimeout(function () {GenericSoundsInstance.happyPet();}, 5000);

		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () {
			tabApp.blocked = false;
			tabApp.loadPage(page); 
		}, 8000);
	},	

	tripAnimation: function() {
		GenericSoundsInstance.outside();

		//animation images
		var leftFoot = this.el.appendChild(this.loadImg('leftFoot', 0, 150));
		var leftFoot1 = this.el.appendChild(this.loadImg('leftFoot', 0, 150));
		var leftFoot2 = this.el.appendChild(this.loadImg('leftFoot', 0, 150));
		var leftFoot3 = this.el.appendChild(this.loadImg('leftFoot', 0, 150));
		var leftFoot4 = this.el.appendChild(this.loadImg('leftFoot', 0, 150));
		var leftFoot5 = this.el.appendChild(this.loadImg('leftFoot', 0, 150));

		var rightFoot = this.el.appendChild(this.loadImg('rightFoot', 0, 150));
		var rightFoot1 = this.el.appendChild(this.loadImg('rightFoot', 0, 150));
		var rightFoot2 = this.el.appendChild(this.loadImg('rightFoot', 0, 150));
		var rightFoot3 = this.el.appendChild(this.loadImg('rightFoot', 0, 150));
		var rightFoot4 = this.el.appendChild(this.loadImg('rightFoot', 0, 150));
		var rightFoot5 = this.el.appendChild(this.loadImg('rightFoot', 0, 150));

		// start animation
		this.el.down('img').style.visibility = 'hidden';
		this.popup.style.visibility = 'hidden'; // no popup needed
		this.el.style.display = 'block';
		this.walkAnimation(0, 50, 70, rightFoot, rightFoot1, rightFoot2, leftFoot, leftFoot1, leftFoot2);
		var act = this;
		this.timer = window.setTimeout(function() {act.walkAnimation(0, 170, 190, rightFoot3, rightFoot4, rightFoot5, leftFoot3, leftFoot4, leftFoot5);}, 3000);

		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () { 
			soundManager.stop('outside');
			tabApp.blocked = false;
			tabApp.loadPage(page); 
		}, 14000);
	},

	walkAnimation: function(index, xDistanceRightFoot, xDistanceLeftFoot, rightFoot, rightFoot1, rightFoot2, leftFoot, leftFoot1, leftFoot2) {
		var xR = xDistanceRightFoot;
		var xL = xDistanceLeftFoot;
		var r = rightFoot, r1 = rightFoot1, r2 = rightFoot2, l = leftFoot, l1 = leftFoot1, l2 = leftFoot2;
		r.style.left = xR + 'px';
		xR += 40;
		r1.style.left = xR + 'px';
		xR += 40;
		r2.style.left = xR + 'px';
		l.style.left = xL + 'px';
		xL += 40;
		l1.style.left = xL + 'px';
		xL += 40;
		l2.style.left = xL + 'px';

		//start animation
		new Effect.Appear(r, {duration: 0.5, queue: {position: 'end', scope: 'r'}});
		new Effect.Appear(l, {duration: 0.5, queue: {position: 'end', scope: 'r'}});
		new Effect.Appear(r1, {duration: 0.5, queue: {position: 'end', scope: 'r'}});
		new Effect.Appear(l1, {duration: 0.5, queue: {position: 'end', scope: 'r'}});
		new Effect.Appear(r2, {duration: 0.5, queue: {position: 'end', scope: 'r'}});
		new Effect.Appear(l2, {duration: 0.5, queue: {position: 'end', scope: 'r'}});

		this.timer = window.setTimeout(
			function() {
				new Effect.Appear(r, {duration: 0.5, from: 1.0, to: 0.0, queue: {position: 'end', scope: 'rDisappear'}});
				new Effect.Appear(l, {duration: 0.5, from: 1.0, to: 0.0, queue: {position: 'end', scope: 'rDisappear'}});
				new Effect.Appear(r1, {duration: 0.5, from: 1.0, to: 0.0, queue: {position: 'end', scope: 'rDisappear'}});
				new Effect.Appear(l1, {duration: 0.5, from: 1.0, to: 0.0, queue: {position: 'end', scope: 'rDisappear'}});
				new Effect.Appear(r2, {duration: 0.5, from: 1.0, to: 0.0, queue: {position: 'end', scope: 'rDisappear'}});
				new Effect.Appear(l2, {duration: 0.5, from: 1.0, to: 0.0, queue: {position: 'end', scope: 'rDisappear'}});
			}
		, 2000);

		if(index < 1) {
			var act = this;
			this.timer = window.setTimeout(function () { act.walkAnimation(index + 1, xR + 160, xL + 160, r, r1, r2, l, l1, l2); }, 6000);
		}
	},

	exerciseAnimation: function() {
		// animation images
		var petImg = new Image();
		petImg.src = this.petImgUrl;
		petImg.style.position = 'absolute';
		petImg.style.top = '47px';
		petImg.style.left = '10px';
		petImg.style.display = 'block';
		this.el.appendChild(petImg);

		this.el.up('.GNOMApplication').down('.imageBox').style.visibility = 'hidden';
		this.el.down('img').style.visibility = 'hidden';
		this.popup.style.visibility = 'hidden'; // no popup needed
		this.el.style.display = 'block';

		GenericSoundsInstance.jump();
		this.timer = window.setTimeout(function() {GenericSoundsInstance.jump();}, 1600);
		this.timer = window.setTimeout(function() {GenericSoundsInstance.jump();}, 3200);
		this.timer = window.setTimeout(function() {GenericSoundsInstance.jump();}, 4800);

		for(var i = 0; i < 4; i++) {
			new Effect.Move(petImg, {duration: 0.8, x: 0, y: -100, mode: 'relative', queue: 'end' });
			new Effect.Move(petImg, {duration:0.8, x: 0, y: 100, mode: 'relative', transition: Effect.Transitions.linear, queue: 'end'});
		}

		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () { 
			tabApp.blocked = false;
			tabApp.loadPage(page); 
		}, 6800);
	},

	beautySaloonAnimation: function(popup, width, height, receivedItems, numberOfItems, petImgUrl) {
		this.setButtonsInvisible();
		GenericSoundsInstance.hairdryer();
		this.showPopup(popup, 'playAction');
		this.el.down('img').style.visibility = 'hidden';
		this.el.down('.outerPopup').down('img').style.visibility = 'hidden';
		
		//animation images
		var petImg = new Image();
		petImg.src = petImgUrl;
		petImg.style.position = 'absolute';
		petImg.style.top = '50px';
		petImg.style.left = '50px';
		petImg.style.display = 'block';
		this.el.appendChild(petImg);

		var hairdryer = this.el.appendChild(this.loadImg('hair0', 200, 70));
		var hairdryer1 = this.el.appendChild(this.loadImg('hair1', 200, 70));
		var hairdryer2 = this.el.appendChild(this.loadImg('hair2', 200, 70));

		//start animation
		this.el.style.display = 'block';
		
		this.timer = window.setTimeout(function() { 
			for(var i=0; i < 11; i++) {
				new Effect.Appear(hairdryer1, {duration: 0.3, queue: {position: 'end', scope: 'h'}});
				new Effect.Appear(hairdryer1, {duration: 0.3, from: 1.0, to: 0.0, queue: {position: 'end', scope: 'h'}});
				new Effect.Appear(hairdryer2, {duration: 0.3, queue: {position: 'end', scope: 'h'}});
				new Effect.Appear(hairdryer2, {duration: 0.3, from: 1.0, to: 0.0, queue: {position: 'end', scope: 'h'}});
			}
		}, 1000);

		//items appear
		var act = this;
		this.timer = window.setTimeout(function() { act.itemAppearAnimation(receivedItems, numberOfItems);}, 2000);
		
		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () { 
			tabApp.blocked = false;
			tabApp.loadPage(page); 
		}, 15000);
	},

	circusAnimation: function(popup, width, height, receivedItems, numberOfItems) {
		this.setButtonsInvisible();
		this.showPopup(popup, 'playAction');

		// select random pony and sound
		var random = [Math.floor(Math.random() * (4)) + 1, Math.floor(Math.random() * (4)) + 1];
		if (random[0] == random[1]) {
			if (random[0] == 4) {
				random[0] = 2;
			}
			random[0]++;
		}

		//animation images
		//first pony animation
		var ponies = [[],[]];
		for (var i = 0; i < 2; i++) {
			for (var j = 1; j <= 7; j++) {
				ponies[i].push(this.el.appendChild(this.loadImg('pony' + random[i] + j, 450, 100)));
			}
		}
		ponies[0][0].style.display = 'block';

		if(random[1] == 1 || random[1] == 2) {
			var shortSound = new Boolean(false);
			this.timer = window.setTimeout(function() {GenericSoundsInstance.circus();}, 1000);
		} else {
			var shortSound = new Boolean(true);
			this.timer = window.setTimeout(function() {GenericSoundsInstance.circus2();}, 1000);			
		}

		//animation
		this.ponyAnimation(450, ponies[0][0], ponies[0][1], ponies[0][2], ponies[0][3], ponies[0][4], ponies[0][5], ponies[0][6]);
		var act = this;
		this.timer = window.setTimeout(function() {act.ponyAnimation(450, ponies[1][0], ponies[1][1], ponies[1][2], ponies[1][3], ponies[1][4], ponies[1][5], ponies[1][6]);}, 3000);

		//items appear
		var act = this;
		this.timer = window.setTimeout(function() { act.itemAppearAnimation(receivedItems, numberOfItems);}, 3000);

		//reload page
		if(shortSound) {
			var tabApp = this.el.up('.tabbedApplication').attachedObj;
			var page = this.pageUrl;
			this.timer = window.setTimeout(function () { 
				tabApp.blocked = false;
				tabApp.loadPage(page); 
			}, 14500);
		} else {
			var tabApp = this.el.up('.tabbedApplication').attachedObj;
			var page = this.pageUrl;
			this.timer = window.setTimeout(function () { 
				tabApp.blocked = false;
				tabApp.loadPage(page); 
			}, 27500);
		}
	},

	ponyAnimation: function(xDistance, p, p1, p2, p3, p4, p5, p6) {
		var pony = p, pony1 = p1, pony2 = p2, pony3 = p3, pony4 = p4, pony5 = p5, pony6 = p6;
		var x = xDistance;
		//start animation
		pony6.style.display = 'none';
		pony.style.left = x + 'px';
		pony.style.display = 'block';
		this.timer = window.setTimeout(
			function() {
				pony1.style.left = x + 'px';
				pony1.style.display = 'block';
				pony.style.display = 'none';
			}
		, 100);
		this.timer = window.setTimeout(
			function() {
				x-= 3;
				pony2.style.left = x + 'px';
				pony2.style.display = 'block';
				pony1.style.display = 'none';
			}
		, 200);
		this.timer = window.setTimeout(
			function() {
				x-= 8;
				pony3.style.left = x + 'px';
				pony3.style.display = 'block';
				pony2.style.display = 'none';
			}
		, 300);
		this.timer = window.setTimeout(
			function() {
				x-= 6;
				pony4.style.left = x + 'px';
				pony4.style.display = 'block';
				pony3.style.display = 'none';
			}
		, 400);
		this.timer = window.setTimeout(
			function() {
				x-= 4;
				pony5.style.left = x + 'px';
				pony5.style.display = 'block';
				pony4.style.display = 'none';
			}
		, 500);
		this.timer = window.setTimeout(
			function() {
				x-= 7;
				pony6.style.left = x + 'px';
				pony6.style.display = 'block';
				pony5.style.display = 'none';
			}
		, 600);
		
		if(x > 150) {
			var act = this;
			this.timer = window.setTimeout(function() { act.ponyAnimation(x, pony, pony1, pony2, pony3, pony4, pony5, pony6); }, 600);
		} else {
			this.timer = window.setTimeout(function() { pony6.style.display = 'none'; }, 600);
		}
	},

	shoppingAnimation: function(popup, width, height, receivedItems, numberOfItems) {
		GenericSoundsInstance.shoppingMall();
		this.setButtonsInvisible();
		this.showPopup(popup, 'playAction');
		this.el.down('.outerPopup').down('img').style.visibility = 'hidden';

		//animation images
		var gift = this.el.appendChild(this.loadImg('gift1', 200, 40));
		gift.style.display = 'block';

		var gift1 = this.el.appendChild(this.loadImg('gift2', 200, 40));
		var gift2 = this.el.appendChild(this.loadImg('gift3', 200, 40));
		var gift3 = this.el.appendChild(this.loadImg('gift4', 200, 40));
		var gift4 = this.el.appendChild(this.loadImg('gift5', 200, 40));
		var gift5 = this.el.appendChild(this.loadImg('gift6', 200, 40));

		//start animation
		gift.style.display = 'block';
		this.timer = window.setTimeout(
			function() {
				gift1.style.display = 'block';
				gift.style.display = 'none';
			}
		, 350);
		this.timer = window.setTimeout(
			function() {
				gift2.style.display = 'block';
				gift1.style.display = 'none';
			}
		, 450);
		this.timer = window.setTimeout(
			function() {
				gift3.style.display = 'block';
				gift2.style.display = 'none';
			}
		, 550);
		this.timer = window.setTimeout(
			function() {
				gift4.style.display = 'block';
				gift3.style.display = 'none';
			}
		, 650);
		this.timer = window.setTimeout(
			function() {
				gift5.style.display = 'block';
				gift4.style.display = 'none';
			}
		, 750);

		//items appear
		var act = this;
		this.timer = window.setTimeout(function() { act.itemAppearAnimation(receivedItems, numberOfItems);}, 1000);

		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () { 
			tabApp.blocked = false;
			tabApp.loadPage(page); 
		}, 17500);
	},
	
	treasureHuntAnimation: function(popup, width, height, receivedItems, numberOfItems) {
		this.setButtonsInvisible();
		this.showPopup(popup, 'playAction');
		this.el.down('.outerPopup').down('img').style.visibility = 'hidden';

		//animation images
		var treasure = this.el.appendChild(this.loadImg('treasureChest1', 200, 40));
		treasure.style.display = 'block';

		var treasure1 = this.el.appendChild(this.loadImg('treasureChest2', 200, 40));
		var treasure2 = this.el.appendChild(this.loadImg('treasureChest3', 200, 40));
		var treasure3 = this.el.appendChild(this.loadImg('treasureChest4', 200, 40));
		var treasure4 = this.el.appendChild(this.loadImg('treasureChest5', 200, 40));

		//start animation
		treasure.style.display = 'block';
		this.timer = window.setTimeout(
			function() {
				treasure1.style.display = 'block';
				treasure.style.display = 'none';
			}
		, 350);
		this.timer = window.setTimeout(
			function() {
				treasure2.style.display = 'block';
				treasure1.style.display = 'none';
			}
		, 550);
		this.timer = window.setTimeout(
			function() {
				treasure3.style.display = 'block';
				treasure2.style.display = 'none';
			}
		, 750);
		this.timer = window.setTimeout(
			function() {
				treasure4.style.display = 'block';
				treasure3.style.display = 'none';
			}
		, 950);

		//items appear
		var act = this;
		this.timer = window.setTimeout(function() { act.itemAppearAnimation(receivedItems, numberOfItems);}, 1000);

		//reload page
		var tabApp = this.el.up('.tabbedApplication').attachedObj;
		var page = this.pageUrl;
		this.timer = window.setTimeout(function () {
			tabApp.blocked = false;
			tabApp.loadPage(page); 
		}, 11000);
	},

	itemAppearAnimation: function(receivedItems, numberOfItems) {
		var items = receivedItems;
		switch(numberOfItems) {
			case 5:
				var item5 = new Image();
				item5.src = items.substring(items.lastIndexOf(';')+1);
				item5.style.position = 'absolute';
				item5.style.left = '500px';
				item5.style.top = '350px';
				item5.style.display = 'none';
				this.el.appendChild(item5);
				new Effect.Appear(item5, {duration: 2.0, queue: {position: 'end', scope: 'item'}});
				items = items.substring(0, items.lastIndexOf(';'));
			case 4:
				var item4 = new Image();
				item4.src = items.substring(items.lastIndexOf(';')+1);
				item4.style.position = 'absolute';
				item4.style.left = '400px';
				item4.style.top = '350px';
				item4.style.display = 'none';
				this.el.appendChild(item4);
				new Effect.Appear(item4, {duration: 2.0, queue: {position: 'front', scope: 'item'}});
				items = items.substring(0, items.lastIndexOf(';'));
			case 3:
				var item3 = new Image();
				item3.src = items.substring(items.lastIndexOf(';')+1);
				item3.style.position = 'absolute';
				item3.style.left = '300px';
				item3.style.top = '350px';
				item3.style.display = 'none';
				this.el.appendChild(item3);
				new Effect.Appear(item3, {duration: 2.0, queue: {position: 'front', scope: 'item'}});
				items = items.substring(0, items.lastIndexOf(';'));
			case 2:
				var item2 = new Image();
				item2.src = items.substring(items.lastIndexOf(';')+1);
				item2.style.position = 'absolute';
				item2.style.left = '200px';
				item2.style.top = '350px';
				item2.style.display = 'none';
				this.el.appendChild(item2);
				new Effect.Appear(item2, {duration: 2.0, queue: {position: 'front', scope: 'item'}});
				items = items.substring(0, items.lastIndexOf(';'));
			case 1:
				var item1 = new Image();
				item1.src = items.substring(items.lastIndexOf(';')+1);
				item1.style.position = 'absolute';
				item1.style.left = '100px';
				item1.style.top = '350px';
				item1.style.display = 'none';
				this.el.appendChild(item1);
				new Effect.Appear(item1, {duration: 2.0, queue: {position: 'front', scope: 'item'}});
				items = items.substring(0, items.lastIndexOf(';'));
				break;
			case 0:
				var scoopImage = new Image();
				scoopImage.src = this.options.pictures['scoops'];
				scoopImage.style.position = 'absolute';
				scoopImage.style.left = '300px';
				scoopImage.style.top = '350px';
				scoopImage.style.display = 'none';
				this.el.appendChild(scoopImage);
				new Effect.Appear(scoopImage, {duration: 2.0, queue: {position: 'end', scope: 'item'}});
				items = items.substring(0, items.lastIndexOf(';'));
				break;
		}
	},

	setButtonsInvisible: function() {
		this.el.up('.GNOMApplication').down('.prevBtn').style.display = 'none';
		this.el.up('.GNOMApplication').down('.nextBtn').style.display = 'none';
	}
});
