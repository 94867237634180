/// <reference path="ActivePlazasList.js" />

var InventoryListInstance = undefined;

var InventoryList = Class.create(DOMAttached, {

	initialize: function ($super, el, options) {
		$super(el, options);
		InventoryListInstance = this;
	},

	filterForCategory: function (category) {
		var shoppingCart = document.getElementById("houseInventory-shoppingCart");
		if (category === "Pet") {
			shoppingCart.addClassName("hidden");
		} else {
			shoppingCart.removeClassName("hidden");
			shoppingCart.href = this.options.shopUrl.replace('PLH_PublicShopType', category);
		}

		var newDataUrl = this.options.dataUrl.replace("PLH_inventoryType", category);
		GetVuexModule(this.options.vuexModuleName).reset(newDataUrl);
	},
});