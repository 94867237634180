/// <reference path="DOMAttached.js" />

var DragonChoose = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);
		this.options = options;
		this.el = $(el);

		this.okButton = $(this.options.okButton);
		this.safeObserve(this.okButton, 'click', this.onConfirm.bindAsEventListener(this));
	},

	onConfirm: function (event) {
		//stop page from reloading
		event.stop();

		var first_color;
		var second_color;

		var first_dragons = document.getElementsByName("firstDragon");
		for (var i = 0; i < first_dragons.length; i++) {
			if (first_dragons[i].checked == true) {
				first_color = first_dragons[i].value;
			}
		}
		var second_dragons = document.getElementsByName("secondDragon");
		for (var i = 0; i < second_dragons.length; i++) {
			if (second_dragons[i].checked == true) {
				second_color = second_dragons[i].value;
			}
		}
		var colors = [first_color, second_color];

		if (first_color == second_color) {
			confirmAI(this.options.trSameDragons, function () { }, function () { }, true, false);
		}
		else {
			var req = new Ajax.Request(
				new UrlTemplate(this.options.link).r('PLH_CDragonColor', colors).url, {
					method: 'post',
					encoding: 'UTF-8',
					onSuccess: this.onResponse.bind(this),
				}
			);
		}
	},

	onResponse: function (req) {
		window.location.reload();
	}

});
