/// <reference path="DOMAttached.js" />

var CloseButton = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el,options);
		this.safeObserve(this.el.down(".TriggerControl"),"click", this.onClick.bindAsEventListener(this));
	},
	
	onClick: function(ev){
		if(this.options.showLink){
			location.href = this.options.url;
		}else{
			var ids=this.options.ids.split(",");
			for(var i=0;i<ids.length;i++){
				var el=$(ids[i]);
				el.style.display="none";
			}
		}
	}
});

