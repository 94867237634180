/// <reference path="DOMAttached.js" />

var Window = Class.create(DOMAttached, {
	initialize: function($super, el) {
		$super(el);

		this.safeObserve(window, 'keypress', this.onKeyPress.bindAsEventListener(this));
		this.uniqueId = 1;
		this.escapeHandlers = new Hash();
	},
	
	onKeyPress: function(ev) {
		if (ev.keyCode == 27) {
			if (this.performEscapeActions())
				ev.stop();
		}
	},
	
	performEscapeActions: function() {
		var res = false;
		this.escapeHandlers.each(function(eh) {
			eh.value();
			res = true;
		});
		return res;
	},
	
	registerEscapeHandler: function(fn) {
		this.escapeHandlers.set(this.uniqueId, fn);
		return this.uniqueId++;
	},
	
	unregisterEscapeHandler: function(id) {
		this.escapeHandlers.unset(id);
	}
});
