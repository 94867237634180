/// <reference path="DOMAttached.js" />
/// <reference path="ColorableSvgItem.js" />
/// <reference path="ColorSelector.js" />
/// <reference path="GenericSounds.js" />

var ChangeInProgress = false;
var ChangeElementFade = null;
var ChangeElementAppear = null;

var ChangeableMiniMeItem = Class.create(DOMAttached, {
	index: 0,
	namespace: "http://www.w3.org/2000/svg",
	givenItems: 0,
	store: null,
	itemInstancePrefix: "ii_",

	initialize: function ($super, editor, groupIdentifier, colorSelector, svgItems, customItems) {
		$super();
		this.editor = editor;
		this.groupIdentifier = groupIdentifier;
		this.colorSelector = colorSelector;
		this.svgItems = svgItems;
		this.customItem = customItems;

		this.items = [];
		this.itemsLoaded = [];
		this.index = 0;
		this.colorSelector = colorSelector;

		this.groupElement = document.createElementNS(this.namespace, "g");
		this.groupElement.setAttribute("class", this.groupIdentifier);
		this.editor.appendChild(this.groupElement);

		this.playFeedbackSound = colorSelector.playFeedbackSound;
		if (GenericSoundsInstance == null) {
			GenericSoundsInstance = new GenericSounds(this.playFeedbackSound);
		}

		this.givenItems = this.svgItems.length;

		// svg items are first in items array
		for (var i = 0; i < this.svgItems.length; ++i) {
			this.items.push({
				type: 'svg',
				content: this.svgItems[i]
			});
			this.itemsLoaded.push(null);
		}

		if (!(customItems.length == 1 && customItems[0] == "")) {
			for (var i = 0; i < customItems.length; i++) {
				// custom items come after svg items
				this.items.push({
					type: 'image',
					content: customItems[i]
				});
				this.itemsLoaded.push(null);
			}
		}

		if (typeof (this.setting) == "string" && this.setting.indexOf(this.itemInstancePrefix) == 0) {
			this.index = 0;
			for (var i = 0; i < this.items.length; i++) {
				if (this.items[i].type == 'image' && (this.itemInstancePrefix + this.items[i].content.id) == this.setting) {
					this.index = i;
					break;
				}
			}
		} else if (this.setting == null || parseInt(this.setting) > this.items.length) {
			this.index = 0;
		} else {
			this.index = this.setting - 1;
		}

		// load selected items
		var selectedItem = this.items[this.index];
		this.loadItem(selectedItem, this.index, true);
	},

	addItem: function (item, visible) {
		this.safeObserve(item, "click", function () { this.clickHandler(); }.bindAsEventListener(this));

		this.groupElement.appendChild(item);

		item.style.display = visible ? "block" : "none";
	},

	loadItem: function (item, idx, visible) {
		if (item.type == "image") {
			// images need to be loaded first
			var customItem = this.createImageItem(item.content, idx);

			this.addItem(customItem, visible);
		} else if (item.type == "svg") {
			// svgs can be added directly
			var svg = document.createElementNS(this.namespace, "g");
			var chains = item.content.objectChains;
			for (var i = 0; i < chains.length; ++i) {
				var svgItem = this.createSvgItem(item.content, chains[i].first(), chains[i].last());
				svg.appendChild(svgItem);
			}
			this.addItem(svg, visible);

			this.itemsLoaded[idx] = svg;
		} else {
			throw ("Unknown item type " + item.type);
		}
	},

	createImageItem: function (imageItem, idx) {
		var image = document.createElementNS(this.namespace, "image");
		image.setAttribute("x", imageItem.x);
		image.setAttribute("y", imageItem.y);
		image.setAttribute("width", imageItem.width);
		image.setAttribute("height", imageItem.height);
		image.setAttribute("preserveAspectRatio", "none");
		image.id = this.itemInstancePrefix + imageItem.id;
		image.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', imageItem.src);
		image.style.pointerEvents = "painted";

		this.itemsLoaded[idx] = image;
		return image;
	},

	createSvgItem: function (svgItem, settingKey, colorableId) {
		var useSvg = document.createElementNS(this.namespace, 'use');
		useSvg.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + colorableId);

		var colorableItem = new ColorableSvgItem(this.colorSelector, useSvg, settingKey, colorableId);
		return colorableItem.item;
	},

	clickHandler: function () {
		if (ColorSelectorActiveColor != null) {
			GenericSoundsInstance.softClickSound();
			ColorSelectorActiveColor = null;
			this.colorSelector.resetColors();
			return;
		}
		if (ChangeInProgress) {
			return;
		}

		GenericSoundsInstance.softClickSound();
		var currentIdx = this.index;
		var nextIdx = (currentIdx + 1) % this.itemsLoaded.length;

		if (this.itemsLoaded[nextIdx] == null) {
			this.loadItem(this.items[nextIdx], nextIdx, false);
		}

		this.itemsLoaded[currentIdx].style.pointerEvents = "none";
		this.fadeOutToNextItem(currentIdx, nextIdx);
		this.itemsLoaded[nextIdx].style.pointerEvents = "all";

		this.store = nextIdx + 1;
		if (this.store > this.givenItems) {
			this.store = this.itemsLoaded[nextIdx].id;
		}
		this.index = nextIdx;
	},

	fadeOutToNextItem: function (currentIndex, nextIndex) {
		function fadeOver() {
			if(step < 10){
				step++;
				ChangeElementFade.style.opacity -= 0.1;
				//weird workaround for broken JS += operator
				ChangeElementAppear.style.opacity = parseFloat(ChangeElementAppear.style.opacity) + 0.1;
				window.setTimeout(function () { fadeOver(); }, 50);
			} else {
				ChangeElementFade.style.opacity = 0;
				ChangeElementAppear.style.opacity = 1;
				ChangeElementAppear.style.cursor = "default";
				ChangeElementFade.style.cursor = "default";
				ChangeInProgress = false;
				MiniMeLastVersion++;
			}
		}
		ChangeInProgress = true;
		var ChangeElementFade = this.itemsLoaded[currentIndex];
		ChangeElementFade.style.opacity = 1;
		ChangeElementFade.style.cursor = "wait";
		var ChangeElementAppear = this.itemsLoaded[nextIndex];
		ChangeElementAppear.style.opacity = 0;
		ChangeElementAppear.style.cursor = "wait";
		ChangeElementAppear.style.display = "block";
		var step = 0;
		fadeOver();
	}
});

var MiniMeFaces = Class.create(ChangeableMiniMeItem, {
	initialize: function($super, svgDoc, colorSelector) {
		this.setting = MiniMeSettings.face;
		$super(svgDoc, "faces", colorSelector, MiniMeStaticItems.face, []);
	},

	clickHandler: function($super) {
		$super();
		MiniMeSettings.face = this.index + 1;
	}
});

var MiniMeHair = Class.create(ChangeableMiniMeItem, {
	initialize: function($super, svgDoc, colorSelector) {
		this.setting = MiniMeSettings.hair;
		$super(svgDoc, "hair", colorSelector, MiniMeStaticItems.hair, []);
	},

	clickHandler: function($super) {
		$super();
		MiniMeSettings.hair = this.index + 1;
	}
});

var MiniMeBottom = Class.create(ChangeableMiniMeItem, {
	initialize: function($super, svgDoc, colorSelector, customItems) {
		this.setting = MiniMeSettings.bottom;
		$super(svgDoc, "bottoms", colorSelector, MiniMeStaticItems.bottom, customItems);
	},

	clickHandler: function($super) {
		$super();
		if (this.store) {
			MiniMeSettings.bottom = this.store;
		}
	}
});

var MiniMeTop = Class.create(ChangeableMiniMeItem, {
	initialize: function($super, svgDoc, colorSelector, customItems) {
		this.setting = MiniMeSettings.top;		
		$super(svgDoc, "tops", colorSelector, MiniMeStaticItems.top, customItems);
	},

	clickHandler: function($super) {
		$super();
		if (this.store) {
			MiniMeSettings.top = this.store;
		}
	}
});

var MiniMeShoes = Class.create(ChangeableMiniMeItem, {
	initialize: function($super, svgDoc, colorSelector, customItems) {
		this.setting = MiniMeSettings.shoes;
		$super(svgDoc, "shoes", colorSelector, MiniMeStaticItems.shoes, customItems);
	},

	clickHandler: function($super) {
		$super();
		if (this.store) {
			MiniMeSettings.shoes = this.store;
		}
	}
});
