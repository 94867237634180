/// <reference path="DOMAttached.js" />

var DragInProgress = false;

var DraggableSvgItem = Class.create(DOMAttached, {
	namespace: "http://www.w3.org/2000/svg",

	// position the object was clicked
	dragx: 0,
	dragy: 0,
	
	// position of cursor in the svg
	posx: 0,
	posy: 0,
	
	// is this svgItem currently dragged
	isActive: false,

	// width and height of MiniMe
	svgHeight: 798,
	svgWidth: 450,
	
	//position drag started
	startx: 0,
	starty: 0,
	
	lastDrag: 0,

	initialize: function ($super, editor) {
		$super();
		this.editor = editor;
	},
	
	addListeners: function() {
		this.item.style.userSelect = "none";
		this.safeObserve(this.item, "mousedown", this.dragStart.bindAsEventListener(this));
		this.safeObserve(this.editor, "mousemove", this.drag.bindAsEventListener(this));
		this.safeObserve(this.editor, "mouseup", this.dragStop.bindAsEventListener(this));
		
		//Mobile
		if (global.isTouchDevice()) {
			this.safeObserve(this.item, "touchstart", this.dragStart.bindAsEventListener(this));
			this.safeObserve(this.editor, "touchmove", this.drag.bindAsEventListener(this));
			this.safeObserve(this.editor, "touchend", this.dragStop.bindAsEventListener(this));
			this.safeObserve(this.editor, "touchcancel", this.dragStop.bindAsEventListener(this));
		}
	},
	
	dragStart: function(e) {
		this.pauseEvent(e);
		
		this.startx = this.item.getAttribute("x");
		this.starty = this.item.getAttribute("y");		
		
		this.isActive = true;
		DragInProgress = true;
		
		if (global.isTouchDevice() && e.type === "touchstart") {
	  		this.posx = e.touches[0].pageX;
  			this.posy = e.touches[0].pageY;
  		}
		
		this.dragx = this.posx - this.item.getAttribute("x");
  		this.dragy = this.posy - this.item.getAttribute("y");
  		
  		return false;
	},
	
	dragStop: function(e) {
		if (this.isActive) {
			this.isActive = false;
			DragInProgress = false;
		}
	},
	
	drag: function (e) {
		if (this.isActive) {
			this.pauseEvent(e);
		}
		
		var now = new Date();
		if (now.getTime() - this.lastDrag > 70) {
			if (global.isTouchDevice() && e.type === "touchmove") {
		  		this.posx = e.touches[0].pageX;
	  			this.posy = e.touches[0].pageY;
	  		} else {
	  			this.posx = document.all ? e.clientX : e.pageX;
	  			this.posy = document.all ? e.clientY : e.pageY;
	  		}
			
			if (this.isActive) {
				var newX = this.posx - this.dragx;
				var newY = this.posy - this.dragy;
				var width = parseFloat(this.item.getAttribute("width"));
				var height = parseFloat(this.item.getAttribute("height"));
	
				// don't drag over left/top border
				if (newX + width / 2 < 0) newX = -1 * width / 2;	
				if (newY + height / 2 < 0) newY =-1 * height / 2;
	
				// dont't drag over right/bottom border
				if (newX + width / 2 > this.svgWidth) newX = this.svgWidth - width / 2;
				if (newY + height / 2 > this.svgHeight) newY = this.svgHeight - height / 2;
			
				this.item.setAttribute("x", Math.round(newX));
				this.item.setAttribute("y", Math.round(newY));
				now = new Date();
				this.lastDrag = now.getTime();
			}
  		}
  		
  		return false;
	},
	
	pauseEvent: function(e) {
		if (typeof(e) == "undefined") return;
		
    	if(e.stopPropagation) e.stopPropagation();
    	if(e.preventDefault) e.preventDefault();
    	e.cancelBubble=true;
    	e.returnValue=false;
    	return false;
	},
});
