// All item objects must implement the following properties / methods:
//   type: miniworldHouseItem.itemType member
//   canvas: canvas DOM object
//   draw(mode, pointerX, pointerY): [mode is miniworldHouseItem.displayMode member]
//				   [pointerX, pointerY is int]
//				   Draw item on its canvas, return nothing
//   startInteraction(pointerX, pointerY): [pointerX, pointerY like above]
//					 React to click / beginning drag on item, return nothing
//   moveInteraction(pointerX, pointerY, allItems): [allItems array of all items in room]
//						  React to drag on item, return miniworldHouseItem.displayMode member
//   endInteraction(pointerX, pointerY): React to end of click / drag, return nothing

var miniworldHouseItem = new function () {
	this.displayMode = {
		DISPLAY: "DISPLAY",
		NONE: "NONE",
		SEMI: "SEMI",
		ICON: "ICON"
	};

	this.itemType = {
		FLOOR: "FLOOR",
		WALL: "WALL",
		DOOR: "DOOR"
	};

	this.setDisplayMode = function (mode, canvas) {
		if (mode == miniworldHouseItem.displayMode.NONE) {
			canvas.style.display = "none";
		} else if (mode == miniworldHouseItem.displayMode.SEMI) {
			canvas.style.display = "block";
			canvas.style.opacity = .5;
		} else if (mode == miniworldHouseItem.displayMode.DISPLAY || mode == miniworldHouseItem.displayMode.ICON) {
			canvas.style.display = "block";
			canvas.style.opacity = 1;
		}
	};

	this.drawIcon = function (context, image, width, height, pointerX, pointerY) {
		var scalingFactor = miniworldHouseGeometry.iconMaxSideLength / Math.max(width, height);
		context.drawImage(image,
	    pointerX - .5 * width * scalingFactor,
	    pointerY - .5 * height * scalingFactor,
	    width * scalingFactor,
	    height * scalingFactor);
	};

	this.itemCollidesWithOtherItems = function (item, items) {
		for (var i = 0; i != items.length; ++i) {
			if (items[i] === item) {
				continue;
			}
			if (item.collidesWithItem(items[i])) {
				return true;
			}
		}
		return false;
	};
};

var MiniworldHouseFloorItem = function (instanceId, imageUrl, posX, posY, width, height, houseLevel, onDraw, allItems) {
	this.type = miniworldHouseItem.itemType.FLOOR;
	this.canvas = miniworldHouseCanvasHelper.createCanvas();
	this.instanceId = instanceId;
	this.posX = posX;
	this.posY = posY;
	this.width = width * miniworldHouseGeometry.levelFactor(houseLevel);
	this.height = height * miniworldHouseGeometry.levelFactor(houseLevel);
	this.relativeDragPositionX = null;
	this.relativeDragPositionY = null;
	this.allItems = allItems;
	this.lastValidX = this.posX;
	this.lastValidY = this.posY;

	this.displayImage(onDraw);
	this.loadImage = function () {
		// Do not load image before draw method is defined
		this.image.src = imageUrl;
	};

	this.serialize = function () {
		return {
			"instanceid": this.instanceId,
			"positionx": this.lastValidX,
			"positiony": this.lastValidY,
		};
	};

	this.upperLeftX = function () {
		var scalingFactor = miniworldHouseGeometry.scalingFactor(this.posY);
		return this.posX - this.width * scalingFactor * .5;
	};

	this.upperLeftY = function () {
		var scalingFactor = miniworldHouseGeometry.scalingFactor(this.posY);
		return this.posY - this.height * scalingFactor;
	};

	this.draw = function (mode, pointerX, pointerY) {
		if ((pointerX === null || pointerY === null) && mode == miniworldHouseItem.displayMode.ICON) {
			pointerX = this.posX;
			pointerY = this.posY;
		}

		miniworldHouseItem.setDisplayMode(mode, this.canvas);

		var context = this.canvas.getContext("2d");
		context.clearRect(0, 0, this.canvas.width, this.canvas.height);

		if (mode == miniworldHouseItem.displayMode.ICON) {
			miniworldHouseItem.drawIcon(context, this.image, this.width, this.height, pointerX, pointerY);
		} else {
			var scalingFactor = miniworldHouseGeometry.scalingFactor(this.posY);
			if (scalingFactor > 0) {
				context.drawImage(this.image,
					this.upperLeftX(),
					this.upperLeftY(),
					this.width * scalingFactor,
					this.height * scalingFactor);
			}
		}
	};

	this.collidesWithItem = function (other) {
		// furniture doesn't collide with items placed on the walls
		if (other.type == miniworldHouseItem.itemType.WALL
	    || other.type == miniworldHouseItem.itemType.DOOR) {
			return false;
		} else {
			return (Math.abs(this.posY - other.posY) < 10
		    && !(this.upperLeftX() > other.upperLeftX() + other.width
			 || other.upperLeftX() > this.upperLeftX() + this.width));
		}
	};

	this.collidesWithWalls = function () {
		var imageWidth = miniworldHouseGeometry.scalingFactor(this.posY) * this.width;
		return !(miniworldHouseGeometry.isPointOnFloor(this.posX - .5 * imageWidth, this.posY)
		 && miniworldHouseGeometry.isPointOnFloor(this.posX + .5 * imageWidth, this.posY));
	};

	this.displayMode = function (allItems) {
		if (this.posY < 350) {
			return miniworldHouseItem.displayMode.ICON;
		} else if (miniworldHouseItem.itemCollidesWithOtherItems(this, allItems) || this.collidesWithWalls()) {
			return miniworldHouseItem.displayMode.SEMI;
		} else {
			return miniworldHouseItem.displayMode.DISPLAY;
		}
	};

	this.startInteraction = function (pointerX, pointerY) {
		var scalingFactor = miniworldHouseGeometry.scalingFactor(this.posY);

		this.relativeDragPositionX = parseInt((pointerX - this.posX) / (this.width * scalingFactor));
		this.relativeDragPositionY = parseInt((pointerY - this.posY) / (this.height * scalingFactor));

		this.lastValidX = this.posX;
		this.lastValidY = this.posY;
	};

	this.moveInteraction = function (pointerX, pointerY, allItems) {
		var scalingFactor = miniworldHouseGeometry.scalingFactor(this.posY);
		this.posX = parseInt(pointerX - (this.relativeDragPositionX * this.width * scalingFactor));
		this.posY = parseInt(pointerY - (this.relativeDragPositionY * this.height * scalingFactor));

		var mode = this.displayMode(allItems);
		if (mode == miniworldHouseItem.displayMode.DISPLAY) {
			this.lastValidX = this.posX;
			this.lastValidY = this.posY;
		}

		this.draw(mode, pointerX, pointerY);

		return mode;
	};

	this.endInteraction = function (pointerX, pointerY) {
		this.posX = this.lastValidX;
		this.posY = this.lastValidY;

		this.draw(miniworldHouseItem.displayMode.DISPLAY, null, null);
	};

	this.loadImage();
};

var MiniworldHouseWallItem = function (instanceId, imageUrl, posX, posY, width, height, houseLevel, onDraw, allItems) {
	this.type = miniworldHouseItem.itemType.WALL;
	this.canvas = miniworldHouseCanvasHelper.createCanvas();
	this.instanceId = instanceId;
	this.posX = posX;
	this.posY = posY;
	this.allItems = allItems;
	this.width = width * miniworldHouseGeometry.levelFactor(houseLevel);
	this.height = height * miniworldHouseGeometry.levelFactor(houseLevel);
	this.relativeDragPositionX = null;
	this.relativeDragPositionY = null;
	this.lastValidX = this.posX;
	this.lastValidY = this.posY;

	this.displayImage(onDraw);
	this.loadImage = function () {
		// Do not load image before draw method is defined
		this.image.src = imageUrl;
	};

	this.serialize = function () {
		return {
			"instanceid": this.instanceId,
			"positionx": this.lastValidX,
			"positiony": this.lastValidY,
		};
	};

	this.upperLeftX = function () {
		return this.posX - this.width * .5;
	};

	this.upperLeftY = function () {
		return this.posY - this.height;
	};

	this.draw = function (mode, pointerX, pointerY) {
		if ((pointerX === null || pointerY === null) && mode == miniworldHouseItem.displayMode.ICON) {
			pointerX = this.posX;
			pointerY = this.posY;
		}

		miniworldHouseItem.setDisplayMode(mode, this.canvas);

		var context = this.canvas.getContext("2d");
		context.clearRect(0, 0, this.canvas.width, this.canvas.height);

		if (mode == miniworldHouseItem.displayMode.ICON) {
			miniworldHouseItem.drawIcon(context, this.image, this.width, this.height, pointerX, pointerY);
		} else {
			if (this.upperLeftX() >= 150 && this.upperLeftX() + this.width <= 800) {
				context.drawImage(this.image,
		    this.upperLeftX(),
		    this.upperLeftY(),
		    this.width,
		    this.height);
			} else {
				for (var x = 0; x < this.width; ++x) {
					var sliceTopLeft = miniworldHouseGeometry.mapWallPosition(this.upperLeftX() + x, this.upperLeftY());
					var sliceBottomRight = miniworldHouseGeometry.mapWallPosition(this.upperLeftX() + x + 1,
										  this.upperLeftY() + this.height);
					context.drawImage(this.image,
			x * this.image.width / this.width,
			0,
			1,
			this.image.height,
			sliceTopLeft.x,
			sliceTopLeft.y,
			sliceBottomRight.x - sliceTopLeft.x + 1,
			sliceBottomRight.y - sliceTopLeft.y + 1);
				}
			}
		}
	};

	this.collidesWithItem = function (other) {
		// furniture doesn't collide with items placed on the walls
		if (other.type == miniworldHouseItem.itemType.FLOOR) {
			return false;
		} else {
			return !(this.upperLeftX() >= other.upperLeftX() + other.width
		     || other.upperLeftX() > this.upperLeftX() + this.width
		     || this.upperLeftY() >= other.upperLeftY() + other.height
		     || other.upperLeftY() >= this.upperLeftY() + this.height)
		}
	};

	this.displayMode = function (allItems) {
		if (this.upperLeftY() + .5 * this.height < 150
	    || this.upperLeftY() + .5 * this.height > 400) {
			return miniworldHouseItem.displayMode.ICON;
		} else if (this.upperLeftY() < 150
		   || this.upperLeftY() + this.height > 400
		   || this.upperLeftX() < -100
		   || this.upperLeftX() + this.width > 1050) {
			return miniworldHouseItem.displayMode.SEMI;
		} else if ((this.upperLeftX() < 150 && this.upperLeftX() + this.width > 150) // left corner
		   || (this.upperLeftX() < 800 && this.upperLeftX() + this.width > 800) // right corner
		   || miniworldHouseItem.itemCollidesWithOtherItems(this, allItems)) {
			return miniworldHouseItem.displayMode.SEMI;
		} else {
			return miniworldHouseItem.displayMode.DISPLAY;
		}
	};

	this.startInteraction = function (pointerX, pointerY) {
		var translatedPointerPos = miniworldHouseGeometry.reverseMapWallPosition(pointerX, pointerY);

		this.relativeDragPositionX = parseInt((translatedPointerPos.x - this.posX) / this.width);
		this.relativeDragPositionY = parseInt((translatedPointerPos.y - this.posY) / this.height);

		this.lastValidX = this.posX;
		this.lastValidY = this.posY;
	};

	this.moveInteraction = function (pointerX, pointerY, allItems) {
		var translatedPointerPos = miniworldHouseGeometry.reverseMapWallPosition(pointerX, pointerY);

		this.posX = parseInt(translatedPointerPos.x - (this.relativeDragPositionX * this.width));
		this.posY = parseInt(translatedPointerPos.y - (this.relativeDragPositionY * this.height));

		var mode = this.displayMode(allItems);
		if (mode == miniworldHouseItem.displayMode.DISPLAY) {
			this.lastValidX = this.posX;
			this.lastValidY = this.posY;
		}

		this.draw(mode, pointerX, pointerY);

		return mode;
	};

	this.endInteraction = function (pointerX, pointerY) {
		this.posX = this.lastValidX;
		this.posY = this.lastValidY;

		this.draw(miniworldHouseItem.displayMode.DISPLAY, null, null);
	};

	this.loadImage();
};

var MiniworldHouseDoorItem = function (linkedRoom, posX, width, height, color, houseLevel, onDraw) {
	this.category = "DOOR";
	this.type = miniworldHouseItem.itemType.DOOR;
	this.canvas = miniworldHouseCanvasHelper.createCanvas();
	this.linkedRoom = linkedRoom;
	this.posX = posX; // position of center of the door, in virtual wall coordinates (see
	// miniworldHouseGeometry.mapWallPosition)
	this.posY = 400;
	this.width = width * miniworldHouseGeometry.levelFactor(houseLevel);
	this.height = height * miniworldHouseGeometry.levelFactor(houseLevel);
	this.color = color;
	this.relativeDragPositionX = null;
	this.relativeDragPositionY = null;
	this.lastValidX = this.posX;
	this.lastValidY = this.posY;
	this.startDragPositionY = null;
	this.startDragPositionX = null;
	this.state = MiniworldHouseDoorItem.interactionStates.RELEASED;

	this.setup = function () {
		// Do not wait for icon image to load
		this.state = MiniworldHouseDoorItem.interactionStates.RELEASED;
		this.draw(miniworldHouseItem.displayMode.DISPLAY, null, null);
		onDraw(this);
	};

	this.serialize = function () {
		return {
			"roomid": this.linkedRoom,
			"position": this.posX,
			"color": this.color
		};
	};

	this.upperLeftX = function () { // virtual wall coordinates
		return this.posX - this.width / 2;
	};

	this.upperLeftY = function () {
		return this.posY - this.height;
	};

	this.draw = function (mode, pointerX, pointerY) {
		if ((pointerX === null || pointerY === null) && mode == miniworldHouseItem.displayMode.ICON) {
			pointerX = this.posX;
			pointerY = miniworldHouseGeometry.bottomLeft.y;
		}

		miniworldHouseItem.setDisplayMode(mode, this.canvas);

		var context = this.canvas.getContext("2d");
		context.clearRect(0, 0, this.canvas.width, this.canvas.height);

		if (mode == miniworldHouseItem.displayMode.ICON) {
			miniworldHouseItem.drawIcon(context, this.image, this.width, this.height, pointerX, pointerY);
		} else {
			var upperLeftX = this.upperLeftX();
			var upperLeftY = this.upperLeftY();
			var translatedUpperLeft = miniworldHouseGeometry.mapWallPosition(upperLeftX, upperLeftY);
			var translatedUpperRight = miniworldHouseGeometry.mapWallPosition(upperLeftX + this.width, upperLeftY);
			var translatedLowerLeft = miniworldHouseGeometry.mapWallPosition(upperLeftX, upperLeftY + this.height);
			var translatedLowerRight = miniworldHouseGeometry.mapWallPosition(upperLeftX + this.width, upperLeftY + this.height);

			var doorKnobCenter = { x: upperLeftX + .15 * this.width, y: upperLeftY + .5 * this.height };
			var doorKnobRadius = this.width * .07;
			var translatedDoorKnobUpperLeft = miniworldHouseGeometry.mapWallPosition(doorKnobCenter.x - doorKnobRadius,
										     doorKnobCenter.y - doorKnobRadius);
			var translatedDoorKnobLowerRight = miniworldHouseGeometry.mapWallPosition(doorKnobCenter.x + doorKnobRadius,
										      doorKnobCenter.y + doorKnobRadius);

			context.beginPath();
			miniworldHouseCanvasHelper.moveToPoint(context, translatedLowerLeft);
			miniworldHouseCanvasHelper.lineToPoint(context, translatedUpperLeft);
			miniworldHouseCanvasHelper.lineToPoint(context, translatedUpperRight);
			miniworldHouseCanvasHelper.lineToPoint(context, translatedLowerRight);
			context.fillStyle = this.color;
			context.fill();
			context.stroke();
			context.closePath();

			// door knob
			context.save();
			context.beginPath();
			context.translate(translatedDoorKnobUpperLeft.x, translatedDoorKnobUpperLeft.y);
			context.scale((translatedDoorKnobLowerRight.x - translatedDoorKnobUpperLeft.x) / 2,
			  (translatedDoorKnobLowerRight.y - translatedDoorKnobUpperLeft.y) / 2);
			context.arc(1, 1, 1, 0, 2 * Math.PI, false);
			context.restore();
			context.stroke();
			context.closePath();
		}
	};

	this.collidesWithItem = function (other) {
		// furniture doesn't collide with items placed on the walls
		if (other.type == miniworldHouseItem.itemType.FLOOR) {
			return false;
		} else {
			return !(this.upperLeftX() >= other.upperLeftX() + other.width
		     || other.upperLeftX() > this.upperLeftX() + this.width
		     || this.upperLeftY() >= other.upperLeftY() + other.height
		     || other.upperLeftY() >= this.upperLeftY() + this.height)
		}
	};

	this.displayMode = function (allItems) {
		if (this.posY != 400) {
			return miniworldHouseItem.displayMode.ICON;
		} else if (this.upperLeftY() < 150
		   || this.upperLeftY() + this.height > 400
		   || this.upperLeftX() < -100
		   || this.upperLeftX() + this.width > 1050) {
			return miniworldHouseItem.displayMode.SEMI;
		} else if ((this.upperLeftX() < 150 && this.upperLeftX() + this.width > 150) // left corner
		   || (this.upperLeftX() < 800 && this.upperLeftX() + this.width > 800) // right corner
		   || miniworldHouseItem.itemCollidesWithOtherItems(this, allItems)) {
			return miniworldHouseItem.displayMode.SEMI;
		} else {
			return miniworldHouseItem.displayMode.DISPLAY;
		}
	};

	this.isPlaceable = function () {
		return (this.lastValidX == this.posX);
	}

	this.startInteraction = function (pointerX, pointerY) {
		this.state = MiniworldHouseDoorItem.interactionStates.CLICKED;

		var translatedPointerPos = miniworldHouseGeometry.reverseMapWallPosition(pointerX, pointerY);

		this.relativeDragPositionX = parseInt((translatedPointerPos.x - this.posX) / this.width);
		this.relativeDragPositionY = parseInt((translatedPointerPos.y - this.posY) / this.height);

		this.lastValidX = this.posX;

		this.startDragPositionX = translatedPointerPos.x;
		this.startDragPositionY = translatedPointerPos.y;

		if (miniworldHouseGetCurrentRoom().editable) {
			MiniworldHouseInstance.menu.deleteDoor.show();
		}
	};

	this.moveInteraction = function (pointerX, pointerY, allItems) {
		var translatedPointerPos = miniworldHouseGeometry.reverseMapWallPosition(pointerX, pointerY);

		if (this.mouseWasMoved(translatedPointerPos)) {
			this.state = MiniworldHouseDoorItem.interactionStates.DRAGGED;
		}

		if (translatedPointerPos.y >= 400 - this.height && translatedPointerPos.y <= 400) {
			this.posY = 400;
		} else {
			this.posY = parseInt(translatedPointerPos.y - (this.relativeDragPositionY * this.height));
		}
		this.posX = parseInt(translatedPointerPos.x - (this.relativeDragPositionX * this.width));

		var mode = this.displayMode(allItems);
		if (mode == miniworldHouseItem.displayMode.DISPLAY) {
			this.lastValidX = this.posX;
		}

		this.draw(mode, pointerX, pointerY);

		return mode;
	};

	this.endInteraction = function (pointerX, pointerY) {
		if (this.state == MiniworldHouseDoorItem.interactionStates.CLICKED) {
			miniworldHouseSwitchToRoom(this.linkedRoom);
		}

		if (!(this.state == MiniworldHouseDoorItem.interactionStates.PREVIEW)) {
			this.state = MiniworldHouseDoorItem.interactionStates.RELEASED;
		}

		this.posX = this.lastValidX;
		this.posY = 400;

		this.draw(miniworldHouseItem.displayMode.DISPLAY, null, null);
		if (miniworldHouseGetCurrentRoom().editable) {
			MiniworldHouseInstance.menu.deleteDoor.hide();
		}
	};

	this.mouseWasMoved = function (translatedPointerPos) {
		var res = (this.startDragPositionX - translatedPointerPos.x != 0) || (this.startDragPositionY - translatedPointerPos.y != 0);
		return res;
	};

	this.colorMoveInteraction = function (pointerX, pointerY) {
		if (miniworldHouseColorPicker.activeObject == this) {
			var color = miniworldHouseColorPicker.getColor(pointerX, pointerY);
			if (color !== null) {
				this.color = color;
			}
		}
		this.draw(miniworldHouseItem.displayMode.DISPLAY, pointerX, pointerY);
		miniworldHouseColorPicker.draw();
	};

	this.image = document.createElement("img");
	this.image.src = MiniworldHouseImages.door;
	this.setup();
}

MiniworldHouseWallItem.prototype.displayImage = MiniworldHouseFloorItem.prototype.displayImage = function(onDraw) {
	this.image = document.createElement("img");
	this.image.onload = (function () {
		var displayMode = this.displayMode(this.allItems);
		if (this.allItems 
			&& displayMode == miniworldHouseItem.displayMode.ICON
			// Initially, placed items have the same coordinates and are possibly colliding with a previously placed item. 
			// We don't want to put an item in an inventory in that case
			|| (displayMode == miniworldHouseItem.displayMode.SEMI && !miniworldHouseItem.itemCollidesWithOtherItems(this, this.allItems))) {
			this.lastValidX = null;
			this.lastValidY = null;
			// put in the inventory if item has wrong coordinates stored in the database #13372
			miniworldHouseCommunication.storeItemInInventory(this.instanceId);
		}
		this.draw(miniworldHouseItem.displayMode.DISPLAY, null, null);
		onDraw(this);
	}).bind(this);
}

MiniworldHouseDoorItem.DEFAULT_COLOR = 'rgb(173, 114, 70)';

MiniworldHouseDoorItem.interactionStates = {
	RELEASED: "RELEASED",
	CLICKED: "CLICKED",
	DRAGGED: "DRAGGED",
	PREVIEW: "PREVIEW"
};
