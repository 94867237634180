/// <reference path="DOMAttached.js" />

var VisibilitySwitcher = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.visible = this.options.visibleByDefault;
		this.otherControl = $(this.options.otherControl);
		this.control = $(el);
		
		var c = this;
		if (this.autoPageInterval != 0)
			setInterval(function() {c.switchVisibility();}, this.options.interval);
	},
	
	switchVisibility: function() {
		this.visible = !this.visible;
		if (this.visible) {			
			new Effect.Appear(this.control, {duration: 0.8});
			new Effect.Fade(this.otherControl, {duration: 0.8, delay: 0.4});
		}
		else {
			new Effect.Appear(this.otherControl, {duration: 0.8});
			new Effect.Fade(this.control, {duration: 0.8, delay: 0.4});		
		}
	}
});
