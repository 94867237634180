/// <reference path="DOMAttached.js" />

var PetOwnerSelector = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

		this.sharedListUrl = options.sharedListUrl;
		this.filterMemberUrl = options.filterMemberUrl;
		this.dropDownList = $(options.dropDownList);
		this.safeObserve(this.dropDownList, 'change', this.onChange.bindAsEventListener(this));
	},

	onChange: function () {
		var url = (this.dropDownList.options[this.dropDownList.selectedIndex].value === "null")
			? new UrlTemplate(this.sharedListUrl).url
			: new UrlTemplate(this.filterMemberUrl).r('PLH_nchild', this.dropDownList.options[this.dropDownList.selectedIndex].value).url;
		
		if (SharedListInstance.loadPageWithCustomUrl(0, url)) {
			event.stop();
		}
	}
});
