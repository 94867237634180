/// <reference path="DOMAttached.js" />

var AwardScoops = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.messageSelector = this.el.down('.messageSelector');
		this.messageInput = this.el.down('.messageInput').down('input');
		
		this.safeObserve(this.messageSelector, 'change', this.onMessageSelected.bindAsEventListener(this));
	},
	
	onMessageSelected: function(ev) {
		if (this.messageSelector.selectedIndex != 0) {
			this.messageInput.value = this.messageSelector.options[this.messageSelector.selectedIndex].value;
			this.messageSelector.selectedIndex = 0;
		}
	}
});
