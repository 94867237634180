var MessageListUtility = {
	cleanUpMessages: function (el) {
		var li = el.up('li');		
		if (typeof(li) == 'undefined')
			return;
			
		li.hide();
		
		var ul = li.up('ul');
		var hasVisible = false;
		for (var li = ul.down('li'); li; li = li.next('li')) {
			if (Element.getStyle(li, 'display') != 'none') {
				hasVisible = true;
				break;
			}
		}
		if (!hasVisible) {
			var container = el.up('.messageContainer');
			if (container)
				container.hide();
		}
	}
};
