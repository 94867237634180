/// <reference path="FocusFirstInput.js" />

var MessageSend = Class.create(FocusFirstInput, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		var submit = this.el.down('input[type=submit]');
		this.safeObserve(submit, 'click', this.onSubmit.bindAsEventListener(this));
		
		// assume that we are online
		this.online = true;
	},
	
	onSubmit : function(ev) {
		var req = new Ajax.Request(this.options.loginStatusUrl, {
		  asynchronous: false,
			method: 'get',
			onSuccess: this.onLoginCheck.bind(this),
			parameters: {
				nocache: Math.random()
			}
		});
		
		if (!this.online) {
			ev.stop();
			throw new Error(this.options.loginStatusWarning);
		}
	},
	
	onLoginCheck : function(req) {
		var json = req.responseText.evalJSON();
		this.online = json.online;
	}
	
});
