/// <reference path="DOMAttached.js" />

// a listener for video player (currently VimeoPlayer) which
// adds or removes scoops for watching a video

var RewardVideoListener = Class.create(DOMAttached, {
	initialize: function ($super, internalVideoId, rewardTimeSpan, rewardUrl, rewardAmount, rewardDescriptionTr) {
		this.internalVideoId = internalVideoId;
		this.rewardTimeSpan = rewardTimeSpan;
		this.rewardUrl = rewardUrl;
		this.rewardAmount = rewardAmount;
		this.rewardDescriptionTr = rewardDescriptionTr;

		this.previousSeconds = 0;
		this.secondsCounter = 0;
	},

	onPlayProgress: function (currentSeconds) {
		var difference = currentSeconds - this.previousSeconds;
		// progress only counts if the user does not skip ahead or backward
		if (difference > 0 && difference < 5) {
			this.secondsCounter += difference;
			if (this.secondsCounter >= this.rewardTimeSpan) {
				this.secondsCounter = 0;
				new Ajax.Request(new UrlTemplate(this.rewardUrl)
					.r('PLH_video', this.internalVideoId)
					.r('PLH_vc', this.rewardAmount)
					.r('PLH_txtKey', this.rewardDescriptionTr).url, {
					method: 'get'
				});
			}
		}
		this.previousSeconds = currentSeconds;
	},

	onLoadProgress: function (percentage) {
		// do nothing
	},

	onPlay: function () {
		// do nothing
	},

	onPause: function () {
		// do nothing
	},

	onEnd: function () {
		// do nothing
	}
});
