/// <reference path="../../SoundPlayer/soundmanager2-nodebug.js" />

var GenericSoundsInstance = null;

var GenericSounds = Class.create({
	initialize: function(playFeedbackSound) {
		this.playFeedbackSound = playFeedbackSound;
		GenericSoundsInstance = this;
	},

	clickSound: function (override) {
		if (this.playFeedbackSound || override) {
			var clickSound = soundManager.createSound({ id: 'click', url: '/snd/genericClick.mp3' });
			soundManager.setVolume('click', 50);
			clickSound.play();
		}
		return;
	},

	softClickSound: function (override) {
		if (this.playFeedbackSound || override) {
			var clickSound = soundManager.createSound({ id: 'softClick', url: '/snd/clickSoft_option2.mp3' });
			soundManager.setVolume('softClick', 50);
			clickSound.play();
		}
		return;
	},

	notificationSound: function () {
		var notificationSound = soundManager.createSound({ id: 'notification', url: '/snd/sound106.mp3' });
		notificationSound.play();
		return;
	},

	putDownSound: function (override) {
		if (this.playFeedbackSound || override) {
			var putDownSound = soundManager.createSound({ id: 'putDown', url: '/snd/genericPutDown.mp3' });
			soundManager.setVolume('putDown', 50);
			putDownSound.play();
		}
		return;
	},

	pickUpSound: function (override) {
		if (this.playFeedbackSound || override) {
			var pickUpSound = soundManager.createSound({ id: 'pickUp', url: '/snd/genericPickUp.mp3' });
			soundManager.setVolume('pickUp', 70);
			pickUpSound.play();
		}
		return;
	},

	whooshSound: function (override) {
		if (this.playFeedbackSound || override) {
			var whooshSound = soundManager.createSound({ id: 'whoosh', url: '/snd/outfitWhoosh.mp3' });
			soundManager.setVolume('whoosh', 70);
			whooshSound.play();
		}
		return;
	},

	longWhooshSound: function (override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var whooshSound = soundManager.createSound({ id: 'longWhoosh', url: '/snd/listWhoosh.mp3' });
				soundManager.setVolume('longWhoosh', 50);
				window.setTimeout(function () { whooshSound.play() }, 200);
			});
		}
		return;
	},

	themeSound: function (override) {
		var _this = this;
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var themeSound = soundManager.createSound({ id: 'theme', url: '/snd/cleverDragonTheme.mp3' });
				themeSound.play({
					onstop: function() { _this.afterThemeSound(); },
					onfinish: function () { _this.afterThemeSound(); }
				});
			});
		}
		return;
	},

	afterThemeSound: function () {
		if (ThemePlayerInstance != null) {
			ThemePlayerInstance.stop();
		}
	},

	successSound: function (override, onFinished) {
		if (this.playFeedbackSound || override) {
			var successSound = soundManager.createSound({ id: 'succ', url: '/snd/genericReward.mp3' });
			successSound.play({
				onfinish: function () {
					if (onFinished) {
						onFinished();
					}
				}
			});
		} else {
			if (onFinished) {
				onFinished();
			}
		}
	},

	failSound: function (override) {
		if (this.playFeedbackSound || override) {
			var failSound = soundManager.createSound({ id: 'fail', url: '/snd/genericWrong.mp3' });
			failSound.play();
		}
	},

	shopSound: function (override) {
		if (this.playFeedbackSound || override) {
			var shopSound = soundManager.createSound({ id: 'shop', url: '/snd/shop.mp3' });
			shopSound.play();
		}
	},

	introSound: function (override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var introSound = soundManager.createSound({ id: 'intro', url: '/snd/intro.mp3' });
				introSound.play();
			});
		}
	},

	foodContact1: function (override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var foodContact1Sound = soundManager.createSound({ id: 'foodContact1', url: '/snd/foodContact1.mp3' });
				foodContact1Sound.play();
			});
		}
	},

	foodContact2: function (override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var foodContact2Sound = soundManager.createSound({ id: 'foodContact2', url: '/snd/foodContact2.mp3' });
				foodContact2Sound.play();
			});
		}
	},

	toweling: function (override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var towelingSound = soundManager.createSound({ id: 'toweling', url: '/snd/toweling.mp3' });
				towelingSound.play();
			});
		}
	},

	eggMusic: function (override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var eggMusicSound = soundManager.createSound({ id: 'eggMusic', url: '/snd/gnom.mp3' });
				eggMusicSound.play();
			});
		}
	},

	happyPet: function (override) {
		if (this.playFeedbackSound || override) {
			var random = Math.floor(Math.random() * (2)) + 1;
			if(random == 1) {
				soundManager.onready(function () {
					var happyPetSound = soundManager.createSound({ id: 'happyPet', url: '/snd/happyPet.mp3' });
					happyPetSound.play();
				});
			} else {
				soundManager.onready(function () {
					var happyPetSound = soundManager.createSound({ id: 'happyPet', url: '/snd/happyPet2.mp3' });
					happyPetSound.play();
				});
			}
		}
	},

	yawn: function (override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var yawnSound = soundManager.createSound({ id: 'yawn', url: '/snd/yawn.mp3' });
				yawnSound.play();
			});
		}
	},

	hairdryer: function(override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var hairdryerSound = soundManager.createSound({ id: 'hairdryer', url: '/snd/hairdryer.mp3' });
				hairdryerSound.play();
			});
		}
	},

	runningWater: function(override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var runningWaterSound = soundManager.createSound({ id: 'runningWater', url: '/snd/runningWater.mp3' });
				runningWaterSound.play({ onfinish: function() { GenericSoundsInstance.runningWater(); }});
			});
		}
	},

	outside: function(override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var outsideSound = soundManager.createSound({ id: 'outside', url: '/snd/outside.mp3' });
				outsideSound.play();
			});
		}
	},

	jump: function(override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var jumpSound = soundManager.createSound({ id: 'jump', url: '/snd/jump.mp3' });
				jumpSound.play();
			});
		}
	},

	circus: function(override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var circusSound = soundManager.createSound({ id: 'circus', url: '/snd/circus.mp3' });
				circusSound.play();
			});
		}
	},

	shoppingMall: function(override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var shoppingMallSound = soundManager.createSound({ id: 'shoppingMall', url: '/snd/shoppingMall.mp3' });
				shoppingMallSound.play();
			});
		}
	},

	circus2: function(override) {
		if (this.playFeedbackSound || override) {
			soundManager.onready(function () {
				var circus2Sound = soundManager.createSound({ id: 'circus2', url: '/snd/circus2.mp3' });
				circus2Sound.play();
			});
		}
	}
});
