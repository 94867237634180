/// <reference path="DOMAttached.js" />

var ExternalHyperLink = Class.create(DOMAttached, {
	initialize: function($super, el) {
		$super(el);

		this.safeObserve(this.el, 'click', this.onClick.bindAsEventListener(this));
	},

	onClick: function(ev) {
		window.open(this.el.readAttribute('href'));
		ev.stop();
	}
});