/// <reference path="DOMAttached.js" />
/// <reference path="../../../node_modules/soundmanager2/script/soundmanager2-nodebug.js" />

var GamePopupContainer = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.popup = this.el.down('.outerPopup');
		this.el.attachedObj = this;
	},
	
	loadPopup: function(url, onPopupLoaded) {
		this.popup.style.display = 'none';
		this.el.style.display = 'block';
		
		var req = new Ajax.Request(url, {
			method: 'get',
			onSuccess: onPopupLoaded,
		});
	},
	
	showPopup: function(url) {
		this.loadPopup(url, this.onPopupLoaded.bind(this));
	},
	
	showContent: function (content, popupCssClass) {
		if(this.el.up('.GNOMApplication').down('.animationContainer') != undefined) {
			soundManager.stopAll();
			this.el.up('.GNOMApplication').down('.tabbedApplication').attachedObj.blocked = false;
			this.el.up('.GNOMApplication').down('.animationContainer').style.display = 'none';
		}
		this.popup.innerHTML = content;
		this.popup.innerHTML.evalScripts();
		this.popup.style.display = 'block';

		if (popupCssClass) {
			this.popup.classList.add(popupCssClass);
			if (popupCssClass === 'playAction') {
				var playActionPopup = this.popup;
				setTimeout(function () {
					playActionPopup.style.display = 'none';
					playActionPopup.classList.remove('playAction');
				}, 5000);
			}
		}

		// Give Focus to first input control
		var inputEl = this.el.down('input[type="text"]');
		if (inputEl != null) {
			inputEl.focus();
		}
	},
	
	hidePopup: function() {
		this.el.style.display = 'none';
	},
	
	isPopupVisible: function() {
		return (this.el.style.display == 'block');
	},
	
	onPopupLoaded : function(req) {
		this.popup.style.display = 'none';
		this.el.style.display = 'block';
	
		var json = req.responseText.evalJSON();
		
		if (json.success) {
			this.showContent(json.popup, json.popupCssClass);
		} else {			
			//Does it come with a message popup?
			if (json.errorpopup != undefined) {
				this.showContent(json.errorpopup);
				return;
			}
			
			//TODO: Proper error handling
			this.hidePopup();
		}
	}
});
