/// <reference path="DOMAttached.js" />
/// <reference path="MiniMeAccessory.js" />
/// <reference path="MiniMeSpeechBubble.js" />

var MiniMeAccessoryManager = Class.create(DOMAttached, {
	accessoryInstances: [],
	namespace: "http://www.w3.org/2000/svg",
	firstAccessory: true,
	
	initialize: function ($super, editor, accessories, feedback) {
		$super();
		
		this.editor = editor;
		if (GenericSoundsInstance == null) {
			new GenericSounds(feedback);
		}
		for (var i = 0; i < accessories.length; i++) {
			this.accessoryInstances.push(new MiniMeAccessory(this.editor, accessories[i], feedback));
		}
		
		this.initializeAccessoriesBox();
		
		if (this.accessoryInstances.length != 0) {
			this.editor.getElementById("boxGroup").style.display = "block";
		}
	},
	
	initializeAccessoriesBox: function() {
		this.accessoriesBox = this.editor.getElementById("box");
		this.accessoriesBoxShadow = this.editor.getElementById("boxShadow");
		
		if (this.accessoryInstances.length != 0) {
			this.safeObserve(this.accessoriesBox, "mouseover", function () { this.accessoriesBoxMouseOver(); }.bindAsEventListener(this));
			this.safeObserve(this.accessoriesBox, "mouseout", function () { this.accessoriesBoxMouseOut(); }.bindAsEventListener(this));
			this.safeObserve(this.accessoriesBox, "mouseup", function() { this.dropItemCheck(); }.bindAsEventListener(this));
			this.safeObserve(this.accessoriesBox, "click", function () { this.openAccessoryPopUp(); }.bindAsEventListener(this));
		}
	},
	
	accessoriesBoxMouseOver: function() {
		this.accessoriesBoxShadow.style.display = "block";
		this.accessoriesBox.style.cursor = "pointer";
	},
	
	accessoriesBoxMouseOut: function() {
		this.accessoriesBoxShadow.style.display = "none";
	},
	
	dropItemCheck: function() {
		var activeItem = this.getActiveItem();
		
		if (activeItem != null) {
			this.dropItem(activeItem);
		}
	},
	
	dropItem: function (item) {
		GenericSoundsInstance.softClickSound();
		this.accessoryInstances[item].hideItem();
	},
	
	getActiveItem: function() {
		for (var i = 0; i < this.accessoryInstances.length; i++) {
			if (this.accessoryInstances[i].isActive) {
				return i;
			}
		}
		return null;
	},
	
	openAccessoryPopUp: function () {
		GenericSoundsInstance.softClickSound();
		var rows = Math.floor(this.accessoryInstances.length / 3);
		if (this.accessoryInstances.length % 3 != 0) {
			rows++;
		}
		
		var group = this.createOverlay();
		var selWindow = this.createSelectionWindow(rows);
		
		group.appendChild(selWindow);
		this.editor.appendChild(group);
		
		group = this.insertAccessories(group, rows);
	},
	
	createOverlay: function () {
		var group = document.createElementNS(this.namespace, "g");
		var overlay = document.createElementNS(this.namespace, "rect");
		overlay.setAttribute("x", "0");
		overlay.setAttribute("y", "0");
		overlay.setAttribute("width", "450");
		overlay.setAttribute("height", "798");
		overlay.style.fill = "#FFFFFF";
		overlay.style.fillOpacity = 0.7;
		overlay.id = "overlay";
		group.id = "overlayGroup";
		group.appendChild(overlay);
		this.safeObserve(overlay, "click", this.removeOverlay);
		
		return group;
	},

	removeOverlay: function () {
		this.parentNode.parentNode.removeChild(this.parentNode);
	},
	
	createSelectionWindow: function(rows) {
		var height = Math.max(rows * 90 + 10, 100);
		var width = 3 * 90 + 10;
		var x = (450 - width) / 2.0;
		var y = (798 - height) / 2.0;
		
		var selectionWindow = document.createElementNS(this.namespace, "rect");
		selectionWindow.setAttribute("x", x);
		selectionWindow.setAttribute("y", y);
		selectionWindow.setAttribute("width", width);
		selectionWindow.setAttribute("height", height);
		selectionWindow.setAttribute("rx", 20);
		selectionWindow.setAttribute("ry", 20);
		selectionWindow.style.fill = "#AA3333";
		selectionWindow.style.stroke = "#000000";
		selectionWindow.style.strokeWidth = "5";
		selectionWindow.id = "selectionWindow";
		
		return selectionWindow;
	},
	
	insertAccessories: function(group, rows) {
		for (var i = 0; i < this.accessoryInstances.length; i++) {
			var x = i % 3;
			var y = Math.floor(i / 3);
			
			this.insertAccessory(group, i, x, y, rows);
		}
	},
	
	insertAccessory: function (group, i, x, y, rows) {
		var instance = this.accessoryInstances[i];
		var accessory = instance.accessory;
		
		var clone = document.createElementNS(this.namespace, "image");
		clone.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', accessory.src);
		
		var newHeight;
		var newWidth;
		if (accessory.height <= 85 && accessory.width <= 85) {
			newWidth = accessory.width;
			newHeight = accessory.height;
		} else if (accessory.height > accessory.width) {
			var ratio = accessory.height / accessory.width;
			newHeight = 85;
			newWidth = newHeight / ratio;
		} else {
			var ratio = accessory.width / accessory.height;
			newWidth = 85;
			newHeight = newWidth / ratio;
		}
		clone.setAttribute("width", newWidth);
		clone.setAttribute("height", newHeight);
		
		var newX = 90 + x * 90;
		if (newWidth < 85) {
			newX += (85 - newWidth) / 2;
		}
		
		var boxHeight = rows * 90 + 10;
		var newY = (((798 - boxHeight) / 2) + 5) + y * 90;
		if (newHeight < 85) {
			newY += (85 - newHeight) / 2;
		}
		
		clone.setAttribute("x", newX);
		clone.setAttribute("y", newY);
		
		clone.style.cursor = "pointer";
		
		if (accessory.isApplied) {
			clone.style.opacity = 0.3;
		}
		group.appendChild(clone);
		
		if (this.firstAccessory) {
			this.safeObserve(clone, "click", function () {
				var bubble = new MiniMeSpeechBubble(this.editor, MiniMeSpeechBubbles["accessory"]);
				this.firstAccessory = false;
			}.bindAsEventListener(this));
		}
		
		this.safeObserve(clone, "click", function () {
			instance.showItem();
			instance.item.setAttribute("x", 100);
			instance.item.setAttribute("y", 200);
			clone.parentNode.parentNode.removeChild(clone.parentNode);
			MiniMeLastVersion++;
		}.bindAsEventListener(this));
		
		return group;
	},
	
	saveAccessorySettings: function() {
		var _parameters = new Array();
		var parameter;
		var _ids = new Array();
		var id;
		
		for (var i = 0; i < this.accessoryInstances.length; i++) {
			parameter = "{";
			
			parameter += "\"isApplied\":" + (this.accessoryInstances[i].accessory.isApplied ? "\"True\"" : "\"False\"") + ",";
			parameter += "\"isTopLayer\":" + (this.accessoryInstances[i].accessory.isTopLayer ? "\"True\"" : "\"False\"") + ",";
			parameter += "\"X\":\"" + this.accessoryInstances[i].item.getAttribute("x") + "\",";
			parameter += "\"Y\":\"" + this.accessoryInstances[i].item.getAttribute("y") + "\",";
			parameter += "\"uniqueId\":\"" + this.accessoryInstances[i].accessory.uniqueId + "\"";
			id = this.accessoryInstances[i].accessory.uniqueId;
			
			parameter += "}";
			
			_parameters.push(parameter);
			_ids.push(id);
		}
		
		var joinedParameters = _parameters.join(";");
		var joinedIds = _ids.join(";");
		return {
			parameters: joinedParameters,
			ids: joinedIds
		}
	}
});

