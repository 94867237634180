/// <reference path="DOMAttached.js" />

var PostingListItemTNG = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);
		debugger;
		this.deleteButon = this.el.down('.mia-PostingTeaser-deleteLink');
		if (this.deleteButon !== undefined) {
			this.safeObserve(this.deleteButon, 'click', this.onDeleteButtonClick.bindAsEventListener(this));
		}
	},

	onDeleteButtonClick: function (event) {
		event.preventDefault();
		confirmAI(this.options.deleteConfirmationMessage, this.onDeleteButtonConfirm.bind(this), function () { }, true, true);
	},

	onDeleteButtonConfirm: function () {
		var req = new Ajax.Request(this.options.deleteLink, {
			method: 'get',
			onSuccess: this.onSuccess.bind(this)
		});
	},

	onSuccess: function (req) {
		var json = req.responseText.evalJSON();
		document.fire('messageList:refresh');
		if (json.isListEmpty) {
			document.fire('messageList:hideRemoveAllButton');
		}
	}
});
