/// <reference path="DOMAttached.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="TextResource.js" />

var GamesMathPuzzle = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		var splash = this.el.down('.splash');
		
		if (this.options.level == '1') {
			splash.down('.background').style.opacity = 0.7;//IE10 Hack, up to Prototype supports this
			splash.down('.background').setOpacity(0.7);
			this.demoMode = true;
			this.el.down('.levelDown').style.display = 'none';
		} else {
			splash.style.display = 'none';
			this.demoMode = false;
		}
		
		this.medalImage = this.el.down('.medalBox').down('.medal');
		this.happySmiley = this.el.down('.medalBox').down('.win');
		this.sadSmiley = this.el.down('.medalBox').down('.fail');
		this.levelUpOverlay = this.el.down('.levelUpOverlay');
		
		// register click handler
		
		this.safeObserve($$('.splashClose')[0], 'click', function(ev) {
			if (typeof (ev) != 'undefined') {
				ev.stop();
			}
			this.el.down('.splash').style.display = 'none';
		}.bindAsEventListener(this));
		
		this.safeObserve(this.el.down('.levelUpButton'), 'click', function(ev) {
			if (typeof (ev) != 'undefined') {
				ev.stop();
			}
			this.showNextOverlayContent();
		}.bindAsEventListener(this));
		
		this.safeObserve(this.el.down('.medalOkButton'), 'click', function(ev) {
			if (typeof (ev) != 'undefined') {
				ev.stop();
			}
			this.showNextOverlayContent();
		}.bindAsEventListener(this));
		
		this.safeObserve(this.el.down('.levelDown'), 'click', function(ev) {
			new Ajax.Request(
				new UrlTemplate(this.options.levelJumpUrl)
					.r('PLH_level', -1).url, {
				method: 'get',
				onSuccess: this.ajajCallHandler.bind(this)
			});
			if (typeof (ev) != 'undefined') {
				ev.stop();
			}
		}.bindAsEventListener(this));
		
		this.setRunningEffects();
		this.originalPuzzle = this.el.down('.questionContainer').innerHTML;
		this.initialOperations();
		
		this.resetTimer();
		this.startTimer();
	},
	
	getHint: function() {
		switch (this.solution[0]) {
			case undefined:
				return '';
			case 'Addition':
				return this.options.trAdditionHint;
			case 'Subtraction':
				return this.options.trSubtractionHint;
			case 'Multiplication':
				return this.options.trMultiplicationHint;
			case 'Division':
				return this.options.trDivisionHint;
			default:
				return new TextResource(this.options.trOperandHint).r('{operand}', this.solution[0]).string();
		}
	},
	
	showHint: function() {
		this.addAfterAllEffectsFinishedFunction(function(){
			var newHint = this.getHint();
			if (this.hint.down('.text').innerHTML != newHint) {
				if (this.hint.style.display == 'none') {
					this.hint.down('.text').innerHTML = newHint;
					this.hint.appear({duration: 0.5});
				} else {
					this.hint.fade({
						duration: 0.5,
						afterFinish: function() {
							this.hint.down('.text').innerHTML = newHint;
							this.hint.appear({duration: 0.5});
						}.bind(this)
					});
				}
			}
		}.bind(this));
	},
	
	nextHint: function() {
		this.solution.shift();
		if (this.solution.length > 0) {
			this.showHint();
		}
	},
	
	setRunningEffects: function(change) {
		if (change == undefined) {
			this.runningEffects = 0;
			this.afterAllEffectsFinished = new Array();
		} else {
			this.runningEffects += change;
			if (this.runningEffects == 0) {
				for (var i=0; i<this.afterAllEffectsFinished.length; i++) {
					this.afterAllEffectsFinished[i]();
				}
				this.afterAllEffectsFinished = new Array();
			}
		}
	},

	addAfterAllEffectsFinishedFunction: function(func) {
		if (this.runningEffects == 0) {
			func();
		} else {
			this.afterAllEffectsFinished.push(func);
		}
	},
	
	// operation that should be performed whenever MathPuzzleContainer is loaded
	initialOperations: function() {
		
		var demoModeButton = this.el.down('.DemoModeButton');
		
		this.hint = this.el.down('.hint');
		if (Prototype.Browser.IE) {
			this.hint.select('*').each(function(el) {
				el.style.filter = 'inherit';
			});
		}
		
		if (this.demoMode) {
			demoModeButton.down('.end').style.display = '';
			demoModeButton.down('.start').style.display = 'none';
			this.hint.style.display = 'none';
			this.hint.style.visibility = 'visible';
			this.solution = this.el.down('.questionContainer').down('div').down('.solution').innerHTML.split("|");
			this.showHint();
		} else {
			demoModeButton.down('.end').style.display = 'none';
			demoModeButton.down('.start').style.display = '';
			this.hint.style.display = 'none';
			this.hint.style.visibility = 'visible';
		}
		
		if (this.showTryOnYourOwnInfo == true) {
			this.hint.style.display = '';
			this.hint.down('.text').innerHTML = this.options.trTryOnYourOwn;
			this.hint.appear({
				duration: 0.5,
				afterFinish: function() {
					this.hint.fade({delay: 3.0, duration: 0.5});
				}.bind(this)
			});
			this.showTryOnYourOwnInfo = false;
		}
		
		var questionContainerDimensions = this.el.down('.questionContainer').getDimensions();
		this.leftOperandPosition = {left: questionContainerDimensions.width / 2 - this.options.operatorSize / 2 - this.options.operandSize, top: questionContainerDimensions.height / 2 - this.options.operandSize / 2};
		this.operatorPosition = {left: questionContainerDimensions.width / 2 - this.options.operatorSize / 2, top: questionContainerDimensions.height / 2 - this.options.operatorSize / 2};
		this.rightOperandPosition = {left: questionContainerDimensions.width / 2 + this.options.operatorSize / 2, top: questionContainerDimensions.height / 2 - this.options.operandSize / 2};
	
		this.duration = 0.4;
		
		this.resetInput(true, true, true);
		
		$$('.OperatorButton').each(function(btn) {
			btn.unmove = undefined;
			btn.locked = undefined;
		}.bind(this));
		
		$$('.OperandButton').each(function(btn) {
			btn.unmove = undefined;
			btn.locked = undefined;
		}.bind(this));
		
		// register operand buttons
		var i = 0;
		$$('.OperandButton').each(function(btn) {
			btn.number = i++;
			this.safeObserve(btn.down('.bubble-button'), 'click', function(ev) {
				if (this.globalLock == true) return;
				if (this.demoMode && btn.down('.bubble-button').innerHTML != this.solution[0]) { // user must follow the hints
					return;
				}
				
				if (this.operand1 == undefined) { // first operand selected
					this.selectOperand1(btn);
				} else if (this.operand2 == undefined) { // already two operands selected
					if (btn.number != this.operand1) {
						this.selectOperand2(btn);
					} else if (!this.demoMode) { // reset because first operand == second operand
						this.resetInput(true, true, true);
					}
				} else if (!this.demoMode) { // already selected two operands
					if (btn.number == this.operand1) { // select first operand
						this.resetInput(false, false, true);
					} else if (btn.number != this.operand2) { // select other operand
						this.resetInput(true, false, true);
						this.selectOperand1(btn);
					}
				}
			}.bindAsEventListener(this));
		}.bind(this));
		
		// register operator buttons
		var j = 0;
		$$('.OperatorButton').each(function(btn) {
			btn.number = j++;
			this.safeObserve(btn.down('.bubble-button'), 'click', function(ev) {
				if (this.operator == undefined) {
					if (this.demoMode && btn.title != this.solution[0]) { // user must follow the hints
						return;
					}
					this.selectOperator(btn);
				} else if (!this.demoMode) {
					this.resetInput(false, true, false);
				}
			}.bindAsEventListener(this));
		}.bind(this));
		
		this.undoPossible = false;
		
		// register undo button
		this.safeObserve(this.el.down('.UndoButton'), 'click', function(ev) {
			if (this.demoMode) { // do not allow 'undo' in demo mode	
				return;
			}
			if (this.operand1 != undefined || this.operator != undefined || this.operand2 != undefined) {
				this.seconds += 5;
				this.blinkingSmiley(this.sadSmiley);
				this.addAfterAllEffectsFinishedFunction(function() {
					this.resetInput(true, true, true);
				}.bind(this));
			} else if (this.undoPossible) {
				this.seconds += 5;
				this.blinkingSmiley(this.sadSmiley);
				new Ajax.Request(
					new UrlTemplate(this.options.undoUrl).url, {
					method: 'get',
					onSuccess: this.ajajCallHandler.bind(this)
				});
			}
		}.bindAsEventListener(this));
		
		// register demo mode button
		this.safeObserve(this.el.down('.DemoModeButton'), 'click', function(ev) {
			this.demoMode = !this.demoMode;
			if (this.demoMode == false) {
				this.showTryOnYourOwnInfo = true;
				new Ajax.Request(
					new UrlTemplate(this.options.levelJumpUrl)
						.r('PLH_level', 0).url, {
					method: 'get',
					onSuccess: this.ajajCallHandler.bind(this)
				});
			} else {
				this.pauseTimer();
				new Ajax.Request(
					new UrlTemplate(this.options.undoAllUrl).url, {
					method: 'get',
					onSuccess: function() {
						this.addAfterAllEffectsFinishedFunction(function() {
							var container = this.el.down('.questionContainer');
							container.fade({
								afterFinish: function() {
									container.innerHTML = this.originalPuzzle;
									container.appear();
									this.initialOperations();
									this.resetInput(true, true, true);
								}.bind(this)
							});
						}.bind(this));
					}.bind(this)
				});
			}
		}.bindAsEventListener(this));
		
	},
	
	move: function(btn, dx, dy) {
		if (btn.locked == undefined) {
			btn.locked = true;
			if (btn.hasClassName('OperatorButton')) {
				$$('.OperatorButton').each(function(otherOperator) {
					if (otherOperator != btn) {
						otherOperator.style.display = 'none';
					}
				}.bind(this));
			}
			var dxOld = btn.dx;
			var dyOld = btn.dy;
			btn.dx = dx;
			btn.dy = dy;
			new Effect.Move(btn, {
				x: btn.dx,
				y: btn.dy,
				mode: 'relative',
				duration: this.duration,
				afterSetup: function() {this.setRunningEffects(1);}.bind(this),
				afterFinish: function() {this.setRunningEffects(-1); btn.locked = undefined; if (btn.unmove) this.unmove(btn);}.bind(this)});
			if (dxOld != undefined && dyOld != undefined) {
				btn.dx += dxOld;
				btn.dy += dyOld;
			}
			return true;
		} else {
			return false;
		}
	},
	
	unmove: function(btn) {
		if (btn.locked == undefined && btn.dx != undefined && btn.dy != undefined) {
			btn.locked = true;
			btn.unmove = undefined;
			new Effect.Move(btn, {
				x: -btn.dx,
				y: -btn.dy,
				mode: 'relative',
				duration: this.duration,
				afterSetup: function() {this.setRunningEffects(1);}.bind(this),
				afterFinish: function() {
					this.setRunningEffects(-1);
					btn.locked = undefined;
					if (btn.hasClassName('OperatorButton')) {
						$$('.OperatorButton').each(function(operator) {
							operator.style.display = '';
						}.bind(this));
					}
				}.bind(this)
			});
			btn.dx = undefined;
			btn.dx = undefined;
		} else {
			btn.unmove = true;
		}
	},
	
	selectOperand1: function(btn) {
		if (this.operand1 != undefined && this.operand1 != btn.number) {
			this.deselectOperand1();
		}
		if (this.operand1 == undefined) {
			if (this.move(btn, this.leftOperandPosition.left - btn.offsetLeft, this.leftOperandPosition.top - btn.offsetTop)) {
				this.operand1 = btn.number;
			}
		}
		this.checkTerm();
	},
	
	deselectOperand1: function() {
		if (this.operand1 != undefined) {
			var btn = $$('.OperandButton')[this.operand1];
			this.operand1 = undefined;
			this.unmove(btn);
		}
	},
	
	selectOperand2: function(btn) {
		if (this.operand2 != undefined && this.operand2 != btn.number) {
			this.deselectOperand2();
		}
		if (this.operand2 == undefined) {
			if (this.move(btn, this.rightOperandPosition.left - btn.offsetLeft, this.rightOperandPosition.top - btn.offsetTop)) {
				this.operand2 = btn.number;
			}
		}
		this.checkTerm();
	},
	
	deselectOperand2: function() {
		if (this.operand2 != undefined) {
			var btn = $$('.OperandButton')[this.operand2];
			this.operand2 = undefined;
			this.unmove(btn);
		}
	},
	
	selectOperator: function(btn) {
		if (this.operator != undefined && this.operator != btn.number) {
			this.deselectOperator();
		}
		if (this.operator == undefined) {
			if (this.move(btn, this.operatorPosition.left - (btn.offsetLeft + btn.parentNode.offsetLeft),this.operatorPosition.top - (btn.offsetTop + btn.parentNode.offsetTop))) {
				this.operator = btn.number;
			}
		}
		this.checkTerm();
	},
	
	checkTerm: function() {
		if (this.operand1 != undefined && this.operator != undefined && this.operand2 != undefined) {
			new Ajax.Request(
				new UrlTemplate(this.options.performOperationUrl)
					.r('PLH_Operations', $$('.OperatorButton')[this.operator].title)
					.r('PLH_term1', this.operand1)
					.r('PLH_term2', this.operand2)
					.r('PLH_time', this.seconds)
					.r('PLH_demoMode', this.demoMode).url, {
				method: 'get',
				onSuccess: this.ajajCallHandler.bind(this)
			});
		} else if (this.demoMode) {
			this.nextHint();
		}
	},
	
	deselectOperator: function() {
		if (this.operator != undefined) {
			var btn = $$('.OperatorButton')[this.operator];
			this.operator = undefined;
			this.unmove(btn);
		}
	},
	
	resetInput: function(operand1, operator, operand2) {
		if (operand1) this.deselectOperand1();
		if (operator) this.deselectOperator();
		if (operand2) this.deselectOperand2();
	},
	
	ajajCallHandler: function(req) {
		this.json = req.responseText.evalJSON();
		
		if (this.json.invalidOperation != undefined) {
			this.resetInput(true, true, true);
			
		} else if (this.json.result != undefined) {
			
			var operand1 = $$('.OperandButton')[this.operand1];
			var operand2 = $$('.OperandButton')[this.operand2];
			var operator = $$('.OperatorButton')[this.operator];
			
			this.globalLock = true;
			
			this.setRunningEffects(1);
			
			new Effect.Parallel(
				[
					new Effect.Fade(operator, {duration:0.5}),
					new Effect.Fade(operand1, {duration:0.5}),
					new Effect.Fade(operand2, {duration:0.5})				
				],
				{
					queue: 'end',
					afterFinish: function() {
						if (this.json.page != undefined) {
							var isWin = this.demoMode || this.json.levelUp || this.json.progress > 0;
							operand2.down('.img').toggleClassName("win", isWin).toggleClassName("fail", !isWin);
						}
					
						operand2.down('.bubble-button').innerHTML = this.json.result;
						var originalx = parseFloat(operand2.getStyle('left') || '0');
						var dx = Math.floor((this.leftOperandPosition.left - this.rightOperandPosition.left)/2);
						operand2.setStyle({left: (originalx + dx)+'px'});
						operand2.dx += dx;
						operand2.appear({
							duration: 0.5,
							afterFinish: function() {
								this.globalLock = undefined;
								if (this.json.page == undefined) {
									this.resetInput(true, true, true);
								}
								this.setRunningEffects(-1);
							}.bind(this)
						});
						
					}.bind(this)
				}			
			);
			
			if (this.demoMode) {
				this.nextHint();
			}
						
			this.undoPossible = this.json.undoPossible;
		
		}
		
		if (this.json.page != undefined) { // new math puzzle
			
			this.originalPuzzle = this.json.page;
			
			this.addAfterAllEffectsFinishedFunction(function() {
				
				this.undoPossible = false;
				
				var container = this.el.down('.questionContainer');
	
				if (this.json.result != undefined) {
					var operand2 = $$('.OperandButton')[this.operand2];
					if (this.demoMode || this.json.levelUp || this.json.progress > 0) {
						new Effect.Move(operand2, {x:0, y:-150, mode:'relative'});
					} else {
						new Effect.Move(operand2, {x:0, y:150, mode:'relative'});
					}
				}
				
				container.fade({afterFinish: function() {
					container.innerHTML = this.json.page;
					container.appear();
					
					if (this.json.progressBar != undefined) {
						this.el.down('.progressBar').replace(this.json.progressBar);
					}
					
					if (this.json.personalRecordBox != undefined) {
						this.el.down('.personalRecordBox').replace(this.json.personalRecordBox);
					}
					
					if (this.json.overallRecordBox != undefined) {
						this.el.down('.overallRecordBox').replace(this.json.overallRecordBox);
					}
								
					var progressBar = this.el.down('.progressBar');
					
					for (var i=0; i<this.json.maxProgress; i++) {
						if (i<this.json.progress) {
							progressBar.down('.greySegment', i).style.visibility = 'hidden';
							progressBar.down('.yellowSegment', i).style.visibility = 'visible';
						} else {
							progressBar.down('.yellowSegment', i).style.visibility = 'hidden';
							progressBar.down('.greySegment', i).style.visibility = 'visible';				
						}
					}
					
					this.el.down('.currentLevel').innerHTML = 'Level: ' + this.json.level;
					this.el.down('.levelDown').style.display = (this.json.level == 1 ? "none" : "");
			
					if (this.demoMode || this.json.levelUp || this.json.progress > 0) {
						this.blinkingSmiley(this.happySmiley);
					} else {
						this.blinkingSmiley(this.sadSmiley);
					}
					
					if (this.demoMode) {
						this.demoMode = false;
						this.showTryOnYourOwnInfo = true;
					}
					
					this.showNextOverlayContent();
					
					this.initialOperations();
					
					this.resetInput(true, true, true);
					
				}.bind(this)});
				
			}.bind(this));
			
		} else if (this.json.idx1 != undefined) { // undo
			this.undoPossible = this.json.undoPossible;
			$$('.OperandButton')[this.json.idx1].down('.bubble-button').innerHTML = this.json.result1;
			$$('.OperandButton')[this.json.idx1].style.display = '';
			$$('.OperandButton')[this.json.idx2].down('.bubble-button').innerHTML = this.json.result2;
			$$('.OperandButton')[this.json.idx2].style.display = '';
			this.resetInput.bind(this).delay(1, true, true, true);
		}
		
		
	},
	
	blinkingSmiley: function(smiley) {
		smiley.style.display = 'none';
			smiley.style.visibility = 'visible';
			this.medalImage.fade({ duration: 0.5 });
			smiley.appear({duration: 0.5, afterFinish: function() {
				smiley.pulsate({pulses: 2, duration: 1.5, afterFinish: function() {
					smiley.fade({duration: 0.5});
					this.medalImage.appear({duration: 0.5});
				}.bind(this)});
			}.bind(this)});
	},
	
	showNextOverlayContent: function() {
		this.pauseTimer();
		if (this.json.medal != undefined && !this.json.medal.endsWith("games/medals/medal_none.svg")) { // show medal in level up overlay
			
			// hide some content of overlay
			this.levelUpOverlay.down('.level').style.display = 'none';
			this.levelUpOverlay.down('.levelUpButton').style.display = 'none';
			this.levelUpOverlay.down('.continueButton').style.display = 'none';
			// show some content of overlay
			this.levelUpOverlay.down('.medalOkButton').style.display = 'block';
			this.levelUpOverlay.down('.image').style.display = "block";
			this.levelUpOverlay.down('.imageCaption').style.display = "block";
			// set content to show
			this.levelUpOverlay.down('.infoText').innerHTML = this.options.trMedalMessage;
			this.levelUpOverlay.down('.image').down('img').src = this.json.medal;
			this.levelUpOverlay.down('.imageCaption').innerHTML = this.json.medalName;
			this.medalImage.src = this.json.medal;
			// prevent from showing the medal again
			this.json.medal = undefined;
			this.levelUpOverlay.style.display = 'block';
			
		} else if (this.json.levelUp && this.json.level > 1) {
			// hide some content of overlay
			this.levelUpOverlay.down('.medalOkButton').style.display = 'none';
			this.levelUpOverlay.down('.image').style.display = 'none';
			this.levelUpOverlay.down('.imageCaption').style.display = 'none';
			this.levelUpOverlay.down('.levelUpButton').style.display = 'block';
			this.levelUpOverlay.down('.infoText').innerHTML = new TextResource(this.options.trLevelReached).r('{level}', this.json.level).r('{amount}', this.json.amount).string();
			this.levelUpOverlay.down('.level').style.display = 'block';
			// set content to show
			if (this.json.overallRecord) {
				this.levelUpOverlay.down('.level').innerHTML = new TextResource(this.options.trOverallRecord).r('{seconds}', this.json.timeInSeconds).string();
			} else if (this.json.personalRecord) {
				this.levelUpOverlay.down('.level').innerHTML = new TextResource(this.options.trPersonalRecord).r('{seconds}', this.json.timeInSeconds).string();
			} else {
				this.levelUpOverlay.down('.level').innerHTML = new TextResource(this.options.trLevelTime).r('{seconds}', this.json.timeInSeconds).string();
			}
			// 'Level: ' + this.json.level;	
			//prevent from showing level up info again
			this.json.levelUp = undefined;
			this.levelUpOverlay.style.display = 'block';
			
		} else if (this.json.pet != undefined && this.json.pet != "") { // show pet in level up overlay
			
			// hide some content of overlay
			this.levelUpOverlay.down('.medalOkButton').style.display = 'none';
			this.levelUpOverlay.down('.level').style.display = 'none';
			this.levelUpOverlay.down('.continueButton').style.display = 'none';
			// show some content of overlay
			this.levelUpOverlay.down('.levelUpButton').style.display = 'block';
			this.levelUpOverlay.down('.image').style.display = "block";
			this.levelUpOverlay.down('.imageCaption').style.display = "block";
			// set content to show
			this.levelUpOverlay.down('.infoText').innerHTML = this.options.trPetMessage;
			this.levelUpOverlay.down('.image').down('img').src = this.json.pet;
			this.levelUpOverlay.down('.imageCaption').innerHTML = this.json.petName;
			// prevent from showing the pet again
			this.json.pet = undefined;
			this.levelUpOverlay.style.display = 'block';
			
		} else if (this.json.levelUp && this.json.level == 1) { // show end of game message in overlay
			// hide some content of overlay
			this.levelUpOverlay.down('.level').style.display = 'none';
			this.levelUpOverlay.down('.image').style.display = 'none';
			this.levelUpOverlay.down('.imageCaption').style.display = 'none';
			this.levelUpOverlay.down('.levelButtons').style.display = 'none';
			// show some content of overlay
			this.levelUpOverlay.down('.continueButton').style.display = 'block';
			// set content to show
			this.levelUpOverlay.down('.infoText').innerHTML = new TextResource(this.options.trEndOfGame).r('{amount}', this.json.amount).r('{newreward}', this.json.newreward).string();
			// prevent from shopwing end of game message again
			this.json.levelUp = undefined;
			this.levelUpOverlay.style.display = 'block';
			
		} else { // close level up overlay
			this.levelUpOverlay.style.display = 'none';
			if (this.json.progress == 0) {
				this.resetTimer();
			}
			if (!this.demoMode) {
				this.startTimer();
			}
		}
	},
	
	startTimer: function() {
		if (this.timer == undefined) {
			this.increaseTimer();
			this.timer = new PeriodicalExecuter(this.increaseTimer.bind(this), 1);
		}
	},
	
	pauseTimer: function() {
		if (this.timer != undefined) {
			this.timer.stop();
			this.seconds--;
			this.timer = undefined;
		}
	},
	
	resetTimer: function() {
		if (this.timer != undefined) {
			this.timer.stop();
			this.timer = undefined;
		}
		this.seconds = -1;
		this.el.down('.timer').innerHTML = "00:00";
	},
	
	increaseTimer: function() {
		this.seconds++;
		
		var string = Math.floor(this.seconds%60);
		if (String(string).length < 2) string = '0'+string;
		string = Math.floor(this.seconds/60) + ':' + string;
		if (string.length < 5) string = '0' + string;
		
		this.el.down('.timer').innerHTML = string;
	}
});
