/// <reference path="DOMAttached.js" />

var GameLoadTabAndPopupButton = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.popupLink = options.popupLink;
		this.tabLink = options.tabLink;
		
		this.tabApp = this.el.up('.tabbedApplication').attachedObj;
		
		this.safeObserve(this.el.down('input'), 'click', this.onClick.bindAsEventListener(this));
	},
	
	onClick: function(ev) {
		ev.stop();
		if (this.tabLink != "") {
			$$('.GNOMApplication')[0].down('.popupContainer').attachedObj.loadPopup(this.popupLink, this.onPopupLoaded.bind(this));
		} else {
			$$('.GNOMApplication')[0].down('.popupContainer').attachedObj.showPopup(this.popupLink);
		}
	},
	
	onPopupLoaded: function(response) {
		this.popup = response;
		$$('.GNOMApplication')[0].down('.popupContainer').attachedObj.onPopupLoaded(this.popup);
		this.tabApp.loadPageWithOwnSuccessHandler(this.tabLink, this.onPageLoaded.bind(this));
	},
	
	onPageLoaded: function(response) {
		this.tabApp.currentTab.style.display = 'none';
		this.tabApp.selectTab(this.tabApp.findTab(this.tabLink));
		this.tabApp.onPageLoaded(response);
	}
});
