var MiniworldHouseRoom = function (wallCanvasZIndex, zIndexOffset) {
	this.wallCanvas = miniworldHouseCanvasHelper.createCanvas(null, wallCanvasZIndex);
	this.itemDiv = document.createElement("div");
	MiniworldHouseRoom.zIndexOffset = zIndexOffset;

	this.id = null;
	this.name = null;
	this.editable = false;
	this.itemManager = null;

	this.changedSinceSave = false;

	this.state = MiniworldHouseRoom.interactionStates.NO_INTERACTION;

	this.load = function (response) {

		var representation = response.responseJSON;

		this.id = representation["id"];
		this.name = representation["name"] || representation["unnamed"];
		this.unnamed = representation["unnamed"];
		this.emptyRoomNameMessage = representation["emptyRoomNameMessage"];
		this.editable = representation["editable"];
		this.parentId = representation["parentId"];

		this.itemManager = new MiniworldHouseItemManager(this.itemDiv, zIndexOffset, representation["level"]);
		this.itemManager.loadRoomItems(representation["items"], representation["doors"]);

		this.walls = MiniworldHouseWall.loadRoomWalls(this.wallCanvas,
						      representation["settings"]["textures"],
						      representation["settings"]["colors"]);
	};

	this.serialize = function () {
		var representation = this.itemManager.serializeRoomItems();
		representation["settings"] = JSON.stringify(MiniworldHouseWall.serializeRoomWalls(this.walls));
		representation["id"] = this.id;
		representation["name"] = this.name;

		return representation;
	};

	this.onShow = function () {
		this.state = MiniworldHouseRoom.interactionStates.NO_INTERACTION;
		MiniworldHouseRoom.zIndexBeforeCurrent = this.zIndexBefore.bind(this);
	};

	this.draggedItem = function () {
		return this.itemManager.draggedItem;
	}

	this.startInteraction = function (pointerX, pointerY) {
		switch (this.state) {
			case MiniworldHouseRoom.interactionStates.NO_INTERACTION:
				if (this.itemManager.tryToDragItem(pointerX, pointerY) !== null) {
					this.state = MiniworldHouseRoom.interactionStates.ITEM_INTERACTION;
				}
				break;
			case MiniworldHouseRoom.interactionStates.PREVIEW_DOOR_INTERACTION:
				this.startToPlacePreviewDoor();
				break;
			case MiniworldHouseRoom.interactionStates.COLOR_INTERACTION:
				if (miniworldHouseColorPicker.activeObject === null) {
					var draggedObject = this.itemManager.tryToDragItem(pointerX, pointerY);
					if (draggedObject == null) {
						draggedObject = this.getWallForInteraction(pointerX, pointerY);
						miniworldHouseColorPicker.activeObject = draggedObject;
						miniworldHouseColorPicker.drawPicker(pointerX, pointerY);
					} else if (draggedObject.type === miniworldHouseItem.itemType.DOOR) {
						miniworldHouseColorPicker.activeObject = draggedObject;
						miniworldHouseColorPicker.drawPicker(pointerX, pointerY);
					} else {
						MiniworldHouseInstance.menu.colorMode.clickInteraction();
					}
				} else {
					miniworldHouseColorPicker.startInteraction(pointerX, pointerY);
				}
				break;
		}
	};

	this.getWallForInteraction = function (pointerX, pointerY){
		for (var wallName in this.walls) {
			if (!this.walls.hasOwnProperty(wallName)){
				continue;
			}
			var wall = this.walls[wallName];
			if (wall.doesAcceptInteraction(pointerX, pointerY)) {
				return wall;
			}
		}
	};

	this.startToPlacePreviewDoor = function (){
		this.draggedItem().state = MiniworldHouseDoorItem.interactionStates.CLICKED;
	};

	this.moveInteraction = function (pointerX, pointerY) {
		switch (this.state) {
			case MiniworldHouseRoom.interactionStates.ITEM_INTERACTION:
				this.changedSinceSave = true;
				this.itemManager.moveInteraction(pointerX, pointerY);
				break;
			case MiniworldHouseRoom.interactionStates.PREVIEW_DOOR_INTERACTION:
				this.itemManager.moveInteraction(pointerX, pointerY);
				break;
			case MiniworldHouseRoom.interactionStates.COLOR_INTERACTION:
				if (miniworldHouseColorPicker.activated && miniworldHouseColorPicker.activeObject != null) {
					miniworldHouseColorPicker.activeObject.colorMoveInteraction(pointerX, pointerY);
				}
				break;
		}
	};

	this.endInteraction = function (pointerX, pointerY) {
		switch (this.state) {
			case MiniworldHouseRoom.interactionStates.ITEM_INTERACTION:
				this.itemManager.placeItemInteraction(pointerX, pointerY);
				break;
			case MiniworldHouseRoom.interactionStates.PREVIEW_DOOR_INTERACTION:
				if (!(this.draggedItem().state == MiniworldHouseDoorItem.interactionStates.PREVIEW)) {
					this.tryToPlaceNewDoor();
				}
				break;
			case MiniworldHouseRoom.interactionStates.COLOR_INTERACTION:
				miniworldHouseColorPicker.endInteraction(pointerX, pointerY);
				this.changedSinceSave = true;
				break;
		}

		if (this.changedSinceSave) {
			miniworldHouseSaveTimer.saveAfterInterval();
		}
		if (!(this.state == MiniworldHouseRoom.interactionStates.PREVIEW_DOOR_INTERACTION ||
			this.state == MiniworldHouseRoom.interactionStates.COLOR_INTERACTION)) {
			this.state = MiniworldHouseRoom.interactionStates.NO_INTERACTION;
		}
	};

	this.tryToDeleteItem = function () {
		var itemToDelete = this.draggedItem();
		var tr;
		if (itemToDelete.type == miniworldHouseItem.itemType.DOOR) {
			tr = MiniworldHouseTR.tr_deleteRoom;
		} else {
			tr = MiniworldHouseTR.tr_deleteItem;
		}
		confirmAI(tr,
			(function () {
				if (itemToDelete.type == miniworldHouseItem.itemType.DOOR) {
					miniworldHouseCommunication.deleteRoom(itemToDelete.linkedRoom);
				} else {
					console.log(itemToDelete);
					miniworldHouseCommunication.deleteItem(itemToDelete.instanceId);
				}
				this.itemManager.removeItemInteraction();
				MiniworldHouseInstance.menu.deleteDoor.hide();
				this.state = MiniworldHouseRoom.interactionStates.NO_INTERACTION;
			}).bind(this),
			(function () {}),
			true,
			true);
	};

	this.tryToPlaceNewDoor = function () {
		if (this.draggedItem().isPlaceable()) {
			miniworldHouseTryToBuyNewRoom(this.draggedItem().lastValidX);
			this.itemManager.removeItemInteraction();
			this.state = MiniworldHouseRoom.interactionStates.NO_INTERACTION;
		}
	};

	this.placeItemFromInventory = function (item) {
		var newItem = this.itemManager.loadItem(item);
		
		var positionX = (miniworldHouseGeometry.frameBottomLeft.x + miniworldHouseGeometry.frameBottomRight.x) / 2;
		var positionY;
		if (newItem.type === miniworldHouseItem.itemType.FLOOR) {
			positionY = miniworldHouseGeometry.bottomLeft.y + (miniworldHouseGeometry.frameBottomLeft.y - miniworldHouseGeometry.bottomLeft.y) * 3 / 4;
		} else {
			positionY = miniworldHouseGeometry.topLeft.y + (miniworldHouseGeometry.bottomLeft.y - miniworldHouseGeometry.topLeft.y) / 2;
		}

		item.positionx = newItem.lastValidX = newItem.posX = parseInt(positionX);
		item.positiony = newItem.lastValidY = newItem.posY = parseInt(positionY);

		newItem.draw(miniworldHouseItem.displayMode.DISPLAY, null, null);
		this.itemManager.resortLayers();
	};

	this.storeItemInInventory = function () {
		miniworldHouseCommunication.storeItemInInventory(this.draggedItem().instanceId);
		this.itemManager.removeItemInteraction();
		this.state = MiniworldHouseRoom.interactionStates.NO_INTERACTION;
	}

	this.zIndexBefore = function () {
		// return lowest z-index in front of all items
		return this.itemManager.zIndexOffset + this.itemManager.layerOrder.length;
	};

	this.switchColorState = function () {
		if (this.state == MiniworldHouseRoom.interactionStates.NO_INTERACTION) {
			this.state = MiniworldHouseRoom.interactionStates.COLOR_INTERACTION;
			return true;
		} else {
			this.state = MiniworldHouseRoom.interactionStates.NO_INTERACTION;
			miniworldHouseColorPicker.hide();
			return false;
		}
	};

	miniworldHousePreviewDoor = (function () {
		this.state = MiniworldHouseRoom.interactionStates.PREVIEW_DOOR_INTERACTION;
		this.itemManager.createPreviewDoor();
	}).bind(this);
	
	miniworldHouseAbortPreviewDoor = (function () {
		this.itemManager.removeItemInteraction();
		this.state = MiniworldHouseRoom.interactionStates.NO_INTERACTION;
	}).bind(this);
};

MiniworldHouseRoom.interactionStates = {
	NO_INTERACTION: "NOINTERACTION",
	COLOR_INTERACTION: "COLORINTERACTION",
	ITEM_INTERACTION: "ITEMINTERACTION",
	PREVIEW_DOOR_INTERACTION: "PREVIEWDOORINTERACTION"
};

MiniworldHouseRoom.zIndexOffset = 0;
MiniworldHouseRoom.zIndexBeforeCurrent = function () { return MiniworldHouseRoom.zIndexOffset };
var miniworldHousePreviewDoor = function () { };
var miniworldHouseAbortPreviewDoor = function () { };
