/// <reference path="DOMAttached.js" />

// a listener for video players which reports that a user has fully
// watched the video if they watched at least 80% of the video

var ReportWatchedVideoListener = Class.create(DOMAttached, {
	initialize: function ($super, fullyWatchedUrl, videoDuration, videoProgressKey) {
		this.fullyWatchedUrl = fullyWatchedUrl;
		this.videoDuration = videoDuration;
		this.fullyWatchedNotificationWasSent = false;
		this.logMessageWasSent = false;
		this.videoProgressKey = videoProgressKey;
	},

	onPlayProgress: function (currentSeconds) {
		this.saveVideoProgress(currentSeconds);
		
		// We don't send an AJAX call onEnd event because users tend to abort it by clicking to some link too fast after video finishes.
		if (!this.fullyWatchedNotificationWasSent && (this.videoDuration - currentSeconds <= 30)) {
			new Ajax.Request(this.fullyWatchedUrl, {
				method: 'post'
			});
			this.fullyWatchedNotificationWasSent = true;
		}
	},

	/**
	 * Save the video progress in the local or session storage
	 */
	saveVideoProgress: function(currentSeconds) {
		if (this.videoProgressKey) {
			try {
				localStorage.setItem(this.videoProgressKey, currentSeconds);
			} catch (e) {
				if (e.name === 'QuotaExceededError' 
					|| e.name === "NS_ERROR_DOM_QUOTA_REACHED" 
					|| e.name === "QUOTA_EXCEEDED_ERR") {
					sessionStorage.setItem(this.videoProgressKey, currentSeconds);
				} else if (!this.logMessageWasSent) {
					window.onerror('Storage error: ' + e, window.location.href, undefined, undefined, e);
					// We want to reduce the amount of spam in the logs, so we only log a message once
					this.logMessageWasSent = true;
				}
			}	
		}
	},

	/**
	 * Remove the video progress to the local or session storage
	 */
	removeVideoProgress: function(currentSeconds) {
		if (this.videoProgressKey) {
			localStorage.removeItem(this.videoProgressKey);
			sessionStorage.removeItem(this.videoProgressKey);			
		}
	},

	onLoadProgress: function (percentage) {
		// do nothing
	},

	onPlay: function () {
		// do nothing
	},

	onPause: function () {
		// do nothing
	},

	onEnd: function () {
		this.removeVideoProgress()
	}
});
