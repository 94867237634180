var MiniworldHouseColorPicker = function (canvas) {

	this.canvas = canvas;
	this.frameSize = 7;
	this.colorSize = 200;
	this.textureSize = 25;
	this.closeIconSize = 4 * this.frameSize;
	this.width;
	this.height = this.closeIconSize + this.colorSize + this.frameSize;
	this.posX;
	this.posY;
	this.activeObject = null;
	this.numberOfTextures = 0;
	this.currentColorX = -1;
	this.currentColorY = -1;
	this.activated = false;

	this.colorImage = document.createElement("img");
	this.colorImage.src = MiniworldHouseImages.colorpickerspectrum;
	this.closeIcon = document.createElement("img");
	this.closeIcon.src = MiniworldHouseImages.closeicon;

	var loadColorPickerTextures = function () {
		var texturesWall = [];
		for (var i = 0; i < 7; i++) {
			var textureImg = document.createElement("img");
			textureImg.src = MiniworldHouseImages.colorpickerwalltextures[i];
			texturesWall[i] = textureImg;
		}
		var texturesFloor = [];
		for (var i = 0; i < 4; i++) {
			var textureImg = document.createElement("img");
			textureImg.src = MiniworldHouseImages.colorpickerfloortextures[i];
			texturesFloor[i] = textureImg;
		}

		var textures = [];
		textures["wall"] = texturesWall;
		textures["floor"] = texturesFloor;

		return textures;
	};
	this.textures = loadColorPickerTextures();

	this.drawPicker = function (pointerX, pointerY, object) {
		this.width = this.colorSize + 2 * this.frameSize;
		if (this.activeObject.category == "DOOR") {
			this.numberOfTextures = 0;
		} else {
			this.numberOfTextures = this.activeObject.allTextures.length - 1;
		}
		if (this.numberOfTextures > 0) {
			this.width += (this.numberOfTextures + 1) * (this.textureSize + this.frameSize);
		}
		this.getPosition(pointerX, pointerY);

		this.draw();
	}

	this.startInteraction = function (pointerX, pointerY) {
		this.activated = true;
	}

	this.endInteraction = function (pointerX, pointerY) {
		if (pointerX >= this.posX + this.width - this.closeIconSize && pointerX <= this.posX + this.width
			 && pointerY >= this.posY && pointerY <= this.posY + this.closeIconSize) {
			//hide color window by clicking on the close icon
			this.hide();
			this.currentColorX = -1;
			this.currentColorY = -1;
			this.activated = false;
			MiniworldHouseInstance.menu.colorMode.clickInteraction();
		} else {
			if (this.activeObject) {
				this.draw();
				if (this.activated) {
					this.activeObject.colorMoveInteraction(pointerX, pointerY); //allows clicking after opening window
				}
			}
			this.activated = false;
		}
	}

	this.getPosition = function (pointerX, pointerY) {
		if (this.activeObject.category == "WALL") {
			this.posX = Math.min(Math.max(pointerX - parseInt(this.width / 2), 5), 945 - this.width);
			this.posY = Math.min(Math.max(pointerY - parseInt(this.height / 2), 75), 545 - this.height);

		} else if (this.activeObject.category == "DOOR") {
			if (this.activeObject.posX + .5 * this.activeObject.width < 475) { // display colorpicker on the right side of the door
				var translatedUpperRight = miniworldHouseGeometry.mapWallPosition(this.activeObject.upperLeftX() + this.activeObject.width, this.activeObject.upperLeftY());
				var translatedLowerRight = miniworldHouseGeometry.mapWallPosition(this.activeObject.upperLeftX() + this.activeObject.width, this.activeObject.upperLeftY() + this.activeObject.height);

				this.posX = translatedUpperRight.x + this.frameSize;
				this.posY = translatedUpperRight.y + parseInt(((translatedLowerRight.y - translatedUpperRight.y) - this.height) / 2);
			} else { // on the left side
				var translatedUpperLeft = miniworldHouseGeometry.mapWallPosition(this.activeObject.upperLeftX(), this.activeObject.upperLeftY());
				var translatedLowerLeft = miniworldHouseGeometry.mapWallPosition(this.activeObject.upperLeftX(), this.activeObject.upperLeftY() + this.activeObject.height);

				this.posX = translatedUpperLeft.x - this.width - this.frameSize;
				this.posY = translatedUpperLeft.y + parseInt(((translatedLowerLeft.y - translatedUpperLeft.y) - this.height) / 2);
			}
		}
	}

	this.draw = function () {
		this.canvas.style.display = "block";

		var context = this.canvas.getContext("2d");
		context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		context.fillStyle = "#aaaaaa";
		context.fillRect(this.posX, this.posY, this.width, this.height);
		context.fillStyle = "#FFFFFF";
		context.font = (2 * this.frameSize) + "px sans-serif";
		context.fillText("Pick a Color", this.posX + parseInt(1.5 * this.frameSize), this.posY + this.closeIconSize - this.frameSize);
		if (this.numberOfTextures > 0) {
			context.fillText("Pick a Texture", this.posX + this.colorSize + parseInt(1.5 * this.frameSize), this.posY + this.closeIconSize - this.frameSize);
			var type = this.activeObject.name == "floor" ? "floor" : "wall";
			for (var i = 0; i <= this.numberOfTextures; i++) {
				if (i == this.activeObject.textureIndex) {
					context.fillStyle = "#000000";
					context.fillRect(this.posX + this.colorSize + (i + 2) * this.frameSize + i * this.textureSize - 2, this.posY + this.closeIconSize - 2, this.textureSize + 4, this.colorSize + 4);
				}
				context.drawImage(this.textures[type][i], this.posX + this.colorSize + (i + 2) * this.frameSize + i * this.textureSize, this.posY + this.closeIconSize, this.textureSize, this.colorSize);
			}
		}
		context.drawImage(this.closeIcon, this.posX + this.width - this.closeIconSize, this.posY, this.closeIconSize, this.closeIconSize);
		context.drawImage(this.colorImage, this.posX + this.frameSize, this.posY + this.closeIconSize, this.colorSize, this.colorSize);

		if (this.currentColorX != -1) {	//pointer for color
			this.drawCircle(context, this.currentColorX, this.currentColorY, 5, "#000000");
			this.drawCircle(context, this.currentColorX, this.currentColorY, 3, "#00FFFF");
		} else if (this.activeObject.color && this.activeObject.color.indexOf("hsl") >= 0) {
			this.drawInitialCoordinatesForColor(context, this.activeObject.color);
		}

	};

	this.drawCircle = function (context, x, y, radius, color) {
		context.strokeStyle = color;
		context.beginPath();
		context.arc(x, y, radius, 0, 2 * Math.PI, false);
		context.stroke();
		context.closePath();
	};

	this.hide = function () {
		this.activeObject = null;
		this.canvas.style.display = "none";
	};

	this.getColor = function (pointerX, pointerY) {
		if (pointerX >= this.posX + this.frameSize && pointerX <= this.posX + this.frameSize + this.colorSize
			&& pointerY >= this.posY + this.closeIconSize && pointerY <= this.posY + this.height - this.frameSize) {
			this.currentColorX = parseInt(pointerX);
			this.currentColorY = parseInt(pointerY);
			var hue = (pointerY - this.posY - this.closeIconSize) / this.colorSize * 360;
			lightness = (pointerX - this.posX - this.frameSize) / this.colorSize * 100;
			return "hsl(" + hue + ", 80%, " + lightness + "%)";
		} else {
			return null;
		}
	};

	//draws initial color point on colorpicker
	//retrieves coordinates from hsl format of color
	this.drawInitialCoordinatesForColor = function (context, color) {
		var hue = parseFloat(color.slice(color.indexOf('(') + 1, color.indexOf(',')));
		var lightness = parseFloat(color.slice(color.lastIndexOf(' ') + 1, color.lastIndexOf('%')));

		//inverse calculations to calculations in getColor function
		var initialX = ((this.colorSize * lightness) / 100) + this.posX + this.frameSize;
		var initialY = ((this.colorSize * hue) / 360) + this.posY + this.closeIconSize;

		this.drawCircle(context, initialX, initialY, 5, "#000000");
		this.drawCircle(context, initialX, initialY, 3, "#00FFFF");
	};

	this.getTexture = function (pointerX, pointerY) {
		if (pointerX >= this.posX + this.colorSize + 2 * this.frameSize && pointerX <= this.posX + this.width
	    && pointerY >= this.posY + this.closeIconSize && pointerY <= this.posY + this.height - this.frameSize) {
			var index = Math.min(Math.floor((pointerX - (this.posX + this.colorSize + 2 * this.frameSize)) / (this.textureSize + this.frameSize)),
				this.numberOfTextures);
			return index;
		} else {
			return null;
		}
	};

}

var miniworldHouseColorPicker = null;
