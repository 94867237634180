var MiniworldHouseWall = function (name, canvas, polygon, upperLeft, allTextures, color, textureIndex) {
	this.category = "WALL";
	this.name = name;
	this.canvas = canvas;
	this.polygon = polygon;
	this.upperLeft = upperLeft;
	this.allTextures = allTextures;
	this.color = color;
	this.textureIndex = textureIndex;
	this.texture = this.allTextures[textureIndex];

	this.draw = function () {
		var context = this.canvas.getContext("2d");

		context.beginPath();
		miniworldHouseCanvasHelper.moveToPoint(context, polygon[polygon.length - 1]);
		for (var i = 0; i != polygon.length; ++i) {
			miniworldHouseCanvasHelper.lineToPoint(context, polygon[i]);
		}

		context.fillStyle = this.color;
		context.fill();
		context.closePath();

		if (this.texture) {
			context.drawImage(this.texture, this.upperLeft.x, this.upperLeft.y, this.texture.width, this.texture.height);
		}
	};

	this.setTexture = function (textureIndex) {
		this.textureIndex = textureIndex;
		this.texture = this.allTextures[textureIndex];
	};

	this.doesAcceptInteraction = function (pointerX, pointerY) {
		return miniworldHouseGeometry.polygonCheck({ x: pointerX, y: pointerY }, this.polygon);
	};

	this.colorMoveInteraction = function (pointerX, pointerY) {
		if (this == miniworldHouseColorPicker.activeObject) {
			var color = miniworldHouseColorPicker.getColor(pointerX, pointerY);
			var textureIndex = miniworldHouseColorPicker.getTexture(pointerX, pointerY);
			if (color !== null) {
				this.color = color;
			}
			if (textureIndex !== null) {
				this.setTexture(textureIndex);
			}
			this.draw();
			miniworldHouseColorPicker.draw();
		}
	};
};

MiniworldHouseWall.textureNumbers = {
	"left": 6,
	"right": 6,
	"ceiling": 0,
	"floor": 3,
	"back": 6
};

MiniworldHouseWall.loadRoomWalls = function (canvas, wallTextures, wallColors) {
	// Load textures and draw as soon as required images are loaded

	var walls = {};

	var j, textures, texture;
	for (var wallName in wallTextures) {

		if (!wallTextures.hasOwnProperty(wallName))
			continue;

		textures = [null];
		for (j = 1; j <= MiniworldHouseWall.textureNumbers[wallName]; ++j) {
			texture = document.createElement("img");
			textures.push(texture);
		}

		var wall = new MiniworldHouseWall(wallName,
	    canvas,
	    miniworldHouseGeometry.wallPolygon[wallName],
	    miniworldHouseGeometry.wallUpperLeft[wallName],
	    textures,
	    wallColors[wallName],
	    wallTextures[wallName]);

		if (textures[wallTextures[wallName]] !== null) {
			textures[wallTextures[wallName]].onload = wall.draw.bind(wall);
		} else {
			wall.draw();
		}

		for (j = 1; j <= MiniworldHouseImages.patterns[wallName].length; ++j) {
			textures[j].src = MiniworldHouseImages.patterns[wallName][j - 1];
		}

		walls[wallName] = wall;
	}

	return walls;
};

MiniworldHouseWall.serializeRoomWalls = function (walls) {
	var colors = {};
	var textures = {};
	for (var wallName in walls) {
		if (!walls.hasOwnProperty(wallName)) {
			continue;
		}
		colors[wallName] = walls[wallName].color;
		textures[wallName] = walls[wallName].textureIndex;
	}

	return {
		"colors": colors,
		"textures": textures
	};
};

MiniworldHouseWall.drawWallLines = function (canvas) {
	var context = canvas.getContext("2d");

	context.clearRect(0, 0, canvas.width, canvas.height);

	context.beginPath();
	miniworldHouseCanvasHelper.moveToPoint(context, miniworldHouseGeometry.cornerTopLeft);
	miniworldHouseCanvasHelper.lineToPoint(context, miniworldHouseGeometry.topLeft);
	miniworldHouseCanvasHelper.lineToPoint(context, miniworldHouseGeometry.topRight);
	miniworldHouseCanvasHelper.lineToPoint(context, miniworldHouseGeometry.cornerTopRight);
	miniworldHouseCanvasHelper.moveToPoint(context, miniworldHouseGeometry.topRight);
	miniworldHouseCanvasHelper.lineToPoint(context, miniworldHouseGeometry.bottomRight);
	miniworldHouseCanvasHelper.lineToPoint(context, miniworldHouseGeometry.cornerBottomRight);
	miniworldHouseCanvasHelper.moveToPoint(context, miniworldHouseGeometry.bottomRight);
	miniworldHouseCanvasHelper.lineToPoint(context, miniworldHouseGeometry.bottomLeft);
	miniworldHouseCanvasHelper.lineToPoint(context, miniworldHouseGeometry.cornerBottomLeft);
	miniworldHouseCanvasHelper.moveToPoint(context, miniworldHouseGeometry.bottomLeft);
	miniworldHouseCanvasHelper.lineToPoint(context, miniworldHouseGeometry.topLeft);
	context.globalAlpha = .5;
	context.stroke();
	context.globalAlpha = 1.;
	context.closePath();

	var shadowImage = document.createElement("img");
	shadowImage.onload = function () {
		context.drawImage(shadowImage, 0, 0, canvas.width, canvas.height);
	};
	shadowImage.src = MiniworldHouseImages.shadow;
};
