//clickcoords will be an object of four integers, the coordinates x and y for a click on the control panel, 
//and the coordinates for a click on the item area. Unused values should be -1
function paintAll(clickcoords) {

	if (designData.paintAllFirstTime) {
		resizeCanvas();
	}

	if (clickcoords === undefined) {
		var clickcoords = {
			ctrl_x: -1,
			ctrl_y: -1,
			item_x: -1,
			item_y: -1,
		}
	}

	controlpanel.paintAllControlElements(clickcoords.ctrl_x, clickcoords.ctrl_y);
	repaintItemArea();
	var fillNewShapeFlag = controlpanel.paintMode == currentMode.FILLSHAPE &&
		controlpanel.letterDrag == activeDrag.NO &&
		controlpanel.imageDrag == activeDrag.NO;
	designitem.paintOnCanvas("background", clickcoords.item_x * designData.XScale, clickcoords.item_y * designData.YScale, fillNewShapeFlag, controlpanel.chosenColor.get());

	if (designData.paintAllFirstTime) {
		designitem.paintShape("topping");
		designData.paintAllFirstTime = false;
	}
}

function repaintItemArea() {
	if (clickcoords === undefined) {
		var clickcoords = {
			ctrl_x: -1,
			ctrl_y: -1,
			item_x: -1,
			item_y: -1,
		}
	}

	if (designData.paintAllFirstTime) {
		var ctxtop = toppingCanvas.getContext("2d");
		ctxtop.fillStyle = colorscheme.ContentBackground;
		ctxtop.fillRect(0, 0, toppingCanvas.width / designData.XScale, toppingCanvas.height / designData.YScale);
	}

	var ctxFree = freestyleCanvas.getContext("2d");
	paintFreePoints(ctxFree, "source-over");

	var fillNewShapeFlag = controlpanel.paintMode == currentMode.FILLSHAPE &&
		controlpanel.letterDrag == activeDrag.NO &&
		controlpanel.imageDrag == activeDrag.NO;
	designitem.paintOnCanvas("background", clickcoords.item_x * designData.XScale, clickcoords.item_y * designData.YScale, fillNewShapeFlag, controlpanel.chosenColor.get());

	if (designData.paintAllFirstTime) {
		designitem.paintShape("topping");
		designData.paintAllFirstTime = false;
	}
}

function FreePoint(nx, ny, nColor, nDrag, nStrokeSize, nPainted) {
	this.X = nx; //x-Coordinate (int)
	this.Y = ny; //y-Coordinate (int)
	this.Color = nColor; //Color (string)
	this.Drag = nDrag; //Drag (bool)
	this.StrokeSize = nStrokeSize; //Stroke-Size (int)
	this.Painted = nPainted; //completely painted (bool)
}


//add a single free point
function addFreePoint(x, y, drag) {
	if (controlpanel.letterDrag == 0) {
		designitem.freePoints.push(new FreePoint(x, y, controlpanel.chosenColor.get(), drag, controlpanel.brushsize, false));
	}
}
var freePointRedrawCache = 0;
function paintFreePoints(ctxFree, compositeDefault) {
	ctxFree.lineJoin = "round";
	ctxFree.lineWidth = controlpanel.brushsize;

	for (var idx = freePointRedrawCache; idx < designitem.freePoints.length; ++idx) {
		if (!designitem.freePoints[idx].Painted) {
			designitem.freePoints[idx].Painted = true;
			ctxFree.lineWidth = designitem.freePoints[idx].StrokeSize;
			ctxFree.beginPath();
			if (idx > 0 && designitem.freePoints[idx].Drag) {
				ctxFree.moveTo(designitem.freePoints[idx - 1].X, designitem.freePoints[idx - 1].Y);
			}
			else {
				ctxFree.moveTo(designitem.freePoints[idx].X - 1, designitem.freePoints[idx].Y);
			}

			if (designitem.freePoints[idx].Color == "erase") {
				ctxFree.globalCompositeOperation = "destination-out";
			}
			else {
				ctxFree.globalCompositeOperation = compositeDefault;
			}
			ctxFree.strokeStyle = designitem.freePoints[idx].Color;
			ctxFree.lineTo(designitem.freePoints[idx].X, designitem.freePoints[idx].Y);
			ctxFree.closePath();
			ctxFree.stroke();
		}
		++freePointRedrawCache;
	}
	ctxFree.globalCompositeOperation = compositeDefault;
}

function paintText(ctx, text, x, y, fontOverride) {
	if (typeof fontOverride == "undefined") {
		ctx.fillStyle = "black";
		ctx.font = "10px Comic Sans MS";
	}
	else {
		ctx.font = fontOverride;
	}
	ctx.fillText(text, x, y);
}

// copied this function from StackOverflow: http://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-on-html-canvas
function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
	if (typeof stroke == "undefined") {
		stroke = true;
	}
	if (typeof radius === "undefined") {
		radius = 5;
	}
	ctx.beginPath();
	ctx.moveTo(x + radius, y);
	ctx.lineTo(x + width - radius, y);
	ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
	ctx.lineTo(x + width, y + height - radius);
	ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
	ctx.lineTo(x + radius, y + height);
	ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
	ctx.lineTo(x, y + radius);
	ctx.quadraticCurveTo(x, y, x + radius, y);
	ctx.closePath();
	if (stroke) {
		ctx.stroke();
	}
	if (fill) {
		ctx.fill();
	}
}