/// <reference path="DOMAttached.js" />

var FriendshipRequest = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		this.options = options;
		this.el = $(el);
		
		this.triggerOK = $(this.options.triggerOK);
		this.triggerClose = $(this.options.triggerClose);
		this.statusfield = $(this.options.statusfield);
		this.inputCode = $(this.options.inputCode);
		this.pbody = $(this.options.pbody);
		this.overlay = $(this.options.overlay);
		
		this.safeObserve(this.triggerOK, 'click', this.onConfirm.bindAsEventListener(this));
		
		if(this.options.inputCodeShowTrigger != undefined){
		    this.inputCodeShowTrigger = $(this.options.inputCodeShowTrigger);
		    this.safeObserve(this.inputCodeShowTrigger, 'click', 
			this.showCodeInput.bindAsEventListener(this));
		}
	},
	
	showCodeInput: function(event) {
	    this.inputCode.setStyle({display: 'block'});
	    this.inputCodeShowTrigger.setStyle({display: 'none'});
	},
	
	onConfirm: function(event) {
	    // add friendcode to callback url (if present)
	    var icode = '';
	    if(this.inputCode != undefined && this.inputCode != null) {
		icode = this.inputCode.down('input').value;
		if((icode == undefined || icode == "") 
			&& this.inputCodeShowTrigger == undefined)
		    icode = "invalid";
	    }
	    
	    var req = new Ajax.Request(
		new UrlTemplate(this.options.link).r('PLH_friendcode', icode).url, {
			    method: 'post',
			    encoding: 'UTF-8',
			    onSuccess: this.onResponse.bind(this),
		    }
		);
	},
	
	onResponse: function(req) {
		var json = req.responseText.evalJSON();
		if(json.end) {
		    this.pbody.update();
		    if(json.reload)
			this.safeObserve(this.triggerClose, 'click', this.onClose.bindAsEventListener(this));
		}
		this.statusfield.update(json.ret);
		this.overlay.position();
	},
	
	onClose: function(req) {
	    window.location.reload();
	}
	
});
