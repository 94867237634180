/// <reference path="DOMAttached.js" />

var PostingRating = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

		this.btn = this.el.down('span');		
		this.count = this.el.down('.count');
		
		this.safeObserve(this.btn, 'click', this.onClick.bindAsEventListener(this));		
		this.disableRating = false;
	},
	
	onClick: function(ev) {
		this.rate();
		ev.stop;
	},	

	rate: function() {
		if(this.disableRating)
			return;

		this.disableRating = true;

		var req = new Ajax.Request(this.options.rateUrl, {
			method: 'get',
			onSuccess: this.ratingSuccess.bind(this),
		});
	},

	ratingSuccess: function(req) {
		this.disableRating = false;

		var json = req.responseText.evalJSON();
		
		this.count.update(parseInt(this.count.innerHTML)+1);		
		this.stopObserve(this.btn, 'click');			
		this.btn.removeClassName('link');
		this.btn.down('img').writeAttribute('title', this.options.ratedTooltip);
	},
});
