/// <reference path="DOMAttached.js" />

var Collapser = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		this.collapsedIds = this.options.collapsedIds.split(' ');
		this.afterwardsIds = this.options.afterwardsIds.split(' ');
		this.link = this.el.down('.collapserLink');
		
		this.safeObserve(this.link, 'click', this.onClick.bindAsEventListener(this));
	},
	
	onClick : function(ev) {
		this.afterwardsIds.each(function(id) {
			$(id).style.display = 'none';
		});
		
		this.collapsedIds.each(function(id) {
			$(id).style.display = 'block';
		});
	}
});
