/// <reference path="DOMAttached.js" />

var FocusFirstInput = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);	
		
		if (this.options.overrideFocusFirstInput == true)
			return;

		if (!this.el.visible())
			return;
		
		var inputs = this.el.select('*');
		for (var i = 0 ; i < inputs.length; ++i) {
			var curInput = inputs[i];
			if ((curInput.tagName == 'TEXTAREA' || (curInput.tagName == 'INPUT' && curInput.type == 'text')) && curInput.disabled != true) {
				try {
					curInput.focus();
					return;
				} catch (e) {}
			}
		}
	}
});

