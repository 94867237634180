/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="TextResource.js" />
var GamesMultiplayerConstructionInstance = null;

var GamesMultiplayerConstruction = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);

		GamesMultiplayerConstructionInstance = this;

		SignalRConnection.connect("/multiplayerGameHub", function(connection) {
			connection.on("React", GamesMultiplayerConstructionInstance.onPing.bind(GamesMultiplayerConstructionInstance));
		});

		if (!this.options.needsConstruction) {
			return;
		}

		if (GenericSoundsInstance == null) {
			GenericSoundsInstance = new GenericSounds(this.options.playFeedbackSound);
		}

		this.constructionElement = $(this.options.construction_element);
		this.friendButton = this.constructionElement.down('.friendBtn');
		this.friendsChooser = this.constructionElement.select('[id*="friendsChooserddl"]')[0];
		this.safeObserve(this.friendsChooser, 'change', this.onFriendsChooserChange.bindAsEventListener(this));

		this.json = "";
		this.opponent = "";
		this.accepted = false;

		if (this.options.needsRefreshing) {
			this.safeObserve(document, 'click', this.resetTimer.bindAsEventListener(this));
			this.safeObserve(document, 'mousemove', this.resetTimer.bindAsEventListener(this));
			this.refreshTR = this.options.trRefresh;
			this.timeout = 0;
			this.setTimer();
		}
	},

	setTimer: function () {
		if (this.json == "") {
			var _this = this;
			this.timeout = setTimeout(function () {
				var friendSelectionVisible = $(_this.constructionElement.down('.friendsChooserContainer')).getStyle('display') == 'block';
				if (friendSelectionVisible) {
					confirmAI(_this.refreshTR,
						function () {
							location.reload(true);
						},
						function () { },
						true,
						true
					);
				}
				this.resetTimer
			},
			300000);
		}
	},

	resetTimer: function () {
		window.clearTimeout(this.timeout);
		this.setTimer();
	},

	onFriendsChooserChange: function (event) {
		if (this.friendsChooser.selectedIndex != 0) {
			this.friendButton.disabled = false;
			this.friendButton.style.opacity = "1";
			this.friendButton.style.cursor = "pointer";
		} else {
			this.friendButton.disabled = true;
			this.friendButton.style.opacity = "0.3";
			this.friendButton.style.cursor = "default";
		}
	},

	onPing: function (json) {
		if (json.Instance == this.json.instance) {
			if (typeof this.waitScreen == "undefined") {
				this.waitForFriend(true, json);
			}
			this.accepted = true;
			if (json.Status == this.options.status_accepted) {
				this.onSuccess(null);
			}
			else if (json.Status == this.options.status_declined) {
				GenericSoundsInstance.notificationSound();
				var declineMessage = new Element('p').update(this.opponent + " " + new TextResource(this.options.trDeclined).string());
				declineMessage.setStyle({
					color: this.options.secondaryColor,
					fontWeight: 'bold'
				});
				this.waitScreen.insert({
					top: declineMessage
				});
			}
			return true;
		}
		return false;
	},

	waitForFriend: function (goPing, json) {
		this.waitScreen = this.constructionElement.getElementsByClassName("wait_screen")[0];
		//needs to be better implemented + prototype style
		// Remove all controls inside the construction, show the wait screen instead:
		var container = this.constructionElement.select('[id*="multiplayer_mainDiv"]')[0];
		while (container.lastChild) {
			var elem = container.lastChild;
			Event.stopObserving(elem);
			container.removeChild(elem);
		}
		container.insert({
			top: this.waitScreen
		});
		this.waitScreen.style.display = "block";

		this.waitDragon = this.constructionElement.getElementsByClassName("waiting_dragon").item(0);
		this.waitDragonMirror = this.constructionElement.getElementsByClassName("waiting_dragon_mirrored").item(0);
		this.dragonFly(true);

		if (goPing) {
			this.onPing(json);
		}
	},

	dragonFly: function (isLeft) {
		if (isLeft) {
			this.waitDragon.style.visibility = "visible";
			this.waitDragonMirror.style.visibility = "hidden";
			new Effect.Move(this.waitDragon, { x: 290, y: 0, mode: 'relative', duration: 10.0 });
			new Effect.Move(this.waitDragonMirror, { x: 290, y: 0, mode: 'relative', duration: 10.0 });
		} else {
			this.waitDragon.style.visibility = "hidden";
			this.waitDragonMirror.style.visibility = "visible";
			new Effect.Move(this.waitDragon, { x: -290, y: 0, mode: 'relative', duration: 10.0 });
			new Effect.Move(this.waitDragonMirror, { x: -290, y: 0, mode: 'relative', duration: 10.0 });
		}

		_this = this;
		window.setTimeout(function () { _this.dragonFly(!isLeft) }, 10000);
	},

	onSuccess: function (req) {
		if (!this.accepted && req.responseJSON.not_allowed) {
			confirmAI(this.options.trForbidden, function () { }, function () { }, true, false);
			return;
		}

		if (!(this.level == 0 && this.accepted)) {
			this.json = req.responseText.evalJSON();
		}
		if (this.level == 0 && !this.accepted) {
			this.waitForFriend(false, null);
			return true;
		}

		// remove all children
		while (this.constructionElement.lastChild) {
			var elem = this.constructionElement.lastChild;
			Event.stopObserving(elem);
			this.constructionElement.removeChild(elem);
		}

		// add game
		this.constructionElement.insert({
			bottom: this.json.multiplayerGame
		});
	},

	onError: function (req) {
		//it's okay to be in here. This probably just says you got into waitForFriend before finishing onSuccess
	},

	onFailure: function (req) {
		console.log("GamesMultiplayerConstruction.onFailure", req);
	}
});
