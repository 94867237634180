/// <reference path="DOMAttached.js" />

var GNOMStatBox = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.linkEl = this.el.down('a');
		
		if (this.linkEl != undefined && this.options.link != undefined)
			this.safeObserve(this.linkEl, 'click', this.onClick.bindAsEventListener(this));
	},
	
	onClick: function(ev) {
		this.el.up('.tabbedApplication').attachedObj.findAndLoadTab(this.options.link);
		ev.stop();
	}
});
