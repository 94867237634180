/// <reference path="DOMAttached.js" />

var GNOMActionTimer = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.el.attachedObj = this;		
		this.popup = this.el.up('.GNOMPage').down('.outerPopup2');
				
		this.time = options.start;
		this.printTime();
		
		if (this.time > 0)
		{
			this.el.up('.GNOMPage').addClassName('deactivated');
			this.setVisible();
		}

		this.timer = setInterval(this.onTimer.bind(this), 1000);
		this.el.up('.tabbedApplication').attachedObj.registerInterval(this.timer);
	},
	
	onTimer: function() {
		if (this.time <= 0) {
			clearInterval(this.timer);
			this.setInVisible();
			this.el.up('.GNOMPage').removeClassName('deactivated');
			return;
		}
			
		this.time--;
		this.printTime();
	},
	
	printTime: function() {
		this.el.innerHTML = Math.floor(this.time / 60) + ':' + ((this.time % 60 < 10) ? '0' : '') + (this.time % 60);
	},
	
	setVisible: function() {
		this.popup.style.display = 'inline';
	},	
	
	setInVisible: function() {
		this.popup.style.display = 'none';
	},
	
	
	setTime: function(val) {
		clearInterval(this.timer);
		
		this.time = val;
		this.printTime();
		if(this.time>0)
				this.setVisible();
		
		this.timer = setInterval(this.onTimer.bind(this), 1000);
		this.el.up('.tabbedApplication').attachedObj.registerInterval(this.timer);
	}
});

