/// <reference path="DOMAttached.js" />
/// <reference path="../../../node_modules/soundmanager2/script/soundmanager2-nodebug.js" />

var LegacyGameHeader = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

		// Initialize vue instance for games so vue component is parsed to html.
		// That is needed because games are loaded asynchronously in the ajax call (vue initialized globally before that).
		new window.Vue({el: this.el.down(".mia-GameHeader")});

		this.isShown = options.isShown;
		this.toggleFeedbackSoundUrl = options.toggleFeedbackSoundUrl;
		this.toggleGameContentReadingUrl = options.toggleGameContentReadingUrl;

		// We need to wait for the element to appear in the DOM to attach the event handler.
		if (this.isShown) {
			waitForElementToBePresent('.mia-lessonSoundToggleButton .v-switch-input').then(this.initializeLessonSoundToggleButton.bind(this));
			waitForElementToBePresent('.mia-readAloudToggleButton .v-switch-input').then(this.initializeReadAloudToggleButton.bind(this));
		}
	},

	onLessonSoundToggleButtonChange: function(ev) {
		new Ajax.Request(this.toggleFeedbackSoundUrl, {
			method: 'get',
			onSuccess: this.onLessonSoundToggleButtonChangeSuccess.bind(this),
		});
	},

	onLessonSoundToggleButtonChangeSuccess: function(ev) {
		if (GenericSoundsInstance !== undefined && GenericSoundsInstance.playFeedbackSound !== undefined) {
			GenericSoundsInstance.playFeedbackSound = !GenericSoundsInstance.playFeedbackSound;
			if (GenericSoundsInstance.playFeedbackSound) {
				if (window.PlayFeedbackSound != null) {
					window.PlayFeedbackSound = true;
				}
			}
			else {
				soundManager.stop('intro');
				soundManager.stop('theme');
				if (window.PlayFeedbackSound != null) {
					window.PlayFeedbackSound = false;
				}
			}
		}
	},

	onReadAloudToggleButtonChange: function(ev) {
		new Ajax.Request(this.toggleGameContentReadingUrl, {
			method: 'get',
			onSuccess: this.onReadAloudToggleButtonSuccess.bind(this),
		});
	},

	onReadAloudToggleButtonSuccess: function(ev) {
		if (GamesTextToSpeechInstance !== null && GamesTextToSpeechInstance !== undefined) {
			if (GamesTextToSpeechInstance.isEnabled) {
				GamesTextToSpeechInstance.turnOffTextToSpeech();
			} else {
				GamesTextToSpeechInstance.turnOnTextToSpeech();
				GamesQuizInstance.createAndReadAllTextToSpeech();
			}
		}
	},

	initializeLessonSoundToggleButton: function(element) {
		this.safeObserve(element, 'click', this.onLessonSoundToggleButtonChange.bindAsEventListener(this));
	},

	initializeReadAloudToggleButton: function(element) {
		this.safeObserve(element, 'click', this.onReadAloudToggleButtonChange.bindAsEventListener(this));
	},
});
