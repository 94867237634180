/// <reference path="PopupEmailPreview.js" />

var DeleteMessageDesign = Class.create(PopupEmailPreview, {
	initialize: function ($super, el, options) {
		$super(el, options);

		this.subjectBox = $(options.subjectBox);
		this.messageBox = $(options.messageBox);
		this.itemId = options.itemId;
		this.emailAddress = options.emailAddress;
	},

	getParameters: function () {
		return {
			"PLH_subject": this.subjectBox.value,
			"PLH_message": this.messageBox.value,
		};
	}
});
