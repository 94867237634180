/// <reference path="ListBase.js" />

var GameList = Class.create(ListBase, {
	initialize: function($super, el, options) {
		$super(el, Object.extend({pagingEffect: 'returnToTop'}, options));
	},
	
	loadPage: function($super, page) {
		$super(page);
		this.standardJSONRequest(this.options.url.replace('PLH_pageNum', page));
	}
});
