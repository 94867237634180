/// <reference path="DOMAttached.js" />

 var EnhancedButton = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

		this.oneClickOnly = this.options.oneClickOnly;
		this.safeObserve(this.el, 'click', this.onclick.bindAsEventListener(this));
		
		/*
		 * Mimick the behavior of a native button. If you click and hold the mouse button
		 * on a butten, then drag the cursor out of the button and onto the button, again
		 * a native button is pushed down, again. This button is not going to be pushed down
		 * again because there is no reliable way to find out whether the mouse button has
		 * been released between a mouseout and a mouseover event.
		 */
		this.safeObserve(this.el, 'mousedown', this.onMouseDown.bindAsEventListener(this));
		this.safeObserve(this.el, 'mouseup', this.onMouseUp.bindAsEventListener(this));
		this.safeObserve(this.el, 'mouseout', this.onMouseUp.bindAsEventListener(this));
	// I commented this out, because otherwise it deletes some necassary javacode
	//	var script = this.el.next('script');
	//	if (script != null) {
	//		this.removeElement(this.el.next('script'));
	//	}
	},
	
	onclick: function(ev) {
		// We can't disable the button instantaniously because then the ASPX JS won't work anymore.
		// Thus we wait 0ms causing the event chain to be processed before the button is disabled (since JS is singlethreaded).
	  if (this.oneClickOnly)
			setTimeout(function() {	this.el.disable(); }.bindAsEventListener(this), 0);
	},
	
	onMouseDown: function(ev) {
		if (!this.el.disabled)
			this.el.addClassName('pushed');
	},
	
	onMouseUp: function(ev) {
		this.el.removeClassName('pushed');
	}
});
