/// <reference path="DOMAttached.js" />

var TabPopupMenu = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

		Element.extend(this.el);
		this.hide();
		this.options.attachTo = $(this.options.attachTo);
		this.safeObserve(this.options.attachTo, 'mouseover', this.onAttMouseOver.bindAsEventListener(this));
		this.safeObserve(this.el, 'mouseover', this.onAttMouseOver.bindAsEventListener(this));
		this.safeObserve(this.options.attachTo, 'mouseout', this.onAttMouseOut.bindAsEventListener(this));
		this.safeObserve(this.el, 'mouseout', this.onAttMouseOut.bindAsEventListener(this));
		
		this.reparented = false;
			
		this.registerUnloadAction(this.cleanUp.bind(this));
	},
	
	cleanUp: function() {
		this.options.attachTo = null;
	},
	
	onAttMouseOver: function(ev) {
		if (!this.visible) {
			this.show();
		} else {
			if (this.hideTimer)
				clearTimeout(this.hideTimer);
				this.hideTimer = undefined;
		}
	},
	
	onAttMouseOut: function(ev) {
		if (!this.hideTimer) {
			this.hideTimer = setTimeout(this.hide.bind(this), 1000);
		}
	},
	
	show: function() {
		if (!this.reparented) {
			this.el.remove();
			global.getToplevelElement().appendChild(this.el);
			this.reparented = true;
		}
		var attPos = this.options.attachTo.cumulativeOffset();
		var attDim = this.options.attachTo.getDimensions();
		this.el.setStyle({
			left: (attPos.left + (attDim.width - this.el.getWidth()) / 2).round() + 'px',
			top: (attPos.top + attDim.height) + 'px'
		});
		this.el.show();
		this.visible = true;

		if (global.currentlyActiveTabPopup)
			global.currentlyActiveTabPopup.hide();
		global.currentlyActiveTabPopup = this;
	},
	
	hide: function() {
		this.el.hide();
		this.visible = false;
		if (this.hideTimer)
			clearTimeout(this.hideTimer);
		this.hideTimer = undefined;
		global.currentlyActiveTabPopup = undefined;
	}
});
