/// <reference path="ListBase.js" />
var ActivePlazasListLastInstances = {};

var ActivePlazasList = Class.create(ListBase, {
	initialize: function ($super, el, options) {
		$super(el, options);
		ActivePlazasListLastInstances[options.activePlazaListIdentifier] = this;
		//Class is reused for friends list and several other lists (which uses different paging effect)
		if ($(el).hasClassName("myFriendsList") || $(el).hasClassName("guestBookList"))
			$super(el, Object.extend({pagingEffect: 'returnToTop'}, options));
		else if ($(el).hasClassName("commentsList"))
			$super(el, Object.extend({pagingEffect: 'returnToTop', customProgressIndicator: 'div'}, options));
		else if ($(el).hasClassName("memberList"))
			$super(el, Object.extend({pagingEffect: 'vertSlide', legacy: false}, options));
		else if ($(el).hasClassName("messageList"))
			$super(el, Object.extend({pagingEffect: 'returnToTop', legacy: true, fixDimensions: false}, options));
		else if ($(el).hasClassName("inventoryList"))
			$super(el, Object.extend({ pagingEffect: 'horizSlide' }, options));
		else if ($(el).hasClassName("designedItemList"))
			$super(el, Object.extend({ pagingEffect: 'horizSlide' }, options));
		else if ($(el).hasClassName("shapeList"))
			$super(el, Object.extend({ pagingEffect: 'horizSlide' }, options));
		else
			$super(el, Object.extend({ pagingEffect: 'vertSlide', removeButtonsForOnePageLists: true }, options));
	},

	loadPage: function ($super, page, search) {
		$super(page);

		this.resetDimensions();

		if (search === null || search === undefined) {
			if (this.options.search === null || this.options.search === undefined) {
				search = "";
			} else {
				search = this.options.search;
			}
		}

		var url = this.options.url.replace('PLH_pagenum', page).replace('PLH_search', search);
		var minslider = $("contentcontent_grade_slider_min");
		var maxslider = $("contentcontent_grade_slider_max");
		if (minslider && maxslider) {
			var min = minslider.value || 0;
			var max = maxslider.value || 8;
			url = url.replace('PLH_gradeMin', min).replace('PLH_gradeMax', max);
		} else {
			url = url.replace('PLH_gradeMin', 0).replace('PLH_gradeMax', 8);
		}
		this.standardJSONRequest(url);

		return true;
	}
});
