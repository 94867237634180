/// <reference path="DOMAttached.js" />

var NewspaperAd = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);
		
		if (this.options.isInPreviewMode) {
			this.initializeChangeHandlers();
		} 
	},

	initializeChangeHandlers: function () {
		this.titleText = this.el.down('.titleText');
		this.bodyText = this.el.down('.bodyText');

		this.titleBoundTextArea = $(this.options.textBoxTitleId).down('textarea');
		this.bodyBoundTextArea = $(this.options.textBoxBodyId).down('textarea');
		this.boundImageContainer = $(this.options.pictureSelection).down('.imageShow');

		this.safeObserve(this.titleBoundTextArea, 'change', this.onTitleChange.bindAsEventListener(this));

		this.safeObserve(this.bodyBoundTextArea, 'change', this.onBodyChange.bindAsEventListener(this));
	},

	onTitleChange: function (ev) {
		this.titleText.innerHTML = this.titleBoundTextArea.value;
	},

	onBodyChange: function (ev) {
		this.bodyText.innerHTML = this.bodyBoundTextArea.value;
	},
});