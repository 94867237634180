/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="Confirm.js" />
/// <reference path="ProgressIndicatingControl.js" />

var FeedStatusMessage = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);

		this.commentBox = this.el.down(".newComment");
		this.textbox = this.el.down(".newComment").down("textarea");

		this.showCommentBoxLink = this.el.up(".mia-FeedEntry").down("div.mia-timespan a");

		this.safeObserve(this.el.down(".newComment .button"), 'click', this.onSubmitClick.bindAsEventListener(this));
		this.safeObserve(this.showCommentBoxLink, 'click', this.onCommentClick.bindAsEventListener(this));

		this.loadMoreComments = $(this.el.down(".loadMoreComments"));

		// Initialize vue instance for smiley selector so vue component is parsed to html.
		// That is needed because comments are loaded asynchronously in the ajax call (vue initialized globally before that).
		new window.Vue({el: this.el.down(".mia-smileySelectorWrapper")});
	if(this.loadMoreComments != undefined)
	    this.safeObserve(this.loadMoreComments, 'click', this.loadMore.bindAsEventListener(this));

		var comments = this.el.getElementsByClassName("feedComment");

		if (comments.length == 0) {
			this.el.hide();
		} else {
			this.showCommentBoxLink.hide();
		}
	},

	onCommentClick: function(ev) {
			ev.stop();
			this.showCommentBoxLink.hide();
			this.el.toggle();
	},

	onSubmitClick: function(ev) {
		ev.stop();
		
		var text = this.textbox.value;

		if (text.trim() == "") {
			confirmAI(this.options.errorEmptyText, function () { }, function () { }, true, false);
			return;
		} else if (text.length > this.options.maxLength) {
			confirmAI(this.options.errorTooLongText, function () { }, function () { }, true, false);
			return;
		}

		this.showProgressIndicator();
		new Ajax.Request(new UrlTemplate(this.options.addUrl).r("PLH_text", this.textbox.value).url, {
			method: 'get',
			onSuccess: this.onLoaded.bind(this),
		});
	},

	onLoadException: function (ex) {
		console.log(ex.message);
		this.hideProgressIndicator();
	},

	onLoaded: function(res) {
	    this.hideProgressIndicator();

		var json = res.responseText.evalJSON();

		if (json.success) {
			if (json.hideLastComment) {
				var commentContainer = this.commentBox.previous();
				if (commentContainer) {
					var lastComment = jQuery(commentContainer).find('.feedComment').last();
					if (lastComment) {
						lastComment.hide();
					}
				}
			}
			var newComment = $(document.createElement("div"));
			this.commentBox.parentNode.insertBefore(newComment, this.commentBox);
			newComment.update(json.comment);
			this.textbox.value = "";
		} else {
			confirmAI(json.errorMessage, function () { }, function () { }, true, false);
		}
	},

    loadMore: function (ev) {
	ev.stop();
	var req = new Ajax.Request(
			this.options.feedCommentsUrl, {
			    method: 'get',
			    onSuccess: this.onResponse.bind(this),
			}
		);
    },

    onResponse: function (req) {
	var json;
	try {
	    json = req.responseText.evalJSON();
	} catch (e) {
	    this.write(e);
	}
	// insert after loadMore
	var div  = document.createElement("div");
	div.innerHTML = json.comments;
	this.el.insertBefore(div, this.el.firstChild.nextSibling);
      
	// set new loadMoreURL
	this.options.feedCommentsUrl = json.feedCommentsUrl;
	
	// disable loadMore, if there are not more comments
	if (!json.moreComments) {
	    this.loadMoreComments.hide();
	}
	else {
	    this.el.firstChild.innerHTML = json.moreCommentsString;
	}

	var scripts = div.getElementsByTagName('script');

	for (var ix = 0; ix < scripts.length; ix++) {
	   eval(scripts[ix].text);
	}
    }
});

FeedStatusMessage.addMethods(ProgressIndicatingControl);
