/*
 * Convenience class for logic that is needed for displaying level media
 * (which explains the content of the current level).
 */
var LevelMedia = Class.create({
	initialize: function (game) {
		this.game = game;

		this.mediaOverlay = this.game.el.down('.LevelMediaOverlay');
		this.mediaOverlay.down('.background').style.opacity = 0.8;//IE10 Hack, up to Prototype supports this
		this.mediaOverlay.down('.background').setOpacity(0.8);
		this.mediaText = this.mediaOverlay.down('.LevelMediaText');

		this.initializeListenersForOpeningMedia();
		this.initializeListenersForClosingMedia();

		this.closeMedia();
	},

	initializeListenersForClosingMedia: function () {
		this.game.safeObserve(this.mediaOverlay.down('.levelTextClose'), 'click', this.onLevelMediaCloseButtonClick.bindAsEventListener(this));
	},

	onLevelMediaCloseButtonClick: function (ev) {
		this.closeMedia();
	},

	initializeListenersForOpeningMedia: function () {
		this.attachHandlerOnClickIfElementExists('.textMediaButton', this.onToLessonClick);
	},

	onToLessonClick: function (ev) {
		this.showMedia();
	},


	/**
	 * Public. Loads the given media.
	 */
	loadMedia: function (mediaText) {
		this.hasTextMedia = (mediaText != undefined) && (mediaText.text != undefined);

		if (this.hasTextMedia) {
			this.mediaText.innerHTML = mediaText.text;
			this.viewCount = 0;
			this.viewTimeSeconds = mediaText.viewTimeSeconds;
			this.secondViewTimeSeconds = mediaText.secondViewTimeSeconds;
		}
	},

	showMedia: function () {
		if (this.hasTextMedia) {
			var maximumDisplayTimeInSeconds = this.viewCound == 0 ? this.viewTimeSeconds : this.secondViewTimeSeconds;
			this.openMedia(maximumDisplayTimeInSeconds);
		}
	},

	closeMedia: function () {
		this.mediaOverlay.style.display = 'none';
		this.stopCountdown();
		var event = document.createEvent('Event');
		event.initEvent("closeMedia", true, true);
		this.game.el.dispatchEvent(event);
	},

	/**
	 * Makes the level media show up. null for maximumDisplayTimeInSeconds means unlimited.
	 */
	openMedia: function (maximumDisplayTimeInSeconds) {
		this.mediaOverlay.style.display = '';
		this.viewCount++;
		if (maximumDisplayTimeInSeconds != null) {
			this.startCountdown(maximumDisplayTimeInSeconds);
		}
	},

	/**
	 * Starts the countdown for displaying the level media; once the end of the 
	 * countdown is reached, the level media is automatically closed.
	 */
	startCountdown: function (time) {
		this.stopCountdown();
		this.secondsLeftToViewLeveMedia = time;
		this.countdown = new PeriodicalExecuter(this.decreaseSecondsLeftToViewLevelMedia.bind(this), 1);
		this.updateDisplayOfTimeLeftToViewLevelMedia();
	},

	stopCountdown: function () {
		if (this.countdown != undefined) {
			this.countdown.stop();
			this.countdown = undefined;
		}
	},

	/**
	 * This decreases the time left to view the level media by one second; once it reaches 0, 
	 * the level media is closed.
	 */
	decreaseSecondsLeftToViewLevelMedia: function () {
		this.secondsLeftToViewLeveMedia--;
		this.updateDisplayOfTimeLeftToViewLevelMedia();
		if (this.secondsLeftToViewLeveMedia <= 0) {
			this.stopCountdown();
			this.closeMedia();
		}
	},

	updateDisplayOfTimeLeftToViewLevelMedia: function () {
		this.game.el.down('.LevelMediaTimer').innerHTML = this.secondsLeftToViewLeveMedia;
	},

	attachHandlerOnClickIfElementExists: function(elementSelector, handlerCallback) {
		var selectedElement = this.game.el.down(elementSelector);
		if (selectedElement != undefined) {
			this.game.safeObserve(selectedElement, 'click', handlerCallback.bindAsEventListener(this));
		}
	}
});
