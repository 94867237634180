/// <reference path="PopupEmailPreview.js" />

var NewsletterDesign = Class.create(PopupEmailPreview, {
	initialize: function ($super, el, options) {
		$super(el, options);

		this.subjectBox = $(options.subjectBox);
		this.salutationBox = $(options.salutationBox);
		this.messageBox = $(options.messageBox);
	},

	getParameters: function () {
		return {
			"PLH_subject": this.subjectBox.value,
			"PLH_salutation": this.salutationBox.value,
			"PLH_message": this.messageBox.value
		};
	}
});
