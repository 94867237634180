/// <reference path="DOMAttached.js" />

var IFrame = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.loadReady();
		
		document.loadReady = this.loadReady.bind(this);
		

	},
	
	loadReady : function() {
		this.iframeDoc = this.el.contentWindow || this.el.contentDocument;
		if (this.iframeDoc.document) {
			this.iframeDoc = this.iframeDoc.document;
			this.el.style.height = this.iframeDoc.body.scrollHeight + "px";
		}
	}
});
