var UrlTemplate = Class.create({
	initialize: function(url) {
		this.url = url;
	},

	isNotNull: function(value) {
		return value != 'NULL';
	},
	
	// r for replace
	r: function(placeholder, value) {
		if (this.isNotNull(value)) {
			this.url = this.url.replace(placeholder, encodeURIComponent(value));
		} else {
			this.url = this.url.replace(placeholder, encodeURIComponent(' '));
		}
		return this;
	}
});
