function ItemDraft() {
	this.colors = [];
	this.freepng = null;
}

function Item() {
    var svgstring = "";
    var tmp = "";

    var paths = [];
    var colors = [];
    this.freePoints = [];
    var draft_history = [];

    this.clearColors = function(){
	for (var i = 0; i < colors.length; ++i) {
	    colors[i] = "white";
	}
    }

    this.extractPathsFromSvg = function () {
	while (tmp.search("<path") != -1) {
	    tmp = tmp.substring(tmp.search("<path"), tmp.length);
	    var path = tmp.substring(0, tmp.search("/>"))+"/>";
	    tmp = tmp.substring(tmp.search("/>") + 2, tmp.length);
	    paths.push(path);
	    colors.push("white");
	}
    };
	this.svg_adapt_stroke_width = function(contents, desired_stroke_width) {
		contents = contents.replace(/stroke-width: ?[^;\"]+/g, "");
		contents = contents.replace(/stroke: ?[^;\"]+/g, "stroke:#000000;stroke-width:" + desired_stroke_width.toString());
		contents = contents.replace(/stroke-width=\"[^\"]+\"/g, "");
		contents = contents.replace(/stroke=\"[^\"]+\"/g, "stroke=\"#000000\" stroke-width=\"" + desired_stroke_width.toString() + "\"");
		return contents;
	}

    //the paths often contain unnecessary information provided by the editor. Canvg doesn't like them, so let's get rid of them
    //hopefully this function becomes obsolete after cleaning svgs...
    this.cleanpaths = function () {
	for (idx = 0; idx < paths.length; ++idx) {
	    paths[idx] = paths[idx].replace(new RegExp('sodipodi:nodetypes="([c]*)"'), "");
	    paths[idx] = paths[idx].replace(new RegExp('inkscape:connect([^ ]*)="([^ ])"'), "");
	}
    }

	// the svgs may be transformed by some meta settings. To paint right we need to extract these information
    var transform = [];
    this.extractTransformFromSvg = function () { 
    	var start = svgstring.search("transform=");
    	var tmp = svgstring.substr(start + 21, 15);
    	this.transform = [];
    	this.transform.push(parseFloat(tmp));
    	if (tmp.search(",") != -1) {
    		tmp = tmp.substr(tmp.search(",") + 1, 20);
    	}
    	else if (tmp.search(" ") != -1) {
    		tmp = tmp.substr(tmp.search(" ") + 1, 20); //Internet Explorer AND Edge suck in the same way
    	}
    	else {
    		tmp = tmp.substr(tmp.substr(1, 19).search("-") + 1, 20);
    	}
    	this.transform.push(parseFloat(tmp));
    }

    this.width = 0;
	this.height = 0;
    //we need width and height of the svg item
    this.extractSizeFromSvg = function () {

    	var start = svgstring.search("width=");
    	var tmp;
    	var stroketest = svgstring.substr(start - 7, 6);
    	if (start == -1 || stroketest=="stroke") {
    		start = svgstring.search("viewBox");
    		tmp = svgstring.substr(start + 13, 20);
    		this.width = parseFloat(tmp);
    		tmp = tmp.substr(tmp.search(' '), 10);
    		this.height = parseFloat(tmp);
    	}
    	else {

    		tmp = svgstring.substr(start + 7, 20); tmp = tmp.substring(0, tmp.search('"'));
    		if (tmp.substr(tmp.length - 2, 2) == "mm") { //why the hell would anyone store svg-width in mm?
    			this.width = parseFloat(tmp) * 3.54;
    		}
    		else {
    			this.width = parseFloat(tmp);
    		}

    		var start = svgstring.search("height=");
    		var tmp = svgstring.substr(start + 8, 20);
    		tmp = tmp.substring(0, tmp.search('"'));
    		if (tmp.substr(tmp.length - 2, 2) == "mm") {
    			this.height = parseFloat(tmp) * 3.54;
    		}
    		else {
    			this.height = parseFloat(tmp);
    		}
    	}
    }

    this.init = function () {
	svgstring = designData.svgContent;
	tmp = svgstring;
		this.extractSizeFromSvg();
		var desired_stroke_width = 3 * Math.max(this.width / designData.paintingWidth, this.height / designData.paintingHeight);
		tmp=this.svg_adapt_stroke_width(tmp, desired_stroke_width);
	this.extractPathsFromSvg();
	this.cleanpaths();
	this.extractTransformFromSvg();
    }

    this.paintOnCanvas = function (canvasId, x, y, fillNewShapeFlag, newcolor) {
	//defaults
    	if (x == undefined) {
    		x = -1;
    	}
    	if (y == undefined) {
    		y = -1;
    	}
    	if (fillNewShapeFlag == undefined) {
    		fillNewShapeFlag = false;
    	}
    	if (newcolor == undefined) {
    		newcolor = "white";
    	}

	var ctx = document.getElementById(canvasId).getContext("2d");
	//ctx.save();
	ctx.setTransform(designData.XScale, 0, 0, designData.YScale, designData.XScale * this.transform[0], designData.YScale * this.transform[1]);
	var draft = this.create_draft();
	var updated = false;
	for (idx = 0; idx < paths.length; ++idx) {
	    canvg(canvasId, paths[idx], { ignoreMouse: true, ignoreAnimation: true, ignoreClear: true });

	    if (fillNewShapeFlag && ctx.isPointInPath(x, y)) {
		colors[idx] = controlpanel.chosenColor.getPositioned(x, y);
		if (colors[idx] == "erase") {
		    colors[idx] = "white";
		}
		updated = true;
	    }
	    ctx.save();
	    ctx.setTransform(designData.XScale, 0, 0, designData.YScale, 0, 0);
	    ctx.fillStyle = colors[idx];
	    ctx.fill();
	    ctx.restore();
	}
	if (updated) {
	    this.draft_stash(draft);
	}
    }

    this.paintShape = function (canvasId) {
    	var ctxtop = document.getElementById(canvasId).getContext("2d");
    	ctxtop.setTransform(designData.XScale, 0, 0, designData.YScale, designData.XScale * this.transform[0], designData.YScale * this.transform[1]);
		ctxtop.fillStyle = canvasId=="finaltmp" ? "rgba(0,0,0,0)" : "#BEBEBE";
	    ctxtop.fillRect(0,0,designData.paintingWidth/designData.XScale,designData.paintingHeight/designData.YScale);
    	for (idx = 0; idx < paths.length; ++idx) {
			//display paint only within shape:
		    ctxtop.globalCompositeOperation = "destination-out";
    		canvg(canvasId, paths[idx], { ignoreMouse: true, ignoreAnimation: true, ignoreClear: true });
    		ctxtop.fill();
			//make sure shape borders cannot be overpainted:
    		ctxtop.globalCompositeOperation = "source-over";
    		canvg(canvasId, paths[idx], { ignoreMouse: true, ignoreAnimation: true, ignoreClear: true });
    	}
	}

	this.create_draft = function() {
		var draft = new ItemDraft();
		draft.colors = colors.slice(); //slice: copy by value
		draft.freepng = freestyleCanvas.toDataURL("image/png");
		return draft;
	}

	this.draft_history_size = function () {
		return draft_history.length;
	}

	this.draft_history_clear = function () {
		draft_history = [];
	}

	this.draft_stash = function (draft) {
		draft = (typeof draft !== 'undefined') ? draft : this.create_draft();
		// for now only store last 50 changes. Multiple undos will be implemented after further discussion
		while (draft_history.length > 50) {
			draft_history.shift();
		}
		draft_history.push(draft);
		if (this.draft_history_size() == 1) {
			controlpanel.paintAllControlElements(-1, -1);
		}
	}

	this.draft_pop = function () {
		if (draft_history.length == 0) {
			return;
		}
		controlpanel.clearClickHandler();
		draft = draft_history.pop();
		colors = draft.colors;
		var freeimg = new Image;
		freestyleCanvas.getContext("2d").clearRect(0, 0, freestyleCanvas.width, freestyleCanvas.height);
		freeimg.onload = function () {
			freestyleCanvas.getContext("2d").drawImage(freeimg, 0, 0, designData.paintingWidth / designData.XScale, designData.paintingHeight / designData.YScale);
		};
		freeimg.src = draft.freepng;
		paintAll();
	}

	this.draft_last = function () {
		if (draft_history.length == 0) {
			return;
		}
		return draft_history[draft_history.length - 1];
	}
}
