/// <reference path="UrlTemplate.js" />
/// <reference path="ListBase.js" />

var MedalList = Class.create(ListBase, {
	initialize: function($super, el, options) {
		$super(el, Object.extend({ pagingEffect: (global.isIE ? 'none' : 'horizSlide') }, options));
		this.el.attachedObj = this;
		this.registerUnloadAction(this.cleanUp.bind(this));
	},
	
	cleanUp: function() {
		this.el.attachedObj = null;
	},
	
	loadPage: function($super, page) {
		$super(page);
		this.standardJSONRequest(this.options.url.replace('PLH_pagenum', page));
	}
});