var MiniworldHouseMenu = function (editableRoom, options) {

	this.options = options;
	this.editableRoom = editableRoom;

	this.menu = [];
	this.menuCounter = 0;

	this.addToMenu = function (element) {
		this.menu[this.menuCounter] = element;
		this.menuCounter++;
	}

	this.doorBack = new MiniworldHouseIcon("Exit-Icon", MiniworldHouseDoorBack, this.options);
	this.addToMenu(this.doorBack);
	if (this.editableRoom) {
		this.doorAdd = new MiniworldHouseIcon("Add-Icon", MiniworldHouseDoorAdd, this.options);
		this.addToMenu(this.doorAdd);
		this.deleteDoor = new MiniworldHouseIcon("Delete-Icon", MiniworldHouseDeleteItem, this.options);
		this.deleteDoor.hide();
		this.addToMenu(this.deleteDoor);
		this.colorMode = new MiniworldHouseIcon("ColorMode-Icon", MiniworldHouseColorMode, this.options);
		this.addToMenu(this.colorMode);
		this.inventory = new MiniworldHouseIcon("Inventory-Icon", MiniworldHouseInventory, this.options);
		this.addToMenu(this.inventory);
	}

	this.touchStartInteraction = function (event) {
		for (var i = 0; i < this.menu.length; i++) {
			this.menu[i].touchStartInteraction(event);
		}
	};

	this.touchMoveInteraction = function (event) {
		for (var i = 0; i < this.menu.length; i++) {
			this.menu[i].touchMoveInteraction(event);
		}
	};
	
	this.touchEndInteraction = function (event) {
		for (var i = 0; i < this.menu.length; i++) {
			this.menu[i].touchEndInteraction(event);
		}
	};
};

MiniworldHouseMenu.type = {
	DOORBACK: "DOORBACK",
	DOORADD: "DOORADD",
	INVENTORY: "INVENTORY",
	DELETEDOOR: "DELETEDOOR",
	COLORMODE: "COLORMODE",
	NONE: "NONE"
};