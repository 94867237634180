/**
 * This is a spinning wheel that can be displayed to indicate that 
 * something is happening and the user has to wait.
 */
var ProgressIndicatingControl = {
	showProgressIndicator: function (positionRelative, cssRuleForAlignEl, searchCssRuleDown) {
		if (this.suppressProgressIndicator) return;
		if (this.progressIndicator == undefined) {
			this.progressIndicator = Builder.node('div', { className:'progressIndicatorDiv' });
			Element.extend(this.progressIndicator);
			
			var progImg = Builder.node('img', { className: 'progressIndicator' });
			progImg.setAttribute('src', '/static-images/shared/shared/tng/progress.gif');
			Element.extend(progImg);
			this.progressIndicator.appendChild(progImg);	
			
			if (positionRelative != undefined && positionRelative) {
				this.positionRelative = true;
			} else {
				global.getToplevelElement().appendChild(this.progressIndicator);
				this.positionRelative = false;
			}
		}
		if(cssRuleForAlignEl == undefined) {
			cssRuleForAlignEl = 'div';
		}
		if (typeof (this.el) != undefined && cssRuleForAlignEl) { // quickfix to prevent undefined access error from happening
			var alignEl = searchCssRuleDown == true
				? this.el.down(cssRuleForAlignEl)
				: this.el.up(cssRuleForAlignEl);
		}
		if (!alignEl) {
			alignEl = this.el;
		}

		var masterDims = alignEl.getDimensions();
		if (this.positionRelative) {
			alignEl.makePositioned();
			alignEl.appendChild(this.progressIndicator);
			this.progressIndicator.setStyle({
				left: 0,
				top: 0,
				width: masterDims.width + 'px',
				height: masterDims.height + 'px'
			});
		} else {
			var masterPos = alignEl.cumulativeOffset();
			this.progressIndicator.setStyle({
				left:masterPos.left + 'px',
				top:masterPos.top + 'px',
				width: masterDims.width + 'px',
				height: masterDims.height + 'px'
			});
		}
		this.progressIndicator.show();
		this.progressIndicator.style.position = 'absolute';
		this.progressIndicator.style.zIndex = '900';
		this.progressIndicator.style.opacity = .5;//IE10 Hack, up to Prototype supports this
		this.progressIndicator.setOpacity(.5)		
	},
	
	
	hideProgressIndicator: function() {
		if (this.progressIndicator)
			this.progressIndicator.hide();
	}
};