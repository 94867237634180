//Encloses modal inside window with specified class
function encloseModalInWindow(windowClass) {
	jQuery(".modal-backdrop").appendTo('.' + windowClass);
	var bodyJQuery = jQuery("body");
	bodyJQuery.removeClass("modal-open");
	//Bootstrap adds padding-right: 5px by default but we don't need it
	bodyJQuery.css("padding-right", 0);
}

//Allows to do other staff outside window in which modal is shown
function removeFocusFromModalInWindow(modalId) {
	jQuery(".modal[modal-id='" + modalId + "']").on("shown.bs.modal", function () {
		jQuery(document).off("focusin.modal");
	});
}