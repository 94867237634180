// This file is evil and should go away.
// Global state that has to run at the right point in time has created lots of issues in the past.
// Please do not add to this file but only remove things.
var global = {
	currentlyActiveTabPopup: undefined,
	toplevelElement: undefined,
	isIE: (new RegExp("MSIE (\\d+)\\.(\\d+)", "i").exec(navigator.appVersion) != null),
	isIE6: (new RegExp("MSIE 6\\.(\\d+)", "i").exec(navigator.appVersion) != null),
	isIE7: (new RegExp("MSIE 7\\.(\\d+)", "i").exec(navigator.appVersion) != null),
	isIE8: (new RegExp("MSIE 8\\.(\\d+)", "i").exec(navigator.appVersion) != null),
	isIE9: (new RegExp("MSIE 9\\.(\\d+)", "i").exec(navigator.appVersion) != null),
	isIE10: (new RegExp("MSIE 10\\.(\\d+)", "i").exec(navigator.appVersion) != null),
	isIE11: (new RegExp("MSIE 11\\.(\\d+)", "i").exec(navigator.appVersion) != null),
	isOpera : /Opera[\/\s](\d+\.\d+)/.test(navigator.userAgent),
	isFirefox :/Firefox[\/\s](\d+\.\d+)/.test(navigator.userAgent),
	isChrome : navigator.userAgent.toLowerCase().indexOf('chrome') > -1,
	isIPad: (navigator.userAgent.match(/iPad/i) != null),
	isIPhone: (navigator.userAgent.match(/iPhone/i) != null),
	portal: "classic",
	cloudComputeCalls: 0,

	getToplevelElement: function() {
		if (!global.toplevelElement) {
			global.toplevelElement = Element.down(document.body, 'form');
			if (!global.toplevelElement)
				global.toplevelElement = document.body;
		}
		return global.toplevelElement;
	},

	DOMReadyConstruct: function (clazz, el, options) {
		jQuery(document).ready(function () {
			new clazz(el, options);
		});
	},
	
	DOMReadyActions: function () {
		global.toplevelElement = Element.down(document.body, 'form');

		if (!global.toplevelElement)
			global.toplevelElement = document.body;

		global.window = new Window(window);

		document.fire("document:allSet");
	},
	
	// scales the window depending on the width of the window
	// works only for chrome so far
	scaleScreen: function(){
		
		var val = window.innerWidth/1280.0;
		
		var b = document.getElementsByTagName("html")[0];

		b.setAttribute("style", "zoom: "+val+";");

		
	},

	onLoadActions: function() {
		Event.stopObserving(window, 'load', global.onLoadActions);
		Event.observe(window, 'unload', global.onUnloadActions);

		global.prepareBlinkingTitle();
	},

	onUnloadActions: function() {
		global.toplevelElement = null;
		global.currentlyActiveTabPopup = null;
		global.tagCloud = null;
		global.searchableList = null;
		Event.stopObserving(window, 'unload', global.onUnloadActions);
	},
	
	prepareBlinkingTitle: function() {
		Element.observe(document, 'blur', global.onBlur);
		Element.observe(document, 'focus', global.onFocus);
		if (global.isIE) {
			document.onfocusin = global.onFocus;
			document.onfocusout = global.onBlur;
		}
		global.oldTitle = document.title;
	},

	onBlur: function(ev) {
		global.isBackground = true;
	},

	onFocus: function(ev) {
		global.isBackground = false;
	},

	blinkTitle: function() {
		if (!global.isBackground) {
			if (typeof(global.oldTitle) != 'undefined')
				document.title = global.oldTitle;
			return;
		}
		var title = document.title;
		title = title.substr(1) + title.substr(0, 1);
		document.title = title;

		window.setTimeout(global.blinkTitle, 100);
	},

	registerTagSearchableList: function(el) {
		global.searchableList = el;
	},

	findTagSearchableList: function(el) {
		return global.searchableList;
	},

	registerTagCloud: function(el) {
		global.tagCloud = el;
	},

	findTagCloud: function(el) {
		return global.tagCloud;
	},

	hhmmss: function(secs) {
		time = [0, 0, secs];
		for (var i = 2; i > 0; i--) {
			time[i - 1] = Math.floor(time[i] / 60);
			time[i] = Math.round(time[i] % 60);
			if (time[i] < 10)
				time[i] = '0' + time[i];
		};
		return time.join(':')
	},

	mmss: function(secs) {
		time = [0, secs];
		for (var i = 1; i > 0; i--) {
			time[i - 1] = Math.floor(time[i] / 60);
			time[i] = Math.round(time[i] % 60);
			if (time[i] < 10)
				time[i] = '0' + time[i];
		};
		return time.join(':')
	},

	isTouchDevice: function () {
		return 'ontouchstart' in window || navigator.maxTouchPoints;
	},

	hasMouseSupport: function() {
		// https://stackoverflow.com/a/50687322
		return matchMedia('(pointer:fine)').matches;
	},
};

jQuery(document).ready(global.DOMReadyActions);
Event.observe(window, 'load', global.onLoadActions);

//Find current portal
Element.down(document.documentElement, 'head').select('meta').each(function (meta) {
	if (meta.readAttribute('name') == 'portal') {
		global.portal = meta.readAttribute('content');
	}
});
