function DesignstudioEventHandler() {

	this.letterPosition = function (event) {

		if (controlpanel.letterDrag == activeDrag.NO) {
			return;
		}

		setClickXandY(event, "item");
		designitem.draft_stash();

		var ctx = freestyleCanvas.getContext("2d");
		ctx.fillStyle = controlpanel.chosenColor.get();
		ctx.font = (8*controlpanel.brushsize/designData.XScale).toString().concat("px Comic Sans MS");
		ctx.fillText(controlpanel.letters[controlpanel.letterIdx], clickcoords.item_x, clickcoords.item_y);
		controlpanel.letterDrag = activeDrag.NO;
		controlpanel.paintAllControlElements(-1, -1);
	}

	function setClickXandY(event, canvas) {
		var rect;

		// css transformation scales down designstudio for small screen sizes. Here we adjust our clickhandlers
		var scale = parseFloat(document.getElementById("contentcontent_designstudioeditor").style.transform.substring(6, 13))

		if (canvas == "control") {
			rect = controlCanvas.getBoundingClientRect();
			clickcoords = {
				ctrl_x: (event.clientX - rect.left) / scale,
				ctrl_y: (event.clientY - rect.top) / scale,
				item_x: -1,
				item_y: -1
			}
		}
		else if (canvas == "item") {
			rect = clicklayer.getBoundingClientRect();
			clickcoords = {
				ctrl_x: -1,
				ctrl_y: -1,
				item_x: (event.clientX - rect.left) / designData.XScale / scale,
				item_y: (event.clientY - rect.top) / designData.YScale / scale
			}
			if (event.type == "touchend") {
				clickcoords.item_x = (event.changedTouches[0].clientX - rect.left) / designData.XScale / scale;
				clickcoords.item_y = (event.changedTouches[0].clientY - rect.top) / designData.YScale / scale;
			}
		}

	}

	this.objectPositionImage = function (event) {
		if (controlpanel.imageDrag == activeDrag.NO) {
			return;
		}
		setClickXandY(event, "item");

		designitem.draft_stash();
		var ctx = freestyleCanvas.getContext("2d");
		var img = controlpanel.chosenpicture;
		ctx.drawImage(img, clickcoords.item_x - 8 * controlpanel.brushsize/designData.XScale,
			clickcoords.item_y - 8 * controlpanel.brushsize/designData.YScale,
			16 * controlpanel.brushsize/designData.XScale,
			16 * controlpanel.brushsize/designData.YScale);
	}

	this.getPosition = function (event) {
		setClickXandY(event, "item");

		if ((controlpanel.paintMode == currentMode.DRAWPOINT
			|| controlpanel.paintMode == currentMode.ERASE)
			&& controlpanel.imageDrag == activeDrag.NO
			&& controlpanel.letterDrag == activeDrag.NO) {
			designitem.draft_stash();
			addFreePoint(clickcoords.item_x, clickcoords.item_y, false);
			activePaint = true;
			repaintItemArea();
		} else {
			paintAll(clickcoords);
		}
	}

	this.control_getPosition = function (event) {
		setClickXandY(event, "control");
		paintAll(clickcoords);
	}

	this.importColorFromColorPicker = function () {

		controlpanel.chosenColor.chooseColorpickerGradient();

		var ctx = document.getElementById("gradXtarget2").getContext("2d");
		var width = designitem.width;
		var height = designitem.height;
		controlpanel.chosenColor.createGradient(ctx, width/2, height/2, width, false);
		controlpanel.paintAllControlElements(-1, -1);
	}

	this.paintDeactivate = function (event) {
		activePaint = false;
	}

	function paintMove(event) {
		ctx = objecthoverCanvas.getContext("2d");
		ctx.clearRect(0, 0, designitem.width, designitem.height);
		setClickXandY(event, "item");

		if (activePaint) {
			addFreePoint(clickcoords.item_x, clickcoords.item_y, true);
			paintFreePoints(freestyleCanvas.getContext("2d"), "source-over");
		}
		else if (controlpanel.letterDrag == activeDrag.YES) {
			repaintItemArea();
			ctx = objecthoverCanvas.getContext("2d");
			ctx.clearRect(0, 0, designitem.width, designitem.height);
			ctx.fillStyle = controlpanel.chosenColor.get();
			ctx.font = (8*controlpanel.brushsize/designData.XScale).toString().concat("px Comic Sans MS");
			ctx.fillText(controlpanel.letters[controlpanel.letterIdx], clickcoords.item_x, clickcoords.item_y);
		}
		else if (controlpanel.imageDrag == activeDrag.YES) {
			repaintItemArea();
			ctx = objecthoverCanvas.getContext("2d");
			ctx.clearRect(0, 0, designitem.width, designitem.height);
			var img = controlpanel.chosenpicture;
			ctx.drawImage(img, clickcoords.item_x - 8 * controlpanel.brushsize/designData.XScale,
				clickcoords.item_y - 8 * controlpanel.brushsize/designData.YScale,
				16 * controlpanel.brushsize/designData.XScale,
				16 * controlpanel.brushsize/designData.YScale);
		}
	}

	this.selectLetter = function (event) {
		var backup = controlpanel.letterIdx;
		controlpanel.letterIdx = controlpanel.letters.indexOf(String.fromCharCode(event.keyCode));
		if (controlpanel.letterIdx == -1) {
			controlpanel.letterIdx = backup;
		}
		controlpanel.letterDrag = activeDrag.YES;
		paintAll();
	}

	this.handlerMousemove = function (event) {
		paintMove(event);
	}

	this.handlerTouchmove = function (event) {
		event.preventDefault();
		event.clientX = event.touches[0].clientX;
		event.clientY = event.touches[0].clientY;
		setClickXandY(event, "item");

		// most touch devices (especially with iOS) don't throw an explicit onClick/touchDown event - catch and detect these cases here while touchmove
		if (controlpanel.imageDrag == activeDrag.NO && controlpanel.letterDrag == activeDrag.NO && activePaint == false
			&& (controlpanel.paintMode == currentMode.DRAWPOINT || controlpanel.paintMode == currentMode.ERASE)) {
			addFreePoint(clickcoords.item_x, clickcoords.item_y, false);
			paintFreePoints(freestyleCanvas.getContext("2d"), "source-over");
			activePaint = true;
		}

		paintMove(event);
	}
}