function waitForElementToBePresent(selector) {
	return new Promise(function (resolve) {
		if (document.querySelector(selector)) {
			return resolve(document.querySelector(selector));
		}
		var observer = new MutationObserver(function() {
			if (document.querySelector(selector)) {
				observer.disconnect();
				resolve(document.querySelector(selector));
			}
		})
	
		observer.observe(document.body, {
			childList: true,
			subtree: true
		});
	})
}
