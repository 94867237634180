/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="GamesRace.js" />
/// <reference path="ToggleQuestionReader.js" />
/// <reference path="gameUtils/GamesTextToSpeech.js" />
/// <reference path="../../../node_modules/soundmanager2/script/soundmanager2-nodebug.js" />
/// <reference path="gameUtils/UpScaleImageHandler.js" />

var GamesMultiplayerQuizInstance = null;
var GamesQuizInstance = null;

var GamesMultiplayerQuiz = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);

		GamesMultiplayerQuizInstance = this;
		GamesQuizInstance = this;
		this.upScaledImageHandler = new UpScaledImageHandler(this);
		this.container = this.el.down('.questionContainer');
		this.message = this.el.down('span.message');
		this.tpReportQuestion = $(el).down('.tpReportQuestion');

		this.beginSound = null;

		this.displayMath = this.options.DisplayMath;
		this.instance = this.options.instance;

		this.playFeedbackSound = this.options.FeedbackSoundOn;
		if (GenericSoundsInstance == null) {
			new GenericSounds(this.playFeedbackSound);
		}
		this.readQuestions = this.options.ReadQuestionsOn && this.options.TextToSpeechEnabled;
		if (GamesTextToSpeechInstance == null) {
			new GamesTextToSpeech(this, this.readQuestions, this.options.TextToSpeechEnabledByParent);
		}
		if (ToggleFeedbackSoundInstance && this.options.TextToSpeechEnabled) {			
			ToggleFeedbackSoundInstance.url = this.options.ToggleFeedbackSoundUrl;
		}
		if (ToggleQuestionReaderInstance && this.options.TextToSpeechEnabled) {
			ToggleQuestionReaderInstance.url = this.options.ToggleQuestionReaderUrl;
			ToggleQuestionReaderInstance.showCorrectImage();
		}
		
		this.position = 0;
		this.questionNumber = 0;
		this.lockPageLoad = false;

		var _this = this;
		var finishInitialization = function () {
			if (_this.playFeedbackSound) {
				_this.sound();
			}
			if (_this.options.useList) {
				_this.safeObserve(el, 'click', _this.onListClick.bindAsEventListener(_this));
			} else {
				_this.safeObserve(el, 'click', _this.onSubmitButtonClick.bindAsEventListener(_this));
				_this.safeObserve(el, 'keypress', _this.onKeypress.bindAsEventListener(_this));
			}

			_this.handleNextPageLoad();
		};

		if (soundManager.ok() || (!this.readQuestions && !this.playFeedbackSound)) {
			finishInitialization();
		} else {
			soundManager.onready(finishInitialization);
		}

		if (this.options.playingAgainstAki) {
			this.opponent = new GamesRaceOpponent(this.options.akiMinTimeout, this.options.akiMaxTimeout, this.options.akiProbabilityCorrect, this.options.endPosition, this.options.akiAnswerUrl);
			this.opponentAlive = true;
		}
	},


	sound: function () {
		this.beginSound = soundManager.createSound({ id: 'succ', url: '/snd/first_call.mp3' });
		this.beginSound.play({ onfinish: function () { this.destruct(); } });
	},

	onSubmitButtonClick: function (ev) {
		if (this.locked)
			return;

		var btn = ev.findElement('.submitButton');
		if (!btn) {
			this.handleOtherClick(ev);
			return;
		}

		this.processInputSolution();
	},

	/*
	 * This is called by ToggleQuestionReader when it turns off TTS.
	 * Don't remove or rename.
	 */
	removeTextToSpeechEffects: function () {
		soundManager.stopAll();
		if (this.beginSound !== undefined && this.beginSound !== null) {
			this.beginSound.destruct();
		}
		GamesTextToSpeechInstance.removeTextToSpeechEffects();
	},

	onKeypress: function (ev) {
		if (this.locked)
			return;
		if (ev.keyCode == 13) {
			this.processInputSolution();
			ev.stop();
		}
	},

	processInputSolution: function () {
		this.locked = true;
		this.lockPageLoad = true;

		this.success = this.checkInputSolution();

		this.el.down('.solutionInput').hide();

		this.playSound();

		if (this.success) {
			this.el.down('.dispIfSolutionCorrect').show();
		} else {
			this.el.down('.dispIfSolutionIncorrectWithCorrectSolution').show();
		}

		if (this.success) {
			if (this.position < this.options.endPosition - 2) {
				this.position += 2;
			}
			else {
				this.position = this.options.endPosition;
				this.opponentAlive = false;
				this.container.parentNode.setStyle({ backgroundColor: this.options.dullred });
			}
		}
		else if (this.position != 0) {
			this.position--;
		}
		GamesRaceInstance.moveDragonTo(0, this.position);
		this.questionNumber++;
		if (this.questionNumber == this.options.endPosition) {
			GamesRaceInstance.takeDragonDown(0, this.position);
		}

		new Ajax.Request(new UrlTemplate(this.options.processAnswerUrl).r('PLH_success', this.success).r('PLH_nquestion', -2).url, {
			method: 'get',
			onSuccess: this.onSuccess.bind(this),
		});
	},

	checkInputSolution: function () {
		var i = 0;
		var input = this.el.down('.solutionInput').down('input', i);
		var solution = this.el.down('.correctSolution').down('input', i);

		while (input != undefined && solution != undefined) {
			if (input.value.gsub(/\s+/, '') != solution.value) {
				return false;
			}
			++i;
			input = this.el.down('.solutionInput').down('input', i);
			solution = this.el.down('.correctSolution').down('input', i);
		}
		return true;
	},

	handleOtherClick: function (ev) {
		var button = ev.findElement('input');
		if (!button)
			return;
		ev.stop();
		if (typeof (this.tpReportQuestion) != 'undefined') { // quickfix to prebent undefined access error from happening
			this.tpReportQuestion.setStyle({ display: 'block' });
		}
	},

	onListClick: function (ev) {
		if (this.locked)
			return;

		this.lockPageLoad = true;

		var li = ev.findElement('li');
		if (!li) {
			this.handleOtherClick(ev);
			return;
		}
		if (!li.up('ol, ul'))
			return;

		this.removeTextToSpeechEffects();

		if (li.hasClassName('solution'))
			$(li).setStyle({ backgroundColor: 'green' });
		else
			$(li).setStyle({ backgroundColor: 'red' });
		this.locked = true;

		var question = this.el.down('.question').innerHTML;
		var solution = this.el.down('.solution').innerHTML;

		var message;
		this.success = li.hasClassName('solution');

		if (!this.success) {
			this.flashInterval = setInterval(this.flashCorrectSolution.bind(this), 250);
		}

		this.playSound();

		if (this.success) {
			if (this.position < this.options.endPosition - 2) {
				this.position += 2;
			}
			else {
				this.position = this.options.endPosition;
				this.opponentAlive = false;
				this.container.parentNode.setStyle({ backgroundColor: this.options.dullred });
			}
		}
		else if(this.position != 0){
			this.position--;
		}
		this.questionNumber++;
		if (this.questionNumber == this.options.endPosition && this.position != this.options.endPosition) {
			GamesRaceInstance.takeDragonDown(0, this.position);
		} else {
			GamesRaceInstance.moveDragonTo(0, this.position);
		}


		new Ajax.Request(new UrlTemplate(this.options.processAnswerUrl).r('PLH_success', this.success).r('PLH_nquestion', this.pageLoadJSON.questionID).url, {
			method: 'get',
			onSuccess: this.onSuccess.bind(this),
		});
	},

	isOpponentAlive: function() {
		return this.opponentAlive;
	},

	handleProcessResult: function () {
		if (!this.success) {
			setTimeout(this.handleNextPageLoad.bind(this), 3000);
		} else {
			setTimeout(this.handleNextPageLoad.bind(this), 1500);
		}
	},

	flashCorrectSolution: function () {
		var sol = this.el.down('.solution');
		if ($(sol).getStyle('backgroundColor') == 'green') {
			$(sol).setStyle({ backgroundColor: this.prevBgColor });
		} else {
			this.prevBgColor = sol.style.backgroundColor;
			$(sol).setStyle({ backgroundColor: 'green' });
		}
	},

	handleNextPageLoad: function () {
		clearInterval(this.flashInterval);
		new Ajax.Request(this.getPageLoadUrl(), {
			method: 'get',
			onSuccess: this.onPageLoaded.bind(this),
		});
	},

	handleNextSpecificPageLoad: function (messageInstance) {
		//handling race condition: opponent may already have answered the question correctly, so we do not need to load this
		if(!this.lockPageLoad && messageInstance == this.instance) {
			clearInterval(this.flashInterval);
			new Ajax.Request(this.getPageLoadUrl(), {
				method: 'get',
				onSuccess: this.onPageLoaded.bind(this),
			});
		}
	},

	getPageLoadUrl: function () {
		return new UrlTemplate(this.options.loadUrl).url;
	},

	onSuccess: function (req) {
		this.json = req.responseText.evalJSON();
		this.handleProcessResult();
	},

	onPageLoaded: function (req) {
		try {
			this.pageLoadJSON = req.responseText.evalJSON();
		} catch (e) {
			global.handleFailedAJAJRequest();
			return;
		}
		if (this.customLoadEvent()) {
			this.showPage();
			this.locked = false;
			this.lockPageLoad = false;
			if (!this.options.useList) {
				if (typeof (this.el.down('.solutionInput')) != 'undefined') { // quickfix to prebent undefined access error from happening
					this.el.down('.solutionInput').down('input').focus();
				}
			}
		}
	},

	customLoadEvent: function () {
		return true;
	},

	showPage: function () {
		if (typeof this.pageLoadJSON == 'undefined') {
			return;
		}

		this.container.innerHTML = this.pageLoadJSON.page;
		if (typeof this.el.down('.question2') != "undefined" && this.el.down('.question2') != "") {
			var questiontext = this.el.down('.question2').firstChild.firstChild;
			var questionbox = this.el.down('.question2');
			var questionContent = questiontext;
			var questionParent = questiontext.parentNode;
			while (typeof (questionContent.innerHTML) !== 'undefined') {
				questionParent = questionContent;
				questionContent = questionContent.innerHTML;
			}

			if (questionContent.length < 400) {
				$(questionParent).setStyle({ fontSize: '30px', lineHeight: '30px', align: 'center' });

				if (160 < questionParent.scrollHeight) {
					$(questionParent).setStyle({ fontSize: '25px' });
				}
				if (160 < questionParent.scrollHeight) {
					$(questionParent).setStyle({ fontSize: '20px' });
				}
				$(questionbox).setStyle({ paddingTop: ((210 - questionParent.offsetHeight) / 2).toString() + 'px' });
			}
		}
		if (PlayPauseButtonInstance != null && this.pageLoadJSON.soundFile) {
			PlayPauseButtonInstance.setSoundUrl(this.pageLoadJSON.soundFile, true, true);
		}

		this.renderMath();

		// Only if there exists a question element we are displaying a question;
		// only then we should read the question and its answers.
		if (this.el.down('.question')) {
			this.createAndReadAllTextToSpeech();
		}
		this.upScaledImageHandler.initializeListenerForZoomInQuestionImg();
	},

	/*
	 * This is called by ToggleQuestionReader when it turns on TTS.
	 * Don't remove or rename.
	 */
	createAndReadAllTextToSpeech: function () {
		GamesTextToSpeechInstance.setTextToSpeechElements(this.createTextToSpeechElements());
		GamesTextToSpeechInstance.readSpeechForAllElementsInOrder();
	},

	createTextToSpeechElements: function () {
		var textToSpeechElements = [];

		textToSpeechElements.push(this.createQuestionElement());
		for (var i = 0; i < this.pageLoadJSON.answerPaths.length; i++) {
			textToSpeechElements.push(this.createAnswerElement(i));
		}

		return textToSpeechElements;
	},

	createQuestionElement: function () {
		var questionElement = {};

		questionElement.domElement = this.el.down('.question');
		questionElement.identifier = 'question';
		questionElement.soundUrl = this.pageLoadJSON.questionPath;
		questionElement.highlightFontColor = '#7F82D2';
		questionElement.defaultFontColor = "white";

		return questionElement;
	},

	createAnswerElement: function (answerNumber) {
		var answerElement = {};

		answerElement.domElement = this.el.down('.answer' + answerNumber);
		answerElement.identifier = 'answer' + answerNumber;
		answerElement.soundUrl = this.pageLoadJSON.answerPaths[answerNumber];
		answerElement.highlightFontColor = '#7F82D2';
		answerElement.defaultFontColor = "white";

		return answerElement;
	},

	getBackgroundColor: function (className) {
		var element = this.el.down(className);
		if (typeof (element) !== 'undefined' && element !== null) {
			return $(element).getStyle('background-color');
		} else {
			return '';
		}
	},

	getFontColor: function (className) {
		var element = this.el.down(className);
		if (typeof (element) !== 'undefined' && element !== null) {
			return $(element).getStyle('color');
		} else {
			return '';
		}
	},

	playSound: function () {
		if (this.success) {
			GenericSoundsInstance.successSound();
		}
		else {
			GenericSoundsInstance.failSound();
		}
	},

	// Ensures that all LaTeX math content is correctly rendered
	renderMath: function () {
		if (this.displayMath) {
			MathJax.Hub.Typeset();
		}
	},
});
