/// <reference path="../../SoundPlayer/soundmanager2-nodebug.js" />

/*
 * Convenience class for text to speech: 
 * This can be used to control a number of different sound files
 * identified by unique identifiers. You simply have to pass in
 * a dictionary mapping identifiers to sound urls.
 */
var TextToSpeech = Class.create({
	initialize: function () {
		this.sounds = {};
	},

	/*
	 * Call this to set the speech sounds that should be played by this instance.
	 * identifierToSoundUrlDictionary is a dictionary mapping unique identifiers
	 * to a url for the corresponding speech sound file. The given identifier can
	 * then be used to play the corresponding sound.
	 */
	setSounds: function (identifierToSoundUrlDictioary) {
		this.destroyAllExistingSounds();
		this.createSounds(identifierToSoundUrlDictioary);
	},

	/*
	 * Call this to play a specific sound. onFinished is executed as soon as the
	 * sound finishes playing.
	 */ 
	playSpeechForTextIdentifier: function (identifier, onFinished) {
		var sound = this.sounds[identifier];
		if (sound) {
			sound.play({
				onfinish: function () {
					this.stop();
					onFinished();
				}
			});
		} else {
			console.log("No sound found for identifier " + identifier + ".");
		}
	},

	/*
	 * Call this to stop playing a specific sound.
	 */
	stopSpeechForTextIdentifier: function (identifier) {
		var sound = this.sounds[identifier];
		if (sound) {
			sound.stop();
		} else {
			console.log("No sound found for identifier " + identifier + ".");
		}
	},

	/* 
	 * Call this to silence any speech that might be playing.
	 */
	stopAllExistingSounds: function () {
		for (var identifier in this.sounds) {
			this.sounds[identifier].stop();
		}
	},

	/*
	 * Private, don't call.
	 */
	destroyAllExistingSounds: function () {
		for (var identifier in this.sounds) {
			this.sounds[identifier].destruct();
		}
	},

	/*
	 * Private, don't call.
	 */
	createSounds: function (identifierToSoundUrlDictioary) {
		this.sounds = {};
		for (var identifier in identifierToSoundUrlDictioary) {
			var soundUrl = identifierToSoundUrlDictioary[identifier];
			this.sounds[identifier] = soundManager.createSound({ id: identifier, url: soundUrl });
		}
	}
});
