/// <reference path="DOMAttached.js" />

function confirmAI(message, onConfirm, onCancel, showConfirm, showCancel, options) {
	var choices = [];
	if (showConfirm) {
		var confirmButton = {
			value: true
		};

		if (typeof (options) == "object" && options.hasOwnProperty("confirmBtnCaption")) {
			confirmButton.label = options.confirmBtnCaption;
		} else {
			confirmButton.label = "Ok";
		}
		choices.push(confirmButton);
	}
	if (showCancel) {
		var cancelButton = {
			value: false
		};

		if (typeof (options) == "object" && options.hasOwnProperty("cancelBtnCaption")) {
			cancelButton.label = options.cancelBtnCaption;
		} else {
			cancelButton.label = "Cancel";
		}
		choices.push(cancelButton);
	}
	MiaPrompt(message, choices).then(function (isConfirmed) {
		if (isConfirmed) {
			onConfirm();
		} else {
			onCancel();
		}
	});
}