/// <reference path="GNOMList.js" />

var SharedListInstance;
var SharedList = Class.create(GNOMList, {
	initialize: function($super, el, options) {
		$super(el, Object.extend({ pagingEffect: 'horizSlide', noProgressIndicator: true }, options));
		SharedListInstance = this;
	},

	loadPageWithCustomUrl: function (page, customUrl) {
		this.customUrl = customUrl;
		this.loadPage(page);
	},

	loadPage: function($super, page) {
		$super(page, this.customUrl);
	}
});
