/// <reference path="GamesFlashQuiz.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="TextResource.js" />
/// <reference path="gameUtils/EasierLevel.js" />

var GamesLeveledFlashQuiz = Class.create(GamesFlashQuiz, {
	initialize: function($super, el, options) {
		$super(el, options);
		this.level = this.options.level;
		this.progress = this.options.progress;
		this.setLevelInformation();

		this.blinkLength = this.options.blinkLength;
		this.nrOfBlinks = this.options.nrOfBlinks;
		this.isArcade = this.options.isArcade;

		this.modalId = 'levelUpModal';
		this.modalJQuery = jQuery('.modal[modal-id=' + this.modalId + ']');
		this.medalBox = $(this.options.medalBox).down('img');
		this.levelText = $(this.options.levelText);
		this.progressBar = $(this.options.progressBar);
		this.menuBar = document.getElementsByClassName("menubar")[0];
		this.easierLevel = new EasierLevel(this.options);

		if (options.nomedal == "true" || this.medalBox.src == "")
			this.medalBox.style.display = "none";

		this.setTaskText(new TextResource(this.options.trLoadingLevel).string());

		this.modalJQuery.find('.image').css("display", "none");
		this.modalJQuery.find('.imageCaption').css("display", "none");

		this.easierLevel.initializeEasierLevel(this);
		waitForElementToBePresent(".mia-easierLevelConfirmButton").then(this.initializeLevelDown.bind(this));

		this.modalJQuery.find('.levelUpButton').on('click', this.onNextLevelOkClick.bind(this));
		this.modalJQuery.find('.lastLevelContinueButton').on('click', this.onNextLevelOkClick.bind(this));

		removeFocusFromModalInWindow(this.modalId);
	},

	setLevelInformation: function () {
		var nextMedalLevel = this.options.nextMedalLevel;
		if (nextMedalLevel > 0 && !(this.options.noNextMedal == "true")) {
			this.el.down('.nextMedalArea').style.display = "inline-block";
			this.el.down('.nextMedalText').innerHTML = 'after Level: ' + nextMedalLevel;
		}
		else {
			this.el.down('.nextMedalArea').style.display = "none";
		}
	},

	customLoadEvent: function (json) {
	},

	showPage: function () {
		this.updateProgressBar();
		this.levelText.innerHTML = 'Level: ' + this.json.level;
		this.easierLevel.initializeEasierLevel(this);
		var nextMedalLevel = this.json.nextMedalLevel;
		if (nextMedalLevel > 0 && !this.json.noNextMedal) {
			this.el.down('.nextMedalArea').style.display = "inline-block";
			var realNextMedalLevel = nextMedalLevel;
			if (realNextMedalLevel > 0 && realNextMedalLevel < this.json.level) {
				realNextMedalLevel = this.json.level;
			}
			this.el.down('.nextMedalText').innerHTML = 'after Level: ' + realNextMedalLevel;
		}
		else {
			this.el.down('.nextMedalArea').style.display = "none";
		}
	},

	initializeLevelDown: function(element) {
		this.safeObserve(element, 'click', this.onLevelDownClick.bindAsEventListener(this));
	},

	onNextLevelOkClick: function (ev) {
		if (typeof (ev) != 'undefined')
			ev.preventDefault();

		if (this.json.medal != undefined && !this.json.medal.endsWith("games/medals/medal_none.svg")) {
			this.medalBox.src = this.json.medal;
			this.medalBox.style.display = "inline";
			this.modalJQuery.find('.infoText').text(new TextResource(this.options.trMedalMessage).r('{medal}', this.json.medalName).string());
			this.modalJQuery.find('.level').css("display", "none");
			this.modalJQuery.find('.image').css("display", "inline-block");
			this.modalJQuery.find('.image').attr("src", this.json.medal);
			if (this.json.openProdding != undefined && this.json.openProdding != "") {
				this.modalJQuery.find('.openProdding').html(this.json.openProdding);
			}
			this.json.medal = "games/medals/medal_none.svg";
			if (this.json.challengeWasCompleted) {
				this.modalJQuery.find('.levelButtons').css("display", "none");
				this.modalJQuery.find('.challengeCompletedButtons').css("display", "inline-block");
			}
			return;
		} else if (this.json.pet != undefined && this.json.pet != "") {
			this.modalJQuery.find('.infoText').html(this.options.trPetMessage);
			this.modalJQuery.find('.level').css("display", "none");
			this.modalJQuery.find('.image').attr("src", this.json.pet);
			this.modalJQuery.find('.image').css("display", "inline-block");
			this.modalJQuery.find('.imageCaption').html(this.json.petName);
			this.modalJQuery.find('.imageCaption').css("display", "block");
			this.modalJQuery.find('.openProdding').html("");
			this.json.pet = "";
			return;
		} else {
			this.modalJQuery.find('.image').css("display", "none");
			this.modalJQuery.find('.imageCaption').css("display", "none");
			this.modalJQuery.find('.openProdding').html("");
			if (this.json.level == 1) {
				this.modalJQuery.find('.infoText').html(new TextResource(this.options.trEndOfGame).r('{amount}', this.json.amount).r('{newreward}', this.json.newreward).string());
				this.modalJQuery.find('.level').css("display", "none");
				this.modalJQuery.find('.levelButtons').css("display", "none");
				this.modalJQuery.find('.endGameButtons').css("display", "block");
				if (this.isArcade) {
					this.modalJQuery.find('.returnToArcadeButton').css("display", "inline-block");
				} else {
					this.modalJQuery.find('.continueButton').css("display", "inline-block");
				}
				return;
			}
			this.startNextLevel();
		}
		this.handleProcessResult();
	},

	startNextLevel: function() {
		this.showPage();
		this.modalJQuery.find('.level').css("display", "block");
		this.modalJQuery.find('.levelButtons').css("display", "block");
		if (this.isArcade) {
			this.modalJQuery.find('.returnToArcadeButton').css("display", "none");
		} else {
			this.modalJQuery.find('.continueButton').css("display", "none");
		}
		this.modalJQuery.modal("hide");
		this.locked = false;
	},

	handleWrongAnswer: function() {
		var itemId = this.numberList[this.progress] | 0;
		if (this.progress > 0) {
			this.progress--;
			this.updateProgressBar();
		}

		var wrongAnswerPromise = new Promise(function(resolve, reject) {
			var url = new UrlTemplate(this.options.processAnswerUrl)
				.r('PLH_success', this.success)
				.r('PLH_Status', 2)
				.r('PLH_progress', this.progress)
				.r('PLH_itemId', itemId)
				.r('PLH_nquestion', -2).url;
			jQuery.ajax({ url: url }).done(resolve).fail(reject);
		}.bind(this));

		var blickingDelay = this.blinkLength * this.nrOfBlinks;
		new Promise(function (resolve) {
			setTimeout(resolve, blickingDelay);
		}).then(function() {
			this.setTaskText(new TextResource(this.options.trLoadingLevel).string());
			wrongAnswerPromise.then(this.onWrongAnswerSuccess.bind(this));
		}.bind(this));
	},

	onWrongAnswerSuccess: function(response) {
		this.json = response;
		if (this.json.numberList.length == 0 || this.json.textList[0] == undefined) {
			this.progress = 0;
			this.initFlashGame();
			return;
		}

		this.numberList = this.json.numberList;
		this.textList = this.json.textList;
		this.questionSoundPaths = this.json.questionSoundPaths;
		
		this.resetUI();
		this.setToClick(this.numberList[this.progress], this.textList[this.progress]);
		this.createAndReadAllTextToSpeech();
	},

	onSuccess: function (req) {
		this.json = req.responseText.evalJSON();

		var newLevel = 'Level: ' + this.json.level;

		//Level up
		if (this.progress == this.numberList.length && newLevel != this.levelText.innerHTML) {
			
			this.removeAllListeners();
			this.updateProgressBar();
			if (this.json.amount == 0) {
				new Ajax.Request(new UrlTemplate(this.options.processAnswerUrl)
					.r('PLH_success', true)
					.r('PLH_Status', 2)
					.r('PLH_progress', this.progress)
					.r('PLH_itemId', -1)
					.r('PLH_nquestion', -2).url, {
					method: 'get',
					onSuccess: this.onSuccess.bind(this)
				});
				return;
			}
			this.progress = 0;
			this.numberList = this.json.numberList;
			this.textList = this.json.textList;
			this.questionSoundPaths = this.json.questionSoundPaths;
			this.levelText.innerHTML = newLevel;
			this.modalJQuery.modal({ backdrop: "static", keyboard: false });
			this.locked = true;
			if (this.json.level == 1) {
				this.modalJQuery.find('.fiveLevelsUpButton').css("display", "");
				this.modalJQuery.find('.modal-header > h3').html(this.options.trCongratsTitle);
				this.onNextLevelOkClick();
				return;
			}

			this.modalJQuery.find('.infoText').html(new TextResource(this.options.trLevelReached)
					.r('{level}', this.json.level).r('{amount}', this.json.amount).string());
			this.modalJQuery.find('.level').html(newLevel);
			
			var nextMedalLevel = this.json.nextMedalLevel;
			if (nextMedalLevel > 0 && !this.json.noNextMedal) {
				this.el.down('.nextMedalText').innerHTML = 'after Level: ' + nextMedalLevel;
				this.el.down('.medalIcon').src = this.json.nextMedalImageSource;
				this.el.down('.nextMedalArea').style.display = "inline-block";
			}
			else {
				this.el.down('.nextMedalArea').style.display = "none";
			}
			this.modalJQuery.find('.modal-header > h3').html(this.options.trCongratsTitle);
		}
	},
	onLevelJumpSuccess: function (req) {
		try {
			this.json = req.responseText.evalJSON();
		} catch (e) {
			global.handleFailedAJAJRequest();
			return;
		}
		this.success = true;
		this.handleProcessResult();
		this.el.down('.modal-header').down(".mia-img").click();
		this.showPage();
	},
	onLevelDownClick: function(ev) {
		if (this.locked) {
			return;
		}
		this.easierLevel.setEasierLevel(this.options.levelJumpUrl, this.onLevelJumpSuccess.bind(this));
	},
});
