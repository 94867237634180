/// <reference path="DOMAttached.js" />
var marBottom;
var height, width;
var GNOMListItem = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		
		this.behaviour = options.behaviour;
		this.link = options.link;
		this.locked = false;
		
		if (this.el.down('.petImageContainer') != undefined && this.behaviour != 'None') {
			this.safeObserve(this.el.down('.petImageContainer'), 'click', this.onClick.bindAsEventListener(this));
		} else if (this.el.down('img') != undefined && this.behaviour != 'None') {
			this.safeObserve(this.el.down('img'), 'click', this.onClick.bindAsEventListener(this));
		}
	},
	
	onClick: function (ev) {
		if (this.el.up('.GNOMApplication') != undefined
			&& !this.el.up('.GNOMApplication').down('.tabbedApplication').attachedObj.blocked) {

			if (this.locked)
				return;
			
			if (this.el.up('.GNOMPage') != undefined && this.el.up('.GNOMPage').hasClassName('deactivated'))
				return;
			
			switch (this.behaviour) {
				case 'MultiSelect':
					if (this.el.hasClassName('selected')){
						this.el.removeClassName('selected');
					} else {
						this.el.addClassName('selected');
					}
					break;
				case 'Popup':
					GenericSoundsInstance.pickUpSound();
					this.el.up('.GNOMApplication').down('.popupContainer').attachedObj.showPopup(this.link);	
					break;
				case 'Link':
					GenericSoundsInstance.clickSound();
					this.el.up('.tabbedApplication').attachedObj.loadPage(this.link);
					break;
				case 'CallbackSelect':
					if (this.el.hasClassName('shared'))
						this.el.removeClassName('shared');
					else
						this.el.addClassName('shared');
				
					var list = this.el.up('.gnomList').attachedObj;
					this.locked = true;
					list.loadPage(list.options.currentPage, this.options.link.replace('PLH_switch', this.el.hasClassName('shared')));
					break;
				case 'Animation':
					this.el.up('.GNOMApplication').down('.animationContainer').attachedObj.loadAnimation(this.link, ev.clientX);
					break;
			}
		}
	},
	
	onLoadPage: function($super, pageEl) {
		this.locked = false;
	},

	onMouseOver: function(ev) {
		this.height = this.el.down('img').height;
		this.width = this.el.down('img').width;
		if(!this.el.up('.GNOMApplication').down('.tabbedApplication').attachedObj.blocked) {
			this.el.down('img').style.width = (this.width + 7) + 'px';
			this.el.down('img').style.height = (this.height + 7) + 'px';
			this.marBottom = this.el.getStyle('marginBottom');
			if(this.marBottom == '18px') {
				this.el.setStyle({marginBottom: '11px'});
			}
		}
	},

	onMouseOut: function(ev) {
		this.el.down('img').style.width = this.width + 'px';
		this.el.down('img').style.height = this.height + 'px';
		this.el.setStyle({marginBottom: this.marBottom});
	}
});