/// <reference path="DOMAttached.js" />
/// <reference path="../../SoundPlayer/soundmanager2-nodebug.js" />

var PlayPauseButtonInstance = null;

// Currently we only support having ONE PlayPauseButton on the whole site. 
var PlayPauseButton = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);
		PlayPauseButtonInstance = this;

		this.imgPlay = $(options.playButton);
		this.imgPause = $(options.pauseButton);
		if (options.soundUrl != null) {
			this.soundUrl = options.soundUrl;
		}
		this.autoplay = options.autoplay;
		this.playInfinite = options.playInfinite;

		var _this = this;

		var finishInitialization = function() {
			_this.safeObserve(_this.imgPlay, 'click', _this.play.bindAsEventListener(_this, _this.playInfinite));
			_this.safeObserve(_this.imgPause, 'click', _this.pause.bindAsEventListener(_this));

			if (_this.soundUrl != null) {
				_this.setSoundUrl(_this.soundUrl, _this.autoplay, _this.playInfinite);
			}
		}

		if (soundManager.ok()) {
			finishInitialization();
		} else {
			soundManager.onready(finishInitialization);
		}
	},

	play: function (infinitely) {
		var _this = this;
		this.displayPauseButton();
		if (infinitely) {
			this.playInfinitely(_this);
		} else {
			this.playOnce(_this);
		}
	},

	playInfinitely: function (obj) {
		if (!obj.sound || !obj.sound.id) {
			return;
		}

		soundManager.play(obj.sound.id,	{
			onfinish: function () {
				obj.delayedPlay = setTimeout(function () { obj.playInfinitely(obj) }, 1800);
			}
		});
	},

	playOnce: function (obj) {
		if (!obj.sound || !obj.sound.id) {
			return;
		}

		soundManager.play(obj.sound.id, {
			onfinish: function() {
				obj.displayPlayButton();
			}
		});
	},

	pause: function () {
		if (!this.sound || !this.sound.id) {
			return;
		}

		if (this.delayedPlay) {
			clearTimeout(this.delayedPlay);
		}
		this.displayPlayButton();
		var soundId = this.sound.id;
		soundManager.pause(soundId);
	},

	displayPlayButton: function() {
		this.imgPlay.style.display = 'block';
		this.imgPause.style.display = 'none';
	},

	displayPauseButton: function() {
		this.imgPause.style.display = 'block';
		this.imgPlay.style.display = 'none';
	},

	createSound: function (soundUrl) {
		return soundManager.createSound({ id: 'sound', url: soundUrl });
	},

	setSoundUrl: function (soundUrl, autoplay, repeatInfinitely) {
		if (!soundUrl) {
			return;
		}

		soundManager.destroySound('sound');
		this.soundUrl = soundUrl;
		this.sound = this.createSound(soundUrl);
		if (autoplay) {
			this.play(repeatInfinitely);
		} else {
			this.pause();
		}
	}
});
