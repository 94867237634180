var DOMAttached = Class.create({
	initialize: function(el, options) {
		this.observingUnload = false;
		this.listenerList = [];
		this.onUnloadActions = [];
		this.el = $(el);
		this.options = options;
		this.safeObserve(window, 'unload', this.onUnload.bind(this));
	},
	
	onUnload: function(ev) {
		this.listenerList.each(function(el) {
			if (el !== null && el.el !== null && $(el.el) !== null) {
				Element.stopObserving(el.el, el.evStr, el.func);
				delete (el.el);
				delete (el.func);
				el.el = null;
				el.func = null;
			}
		});
		this.listenerList.clear();
		this.onUnloadActions.each(function(it) {
			it();
			it = null;
		});
		this.onUnloadActions.clear();
		
		// Unlink the DOM element
		this.el = null;
	},
	
	safeObserve: function(el, evStr, func) {
		this.listenerList.push({el:el, evStr:evStr, func:func});
		Element.observe(el, evStr, func);
	},
	
	stopObserve: function(el, evStr) {
		this.listenerList.each(function(it) {
			if (it.el == el && it.evStr == evStr) {
				Element.stopObserving(it.el, it.evStr, it.func);
				it.el = null;
				it.func = null;
				it = null;
			}
		});
		this.listenerList = this.listenerList.compact();
	},
	
	stopObserveAllListeners: function(el) {
		this.listenerList.each(function(it) {
			if (it.el == el){
				Element.stopObserving(it.el, it.evStr, it.func);
				it.el = null;
			    it.func = null;
			    it = null;
			}
			//it.el = null;
			//it.func = null;
			//it = null;
		});
		this.listenerList = this.listenerList.compact();
	},
	
	registerUnloadAction: function(func) {
		this.onUnloadActions.push(func);			
	},
	
	removeElement: function(el) {
		this.purge(el);
		Element.remove(el);
  },

	purge: function(d) {
		var a = d.attributes, i, l, n;
		if (a) {
			l = a.length;
			for (i = 0; i < l; i += 1) {
				n = a[i].name;
				if (typeof d[n] === 'function') {
					d[n] = null;
				}
			}
		}
		a = d.childNodes;
		if (a) {
			l = a.length;
			for (i = 0; i < l; i += 1) {
				this.purge(d.childNodes[i]);
			}
		}
	},
});
