/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="Confirm.js" />

var FeedComment = Class.create(DOMAttached, {
    initialize: function ($super, el, options) {
	$super(el, options);
	deleteButton = this.el.down('.deleteBtn');
	if (deleteButton != undefined) {
	    this.safeObserve(deleteButton, 'click', this.onDeleteClick.bindAsEventListener(this, this.el));
	}
    },

    onDeleteClick: function (ev, comment) {
	ev.stop();
	this.dialogComment = comment;
	confirmAI(this.options.trReallyDelete, this.onDeleteConfirm.bind(this), this.onDeleteCancel.bind(this), true, true);
    },

    onDeleteConfirm: function () {
	var id = this.dialogComment.down(".id").innerHTML;

	new Ajax.Request(new UrlTemplate(this.options.deleteUrl).r("PLH_smsgc", id).url, {
	    method: 'get',
	    onSuccess: this.onDeleted.bind(this, this.dialogComment),
	});
    },

    onDeleteCancel: function() {
    },

    onDeleted: function (comment, res) {
	//this.hideProgressIndicator();

	var json = res.responseText.evalJSON();

	if (json.success)
	    comment.remove();
    },
})
