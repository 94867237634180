/// <reference path="GamesRace.js" />

/* MIT - May Induce Trouble */

var GamesRaceOpponent = Class.create({
	initialize: function (minTimeout, maxTimeout, probabilityCorrect, endPosition, processAnswerUrl) {
		var _this = this;
		this.position = 0;
		this.questionNumber = 0;
		this.endPosition = endPosition;
		
		this.minTimeout = minTimeout;
		this.maxTimeout = maxTimeout;
		this.probabilityCorrect = probabilityCorrect;
		
		this.processAnswerUrl = processAnswerUrl;
		this.timer = window.setTimeout(function () { _this.answer(); }, this.getTimeout());
	},
	
	getTimeout: function() {
		return this.minTimeout + Math.random() * (this.maxTimeout - this.minTimeout);
	},

	answer: function () {
		if (GamesMultiplayerQuizInstance.isOpponentAlive() && this.questionNumber != this.endPosition) {
			var _this = this;
			this.timeout = this.getTimeout();
			var correct = Math.random() < _this.probabilityCorrect;
			if (correct) {
				if (_this.position <= _this.endPosition - 2) {
					_this.position += 2;
				}
				else {
					_this.position = _this.endPosition;
				}
			}
			else {
				if (_this.position != 0) {
					_this.position--;
				}
			}
			
			GamesRaceInstance.moveDragonTo(1, _this.position);
			new Ajax.Request(new UrlTemplate(this.processAnswerUrl).r('PLH_success', correct).r('PLH_nquestion', -2).url, {
				method: 'get',
				onSuccess: this.onSuccess.bind(this),
			});
		}
	},

	onSuccess: function (req) {
		var _this = this;
		this.json = req.responseText.evalJSON();
		if (this.position == this.endPosition) {
			GamesMultiplayerQuizInstance.handleNextPageLoad();
		}
		else {
			clearTimeout(this.timer);
			this.questionNumber++;
			this.timer = window.setTimeout(function () { _this.answer() }, this.timeout);
			if (this.questionNumber == this.endPosition) {
				GamesRaceInstance.takeDragonDown(1, _this.position);
			}
		}
	}
});
