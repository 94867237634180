// This function can be applied if it's impossible to change height of an element using scss.
// For example it could happen when element is position:absolute and thus out of page workflow

// You can call this function either with or without parameter.

// When you call it without parameter it will compute maximum of the heights of blockForWhomChangeHeight's visible children
// and assign it to the parent(`blockForWhomChangeHeight`) block.

// When you call it with parameter searchRoot it will compute maximum of the heights of of searchRoot's visible children
// and assign it to the parent(`blockForWhomChangeHeight`) block.
function scaleParentHeigthToMaximumOfChildrenHeights(event) {
	var parent = jQuery(event.data.blockForWhomChangeHeight);
	var maxHeight = -1;
	var childHeight;
	
	if(!jQuery(event && event.data.searchRoot || parent).children(":visible").length) {
		var intervalId = setInterval(function() {
			if(jQuery(event && event.data.searchRoot || parent).children(":visible").length) {
				clearInterval(intervalId);
				trackAllScaleHeightToAbsoluteChildrenElements();
			}
		}, 50);
		return;
	}

	jQuery(event && event.data.searchRoot || parent).children(":visible").each(function (index, element) {
		jQuery(element).each(function () {
			// Get actual height of element after all transformations
			childHeight = this.getBoundingClientRect().height;
			if (childHeight > maxHeight) {
				maxHeight = childHeight
			}
		});
	});
	if (maxHeight !== -1) {
		parent.height(maxHeight);
	}

	showDataBelow();
}

// This function tracks all of the .mia-js-scaleHeightToAbsoluteChildren elements and
// 1. Applies for them function scaleParentHeigthToMaximumOfChildrenHeights
// 2. Set for them resize event with handler scaleParentHeigthToMaximumOfChildrenHeights and appropriate data
function trackAllScaleHeightToAbsoluteChildrenElements() {
	var elementsToChangeHeightFor = jQuery(".mia-js-scaleHeightToAbsoluteChildren");
	jQuery.each(elementsToChangeHeightFor, function (index, value) {
		var eventData;
		if (value.dataset && value.dataset.searchroot) {
			eventData = {
				blockForWhomChangeHeight: value,
				searchRoot: value.dataset.searchroot,
			};
		} else {
			eventData = {
				blockForWhomChangeHeight: value,
			};
		}
		scaleParentHeigthToMaximumOfChildrenHeights({ data: eventData });
	});
}

// As the height of .mia-js-scaleHeightToAbsoluteChildren is computed dynamically after all the js loaded
// content which goes after .mia-js-scaleHeightToAbsoluteChildren jumps down after the height is finally set.
// To avoid this behavior you can set .mia-u-initiallyHidden and .mia-dataBelowDynamicallyComputedElement classes
// to elements you want to hide. Then after height of .mia-js-scaleHeightToAbsoluteChildren is computed
// this function shows all following content.
function showDataBelow() {
	jQuery(".mia-u-initiallyHidden.mia-dataBelowDynamicallyComputedElement").show();
}

window.addEventListener('load', trackAllScaleHeightToAbsoluteChildrenElements);
jQuery(window).on("resize", trackAllScaleHeightToAbsoluteChildrenElements);
