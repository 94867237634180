/// <reference path="ExceptionHandling.js" />
/// <reference path="../ProgressIndicatingControl.js" />

var AssessmentMode = Class.create({
	/**
	 * The container is used to get access to the DOM.
	 */
	initialize: function (container, assessmentOptions) {
		this.container = container;
		this.assessmentOptions = assessmentOptions;
		this.el = this.container.el;

		this.showTextMediaInitially = false;

		this.restartOverlay = this.container.el.down('.restartOverlay');
		this.restartOverlay.hide();

		this.initializeRestartClickHandlers();
	},

	/**
	 * This is the method that is called from the quiz right before the next question 
	 * is displayed. The return value indicates whether the next question should be 
	 * displayed immediately.
	 */
	customLoadEvent: function () {
		return true;
	},

	onSendAnswerToServerSuccess: function (response, rejectPromise) {
		if (response && response.page) {
			this.container.pageLoadJSON = response;
			this.container.showPage();

			this.initializeTryAgainButtonClickHandler();
			// don't need to load the next page if it's already in response
			rejectPromise();
		}
	},

	initializeTryAgainButtonClickHandler: function () {
		var tryAgainButton = this.el.down('.tryAgain');
		if (tryAgainButton != undefined) {
			this.container.safeObserve(tryAgainButton, 'click', this.restartAssessmentGame.bindAsEventListener(this));
		}
	},

	initializeRestartClickHandlers: function () {
		this.container.safeObserve(this.el.down('.restartOverlayYesButton'), 'click', this.onRestartOverlayYesClick.bindAsEventListener(this));
		this.container.safeObserve(this.el.down('.restartOverlayNoButton'), 'click', this.onRestartOverlayNoClick.bindAsEventListener(this));
		this.container.safeObserve(this.el.down('.restartButton'), 'click', this.onRestartClick.bindAsEventListener(this));
	},

	onRestartOverlayYesClick: function (ev) {
		ev.stop();
		this.restartAssessmentGame();
	},

	onRestartOverlayNoClick: function (ev) {
		ev.stop();
		this.restartOverlay.hide();
	},

	onRestartClick: function (ev) {
		ev.stop();
		this.restartOverlay.show();
	},

	restartAssessmentGame: function (event) {
		if (event) {
			// Prevent target for clicking one more time
			jQuery(event.target).addClass("disabled");
		}
		this.restartOverlay.hide();

		new Ajax.Request(this.assessmentOptions.restartURL, {
			method: 'get',
			onSuccess: function(response) { 
				this.updateQuestion(response.responseText.evalJSON(), this.showTextMediaInitially); 
			}.bind(this.container),
		});
	}
});

AssessmentMode.addMethods(ProgressIndicatingControl);
AssessmentMode.addMethods(ExceptionHandling);
