/// <reference path="DOMAttached.js" />

var MoveAnimation = Class.create(DOMAttached, {

	initialize: function($super, el, options) {
		$super(el, options);
		
		this.nextTarget();
		this.el.style.left = this.xTarget + "px";
		this.el.style.top = this.yTarget + "px";
		this.nextTarget();
		this.el.style.visibility = "visible";
		
		setInterval(
			function(){
				var xCurrent = parseInt(this.el.style.left, 10);
				var yCurrent = parseInt(this.el.style.top, 10);
				var distance = Math.pow(this.xTarget-xCurrent, 2) + Math.pow(this.yTarget-yCurrent, 2);
				
				var angle = Math.atan2(this.yTarget - yCurrent, this.xTarget - xCurrent);
				var xCurrent = xCurrent + Math.cos(angle) * this.options.speed;
				var yCurrent = yCurrent + Math.sin(angle) * this.options.speed;
				var distance2 = Math.pow(this.xTarget-xCurrent, 2) + Math.pow(this.yTarget-yCurrent, 2);
				this.el.style.left = xCurrent + "px";
				this.el.style.top = yCurrent + "px";
				
				// change target?
				if (distance2 >= distance || distance2 < 4){
					this.nextTarget();
					if (this.target == 0 && this.options.jumpBack){
						this.el.style.left = this.xTarget + "px";
						this.el.style.top = this.yTarget + "px";
						this.nextTarget();
					}
				}
			}.bind(this), 
			this.options.framerate
		);
	},
	
	target: -1,
	xTarget: 0,
	yTarget: 0,
	
	nextTarget: function (){
		this.target = this.target + 1;
		eval("if(!this.options.x"+this.target+") this.target = 0;");
		this.xTarget = parseInt(eval("this.options.x"+this.target), 10);
		this.yTarget = parseInt(eval("this.options.y"+this.target), 10);
		
		if (this.xTarget == -1){
			this.xTarget = -(window.screen.availWidth - 1000) / 2 - 100;
		}
		if (this.xTarget == -2){
			this.xTarget = 1000 + (window.screen.availWidth - 1000) / 2 + 150;
		}
	}
	
});
